import React from 'react';
import { Box, Typography } from '@mui/material';
import OrderSection from './OrderSection';
import './style.css'
import { t } from 'i18next';

const Orders = () => {
    return (
        <Box className=''>
            <Box className="partner-title">
                <Typography variant="h2">{t("orders_title")}</Typography>
            </Box>
            < OrderSection />
        </Box>
    );
};

export default Orders;
