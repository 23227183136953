import { put, call } from "redux-saga/effects";
import axios from "../../axiosConfig";
import {
  LOGOUT_SUCCESS,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAILED,
} from "../Actions/AuthActions";
import { TOAST_SHOW } from "../Actions/ToastAction";

export function* LoginSaga(action) {
  try {
    const response = yield call(axios.post, `/common/signin`, action.payload);
    const { isAuthenticated, token, userType, id } = response?.data;
    const adminId = response?.data.otherInfo.AdminId
    const PartnerId = response?.data.otherInfo.PartnerId
    const ebrizaLinkedId = response?.data.otherInfo.ClientId
    if (response.status === 200 && isAuthenticated === true) {
      localStorage.setItem('adminId', adminId?adminId:'')
      localStorage.setItem('partnerId', PartnerId?PartnerId:'')
      localStorage.setItem('id', id);
      localStorage.setItem('userRole', userType);
      localStorage.setItem('ebrizaLinkedId', ebrizaLinkedId?ebrizaLinkedId:"");
      localStorage.setItem("token", token.split("Bearer ")[1]);
      const message = isAuthenticated && 'Logged In Successfully !';
      yield put({ type: LOGIN_SUCCESS, payload: { token, message, id} });
      yield put({
        type: TOAST_SHOW,
        payload: { message: 'Logged In Successfully !', severity: "success" },
      });
      window.location.replace("/");
    };
  } catch (e) {
    const message = e.response.status === 404 ? 'Something went wrong !' : e.response.data.message;
    yield put({ type: LOGIN_FAILED, payload: { message } });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: message,
        severity: "error",
      },
    });
  }
}

export function* AdminLoginSaga(action) {
  try {
    const response = yield call(axios.post, `/admins/signin`, action.payload);
    const { isAuthenticated, token } = response?.data;
    localStorage.setItem("token", token.split("Bearer ")[1]);
    if (action.payload.username === 'BNikolov' && action.payload.password === '12345') {
      localStorage.setItem('userRole', 'admin')
    }
    const message = isAuthenticated && 'Logged In Successfully !';
    yield put({ type: ADMIN_LOGIN_SUCCESS, payload: { token, message } });
    yield put({
      type: TOAST_SHOW,
      payload: { message: 'Logged In Successfully !', severity: "success" },
    });
    window.location.replace("/");
  } catch (e) {
    const message = e.response.status === 404 ? 'Something went wrong !' : e.response.data.message;
    yield put({ type: ADMIN_LOGIN_FAILED, payload: { message } });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: message,
        severity: "error",
      },
    });
  }
}

export function* LogoutSaga() {
  try {
    yield put({ type: LOGOUT_SUCCESS });
    yield put({
      type: TOAST_SHOW,
      payload: { message: "Logout Successfully!", severity: "success" },
    });
  } catch (e) {
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: "Something Went wrong please try again",
        severity: "error",
      },
    });
  }
}
