import React, { useEffect, useState } from "react";
import ControlledOpenSelect from "./ControlledOpenSelect";
import LongMenu from "./LongMenu";
import ReactVirtualizedTable from "./ReactVirtualizedTable";
import TipsReport from "./TipsReport";
import axios from '../../axiosConfig';
import OrderListReport from "./OrderListReport";
export default function Details({ heading, selectedIndex }) {
  const partnerId = localStorage.getItem('partnerId');
  const [reportData, setReportData] = useState();
  const [ordersList, setOrderList] = useState([]);
  const [tipsTotal, setTipsTotal] = useState();

  const [totalOrders, setTotalOrders] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [fromDates, setfromDates] = useState();
  const [toDates, setToDates] = useState();


  const handleDateChange = (fromDate, toDate) => {
    console.log("From Date:", fromDate);
    console.log("To Date:", toDate);
    let fromDateZeroB =  fromDate.toISOString().split('T')[0]+'T00:00:00.000Z'
    let toDateZeroB =  toDate.toISOString().split('T')[0]+'T23:59:59.999Z'
    setfromDates(fromDateZeroB)
    setToDates(toDateZeroB)
    setPage(1)
    // You can add additional logic here to handle the date changes
    fetchDetails(fromDateZeroB, toDateZeroB)
  };

  const downloadReport = async () => {
    if (fromDates && toDates) {
      try {
        // Make the API request to get the report data
        const response = await axios.get(`/partners/orders/export`, {
          params: {
            PartnerId: partnerId,
            start_date: fromDates,
            end_date: toDates,
          },
          responseType: 'blob' // Important: this tells axios to handle the response as a binary large object
        });
  
        // Create a URL for the file using the response data
        const url = window.URL.createObjectURL(new Blob([response.data]));
        
        // Create a temporary link element
        const link = document.createElement('a');
        link.href = url;
  
        // Set the download attribute to trigger the download with a specific file name
        link.setAttribute('download', `orders_report_${new Date().toISOString()}.xlsx`);
        
        // Append the link to the body
        document.body.appendChild(link);
        
        // Programmatically click the link to trigger the download
        link.click();
        
        // Clean up by removing the link after the download has been triggered
        document.body.removeChild(link);
  
        console.log("Report download triggered successfully.");
        
      } catch (error) {
        console.error("Failed to download report:", error.message);
      }
    } else {
      console.error("Start date and end date are required to download the report.");
    }
  };

  const fetchDetails = async (fromDate, toDate, page=1) => {

    if (selectedIndex == 0) {
      if(fromDate && toDate){

        let payload = {
          'PartnerId': partnerId,
          'start_date': fromDate,
          'end_date': toDate
        }
        let treportData = await axios.post(`/partners/orders/report_total`, payload)
        console.log("reportData..", treportData.data.data);
  
        setReportData(treportData.data.data)
      }

    } 
    else if (selectedIndex == 1) {
      if(fromDate && toDate){

        let payload = {
          'PartnerId': partnerId,
          'start_date': fromDate,
          'end_date': toDate
        }
        let treportData = await axios.post(`/partners/orders/total_tips`, payload)
        console.log("setTipsTotal..", treportData.data.data);
  
        setTipsTotal(treportData.data.data)
      }

    }
    else if (selectedIndex == 2) {
      // let payload = {
      //   'PartnerId': partnerId,
      //   'start_date': fromDate,
      //   'end_date': toDate,
      // }
      if(fromDate && toDate){

        let treportData = await axios.get(`/partners/orders/paginated?PartnerId=${partnerId}&start_date=${fromDate}&end_date=${toDate}&page=${page}`)
        console.log("ExportData..", treportData.data.orders);
  
        treportData.data.orders.map((ele) => {
          if (ele['tableIds'])
            ele['tableIds'] = JSON.parse(ele['tableIds'])[0]
        })
        setOrderList(treportData.data)
      }


    }
  }

  const handlePageChange = (newPage) => {
    console.log("newPage..newPage,",newPage);
    setPage(newPage)
    // Trigger a new API call when page or pageSize changes
    fetchDetails(fromDates, toDates, newPage);
  };


  return (
    <div className="h-screen flex flex-col">
      {/* Heading and Controls Container */}
      <div className="flex items-center justify-between" style={{marginTop: '-8px'}}>
        {/* Heading */}
        <h1 style={{fontSize: '19px'}} className="text-3xl font-semibold flex-shrink-0"> {heading} </h1>

        {/* Controls */}
        <div className="flex items-center gap-4">
          <ControlledOpenSelect placeholder="Select Period" onDateChange={handleDateChange} selectedIndex={selectedIndex} />
          {/* <LongMenu /> */}

        </div>
      </div>

      {/* Table Container */}
      <div className="flex-1 overflow-auto rounded-lg shadow-md">
        {selectedIndex === 0 && <ReactVirtualizedTable payloadData={reportData} />}
        {selectedIndex === 1 && <TipsReport payloadData={tipsTotal}/>}
        {selectedIndex === 2 && 
         <OrderListReport 
         payload={ordersList } 
         onPageChange={handlePageChange}
         page = {page}
         downloadReport = {downloadReport}
       />
        
        }
      </div>
    </div>

  );
}
