

import React, { useState } from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Tab, Tabs, TextField, TextareaAutosize, Typography } from "@mui/material";
import QRCode from "react-qr-code";
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import tableIcon from '../../../assets/img/table.png'
import qrcode from '../../../assets/img/qr-code.png'
import '../style.css'

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const QRGenerator = ({ qrGeneratorOpen, handleClose, rooms }) => {
    const [qrgeneratorValue, setqrgeneratorValue] = useState(0);
    const handleChangeConfigTab = (event, newValue) => {
        setqrgeneratorValue(newValue);
    };
    const [color, setColor] = React.useState(10);
    const [frame, setFrame] = React.useState(10);

    const handleChange = (event) => {
        setFrame(event.target.value);
    };

    const handleColorChange = (event) => {
        setColor(event.target.value);
    };

    return (
        <>
            <Modal
                open={qrGeneratorOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='modal-body add-category-modal'>
                    <Box className='add-product-modal'>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {`QR Code Generator for Table 3 / Room 1`}
                            </Typography>
                            <CloseIcon onClick={handleClose}
                                sx={{ color: '#ffb800', cursor: 'pointer', fontSize: '30px' }} />
                        </Box>
                        <Box className='configuration-tab'>
                            <Box className='room-tab-section'>
                                <Tabs className='room-tab' value={qrgeneratorValue} onChange={handleChangeConfigTab} aria-label="basic tabs example">
                                    {/* <Tab label="Room 1" {...a11yProps(0)} />
                                    <Tab label="Room 2" {...a11yProps(1)} />
                                    <Tab label="Room 3" {...a11yProps(2)} /> */}
                                    {rooms?.length && rooms?.map((room, index) => (
                                        // <Tab label={room?.name} key={index} />
                                        <Tab label={room?.name} {...a11yProps(index)} />
                                    ))}
                                </Tabs>
                                <Box className='room-tab-detail'>
                                    {/* <CustomTabPanel value={qrgeneratorValue} index={0}>
                                        <Box className=''>
                                            
                                            <Box className='table-box'>
                                                <Box className='first-room'>
                                                    <Typography>1</Typography>
                                                    <img src={table} />
                                                </Box>
                                                <Box className='first-room'>
                                                    <Typography>2</Typography>
                                                    <img src={table} />
                                                </Box>
                                                <Box className='first-room'>
                                                    <Typography>3</Typography>
                                                    <img src={table} />
                                                </Box>
                                            </Box>
                                            <Box className='total-table'>
                                                <hr className='table-border' />
                                                <Typography>10</Typography>
                                                <img src={table} />
                                            </Box>
                                        </Box>
                                    </CustomTabPanel> */}
                                    {rooms?.map((room, roomIndex) => (
                                        <CustomTabPanel value={qrgeneratorValue} index={roomIndex} key={roomIndex}>
                                            {room?.tables?.map((table, tableIndex) => (
                                                <Box className='table-box' key={tableIndex}>
                                                    <Box className='first-room'>
                                                        <Typography>{table?.sitsCount}</Typography>
                                                        <img src={tableIcon} alt={`Table ${table?.name}`} />
                                                    </Box>
                                                    <Typography>{table?.name}</Typography>
                                                    {/* <Box>
                                            <Box className='table-box'>
                                                <Box className='first-room'>
                                                    <Typography>1</Typography>
                                                    <img src={table} />
                                                </Box>
                                                <Box className='first-room'>
                                                    <Typography>2</Typography>
                                                    <img src={table} />
                                                </Box>
                                                <Box className='first-room'>
                                                    <Typography>3</Typography>
                                                    <img src={table} />
                                                </Box>
                                            </Box>
                                            <Box className='total-table'>
                                                <hr className='table-border' />
                                                <Typography>10</Typography>
                                                <img src={table} />
                                            </Box>
                                        </Box> */}
                                                </Box>
                                            ))}
                                        </CustomTabPanel>
                                    ))}
                                </Box>
                            </Box>
                            <Box className='qr-code-generator'>
                                <Box className='qr-code-detail'>
                                    {/* <img src={qrcode} /> */}
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={JSON.stringify(rooms)}
                                        // value={'https://m.media-amazon.com/images/M/MV5BN2IzYzBiOTQtNGZmMi00NDI5LTgxMzMtN2EzZjA1NjhlOGMxXkEyXkFqcGdeQXVyNjAwNDUxODI@._V1_FMjpg_UX1000_.jpg'}
                                        viewBox={`0 0 256 256`}
                                    />
                                    <FormControl fullWidth className="input-field-title" sx={{
                                        marginBottom: '15px'
                                    }}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={frame}
                                            label="frame"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={10}>Frame</MenuItem>
                                            <MenuItem value={10}>Frame1</MenuItem>
                                            <MenuItem value={20}>Frame2</MenuItem>
                                            <MenuItem value={30}>Frame3</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth className="input-field-title">
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={color}
                                            label="color"
                                            onChange={handleColorChange}
                                        >
                                            <MenuItem value={10}>Color</MenuItem>
                                            <MenuItem value={10}>Color1</MenuItem>
                                            <MenuItem value={20}>Color2</MenuItem>
                                            <MenuItem value={30}>Color3</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box>
                                    <Button className="download-btn">Download <span>jpg</span></Button>
                                    <Button className="vector-btn">Vector <span>SVG/EPS</span></Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal >
        </>
    )
}
export default QRGenerator