
import { Box } from '@mui/material';
import React from 'react';
import ReviewCard from '../../../component/ReviewCard';
import { useSelector } from 'react-redux';

const RatingReviewTab = () => {
    const { loading } = useSelector((state) => state.Location);
    return (
        <Box className={!loading && 'rating-tab'}>
            <ReviewCard />
        </Box>
    );
};

export default RatingReviewTab;