import React, { useState } from 'react'
import CustomButton from '../../component/CustomButton'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlinePicture } from "react-icons/ai";
import ImageCropper from '../../component/ImageCropper';



const AddBanner = () => {
    const navigate = useNavigate()
    const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10))
    const [endDate, setEndDate] = useState()
    const [showImageWebModal, setShowImageWebModal] = useState(false)
    const [showImageMobModal, setShowImageMobModal] = useState(false)
    const [bannerWeb, setBannerWeb] = useState(null)
    const [bannerMob, setBannerMob] = useState(null)
    const [fileDataURLWeb, setFileDataURLWeb] = useState(null);
    const [fileDataURLMob, setFileDataURLMob] = useState(null);
    const restaurantList = [{ value:'Adhoc Bar', label:'Adhoc Bar'}, {value:'Adhoc Cafe', label: 'Adhoc Cafe'}, { value:'Adhoc Restaurant', label: 'Adhoc Restaurant'}]

    const handleImageChange = (e, type) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            const img = new Image();
            img.onload = async () => {
              console.log(img.naturalWidth, img.naturalHeight, "natural");
                // const uploadedUrl = await commonFunctions.uploadImageToAws(
                //   reader.result
                // );
                // setImage(uploadedUrl);
                // setProfilePic(reader.result)
                if(type == 'website') {
                    setShowImageWebModal(true);
                    setFileDataURLWeb(reader.result);
                } else {
                    setShowImageMobModal(true);
                    setFileDataURLMob(reader.result);
                }
                // formik.setTouched({ image: true }, true);
            };
            img.src = reader.result;
          };
          reader.readAsDataURL(file);
        }
      };
  return (
    <div>
        <div className='flex justify-between items-center'>
            <div className='text-xl font-semibold'>Add Banner</div>
            <CustomButton onClickButton={() => navigate(-1)} backButton label={'Back'} />
        </div>
        <div className=' p-5 bg-secondaryColor rounded-lg drop-shadow-sm mt-5'>
            <div className='grid lg:grid-cols-2 lg:gap-x-60 gap-y-8'>
                <div>
                    <div className='font-semibold'>Restaurant</div>
                    <Select
                        isMulti
                        name="colors"
                        options={restaurantList}
                        placeholder="Select Restaurant"
                        // className="basic-multi-select"
                        // classNamePrefix="select"
                    />

                </div>
                <div>
                    <div className='font-semibold'>Restaurant Branch</div>
                    <Select
                        isMulti
                        name="colors"
                        options={restaurantList}
                        placeholder="Select Branch"
                        // className="basic-multi-select"
                        // classNamePrefix="select"
                    />
                </div>
                <div>
                    <div className='font-semibold'>Category</div>
                    <Select
                        name="colors"
                        options={restaurantList}
                        placeholder="Select Category"
                        // className="basic-multi-select"
                        // classNamePrefix="select"
                    />
                </div>
                <div>
                    <div className='font-semibold'>Banner Name</div>
                    <input 
                        className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                    />
                </div>
                <div>
                    <div className='font-semibold'>Start Date</div>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        minDate={new Date().toISOString().slice(0, 10)}
                        className='drop-shadow-sm h-10 rounded-lg p-3'
                    />
                </div>
                <div>
                    <div className='font-semibold'>End Date</div>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={new Date()}
                        endDate={endDate}
                        minDate={startDate}
                        className='drop-shadow-sm h-10 rounded-lg p-3'
                    />
                </div>

            </div>
            <div className='lg:flex items-start space-y-10 lg:space-y-0 lg:space-x-24 mt-10'>
                <div>
                    <div className='text-xl font-semibold'>Banner Image (website)</div>
                    <p className='text-sm'>Min. Dimension: 900x225</p>
                    <label htmlFor='upload-banner-website'>
                        {bannerWeb ? (
                            <img className='border-2 border-primaryColor rounded-lg' src={bannerWeb} width={225} height={56} />
                        ) : (
                            <div className='flex cursor-pointer items-center justify-center h-[56px] w-[225px] bg-white border-2 border-primaryColor border-solid rounded-lg'>
                                <AiOutlinePicture size={30}/>
                            </div>
                        )}
                    </label>
                    <input
                        type="file"
                        id="upload-banner-website"
                        name="banner-website"
                        accept="image/*"
                        onChange={(e) => handleImageChange(e, 'website')}
                        className='opacity-0 -z-10 absolute'
                    />
                    <ImageCropper
                        show={showImageWebModal}
                        setShowImageModal={setShowImageWebModal}
                        fileDataURL={fileDataURLWeb}
                        //   setCroppedImage={setCroppedImage}
                        setImage={setBannerWeb}
                        aspectRatio={4/1}
                        resizeable={true}
                    />
                </div>
                <div>
                    <div className='text-xl font-semibold'>Banner Image (mobile)</div>
                    <p className='text-sm'>Min. Dimension: 152x168</p>
                    <label htmlFor='upload-banner-mobile' className='w-max block'>
                        {bannerMob ? (
                            <img className='border-2 border-primaryColor rounded-lg' src={bannerMob} width={76} height={84} />  
                        ):(
                            <div className='flex cursor-pointer items-center justify-center h-[84px] w-[76px] bg-white border-2 border-primaryColor border-solid rounded-lg'>
                                <AiOutlinePicture size={30}/>
                            </div>
                        )}

                    </label>
                    <input
                        type="file"
                        id="upload-banner-mobile"
                        name="banner-mobile"
                        accept="image/*"
                        onChange={(e) => handleImageChange(e, 'mobile')}
                        className='opacity-0 -z-10 absolute'
                    />
                    <ImageCropper
                        show={showImageMobModal}
                        setShowImageModal={setShowImageMobModal}
                        fileDataURL={fileDataURLMob}
                        //   setCroppedImage={setCroppedImage}
                        setImage={setBannerMob}
                        aspectRatio={0.9/1}
                        resizeable={true}
                    />
                </div>
            </div>
            <CustomButton label={'Save Changes'} className={'px-10 mt-8'} />
        </div>

    </div>
  )
}

export default AddBanner