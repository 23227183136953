import React, { useState } from 'react'
import CustomButton from '../../component/CustomButton'
import { GoSync } from "react-icons/go";
import CategoryList from '../../component/CategoryList';
import EbrizaLogo from '../../assets/img/ebriza-logo.svg'
import { useNavigate } from 'react-router-dom';


const index = () => {
    const [loadingSync, setLoadingSync] = useState(false)
    const navigate = useNavigate()
    const handleSync = () => {
        setLoadingSync(true)
        setTimeout(() => {
            setLoadingSync(false)
        }, 2000)
    }
  return (
    <div>
        <div className='flex justify-between items-center'>
            <div className={`${loadingSync ? 'font-normal' : 'font-semibold'} text-xl `}>
                {loadingSync ? 'Your menu is configured by somebody else. Please get in contact with third party support. Thank you!' : 'Menu'}
            </div>
            <CustomButton onClickButton={handleSync} secondary label={'Sync & Verify'} endIcon={<GoSync />} />
        </div>
        <div>
            {loadingSync ? (
                <img src={EbrizaLogo} />
                ) : (
                <>
                     <div className='mt-5 grid lg:grid-cols-2 lg:gap-4 gap-8'>
                        <CategoryList list={['Canadian', 'North Indian', 'Pizza', 'Sushi', 'Asian', 'Salad', 'Light Meals', 'Beverages', 'Pasta', 'Heavy Meals', 'Kids Meals']} />
                        <CategoryList title='Asian' list={['Sushi Rolls', 'Poke Bowls', "Fried Rice", "Beef Char", "Szechuan Rice", "Rendang Rice"]} />
                    </div>
                    <div className='mt-8'>
                        <CustomButton onClickButton={() => navigate('/menu/add-category') } label={'Create Sub-Category'} />
                    </div>
                </>
                )
            }
           
        </div>
    </div>
  )
}

export default index