import { all, takeEvery } from "redux-saga/effects";
import { TOAST_INIT } from "../Actions/ToastAction";
import {
  ADMIN_LOGIN_REQUEST,
  LOGIN_REQUEST,
  LOGOUT
} from "../Actions/AuthActions";
import { ToastSaga } from "./ToastSaga";
import {
  AdminLoginSaga,
  LoginSaga,
  LogoutSaga,
} from "./AuthSaga";
import { AddConfigurationstSaga, AddMenuCategorySaga, AddMenuProductSaga, AddMenuSaga, AddMenumodifiertSaga, GetNutriAndCalcSaga, AddReviewsSaga, GetConfigurationstSaga, GetLocationSaga, GetReviewsSaga, AddLocationSaga, EditImageSaga } from "./LocationSaga";
import { ADD_CONFIGURATIONS_REQUEST, ADD_MENU_CATEGORY_REQUEST, ADD_MENU_MODIFIER_REQUEST, ADD_MENU_PRODUCT_REQUEST, ADD_MENU_REQUEST, GET_NUTRI_AND_CALC_REQUEST, ADD_REVIEWS_REQUEST, GET_CONFIGURATIONS_REQUEST, GET_LOCATION_REQUEST, GET_REVIEWS_REQUEST, ADD_LOCATION_REQUEST, EDIT_IMAGE_REQUEST } from "../Actions/LocationActions";
import { ADD_PARTNERS_REQUEST, DELETE_PARTNER_REQUEST, EDIT_PARTNER_DATA_REQUEST, GET_PARTNERS_REQUEST } from "../Actions/PartnerActions";
import { AddPartnerSaga, DeletePartnerSaga, EditPartnerSaga, GetPartnersSaga } from "./PartnersSaga";

function* rootSagas() {
  yield all([takeEvery(TOAST_INIT, ToastSaga)]);
  yield all([takeEvery(LOGIN_REQUEST, LoginSaga)]);
  yield all([takeEvery(ADMIN_LOGIN_REQUEST, AdminLoginSaga)]);
  yield all([takeEvery(GET_LOCATION_REQUEST, GetLocationSaga)]);
  // yield all([takeEvery(EDIT_IMAGE_REQUEST, EditImageSaga)]);
  yield all([takeEvery(ADD_LOCATION_REQUEST, AddLocationSaga)]);
  yield all([takeEvery(ADD_MENU_REQUEST, AddMenuSaga)]);
  yield all([takeEvery(ADD_MENU_CATEGORY_REQUEST, AddMenuCategorySaga)]);
  yield all([takeEvery(ADD_MENU_PRODUCT_REQUEST, AddMenuProductSaga)]);
  yield all([takeEvery(ADD_MENU_MODIFIER_REQUEST, AddMenumodifiertSaga)]);
  yield all([takeEvery(GET_NUTRI_AND_CALC_REQUEST, GetNutriAndCalcSaga)]);
  yield all([takeEvery(ADD_REVIEWS_REQUEST, AddReviewsSaga)]);
  yield all([takeEvery(GET_REVIEWS_REQUEST, GetReviewsSaga)]);
  yield all([takeEvery(ADD_CONFIGURATIONS_REQUEST, AddConfigurationstSaga)]);
  yield all([takeEvery(GET_CONFIGURATIONS_REQUEST, GetConfigurationstSaga)]);
  yield all([takeEvery(GET_PARTNERS_REQUEST, GetPartnersSaga)]);
  yield all([takeEvery(ADD_PARTNERS_REQUEST, AddPartnerSaga)]);
  yield all([takeEvery(EDIT_PARTNER_DATA_REQUEST, EditPartnerSaga)]);
  yield all([takeEvery(DELETE_PARTNER_REQUEST, DeletePartnerSaga)]);
  yield all([takeEvery(LOGOUT, LogoutSaga)]);
}

export default rootSagas;
