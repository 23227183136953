import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, InputAdornment, Menu, MenuItem, Switch, TextField, Tooltip, Typography } from "@mui/material";
import { EditPartnerDataAction, GetPartnersAction } from "../../Redux/Actions/PartnerActions";
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CustonDataGrid from "../../component/CustomDataGrid";
import refreshImg from '../../assets/img/refresh-icon.png'
import rightarrow from '../../assets/img/view-right-arrow.png';
import '../Partners/style.css'
import DefaultProfile from '../../assets/img/default-profile-pic.png'
import CustomButton from "../../component/CustomButton";

const index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
//   const { loading, getPartnersData } = useSelector((state) => state.Partners);
  const { t } = useTranslation();
  const userRole = localStorage.getItem('userRole');
  const [partnerOpen, setPartnerOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [menuOpen, setMenuOpen] = useState(null);
  const [loading, setLoading] = useState(false)
  const [searchedValue, setSearchedValue] = useState('');
  const debouncedSearch = useDebounce(searchedValue, 300);
  const open = Boolean(menuOpen);
  const mockupData = [
    {value: 'test1', name: 'Akhil Ramzen', phone:'+1 (467)433 8998', email: 'woks@gmail.com', commission:'10%', occupation: 'Delivery Agent', revenue: 100222, status: true, restaurant: 'All Restaurant', id:1},
    {value: 'test2', name: 'Akhil Ramzen', phone:'+1 (467)433 8998', email: 'woks@gmail.com', commission:'10%', occupation: 'Delivery Agent', revenue: 100222, status: true, restaurant: 'All Restaurant', id:2},
    {value: 'test3', name: 'Akhil Ramzen', phone:'+1 (467)433 8998', email: 'woks@gmail.com', commission:'10%', occupation: 'Delivery Agent', revenue: 100222, status: true, restaurant: 'All Restaurant', id:3},
    {value: 'test4', name: 'Akhil Ramzen', phone:'+1 (467)433 8998', email: 'woks@gmail.com', commission:'10%', occupation: 'Delivery Agent', revenue: 100222, status: true, restaurant: 'All Restaurant', id:4},
    {value: 'test5', name: 'Akhil Ramzen', phone:'+1 (467)433 8998', email: 'woks@gmail.com', commission:'10%', occupation: 'Delivery Agent', revenue: 100222, status: true, restaurant: 'Choose Restaurant', id:5},
    {value: 'test6', name: 'Akhil Ramzen', phone:'+1 (467)433 8998', email: 'woks@gmail.com', commission:'10%', occupation: 'Delivery Agent', revenue: 100222, status: true, restaurant: 'All Restaurant', id:6},
  ]
  const handleClick = (event) => {
    setMenuOpen(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuOpen(null);
  };
  const handlePartnerClose = () => {
    setPartnerOpen(false)
    dispatch(EditPartnerDataAction({},null,null))
  }

  const handleGoEdit = (val) => {
    navigate('/drivers/add-driver', {state: val})
  }
  const columns = [
    {
        field: 'id', headerName: 'ID', minWidth: 50, flex: 1, headerAlign: "center", align: 'center', renderCell: ({row}) => (
          <React.Fragment>
            {row.id || "N/A"}
          </React.Fragment>
        )
      },
    {
      field: 'driver_image', headerName: 'Image', sortable: false, minWidth: 150, flex: 1, headerAlign: "center", align: 'center', renderCell: ({row}) => (
        <React.Fragment>
          <img className="rounded-full" src={row.picture || DefaultProfile} width={50} height={50}/> 
        </React.Fragment>
      )
    },
    {
      field: 'name', headerName: 'Name', minWidth: 100, flex: 1, headerAlign: "center", align: 'center', renderCell: ({row}) => (
        <div className={'whitespace-normal'}>
          {row.name || "N/A"}
        </div>
      )
    },
    {
      field: 'phone', headerName: 'Phone', sortable: false, minWidth: 120, flex: 2, headerAlign: "center", align: 'center', renderCell: ({row}) => (
        // <Tooltip title={params.value || "Not Available"}>
          <div className={'whitespace-normal'}>
            {row.phone || "N/A"}
          </div>
        // </Tooltip>
      )
    },
    {
      field: 'email', headerName: t('Email'), sortable: false, minWidth: 125, flex: 2, headerAlign: "center", align: 'center', renderCell: ({row}) => (
        <div className={'whitespace-normal'}>
          {row.email || "N/A"}
        </div>
      )
    },
    {
      field: 'commission', headerName: 'Commission', minWidth: 120, flex: 1, headerAlign: "center", align: 'center', renderCell: ({row}) => (
        <React.Fragment>
          {row.commission || "N/A"}
        </React.Fragment>
      )
    },
    {
      field: 'occupation', headerName: 'Occupation', sortable: false, minWidth: 110, flex: 1, headerAlign: "center", align: 'center', renderCell: ({row}) => (
        <div className={'whitespace-normal'}>
          {row.occupation || "N/A"}
        </div>
      )
    },
    {
        field: 'revenue', headerName: 'Revenue', minWidth: 100, flex: 1, headerAlign: "center", align: 'center', renderCell: ({row}) => (
          <React.Fragment>
            ${row.revenue || "N/A"}
          </React.Fragment>
        )
      },
      {
        field: 'status', sortable: false, headerName: 'Status', minWidth: 80, flex: 1, headerAlign: "center", align: 'center', renderCell: ({row}) => (
          <React.Fragment>
            <Switch checked={row.status} color="warning" disabled />
          </React.Fragment>
        )
      },
      {
        field: 'restaurant', sortable: false, headerName: 'Restaurant', minWidth: 100, flex: 1, headerAlign: "center", align: 'center', renderCell: ({row}) => (
          <div className="whitespace-normal">
            {row.restaurant || "N/A"}
          </div>
        )
      },
    // {
    //   field: 'active', headerName: 'Account Status', minWidth: 150, flex: 1, headerAlign: "center", align: 'center', renderCell: (row) => (
    //     <React.Fragment>
    //       {row.value ? 'Active' : 'Inactive' || "N/A"}
    //     </React.Fragment>
    //   )
    // },
    {
      field: 'Action', headerName: t('Action'), sortable: false, minWidth: 130, flex: 2, headerAlign: "center", align: 'center',
      renderCell: ({row}) => (
        <>
            <CustomButton 
                secondary 
                label={'Edit'} 
                onClickButton={() => handleGoEdit(row)}
            />
        </>
      ),
    },
  ];

  const handleEdit = (data) => {
    dispatch(EditPartnerDataAction(data))
    setPartnerOpen(true)
  }

  useEffect(() => {
    if (debouncedSearch.value.length >= 0) {
    //   dispatch(GetPartnersAction(debouncedSearch?.value))
    }
  }, [debouncedSearch?.value, GetPartnersAction, refresh])

  return (
    <>
      <Box className="partner-title">
        <Typography variant="h2">Drivers</Typography>
      </Box>
      <Box className="partner-detail">
        <Box className="search-box">
          <TextField id="outlined-basic" variant="outlined" placeholder={`${t("Search")} by name`} InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ color: "#000" }}>
                <SearchIcon />
              </InputAdornment>
            )
          }} onChange={(e) => setSearchedValue(e.target.value)} />
        </Box>
        <Box>
          <Button className="refresh-btn" onClick={() => setRefresh(!refresh)}><img src={refreshImg} />{t("refresh_button")}</Button>
          <Button className="add-partner" onClick={() => navigate('/drivers/add-driver')}>{'Add Driver'}</Button>
        </Box>
      </Box>

      <CustonDataGrid
        columns={columns}
        rows={mockupData}//getPartnersData?.length && getPartnersData}
        getRowId={(row) => row?.id}
        height={300}
        loading={loading}
        rowHeight={70}
        getRowClassName={(param) => {
            if(param.indexRelativeToCurrentPage % 2 !== 0){
                return 'bg-secondaryColor'
            }
        }}
        disableRowSelectionOnClick
      />
    </>
  );
};
export default index;
