import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, FormHelperText, Switch, Tab, Tabs, TextField, Typography } from '@mui/material';
import "react-datepicker/dist/react-datepicker.css"
import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { t } from 'i18next';
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import tableIcon from '../../../assets/img/table.png'
import '../style.css'
import QRGenerator from './QRGenerator';
import Draggable from 'react-draggable';
import { AddConfigurationstAction, AddRoomAction, AddTableAction, GetConfigurationstAction, RemoveRoomAction, TabIdAction } from '../../../Redux/Actions/LocationActions';
import CustomButton from '../../../component/CustomButton';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ConfigurationTab = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const { loading, configurationData } = useSelector((state) => state.Location);
  const { rooms } = configurationData.length && configurationData[0];
  const [configTabValue, setConfigTabValue] = useState(0);
  const [qrGeneratorOpen, setQrGeneratorOpen] = useState(false);
  const [valueSelected, setValueSelected] = useState('')
  const [activeDrags, setActiveDrags] = useState(0)
  const [deltaPosition, setDeltaPosition] = useState({
    x: 0, y: 0
  })
  const [controlledPosition, setControlledPosition] = useState({
    x: -400, y: 200
  })
  const [tablePosition, setTablePosition] = useState({ x: 0, y: 0 });
  const [selectedTableName, setSelectedTableName] = useState('');

  const handleDrag = (e, ui) => {
    const { x, y } = deltaPosition;
    setDeltaPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    })
  };

  const onStart = () => {
    setActiveDrags((prevActiveDrags) => prevActiveDrags + 1);
  };

  const onStop = () => {
    setActiveDrags((prevActiveDrags) => prevActiveDrags - 1);
  };

  const onDragStop = (e, position) => {
    setTablePosition({ x: position?.x, y: position?.y })
  }
  const handleTableClick = (tableName) => {
    // Set the selected table name when a table is clicked
    setSelectedTableName(tableName);
  };
  const handleQrGeneratorClose = () => {
    setQrGeneratorOpen(false)
  }
  const handleChangeConfigTab = (event, newValue) => {
    setConfigTabValue(newValue);
  };
  const formik = useFormik({
    initialValues: {
      table_name: "",
      sit_count: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      table_name: Yup.string().required("Required"),
      sit_count: Yup.string().required("Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(TabIdAction(configTabValue))
      dispatch(AddTableAction({ name: `Table ${formik.values.table_name}`, sitsCount: Number(formik.values.sit_count) }))
      resetForm();
    },
  });
  const handleAddRooms = () => {
    let lastIndex;
    let finalRoomNo;
    if (rooms?.length === 0) {
      lastIndex = 0
      finalRoomNo = 1
    } else {
      lastIndex = rooms?.length - 1
      const finalRoomValue = rooms[lastIndex]
      const fullName = finalRoomValue?.name;
      const parts = fullName?.split(' ');
      const lastPart = parts[parts?.length - 1];
      finalRoomNo = Number(lastPart) + 1
    }
    dispatch(AddRoomAction({ name: `Room ${finalRoomNo !== undefined ? finalRoomNo : 1}`, tables: [] }))
  }

  const handleRemoveRoom = (selectedRoom) => {
    dispatch(RemoveRoomAction(selectedRoom))
  }

  useEffect(() => {
    dispatch(GetConfigurationstAction())
  }, [GetConfigurationstAction])

  const dragHandlers = { onStart: onStart, onStop: onStop };

  return (
    <>
      <div className='p-5 bg-secondaryColor rounded-lg drop-shadow-sm space-y-5'>
        <div className='font-semibold'>Delivery Timings</div>
        <div>
          <p>Preparation Time</p>
          <input 
              className='h-10 p-3 rounded-lg drop-shadow-sm'
              value={'00:20:00'}
          />
        </div>
        <div className='lg:flex lg:space-x-28'>
          <div>
            <p>Minimum Delivery Time</p>
            <input 
                className='h-10 p-3 rounded-lg drop-shadow-sm'
                value={'15 min'}
            />
          </div>
          <div>
            <p>Minimum Delivery Time</p>
            <input 
                className='h-10 p-3 rounded-lg drop-shadow-sm'
                value={'45 min'}
            />
          </div>
        </div>
      </div>
      <div className='p-5 bg-secondaryColor rounded-lg drop-shadow-sm space-y-5 mt-8'>
        <div className='font-semibold'>Set Time Slot</div>
        <div className='lg:grid lg:grid-cols-5 lg:gap-y-5'>
          <div className='flex text-sm font-semibold items-center lg:place-content-center'>
            <div>Monday</div>
            <Switch className='ml-3 lg:!hidden' color='warning' />
          </div>
          <div className='lg:col-span-3 flex items-center space-x-1 lg:space-x-4 mb-8 lg:mb-0'>
            <input 
                className='h-10 p-3 w-full lg:w-auto rounded-lg drop-shadow-sm'
                value={'00:20:00'}
            />
            <div>To</div>
            <input 
                className='h-10 p-3 w-full lg:w-auto rounded-lg drop-shadow-sm'
                value={'00:20:00'}
            />
          </div>
          <Switch className='!hidden lg:!inline-flex' color='warning' />
          <div className='flex text-sm font-semibold items-center lg:place-content-center'>
            <div>Tuesday</div>
            <Switch className='ml-3 lg:!hidden' color='warning' />
          </div>
          <div className='lg:col-span-3 flex items-center space-x-1 lg:space-x-4 mb-8 lg:mb-0'>
            <input 
                className='h-10 p-3 w-full lg:w-auto rounded-lg drop-shadow-sm'
                value={'00:20:00'}
            />
            <div>To</div>
            <input 
                className='h-10 p-3 w-full lg:w-auto rounded-lg drop-shadow-sm'
                value={'00:20:00'}
            />
          </div>
          <Switch className='!hidden lg:!inline-flex' color='warning' />
          <div className='flex text-sm font-semibold items-center lg:place-content-center'>
            <div>Wednesday</div>
            <Switch className='ml-3 lg:!hidden' color='warning' />
            </div>
          <div className='lg:col-span-3 flex items-center space-x-1 lg:space-x-4 mb-8 lg:mb-0'>
            <input 
                className='h-10 p-3 w-full lg:w-auto rounded-lg drop-shadow-sm'
                value={'00:20:00'}
            />
            <div>To</div>
            <input 
                className='h-10 p-3 w-full lg:w-auto rounded-lg drop-shadow-sm'
                value={'00:20:00'}
            />
          </div>
          <Switch className='!hidden lg:!inline-flex' color='warning' />
          <div className='flex text-sm font-semibold items-center lg:place-content-center'>
            <div>Thursday</div>
            <Switch className='ml-3 lg:!hidden' color='warning' />
            </div>
          <div className='lg:col-span-3 flex items-center space-x-1 lg:space-x-4 mb-8 lg:mb-0'>
            <input 
                className='h-10 p-3 w-full lg:w-auto rounded-lg drop-shadow-sm'
                value={'00:20:00'}
            />
            <div>To</div>
            <input 
                className='h-10 p-3 w-full lg:w-auto rounded-lg drop-shadow-sm'
                value={'00:20:00'}
            />
          </div>
          <Switch className='!hidden lg:!inline-flex' color='warning' />
          <div className='flex text-sm font-semibold items-center lg:place-content-center'>
            <div>Friday</div>
            <Switch className='ml-3 lg:!hidden' color='warning' />
            </div>
          <div className='lg:col-span-3 flex items-center space-x-1 lg:space-x-4 mb-8 lg:mb-0'>
            <input 
                className='h-10 p-3 w-full lg:w-auto rounded-lg drop-shadow-sm'
                value={'00:20:00'}
            />
            <div>To</div>
            <input 
                className='h-10 p-3 w-full lg:w-auto rounded-lg drop-shadow-sm'
                value={'00:20:00'}
            />
          </div>
          <Switch className='!hidden lg:!inline-flex' color='warning' />
          <div className='flex text-sm font-semibold items-center lg:place-content-center'>
            <div>Saturday</div>
            <Switch className='ml-3 lg:!hidden' color='warning' />
            </div>
          <div className='lg:col-span-3 flex items-center space-x-1 lg:space-x-4 mb-8 lg:mb-0'>
            <input 
                className='h-10 p-3 w-full lg:w-auto rounded-lg drop-shadow-sm'
                value={'00:20:00'}
            />
            <div>To</div>
            <input 
                className='h-10 p-3 w-full lg:w-auto rounded-lg drop-shadow-sm'
                value={'00:20:00'}
            />
          </div>
          <Switch className='!hidden lg:!inline-flex' color='warning' />
          <div className='flex text-sm font-semibold items-center lg:place-content-center'>
            <div>Sunday</div>
            <Switch className='ml-3 lg:!hidden' color='warning' />
            </div>
          <div className='lg:col-span-3 flex items-center space-x-1 lg:space-x-4 mb-8 lg:mb-0'>
            <input 
                className='h-10 p-3 w-full lg:w-auto rounded-lg drop-shadow-sm'
                value={'00:20:00'}
            />
            <div>To</div>
            <input 
                className='h-10 p-3 w-full lg:w-auto rounded-lg drop-shadow-sm'
                value={'00:20:00'}
            />
          </div>
          <Switch className='!hidden lg:!inline-flex' color='warning' />
        </div>
      </div>
      <div className='mt-8'>
        <CustomButton label={'Save'} className={'px-24'} /> 
      </div>
      {/* <QRGenerator qrGeneratorOpen={qrGeneratorOpen} handleClose={handleQrGeneratorClose} rooms={rooms} />
      <Box className='configuration-tab'>
        <Box className='add-room-section'>
          <Box>
            <Button className='add-room-btn' onClick={() => { handleAddRooms() }}><AddIcon /> {t("add_room")}</Button>
          </Box>
          <Box className='add-table-detail'>
            <Box className='table-btn'>
              <Button className='add-table-btn' onClick={formik.handleSubmit}><AddIcon /> {t("add_table")}</Button>
              <Button className='add-table-btn'><AddIcon />{t("add_object")}</Button>
            </Box>
            <Box className='add-table-filled'>
              <label>{t("table-name")}</label>
              <TextField placeholder='table name' type='number' name='table_name' value={formik.values.table_name} onChange={formik.handleChange} />
              {formik.touched.table_name && formik.errors.table_name && (
                <FormHelperText style={{ color: 'red' }}>
                  {formik.errors.table_name}
                </FormHelperText>
              )}
            </Box>
            <Box className='add-table-filled'>
              <label>{t("seats_number")}</label>
              <TextField placeholder='site count' type='number' name='sit_count' value={formik.values.sit_count} onChange={formik.handleChange} />
              {formik.touched.sit_count && formik.errors.sit_count && (
                <FormHelperText style={{ color: 'red' }}>
                  {formik.errors.sit_count}
                </FormHelperText>
              )}
            </Box>
            <Box className='add-table-filled'>
              <label>{t("rotate_table")}</label>
              <Box className='rotate-btn'><AutorenewIcon /></Box>
            </Box>
            <Button className='download-btn' onClick={() => setQrGeneratorOpen(true)}>
              {loading ?
                <ReactLoading
                  type={"spin"}
                  height="30px"
                  width="30px"
                  color="white"
                />
                : t("download_qr")}</Button>
            <Button className='remove-btn' onClick={() => handleRemoveRoom(configTabValue)}>{t("remove_btn")}</Button>
          </Box>
        </Box>
        <Box className='room-tab-section'>
          <>
            <Tabs
              className='room-tab'
              value={rooms?.length === configTabValue ? 0 : configTabValue}
              onChange={handleChangeConfigTab}
              aria-label="basic tabs example"
            >
              {rooms?.length && rooms?.map((room, index) => (
                <Tab label={room?.name} key={index} />
              ))}
            </Tabs>
            <Box className='room-tab-detail' >
              {rooms?.map((room, roomIndex) => (
                <CustomTabPanel value={configTabValue} index={roomIndex} key={roomIndex} >
                  {room?.tables?.map((table, tableIndex) => (
                    <Box className='table-box' key={tableIndex}>
                      <Draggable {...dragHandlers} onStop={onDragStop} onDrag={() => handleTableClick(table?.name)}>
                        <Box className='first-room'>
                          <Typography>{table?.sitsCount}</Typography>
                          <img src={tableIcon} alt={`Table ${table?.name}`} />
                        </Box>
                      </Draggable>
                      <Typography>{table?.name}</Typography>
                    </Box>
                  ))}
                </CustomTabPanel>
              ))}
            </Box>
          </>
        </Box>
      </Box> */}
    </>
  );
};

export default ConfigurationTab;
