import { useState, useEffect } from "react";

export function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        const handler = setTimeout(() => {
            setDebouncedValue(value);
            setLoading(false);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return { value: debouncedValue, loading };
}
