import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import FirstLetterCapital from '../../helper/FirstLetterCapital'
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlinePicture } from "react-icons/ai";
import CustomButton from '../../component/CustomButton';
import ImageCropper from '../../component/ImageCropper';
import NutritionalValueCalculator from '../Locations/LocationDetails/NutritionalValueCalculator';
import AddNewModifier from '../Locations/LocationDetails/AddNewModifier';



const AddEditProduct = ({unitPrice = 'Ron'}) => {
    const { action } = useParams()
    const navigate = useNavigate()
    const [showImageModal,setShowImageModal] = useState(false)
    const [fileDataURL,setFileDataURL] = useState()
    const [productImg, setProductImg] = useState(null)
    const [nutritionOpen, setNutritionOpen] = useState(false)
    const [modifierOpen, setModifierOpen] = useState(false)

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            const img = new Image();
            img.onload = async () => {
              console.log(img.naturalWidth, img.naturalHeight, "natural");
                // const uploadedUrl = await commonFunctions.uploadImageToAws(
                //   reader.result
                // );
                // setImage(uploadedUrl);
                // setProfilePic(reader.result)
                setShowImageModal(true);
                setFileDataURL(reader.result);
                // formik.setTouched({ image: true }, true);
            };
            img.src = reader.result;
          };
          reader.readAsDataURL(file);
        }
      };
  return (
    <>
    <AddNewModifier modifierOpen={modifierOpen} handleClose={() => setModifierOpen(false)} />
    <NutritionalValueCalculator nutritionOpen={nutritionOpen} handleClose={() => setNutritionOpen(false)} />
    <div className='lg:grid lg:grid-cols-3' >
        <div className='lg:col-span-2'>
            <div className='flex justify-between items-center'>
                <div className='font-semibold text-xl'>{FirstLetterCapital(action)} Product</div>
                <div onClick={() => navigate(-1)} className='cursor-pointer flex text-primaryColor space-x-2 items-center'>
                    <IoIosArrowBack size={'30px'} />
                    <div>Category List</div>
                </div>
            </div>
            <div className='mt-5 bg-secondaryColor p-5 rounded-lg drop-shadow-md grid lg:grid-cols-3 gap-9'>
                <div className='col-span-2 grid grid-cols-3 gap-6'>
                    <div className='col-span-2 '>
                        <div className='font-semibold'>Name of product</div>
                        <input 
                            className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                            defaultValue={'Beef Burger'}
                        />
                    </div>
                    <div>
                        <div className='font-semibold'>Category</div>
                        <input 
                            defaultValue={'Asian'}
                            className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                        />
                    </div>
                    <div>
                        <div className='lg:text-sm text-xs font-semibold'>Price (including VAT)</div>
                        <div className='flex items-center space-x-3'>
                            <input 
                                defaultValue={'124,99'}
                                className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                            />
                            <div>{unitPrice}</div>
                        </div>
                    </div>
                    <div>
                        <div className='lg:text-sm text-xs font-semibold'>Net Weight</div>
                        <div className='flex items-center space-x-3'>
                            <input 
                                defaultValue={'100'}
                                className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                            />
                            <div>g</div>
                        </div>
                    </div>
                    <div>
                        <div className='lg:text-sm text-xs font-semibold'>No. Portions</div>
                        <input 
                            defaultValue={'1'}
                            className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                        />
                    </div>
                    <div className='col-span-3 '>
                        <div className='font-semibold'>Short description</div>
                        <p className='text-sm'>present on the card only</p>
                        <textarea 
                            style={{resize: 'none'}}
                            className='rounded-lg drop-shadow-sm p-3'
                            cols={25}
                            rows={3}
                        />
                    </div>
                    <div className='col-span-2 '>
                        <div className='font-semibold'>Long description</div>
                        <textarea 
                            style={{resize: 'none'}}
                            className='rounded-lg drop-shadow-sm p-3'
                            cols={40}
                            rows={3}
                        />
                    </div>
                </div>
                <div>
                    <div className='font-semibold'>Product image</div>
                    <p>Aspec ratio 4:3</p>
                    {productImg ? (
                        <img className='border-2 border-primaryColor rounded-lg' src={productImg} width={214} height={160} />
                    ):(
                    <label htmlFor='product-image' >
                        <div className='cursor-pointer flex items-center justify-center h-[160px] w-[214px] bg-white border-2 border-primaryColor border-solid rounded-lg'>
                            <AiOutlinePicture size={30}/>
                        </div>
                    </label>

                    )}
                    <input
                        type="file"
                        id="product-image"
                        name="product-image"
                        accept="image/*"
                        onChange={(e) => handleImageChange(e)}
                        className='opacity-0 -z-10 absolute'
                    />
                    <ImageCropper
                        show={showImageModal}
                        setShowImageModal={setShowImageModal}
                        fileDataURL={fileDataURL}
                        //   setCroppedImage={setCroppedImage}
                        setImage={setProductImg}
                        aspectRatio={4/3}
                        resizeable={true}
                    />
                    <div className='mt-10 space-y-4'>
                        <CustomButton className={'text-sm'} label={'Submit/Save'} />
                        <CustomButton onClickButton={() => setModifierOpen(true)} className={'text-sm'} label={'Add modifiers'} />
                        <CustomButton onClickButton={() => setNutritionOpen(true)} className={'flex-wrap text-sm'} label={'Nutritional Values Calculator'} />
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default AddEditProduct