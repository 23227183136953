import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, FormHelperText, Grid, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { TOAST_SHOW } from "../../Redux/Actions/ToastAction";
import { AddPartnerAction, EditPartnerDataAction } from "../../Redux/Actions/PartnerActions";
import ReactLoading from "react-loading";
import commonFunctions from "../../common/functions";
import Snackbar from '../../component/ErrorHandler/Snackbar';

const AddPartner = ({ partnerOpen, handleClose,selectedPartner }) => {
    const dispatch = useDispatch();
    const [loadingAdd, setLoadingAdd] = useState(false);
    const [showSnack, setShowSnack] = useState(false);
    
    const [partnerId,setPartnerId] =useState('')
    const [clientId,setClientId] =useState('')
    const formik = useFormik({
        initialValues: {
            username: selectedPartner?.username || "",
            email: selectedPartner?.email || "",
            password: selectedPartner?.password || "",
            posKey: selectedPartner?.posKey || "",
            posSecret: selectedPartner?.posSecret || "",
            companyName: selectedPartner?.companyName || "",
            companyAbbreviation: selectedPartner?.companyAbbreviation || "",
            category: selectedPartner?.category || 'ebriza',
            currency: selectedPartner?.currency || 'ron',
            cui: selectedPartner?.cui || "",
            registrationNumber: selectedPartner?.registrationNumber || "",
            address: selectedPartner?.address || "",
            phone: selectedPartner?.phone || "",
            payPlan: selectedPartner?.payPlan || "free",
            ClientId: selectedPartner?.ClientId || "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            username: Yup.string().required("Username is required"),
            email: Yup.string().email("Invalid email address").required("Email address is required"),
            password: Yup.string().required("Password is required"),
            posKey: Yup.string().when('category', {
                is: 'ebriza', // or any condition you need
                then: () => Yup.string().required("POS Key is required"),
                otherwise:() => Yup.string().notRequired()
              }),
              posSecret: Yup.string().when('category', {
                is: 'ebriza', // or any condition you need
                then: () => Yup.string().required("POS Secret is required"),
                otherwise: () =>Yup.string().notRequired()
              }),
              ClientId: Yup.string().when('category', {
                is: 'ebriza', // or other condition
                then: () => Yup.string().required("Ebriza ClientId is required"),
                otherwise: () =>Yup.string().notRequired()
              }),
            companyName: Yup.string().required("Company Name is required"),
            category: Yup.string().required("Category is required"),
            currency: Yup.string().required("Currency is required"),
            payPlan: Yup.string().required("Pay Plan is required"),
        }),
        onSubmit: async (values) => {
            const payload = {
                ...values,
                currency: values.currency.toUpperCase(),
            };

            setLoadingAdd(true);
            try {
                const response = selectedPartner?.id
                ? await dispatch(EditPartnerDataAction({...payload,PartnerId:selectedPartner.PartnerId},setLoadingAdd,handleClose))
                : await dispatch(AddPartnerAction({...payload},setLoadingAdd,handleClose));
                
                
            } catch (error) {
                console.log("error",error.message);
            }
        },
    });

    const handleImageUpload = (e, field) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async () => {
                const uploadedUrl = await commonFunctions.uploadImageToAws(reader.result);
                formik.setFieldValue(field, uploadedUrl);
            };
            reader.readAsDataURL(file);
        }
    };
useEffect(()=>{
    setPartnerId(localStorage.getItem('partnerId'))
    setClientId(localStorage.getItem('clientId'))
},[])
    return (
        <>
            {/* <Snackbar alertOpen={showSnack} handleClose={() => setShowSnack(false)} vertical={'top'} autoHideDuration={4000} /> */}
            <Modal open={partnerOpen} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className='modal-body add-category-modal lg:h-[90%] lg:overflow-y-auto'>
                    <Box className='add-product-modal add-partner-modal'>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {selectedPartner?.id ? t("edit_partner") : t("add_partner_btn")}
                        </Typography>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2} sx={{ alignItems: 'baseline' }}>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box className='input-field-title'>
                                        <label>{t("username")}</label>
                                        <TextField name="username" fullWidth placeholder="Username" value={formik.values.username} onChange={formik.handleChange} />
                                        {formik.touched.username && formik.errors.username && (
                                            <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '2px 5px' }}>
                                                {formik.errors.username}
                                            </FormHelperText>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box className='input-field-title'>
                                        <label>{t("email")}</label>
                                        <TextField name="email" fullWidth placeholder="Email" value={formik.values.email} onChange={formik.handleChange} />
                                        {formik.touched.email && formik.errors.email && (
                                            <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '2px 5px' }}>
                                                {formik.errors.email}
                                            </FormHelperText>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box className='input-field-title'>
                                        <label>{t("password")}</label>
                                        <TextField type="password" name="password" fullWidth placeholder="Password" value={formik.values.password} onChange={formik.handleChange} />
                                        {formik.touched.password && formik.errors.password && (
                                            <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '2px 5px' }}>
                                                {formik.errors.password}
                                            </FormHelperText>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box className='input-field-title'>
                                        <FormControl fullWidth>
                                            <label>{t("category")}</label>
                                            <Select name="category" value={formik.values.category} onChange={formik.handleChange}>
                                                <MenuItem value="ebriza">Ebriza</MenuItem>
                                                <MenuItem value="adhoc">Adhoc</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {formik.touched.category && formik.errors.category && (
                                            <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '2px 5px' }}>
                                                {formik.errors.category}
                                            </FormHelperText>
                                        )}
                                    </Box>
                                </Grid>
                                {/* Other fields */}
                                {formik.values.category == 'ebriza' && (
                                    <>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Box className='input-field-title'>
                                                <label>{t("pos_key")}</label>
                                                <TextField
                                                    name="posKey"
                                                    fullWidth
                                                    placeholder="POS Key"
                                                    value={formik.values.posKey}
                                                    onChange={formik.handleChange}
                                                    disabled={!!selectedPartner?.id} // Disable when editing a partner
                                                />
                                                {formik.touched.posKey && formik.errors.posKey && (
                                                    <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '2px 5px' }}>
                                                        {formik.errors.posKey}
                                                    </FormHelperText>
                                                )}
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Box className='input-field-title'>
                                                <label>{t("pos_secret")}</label>
                                                <TextField
                                                    name="posSecret"
                                                    fullWidth
                                                    placeholder="POS Secret"
                                                    value={formik.values.posSecret}
                                                    onChange={formik.handleChange}
                                                    disabled={!!selectedPartner?.id} // Disable when editing a partner
                                                />
                                                {formik.touched.posSecret && formik.errors.posSecret && (
                                                    <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '2px 5px' }}>
                                                        {formik.errors.posSecret}
                                                    </FormHelperText>
                                                )}
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <Box className='input-field-title'>
                                                <label>{t("ClientId")}</label>
                                                <TextField name="ClientId" fullWidth placeholder="Add Ebriza ClientId" value={formik.values.ClientId} onChange={formik.handleChange} />
                                                {formik.touched.ClientId && formik.errors.ClientId && (
                                                    <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '2px 5px' }}>
                                                        {formik.errors.ClientId}
                                                    </FormHelperText>
                                                )}
                                            </Box>
                                        </Grid>
                                    </>
                                )}
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box className='input-field-title'>
                                        <label>{t("company_name")}</label>
                                        <TextField name="companyName" fullWidth placeholder="Company Name" value={formik.values.companyName} onChange={formik.handleChange} />
                                        {formik.touched.companyName && formik.errors.companyName && (
                                            <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '2px 5px' }}>
                                                {formik.errors.companyName}
                                            </FormHelperText>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box className='input-field-title'>
                                        <label>{t("company_abbreviation")}</label>
                                        <TextField name="companyAbbreviation" fullWidth placeholder="Company Abbreviation" value={formik.values.companyAbbreviation} onChange={formik.handleChange} />
                                    </Box>
                                </Grid>
                                
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box className='input-field-title'>
                                        <FormControl fullWidth>
                                            <label>{t("currency")}</label>
                                            <Select name="currency" value={formik.values.currency} onChange={formik.handleChange}>
                                                <MenuItem value="ron">RON</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {formik.touched.currency && formik.errors.currency && (
                                            <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '2px 5px' }}>
                                                {formik.errors.currency}
                                            </FormHelperText>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box className='input-field-title'>
                                        <label>{t("cui")}</label>
                                        <TextField name="cui" fullWidth placeholder="CUI" value={formik.values.cui} onChange={formik.handleChange} />
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box className='input-field-title'>
                                        <label>{t("registration_number")}</label>
                                        <TextField name="registrationNumber" fullWidth placeholder="Registration Number" value={formik.values.registrationNumber} onChange={formik.handleChange} />
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box className='input-field-title'>
                                        <label>{t("address")}</label>
                                        <TextField name="address" fullWidth placeholder="Address" value={formik.values.address} onChange={formik.handleChange} />
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box className='input-field-title'>
                                        <label>{t("phone")}</label>
                                        <TextField name="phone" fullWidth placeholder="Phone" value={formik.values.phone} onChange={formik.handleChange} />
                                    </Box>
                                </Grid>
                                
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box className='input-field-title'>
                                        <FormControl fullWidth>
                                            <label>{t("pay_plan")}</label>
                                            <Select name="payPlan" value={formik.values.payPlan} onChange={formik.handleChange}>
                                                <MenuItem value="free">FREE</MenuItem>
                                                <MenuItem value="premium">PREMIUM</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {formik.touched.payPlan && formik.errors.payPlan && (
                                            <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '2px 5px' }}>
                                                {formik.errors.payPlan}
                                            </FormHelperText>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box className='modal-btn'>
                                <Button className="cancel-btn" onClick={handleClose}>{t("cancel_btn")}</Button>
                                {loadingAdd ? (
                                    <ReactLoading type={"spin"} height="30px" width="30px" color="black" />
                                ) : (
                                    <Button type="submit" className="submit-btn">{selectedPartner?.id ? t("update_btn") : t("save_btn")}</Button>
                                )}
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default AddPartner;
