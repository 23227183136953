import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import './style.css'

export default function ReportList({ items, selectedIndex, handleListItemClick }) {
  return (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <p>Sales</p>
      <List component="nav" aria-label="main mailbox folders">
        {items.length > 0 ? (
          items.map((item, index) => (
            <ListItemButton
              key={index}
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index)}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: '#FFF3D2',
                }
              }}
            >
              <ListItemIcon>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 18H21" stroke="#222327" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M3 18H3.01" stroke="#222327" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M8 12H21" stroke="#222327" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M3 12H3.01" stroke="#222327" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M8 6H21" stroke="#222327" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M3 6H3.01" stroke="#222327" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItemButton>
          ))
        ) : (
          <ListItemText primary="No items found" />
        )}
      </List>
    </Box>
  );
}
