import styledCompo from "styled-components";
import MuiAlert from "@mui/material/Alert";
import React from "react";
import { Snackbar } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const CustomAlert = styledCompo(Alert)(({ theme }) => ({
  "& .MuiButtonBase-root": {
    border: "none !important",
    fontSize: "18px !important",
    color: "#fff !important",
    padding: "0 !important",
    background: "none !important",
    transition: "0.4s !important",
  },
}));

export const CustomSnackbar = styledCompo(Snackbar)(({ theme }) => ({
  "& .MuiPaper-root ": {
    boxShadow: "none",
  },
}));
