
import React, { useState } from "react";
import { Box, List, Modal, Rating, Typography } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import '../style.css'
import moment from "moment";

const ReviewDetailsModal = ({ reviewOpen, reviewDetail, handleClose }) => {
    return (
        <>
            <Modal
                open={reviewOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='modal-body user-review-modal'>
                    <Box className="review-card-detail review-card-box">
                        <Box className='user-review-profile' >
                            <AccountCircleIcon />
                        </Box>
                        <Box sx={{
                            width: '100%'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <Typography>{moment(reviewDetail?.reviewDate).format('MMM D, YYYY')}</Typography>
                                <CloseIcon onClick={handleClose} sx={{ color: '#ffb800', cursor: 'pointer', fontSize: '30px' }} />
                            </Box>
                            <List className='review-star'>
                                <Rating
                                    name="hover-feedback"
                                    value={reviewDetail?.reviewRating}
                                    precision={0.5}
                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                />
                                {reviewDetail?.reviewRating !== null && (
                                    <Box sx={{ ml: 2 }}>{`${reviewDetail?.reviewRating}/5`}</Box >
                                )}
                            </List>
                            <Typography>{reviewDetail?.reviewText}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Modal >
        </>
    )
}
export default ReviewDetailsModal