import React, { useContext, useEffect, useState } from 'react';
import './style.css'
import { Box, Typography } from '@mui/material';
import CountSection from './CountSection';
import GraphSection from './GraphSection';
import OrderSection from './OrderSection';
import { IoIosArrowDown } from "react-icons/io";
import { FaExpandAlt } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { FiFileText } from "react-icons/fi";
import { BsFileSpreadsheet } from "react-icons/bs";
import { SlFire } from "react-icons/sl";
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { t } from 'i18next';
import BarChart from '../../component/BarChart';
import SmallModal from '../../component/SmallModal';
import Select from 'react-select';
import { GlobalContext } from '../../contexts/GlobalContext';
import axios from '../../axiosConfig';

ChartJS.register(ArcElement, Tooltip);

const Dashboard = () => {
    const [workHourModal, setWorkHourModal] = useState(false)
    const userRole = localStorage.getItem('userRole')
    const [dashboardData, setDashboardData] = useState({
        sales_today: 0,
        sales_yesterday: 0,
        sales_last_week: 0,
        orders_today: 0,
        avg_order_value: 0,
        top_product_last_week: '',
        top_product_sales_last_week: 0
    });
    const {loading} = useContext(GlobalContext)

    const fromHourList = [
        {value: '01', label: '01'},
        {value: '02', label: '02'},
        {value: '03', label: '03'},
        {value: '04', label: '04'},
        {value: '05', label: '05'},
        {value: '06', label: '06'},
        {value: '07', label: '07'},
        {value: '08', label: '08'},
        {value: '09', label: '09'},
        {value: '10', label: '10'}
    ]
    const untilHourList = [
        {value: '11', label: '11'},
        {value: '12', label: '12'},
        {value: '13', label: '13'},
        {value: '14', label: '14'},
        {value: '15', label: '15'},
        {value: '16', label: '16'},
        {value: '17', label: '17'},
        {value: '18', label: '18'},
        {value: '19', label: '19'},
        {value: '20', label: '20'},
        {value: '21', label: '21'},
        {value: '22', label: '22'},
        {value: '23', label: '23'},
        {value: '24', label: '24'},

    ]

    const timezoneList = [
        {value: 'UTC-02', label: '(UTC-02:00) Brazil'},
        {value: 'UTC-03', label: '(UTC-03:00) Salvador'},
        {value: 'UTC-04', label: '(UTC-03:00) United States'},

    ]

    const salesData = {
        labels: [''],
        datasets: [
          {
            label: 'Sales yesterday',
            data: [100],
            backgroundColor: [
                '#FFD877'
            ],
            borderColor: [
                '#FFD877'
            ],
            borderWidth: 1,
            cutout:'70%'
          },
        ],
      };
      const salesDataOptions = {
          plugins: {
              legend: {
                  display: false
              },
              tooltip: {
                enabled: false,
              }
          } 
      }

      const lastFridayData = {
        labels: [''],
        datasets: [
          {
            label: 'Sales Last Friday',
            data: [25, 100],
            backgroundColor: [
                '#FFC1B4',
                '#FFFFFF'
            ],
            borderColor: [
                '#FFC1B4',
                '#FFFFFF'
            ],
            borderWidth: 1,
            cutout:'70%'
          },
        ],
      }

      useEffect(() => {
        console.log('LOADING', loading)
      },[loading])

      useEffect(() => {
        const fetchData = async () => {
            try {
                const partnerId = localStorage.getItem('partnerId'); // Make sure PartnerId is set in local storage
            const result = await axios.get(`/partners/dashboard-admin?PartnerId=${partnerId}`);
                setDashboardData(result.data.data[0])
                console.log(result.data)
        
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };
    
        fetchData();
    }, []);
    return (
        <>
        <SmallModal 
            showModal={workHourModal} 
            title={'Working hours'}
            closeModal={() => setWorkHourModal(false)}
        >
            <div className='flex justify-center items-center space-x-4'>
                <div>
                    <div className=''>From hour</div>
                    <Select
                        className='w-52'
                        name="fromHour"
                        options={fromHourList}
                        placeholder="Select from hour..."
                        // className="basic-multi-select"
                        // classNamePrefix="select"
                    />
                </div>
                <div>
                    <div className=''>Until hour</div>
                    <Select
                        className='w-52'
                        name="fromHour"
                        options={untilHourList}
                        placeholder="Select until hour..."
                        // className="basic-multi-select"
                        // classNamePrefix="select"
                    />
                </div>
            </div>
            <div className='mt-5'>Time zone</div>
            <Select
                className='w-full'
                name="fromHour"
                options={timezoneList}
                placeholder="Select time zone..."
                // className="basic-multi-select"
                // classNamePrefix="select"
            />
        </SmallModal>
        <div  className='absolute w-full lg:w-[calc(100%_-_230px)] top-20 z-10 bg-[#FFD877] left-0 lg:left-[230px] py-2 text-center text-xs'>The data from the dashboard is computed given your working hours. Current working hours: 02 - 02  <span className='font-bold cursor-pointer' onClick={() => setWorkHourModal(true)}>Change working hours</span></div>
        <Box className='mt-10 lg:mt-7'>
            <div className='flex space-x-5'>
                <div className='flex items-center'>Default<span className='ml-2'><IoIosArrowDown/></span></div>
                <div className='flex items-center'><span className='mr-2'><FaPlus/></span> New</div>
                <div className='items-center hidden lg:flex'><span className='mr-2'><FaExpandAlt/></span>Full screen</div>
            </div>
            <div className='mt-3 grid lg:grid-cols-3 gap-y-3 lg:gap-5'>
                <div className='p-5 border rounded-xl'>
                    <div className='text-2xl font-semibold'>Sales yesterday</div>
                    <div className='flex space-x-8 mt-2 items-center'>
                        <div className='max-h-28'>
                            <Doughnut data={salesData} options={salesDataOptions} />
                        </div>
                        <div>
                            {console.log(dashboardData)}
                            <div className='text-3xl font-semibold'>{dashboardData.sales_yesterday.toFixed(2)}</div>
                            {/* <div>until 14:18</div> */}
                        </div>
                    </div>
                </div>
                <div className='p-5 border rounded-xl'>
                    <div className='text-2xl font-semibold'>Sales last Friday RON</div>
                    <div className='flex space-x-8 mt-2 items-center'>
                        <div className='max-h-28'>
                            <Doughnut data={lastFridayData} options={salesDataOptions} />
                        </div>
                        <div>
                            <div className='text-3xl font-semibold'>{dashboardData.sales_last_week.toFixed(2)}</div>
                            {/* <div>until 14:18</div> */}
                        </div>
                    </div>
                </div>
                <div className='p-5 border rounded-xl'>
                    <div className='text-2xl font-semibold'>Sales today RON (02 -02)</div>
                    <div className='text-5xl font-semibold text-center mt-10'>
                    {dashboardData.sales_today.toFixed(2)}
                    </div>
                </div>
                <div className='pt-7 pb-5 px-5 border rounded-xl'>
                    <div className='p-3 bg-primary50 rounded-full w-max'><FiFileText color='#EBA800' size={24} /></div>
                    <div className='text-2xl font-semibold mt-2'>Orders today</div>
                    <div className='flex justify-between items-center'>
                        <div className='text-3xl font-semibold mt-2'>
                        {dashboardData.orders_today}
                        </div>
                        <div className='text-gray-400 font-semibold'>
                            0%
                        </div>
                    </div>
                </div>
                <div className='pt-7 pb-5 px-5 border rounded-xl'>
                    <div className='p-3 bg-primary50 rounded-full w-max'><BsFileSpreadsheet color='#EBA800' size={24} /></div>
                    <div className='text-2xl font-semibold mt-2'>Avg. order value</div>
                    <div className='flex justify-between items-center'>
                        <div className='text-3xl font-semibold mt-2'>
                        {dashboardData.avg_order_value.toFixed(2)} RON
                        </div>
                        <div className='text-gray-400 font-semibold'>
                            0%
                        </div>
                    </div>
                </div>
                <div className='pt-7 pb-5 px-5 border rounded-xl'>
                    <div className='p-3 bg-primary50 rounded-full w-max'><SlFire color='#EBA800' size={24} /></div>
                    <div className='text-2xl font-semibold mt-2'>Product trend</div>
                    <div className='text-3xl font-semibold mt-2'>
                    {dashboardData.top_product_last_week}
                        </div>
                  
                </div>
            </div>
            <div className='mt-7 grid lg:grid-cols-2 gap-y-3 lg:gap-5'>
               
              
                <div className='p-5 border rounded-xl'>
                    <div className='text-2xl font-semibold'>Top 5 tags by sales</div>
                    <div className='mt-8'>
                        <BarChart 
                            data={
                                [
                                  {
                                    data: [800, 150, 100, 380],
                                    color: '#FFB800',
                                    label: 'Today'
                                  },
                                  {
                                    data: [450, 600, 250, 900],
                                    color: '#FFC1B4',
                                    label: 'Yesterday'
                                  }
                                ]
                              } 
                              backgroundColor={'#7389CE'} 
                              labels={['CASH', 'RECEIPTS', 'PAYMENTS', 'CANCEL ORDER']} 
                              borderColor={'rgba(52, 160, 72, 1)'} 
                        />
                    </div>
                </div>
                <div className='p-5 border rounded-xl'>
                    <div className='text-2xl font-semibold'>Top 5 categories by sales</div>
                    <div className='mt-8'>
                        <BarChart 
                            data={
                                [
                                  {
                                    data: [200, 750, 100, 380],
                                    color: '#FFB800',
                                    label: 'Today'
                                  },
                                  {
                                    data: [150, 600, 850, 400],
                                    color: '#FFC1B4',
                                    label: 'Yesterday'
                                  }
                                ]
                              } 
                              backgroundColor={'#7389CE'} 
                              labels={['CASH', 'RECEIPTS', 'PAYMENTS', 'CANCEL ORDER']} 
                              borderColor={'rgba(52, 160, 72, 1)'} 
                        />
                    </div>
                </div>
            </div>
        </Box>
        </>
    );
};

export default Dashboard;
