import React, { useState } from 'react'
import CompanyTemplate from './CompanyTemplate'
import RoomConfigTemplate from './RoomConfigTemplate'
import CompanySettingsTemplate from './CompanySettingsTemplate'

const index = () => {
    const [selectedTab, setSelectedTab] = useState('company')

  return (
    <div>
        <div className='flex space-x-8 overflow-x-scroll'>
            {/* <div onClick={(e) => setSelectedTab(e.currentTarget.ariaLabel)} aria-label='billing' className={`${selectedTab == 'billing' && 'border-b-2 border-b-primaryColor'} cursor-pointer pb-2`}>Billing</div> */}
            <div onClick={(e) => setSelectedTab(e.currentTarget.ariaLabel)} aria-label='company' className={`${selectedTab == 'company' && 'border-b-2 border-b-primaryColor'} cursor-pointer pb-2`}>Company</div>
            <div onClick={(e) => setSelectedTab(e.currentTarget.ariaLabel)} aria-label='company-settings' className={`${selectedTab == 'company-settings' && 'border-b-2 border-b-primaryColor'} cursor-pointer pb-2`}>Company settings</div>
            {/* <div onClick={(e) => setSelectedTab(e.currentTarget.ariaLabel)} aria-label='users' className={`${selectedTab == 'users' && 'border-b-2 border-b-primaryColor'} cursor-pointer pb-2`}>Users</div> */}
            {/* <div onClick={(e) => setSelectedTab(e.currentTarget.ariaLabel)} aria-label='roles' className={`${selectedTab == 'roles' && 'border-b-2 border-b-primaryColor'} cursor-pointer pb-2`}>Roles</div> */}
            {/* <div onClick={(e) => setSelectedTab(e.currentTarget.ariaLabel)} aria-label='document-series' className={`${selectedTab == 'document-series' && 'border-b-2 border-b-primaryColor'} cursor-pointer pb-2`}>Document series</div> */}
            {/* <div onClick={(e) => setSelectedTab(e.currentTarget.ariaLabel)} aria-label='room-config' className={`${selectedTab == 'room-config' && 'border-b-2 border-b-primaryColor'} cursor-pointer pb-2`}>Room configuration</div> */}
        </div>
        <div className='mt-8'>
            {selectedTab == 'company' && <CompanyTemplate /> }
            {selectedTab == 'room-config' && <RoomConfigTemplate />}
            {/* {selectedTab == 'company-settings' && <CompanySettingsTemplate /> } */}

        </div>
    </div>
  )
}

export default index