import React, { useState } from "react";
import { Box, Button, FormHelperText, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import logo from '../../assets/img/logo.svg'
import "./style.css"
import { useNavigate } from "react-router-dom";
import CreateNewPassword from "./CreateNewPassword";

const ForgetPassword = () => {
  const { i18n, t } = useTranslation();
  const [resentLink, setResentLink] = useState(false)
  const [linkVerified, setLinkVerified] = useState(false)
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required")
    }),
    onSubmit: ({ resetForm }) => {
      if (!resentLink) {
        setResentLink(true)
      }
      resetForm();
    },
  });
  const LANGUAGES = [
    { label: "EN", code: "en" }, //English
    { label: "RO", code: "ro" }, //Romanian
    { label: "HU", code: "hu" }, //Hungarian
    { label: "DE", code: "de" }, //German
    { label: "ES", code: "es" }, //Spanish
    { label: "BE", code: "be" }, //French
  ];
  const onChangeLang = (e) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  };
  return (
    <>
      {linkVerified ?
        <CreateNewPassword /> :
        <>
          <Box className="login-info">
            <Box className="language-dropdown">
              <Select
                inputProps={{
                  id: "uncontrolled-native",
                }}
                defaultValue={i18n.language}
                onChange={onChangeLang}
              >
                {LANGUAGES.map((language, index) => (
                  <MenuItem key={index} value={language.code} sx={{ fontSize: '12px', fontWeight: '500' }}>
                    {language.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className="login-bg">
              <img src={logo} />
              {resentLink ? <Typography style={{ marginTop: '60px' }}>Reset Email is sent to your inbox </Typography> : <> <Typography>Forget Password?</Typography>
                <Box className="username-detail">
                  <label>Email</label>
                  <TextField id="outlined-basic" variant="outlined" name='email' onChange={formik.handleChange} value={formik.values.email} />
                  {formik.touched.email && formik.errors.email && (
                    <FormHelperText className="error" style={{ color: 'red', marginTop: '10px' }}>
                      {formik.errors.email}
                    </FormHelperText>
                  )}
                </Box>
                <Button className="sign-in-btn" onClick={() => formik.handleSubmit()}>Request Reset link</Button>
              </>}
            </Box>
          </Box>
        </>
      }
    </>
  );
};
export default ForgetPassword;
