export const GET_PARTNERS_REQUEST = "GET_PARTNERS_REQUEST";
export const GET_PARTNERS_SUCCESS = "GET_PARTNERS_SUCCESS";
export const GET_PARTNERS_FAILED = "GET_PARTNERS_FAILED";

export const ADD_PARTNERS_REQUEST = "ADD_PARTNERS_REQUEST";
export const ADD_PARTNERS_SUCCESS = "ADD_PARTNERS_SUCCESS";
export const ADD_PARTNERS_FAILED = "ADD_PARTNERS_FAILED";

export const DELETE_PARTNER_REQUEST = "DELETE_PARTNER_REQUEST";
export const DELETE_PARTNER_SUCCESS = "DELETE_PARTNER_SUCCESS";
export const DELETE_PARTNER_FAILED = "DELETE_PARTNER_FAILED";

export const EDIT_PARTNER_DATA_REQUEST = "EDIT_PARTNER_DATA_REQUEST";

export const GetPartnersAction = (data) => {
    return { type: GET_PARTNERS_REQUEST, payload: data };
};
export const AddPartnerAction = (data,loadingButton,closeModel) => {
    return { type: ADD_PARTNERS_REQUEST, payload: data ,loadingButton,closeModel};
};
export const EditPartnerDataAction = (data,loadingButton,closeModel) => {
    return { type: EDIT_PARTNER_DATA_REQUEST, payload: data ,loadingButton,closeModel};
};
export const DeletePartnerAction = (id) => {
    return { type: DELETE_PARTNER_REQUEST, payload: {'PartnerId':id} };
};