import React from 'react'
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

const CustomButton = ({backButton = false, label, onClickButton = () => {}, className, secondary = false, endIcon = false, startIcon = false, customBg, textColor, disabled = false }) => {
    const navigate = useNavigate()
  return (
    <div onClick={!disabled && onClickButton} className={`${className} flex cursor-pointer py-2 px-4 ${customBg ? customBg : secondary ? 'bg-white border-2' : 'bg-primaryColor'} rounded-lg w-max items-center`}>
        {startIcon && startIcon}
        <div className={`${startIcon && 'ml-2'} ${endIcon && 'mr-2'} ${textColor ?? ''}`}>{label}</div>
        {endIcon && endIcon}
    </div>
  )
}

export default CustomButton