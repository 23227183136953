import React from 'react';
import { DataGrid, GridCellModes } from '@mui/x-data-grid';
import './style.css'

const CustonDataGrid = ({ columns, rows, pageSize, height,processRowEdit, onRowClick, ...rest }) => {

    const [cellModesModel, setCellModesModel] = React.useState({});

  const handleCellClick = React.useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (event.target.nodeType === 1 && !event.currentTarget.contains(event.target)) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {},
            ),
          }),
          {},
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {},
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

    return (
        <div className='data-table-detail'>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                onRowClick={onRowClick}
                {...rest}
                disableColumnMenu
                showCellVerticalBorder
                showColumnVerticalBorder
                cellModesModel={cellModesModel}
                onCellModesModelChange={handleCellModesModelChange}
                onCellClick={handleCellClick}
                processRowUpdate={processRowEdit}
            />
        </div>
    );
};

export default CustonDataGrid;
