import React, { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import axios from '../axiosConfig.jsx'

export const GlobalContext = createContext()

const GlobalLayout = ({ children }) => {
    const clientId = localStorage.getItem('clientId')
    

    const [loading, setLoading] = useState(false)
    const [location, setLocation] = useState()


    const navigate = useNavigate()
      

    // useEffect(() => {
    //     console.log('CLIENTID', clientId)
    //     setLoading(true)
    //     if (clientId) {
    //         axios.get(`/partners/locations/${clientId}`)
    //         .then((data) => {
    //             console.log('DATA', data)
    //             setLocation(data?.locations[0])
    //             setLoading(false)
    //         })
    //         .catch((error) => {
    //             setLoading(false)
    //             console.log(error)
    //             toast.error('An error has occurred while loading the restaurant')
    //         })
    //     }
    //     else if (clientId === undefined) {
    //         setLoading(false)
    //     }
    //     else {
    //         setLoading(false)
    //         navigate('*')
    //     }
    // }, [ clientId, setLoading, setLocation])
    
    return (
        <GlobalContext.Provider
            value={{
                location, 
                setLocation,
                clientId,
                loading, 
                setLoading,
            }}
        >
            {/* {children} */}
            {loading ? (
            <div className="flex flex-col justify-center items-center font-bold p-[30px] pt-24 text-xl">
              {/* <ReactLoading type="spinningBubbles" /> */}
              
              LOADING...
            </div>
          ) : children}
        </GlobalContext.Provider>
    )
}

export default GlobalLayout
