import React from 'react'
import CategoryList from '../../component/CategoryList'
import CustomButton from '../../component/CustomButton'

const FormAddRestaurant = () => {
  return (
    <div className='w-full bg-secondaryColor rounded-lg p-5'>
        <div className='grid lg:grid-cols-2 lg:gap-x-52 gap-y-10'>
            <div className='space-y-5'>
                <div>
                    <div className='font-semibold'>Restaurant Name</div>
                    <input 
                        className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                    />
                </div>
                <div>
                    <div className='font-semibold'>Restaurant Phone</div>
                    <input 
                        className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                    />
                </div>
                <div>
                    <div className='font-semibold'>Delivery Mode</div>
                    <input 
                        className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                    />
                </div>
                <div>
                    <div className='font-semibold'>Admin Pick-Up Commission (%)</div>
                    <input 
                        className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                    />
                </div>
            </div>
            <div className='space-y-5'>
                <div>
                    <div className='font-semibold'>Restaurant Email</div>
                    <input 
                        className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                    />
                </div>
                <div>
                    <div className='font-semibold'>Restaurant Address</div>
                    <input 
                        className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                    />
                </div>
                <div>
                    <div className='font-semibold'>Admin Delivery Commission (%)</div>
                    <input 
                        className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                    />
                </div>
                <div>
                    <div className='font-semibold'>License Number</div>
                    <input 
                        className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                    />
                </div>
            </div>
            <div className=''>
                <CategoryList list={['Canadian', 'North Africa', 'Asian', 'Pizza', 'Pasta', 'Light Meals', 'Soup', 'Beverage']} />
            </div>
            <div className='place-content-end'>
                <CustomButton className={'px-24'} label={'Save'} />
            </div>
        </div>

    </div>
  )
}

export default FormAddRestaurant