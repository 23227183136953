import { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import "./ConfigurationProductDisplayCatDetails.css";
import ProductCard from '../../../component/ConfigurationsPage/ProductCard.jsx';
import CategoryProductPopup from './CategoryProductPopup.jsx';


const ConfigurationProductDisplayCatDetails = ({ selectedCategory,products,allCatWithProducts,fetchLatestDataAndMap,openAddPopup=false }) => {
  const ebrizaLinkedId = localStorage.getItem('ebrizaLinkedId');
  const catProducts = products.filter((ele)=>ele['CategoryId'] == selectedCategory['CategoryId'])
  const [popupOpen, setPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    if(!ebrizaLinkedId){
      setPopupOpen(true);
    }
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  useEffect(()=>{
    if(openAddPopup){
      handleOpenPopup()
    }
  },[])


  const handleSave = (selectedCategories, selectedProducts) => {
    // Handle save action
    console.log('Selected Categories:', selectedCategories);
    console.log('Selected Products:', selectedProducts);
  };
  return (
    <div className="details-view">
      
      <div className="cards">
      {catProducts.map((product, index) => (
        <div
          key={index}
          className="category-card"
          style={{ cursor: 'pointer' }}
         
        >

          <ProductCard
            product = {product}
            name1={product.name}
            imgUrl={product.imageUrl}
            price={product.priceVAT}
            fetchLatestDataAndMap={fetchLatestDataAndMap}
          />
        </div>
      ))}
      <div onClick={handleOpenPopup}  style={ebrizaLinkedId ? { cursor: 'not-allowed' } : { cursor: 'pointer' }} className="category-card7">
        <img className="icons22" alt="" src="/icons1.svg" />
        <div className="add-category7">Add Product</div>
      </div>
    </div>
    <CategoryProductPopup
        open={popupOpen}
        onClose={handleClosePopup}
        categories={allCatWithProducts}
        activeCategory={selectedCategory}
        onSave={handleSave}
        fetchLatestDataAndMap={fetchLatestDataAndMap}
      />
    </div>
  );
};

export default ConfigurationProductDisplayCatDetails;
