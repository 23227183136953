import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Menu, MenuItem, Pagination, TextField } from "@mui/material";
import StarRating from "./StarRating";
import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import './Reviews.css'
import axios from '../../axiosConfig';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import moment from 'moment';

export default function Reviews({ locationId }) {
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuRowId, setMenuRowId] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [tableId , setTableId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedReviewRating, setSelectedReviewRating] = useState('');
  const [selectedReviewId, setSelectedReviewId] = useState('');
    
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  // continue from here by adding popup for edit rating and delete rating

  const fetchReviews = async (page = 1) => {
    try {
      const response = await axios.get(`/partners/locations/rating_reviews_new?LocationId=${locationId}&page=${page}`);
      const data = response.data;
      console.log("fetchReviews data..",data);
      if (page === 1) {
        setAverageRating(data.average_rating);
        setTotalReviews(data.total_ratings);
        setTotalPages(data.total_pages);
      }

      setReviews(data.reviews);

    } catch (error) {
      console.error("Failed to fetch reviews:", error);
    }
  };

  useEffect(() => {
    fetchReviews(currentPage);
  }, [currentPage]);

  const handleRatingChange = (newRating) => {
    setReviews((prevReviews) =>
      prevReviews.map((review) =>
        review.ReviewId === menuRowId ? { ...review, rating: newRating } : review
      )
    );
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuRowId(null);
  };

  const handleEditRating = (review) => {
    handleMenuClose();
    setSelectedReviewRating(review.reviewRating)
    setSelectedReviewId(review.ReviewId)
    setOpenDialog(true)
  };

  const handleRemoveRating = (revId) => {
    handleMenuClose();
    setSelectedReviewId(revId)
    setOpenConfirmDialog(true)
  };

  const handleMenuClick = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setMenuRowId(rowId);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedReviewRating('');
    setSelectedReviewId('');
  };

  const handleRatingNumChange = (event)=>{
    const value = event.target.value;
    const floatValue = parseFloat(value);

    // Check if the value is a float number and between 0 and 5
    if (!isNaN(floatValue) && floatValue >= 0 && floatValue <= 5) {
      setSelectedReviewRating(floatValue);
    }
  }

  const handleReviewUpdate = async () => {
    try {

      if(selectedReviewId){
        let payload = {
          'reviewRating':selectedReviewRating,
          'ReviewId':selectedReviewId,
        }
        let updateRating = await axios.put(`/partners/locations/rating_reviews`,payload)
        
        if (updateRating.data.success) {
          console.log("updateMenuCat.",updateRating.data);
          let newData = reviews.map(cat => {
            if(cat.ReviewId === selectedReviewId){
              cat['reviewRating'] = selectedReviewRating
            }
            return cat;
          })
          setReviews(newData);
          handleCloseDialog();
        } else {
          console.error('Failed to update rating');
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  async function handleConfirmRemove() {
    console.log("Deleting selectedReviewId:", selectedReviewId);
    
    try {
      let delReview = await axios.delete(`/partners/locations/rating_reviews`,{data:{'ReviewId':selectedReviewId}})
      if (delReview.data) {
        fetchReviews(currentPage);
      }
      
    } catch (error) {
      console.log("Failed to delete product",error.message);
      
    } finally{
      setOpenConfirmDialog(false);
      setSelectedReviewId('');
      setSelectedReviewRating('');

    }
  }

  return (
    <div className="w-full lg:w-[100%] rounded-xl border py-1 px-2 lg:px-5 my-0">
      <div className="w-full lg:w-[50%] mx-auto">
        <div className="py-1 review-heading-cont">
          <h1 className="text-2xl lg:text-xl font-semibold">Reviews</h1>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="avgRating">{averageRating}</span>
            <span className="star filled" style={{ paddingRight: '5px' }}>★</span>
            <span>({totalReviews}) recenzii</span>
          </div>
        </div>
        {/* Review listing container with pagination */}
        <div>
          {reviews.map((review) => (
            <div key={review.ReviewId} className="rounded-xl border my-3 py-1 px-2 lg:px-5 mt-0">
              <div style={{display:'flex',justifyContent:'space-between'}}>
                <div style={{display:'flex', alignItems: 'center'}}>
                    {/* <AccountCircleIcon /> */}
                    <div>
                      <h4 style={{fontSize:'14px'}}>{moment(review?.reviewDate).format('MMM D, YYYY')}</h4>
                      {review?.reviewEmail && <h4 style={{fontSize:'14px'}}>{review.reviewEmail}</h4>} 
                      {review?.tableId && <h4 style={{fontSize:'14px'}}>Table ID: {review.tableId}</h4>} 
                      
                    </div>

                </div>
                <>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={(event) => handleMenuClick(event, review.ReviewId)}
                >
                  <MoreVertIcon />
                </IconButton>

                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  open={menuRowId === review.ReviewId}
                  onClose={handleMenuClose}
                  PaperProps={{
                    style: {
                      maxHeight: 48 * 4.5,
                      width: "20ch",
                    },
                  }}
                >
                  <MenuItem onClick={() => handleEditRating(review)}>Edit</MenuItem>
                  <MenuItem onClick={() => handleRemoveRating(review.ReviewId)}>Delete</MenuItem>
                </Menu>
              </>
              </div>
              <StarRating rating={review.reviewRating} onRatingChange={handleRatingChange} />
              <p>{review.reviewText}</p>
              
            </div>
          ))}
        </div>

        {/* Pagination */}
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          className="pagination"
          style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
        />
      </div>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{'Edit Rating'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="editRating"
            label="Edit Rating"
             type="number"
            fullWidth
            variant="standard"
            inputProps={{ min: "0", max: "5", step: "0.5" }} 
            value={selectedReviewRating}
            onChange={handleRatingNumChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleReviewUpdate}>
            {'Update'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete this rating?</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmRemove} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}
