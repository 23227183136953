import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Tab, Tabs } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import "react-datepicker/dist/react-datepicker.css"
import '../style.css'
import { useLocation, useNavigate } from 'react-router-dom';
import LocationDetailTab from './LocationDetailTab';
import MenuTab from './MenuTab';
import RatingReviewTab from './RatingReviewTab';
import ConfigurationTab from './ConfigurationTab';
import DefaultImg from '../../../assets/img/default-restaurant-img.svg'
import locationfood from '../../../assets/img/location-bg.png'
import { t } from 'i18next';
import EditImgModel from './EditImgModal';
import EditIcon from '@mui/icons-material/Edit';
import RoomNTable from './Room&Table';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    if(value != 3) localStorage.removeItem('tableState');
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const LocationDetails = () => {
    const location = useLocation();
    const selectedLocation = location.state;
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const [profileImgOpen, setProfileImgOpen] = useState(false)
    const [showProfileImage, setShowProfileImage] = useState(DefaultImg);
    const [coverImgOpen, setCoverImgOpen] = useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleEditImage = () => {
        setCoverImgOpen(false)
        setProfileImgOpen(false)
    }
    return (

        <Box className=''>
            <Box className="partner-title">
                <Button className='back-btn' onClick={() => navigate('/locations')}><KeyboardBackspaceIcon /> {t("back_btn")}</Button>
            </Box>
            <EditImgModel coverImgOpen={coverImgOpen} profileImgOpen={profileImgOpen} locationId={selectedLocation?.LocationId} handleClose={handleEditImage} setShowProfileImage={setShowProfileImage}/>
            <Box className='location-img'>
                <img src={locationfood} />
                <Button onClick={() => setCoverImgOpen(true)}>{t("edit_cover_image")}</Button>
                <Box className='location-cover-main'>
                    <Box sx={{
                        position: 'relative',
                    }}>
                        <Box className='location-cover-wrap'>
                            <img src={showProfileImage? showProfileImage : selectedLocation?.image} />
                        </Box>
                        <Box className="edit-icon-box" onClick={() => setProfileImgOpen(true)}>
                            <EditIcon />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Box>
                    <Tabs className='location-tab mt-10 lg:mt-5' value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={t("location_tab")} {...a11yProps(0)} />
                        <Tab label={t("menu_tab")} {...a11yProps(1)} />
                        <Tab label={'Room & Table'} {...a11yProps(2)} />
                        <Tab label={t("rating_tab")} {...a11yProps(3)} />
                        <Tab label={t("configuration_tab")} {...a11yProps(4)} />
                    </Tabs>
                </Box>

                <CustomTabPanel value={value} index={0}>
                    <LocationDetailTab />
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1} className='menu-tab'>
                    <MenuTab />
                </CustomTabPanel>

                <CustomTabPanel value={value} index={2} >
                    <RoomNTable />
                </CustomTabPanel>

                <CustomTabPanel value={value} index={3}>
                    <RatingReviewTab />
                </CustomTabPanel>

                <CustomTabPanel value={value} index={4} >
                    <ConfigurationTab />
                </CustomTabPanel>
            </Box>
        </Box>
    );
};

export default LocationDetails;
