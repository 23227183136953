import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer'
import "./App.css";
import AppRoutes from "./routes";
import "./i18n";
import { useProvideErrorHandler } from "./hooks";
import { errorHandlerContext } from "./contexts";
import Snackbar from "./component/ErrorHandler/Snackbar";
import GlobalLayout from "./contexts/GlobalContext";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
const theme = createTheme();

function ProvideErrorHandler({ children }) {
  const msg = useProvideErrorHandler();
  return (
    <errorHandlerContext.Provider value={msg}>
      <Snackbar
        alertOpen={msg?.msgConfig.alertOpen}
        handleClose={() => msg?.handleClose()}
        message={msg?.msgConfig.message}
        severity={msg?.msgConfig.severity}
      />
      {children}
    </errorHandlerContext.Provider>
  );
}

const App = () => {
  const [count, setCount] = useState(0)
  const onAction = () => {
    setCount(count + 1)
  }
  const { getRemainingTime } = useIdleTimer({
    onAction,
    timeout: 3600_000,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (Math.ceil(getRemainingTime() / 1000) === 0) {
        localStorage.clear();
        window.location.reload();
      }
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })
  return (
    <ThemeProvider theme={theme}>
    <ProvideErrorHandler>
        <BrowserRouter>
          <GlobalLayout>
            <AppRoutes />
          </GlobalLayout>
        </BrowserRouter>
    </ProvideErrorHandler>
    </ThemeProvider>
  );
};

export default App;
