import React, { useState } from "react";
import PropTypes from "prop-types";
import "./StarRating.css"; // You'll need to create this CSS file for styling

const StarRating = ({ rating, onRatingChange }) => {
  const [hoveredRating, setHoveredRating] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredRating(index);
  };

  const handleMouseLeave = () => {
    setHoveredRating(null);
  };

  const handleClick = (index) => {
    onRatingChange(index);
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      let starClass = "star empty";
      if (i <= (hoveredRating || rating)) {
        starClass = "star filled";
      } else if (i === Math.ceil(rating) && rating % 1 !== 0) {
        starClass = "star half-filled"; // Class for half-filled star
      }

      stars.push(
        <span
          key={i}
          className={starClass}
          // onMouseEnter={() => handleMouseEnter(i)}
          // onMouseLeave={handleMouseLeave}
          // onClick={() => handleClick(i)}
        >
          ★
        </span>
      );
    }
    return stars;
  };

  return <div className="star-rating">{renderStars()}</div>;
};

StarRating.propTypes = {
  rating: PropTypes.number.isRequired,
  onRatingChange: PropTypes.func.isRequired,
};

export default StarRating;
