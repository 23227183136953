import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/img/logo.svg'
import logoMobile from '../../assets/img/logo.png'
import global from '../../assets/img/global-icon.png'
import rightarrow from '../../assets/img/right-arrow.png'
import profile from '../../assets/img/profile-img.png'
import restaurant from '../../assets/img/restaurant-icon.svg'
import selRestaurant from '../../assets/img/sel-resIcon.svg'
import speed from '../../assets/img/dashboard.svg'
import selectedDashboard from '../../assets/img/selected-dashboard.svg'
import adbanner from '../../assets/img/banners-icon.svg'
import pos from '../../assets/img/pos.svg'
import selPos from '../../assets/img/pos-selected.svg'
import qrcode from '../../assets/img/qrcode-icon.svg'
import user from '../../assets/img/users-icon.svg'
import order from '../../assets/img/orders-icon.svg'
import selOrder from '../../assets/img/sel-orderIcon.svg'
import menu from '../../assets/img/menu-icon.svg'
import conf from '../../assets/img/configuration.svg'
import selConf from '../../assets/img/sel-configuration.svg'
import rep from '../../assets/img/reports.svg'
import selRep from '../../assets/img/sel-reports.svg'
import mar from '../../assets/img/market.svg'
import selMar from '../../assets/img/sel-market.svg'
import settingsIcon from '../../assets/img/settings.svg'
import selectedSettingsIcon from '../../assets/img/selected-settings.svg'
import toggle from '../../assets/img/toggle-icon.png'



import './style.css'
import { MenuItem, Select } from '@mui/material';
import { useErrorHandler } from '../../hooks';
import { LogoutAction } from '../../Redux/Actions/AuthActions';
import { useDispatch } from 'react-redux';
import useWindowDimensions from '../../hooks/useWindowDimension';

function DrawerAppBar(props) {
  const { window } = props;
  const userRole = localStorage.getItem('userRole');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { setMsgConfig } = useErrorHandler();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(window?.location.pathname);
  const { height, width } = useWindowDimensions()

  const handleTab = (item) => {
    setSelectedTab(item?.label);
    navigate(item?.path);
    setMobileOpen(false)
  };

  React.useEffect(() => {
    console.log('WIDTH', width)
    if (selectedTab === "") {
      if (userRole === 'ADMIN') {
        handleTab(adminsSidebarItems[0]);
      } else {
        handleTab(partnersSidebarItems[0]);
      }
    }
    const handleLocationChange = () => {
      setSelectedHeaderitem(window?.location.pathname);
    };
    window?.addEventListener("popstate", handleLocationChange);
    return () => {
      window?.removeEventListener("popstate", handleLocationChange);
    };
  }, []);

  const partnersSidebarItems = [
    {
      label: "Dashboard",
      image: speed,
      selectedImage: selectedDashboard,
      path: "/",
    },
    {
      label: "Configuration",
      image: conf,
      selectedImage: selConf,
      path: "/configuration",
    },
    // {
    //   label: "Orders",
    //   image: order,
    //   selectedImage:selOrder,
    //   path: "/orders",
    // },
    // {
    //   label: "Locations",
    //   image: restaurant,
    //   selectedImage:selRestaurant,
    //   path: "/locations",
    // },
    // {
    //   label: "POS",
    //   image: pos,
    //   selectedImage:selPos,
    //   path: "/pos",
    // },
    {
      label: "Reports",
      image: rep,
      selectedImage:selRep,
      path: "/reports",
    },
    // {
    //   label: "Marketplace",
    //   image: mar,
    //   selectedImage:selMar,
    //   path: "/marketplace",
    // },
    
    {
      label: "Settings",
      image: settingsIcon,
      selectedImage: selectedSettingsIcon,
      path: "/settings",
    },
    {
      label: "Logout",
      image: qrcode,
      path: "/",
    },
  ];
  const adminsSidebarItems = [
    {
      label: "Dashboard",
      image: speed,
      path: "/",
    },
    {
      label: "Partners",
      image: restaurant,
      path: "/partners",
    },
    {
      label: "Users",
      image: user,
      path: "/users",
    },
    {
      label: "Logout",
      image: qrcode,
      path: "/",
    },
  ];
  const userSidebarItems = [
    {
      label: "Dashboard",
      image: speed,
      path: "/",
    },
    {
      label: "Locations",
      image: restaurant,
      path: "/locations",
    },
    {
      label: "Orders",
      image: order,
      path: "/orders",
    },
    {
      label: "Logout",
      image: qrcode,
      path: "/",
    },
  ];

  const LANGUAGES = [
    { label: "EN", code: "en" }, //English
    { label: "RO", code: "ro" }, //Romanian
    { label: "HU", code: "hu" }, //Hungarian
    { label: "DE", code: "de" }, //German
    { label: "ES", code: "es" }, //Spanish
    { label: "BE", code: "be" }, //French
  ];

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const onChangeLang = (e) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  };

  // const container = window !== undefined ? () => window().document.body : undefined;
  const handleLogout = () => {
    // localStorage.clear();
    dispatch(LogoutAction());
    setMsgConfig({
      alertOpen: true,
      message: "Message",
      severity: "Severity",
      autoHideDuration: 2000,
    });
    localStorage.clear()
    navigate("/login");
  };

  return (
    <>
      <Box className="top-navbar border-b-2 border-[#CBCAC8] z-20 relative bg-white" sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar component="nav">
          <Toolbar>
            <Box className="navbar-logo">
              {width <= 425 ? <img id='logoMobile' src={logoMobile} /> : <img src={logo} /> }
            </Box>

            <Box className="website-detail">
              {/* <a href=''><img src={global} /> {t("view_website")}</a> */}
              <Box sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center'
              }}>
                {/* <Box className="">
                  <Select
                    // defaultValue={"en"}
                    inputProps={{
                      id: "uncontrolled-native",
                    }}
                    defaultValue={i18n.language}
                    onChange={onChangeLang}
                  >
                    {LANGUAGES.map((language, index) => (
                      <MenuItem key={index} value={language.code} sx={{ fontSize: '12px', fontWeight: '500' }}>
                        {language.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box> */}
                {/* <Button className='profile-btn'>
                  <img src={profile} />
                  
                  <span>{userRole === 'ADMIN' ? 'ADMIN' : userRole === 'PARTNER' ? 'PARTNER' : 'USER'}</span>
                  <img src={rightarrow} />
                </Button> */}
              </Box>
            </Box>

            <IconButton
              color="inherit"
              className='toggle-btn'
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, }}
            >
              {!mobileOpen ? <img src={toggle} className='toogle-icon' />
                : <img src={close} className='close-icon' />}
            </IconButton>
          </Toolbar>
        </AppBar>
        {mobileOpen &&
          <Box className={`mobile-sidebar`}>
            <List>
              {userRole === 'ADMIN' ?
                adminsSidebarItems.map((item, index) => (
                  <Box
                    className={
                      item?.label === selectedTab
                        ? `sidebar-menu-active` : 'sidebar-menu'
                    }>
                    <ListItem
                      key={index}
                      href="#"
                      onClick={() => item?.label === 'Logout' ? handleLogout() : handleTab(item)}
                    >
                      <span>{item?.label === 'Logout' ? <LogoutIcon /> : <img src={item?.image} />}</span>
                      {/* {item?.label} */}
                      {t(item?.label)}
                    </ListItem>
                  </Box>
                )) :
                userRole === 'PARTNER' ? partnersSidebarItems.map((item, index) => (
                  <Box
                    className={
                      item?.label === selectedTab
                        ? `sidebar-menu-active` : 'sidebar-menu'
                    }>
                    <ListItem
                      key={index}
                      href="#"
                      onClick={() => item?.label === 'Logout' ? handleLogout() : handleTab(item)}
                    >
                      <span>{item?.label === 'Logout' ? <LogoutIcon /> : (item?.label === selectedTab || item?.path === selectedTab) ? <img src={item?.selectedImage} /> : <img src={item?.image} />}</span>
                      {/* {item?.label} */}
                      {t(item?.label)}
                    </ListItem>
                  </Box>
                )) : userSidebarItems.map((item, index) => (
                  <Box
                    className={
                      item?.label === selectedTab
                        ? `sidebar-menu-active` : 'sidebar-menu'
                    }>
                    <ListItem
                      key={index}
                      href="#"
                      onClick={() => item?.label === 'Logout' ? handleLogout() : handleTab(item)}
                    >
                      <span>{item?.label === 'Logout' ? <LogoutIcon /> : <img src={item?.image} />}</span>
                      {/* {item?.label} */}
                      {t(item?.label)}
                    </ListItem>
                  </Box>
                ))}
            </List>
          </Box>
        }

      </Box>
    </>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
