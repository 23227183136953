import React, { useState } from 'react'
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlinePicture } from "react-icons/ai";
import CustomButton from '../../component/CustomButton';
import { useNavigate } from 'react-router-dom';
import ImageCropper from '../../component/ImageCropper';



const AddCategory = () => {
    const navigate = useNavigate()
    const [showImageModal,setShowImageModal] = useState(false)
    const [fileDataURL, setFileDataURL] = useState()
    const [presentationImg,setPresentationImg] = useState(null)
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            const img = new Image();
            img.onload = async () => {
              console.log(img.naturalWidth, img.naturalHeight, "natural");
                // const uploadedUrl = await commonFunctions.uploadImageToAws(
                //   reader.result
                // );
                // setImage(uploadedUrl);
                // setProfilePic(reader.result)
                setShowImageModal(true);
                setFileDataURL(reader.result);
                // formik.setTouched({ image: true }, true);
            };
            img.src = reader.result;
          };
          reader.readAsDataURL(file);
        }
      };
  return (
    <div className='grid lg:grid-cols-3'>
        <div className='lg:col-span-2'>
            <div className='flex justify-between'>
                <div className='font-semibold text-xl'>Add New Category</div>
                <div onClick={() => navigate(-1)} className='cursor-pointer flex text-primaryColor space-x-2 items-center'>
                    <IoIosArrowBack size={'30px'} />
                    <div>Category List</div>
                </div>
            </div>
            <div className='mt-5 bg-secondaryColor rounded-lg drop-shadow p-5 lg:grid lg:grid-cols-2 lg:gap-5'>
                <div>
                    <div>
                        <div className='text-xl font-semibold'>
                            Name
                        </div>
                        <input className='h-10 p-3 rounded-lg drop-shadow-sm w-full lg:w-auto' />
                    </div>
                    <div className='mt-5'>
                        <div className='text-xl font-semibold'>
                            Description
                        </div>
                        <input className='h-32 lg:h-14 p-6 w-full lg:w-auto rounded-lg drop-shadow-sm' />
                    </div>
                </div>
                <div className='mt-5 lg:mt-0'>
                    <div className='text-xl font-semibold'>Presentation Image</div>
                    <p>Aspec ratio 4:3</p>
                    <label htmlFor='presentation-img'>
                        {presentationImg ? (
                            <img className='border-2 border-primaryColor rounded-lg' src={presentationImg} width={214} height={160} />
                            
                        ):(
                            <div className='cursor-pointer flex items-center justify-center h-[160px] w-[214px] bg-white border-2 border-primaryColor border-solid rounded-lg'>
                                <AiOutlinePicture size={30}/>
                            </div>

                        )}
                    </label>
                    <input
                        type="file"
                        id="presentation-img"
                        name="presentation-img"
                        accept="image/*"
                        onChange={(e) => handleImageChange(e)}
                        className='opacity-0 -z-10 absolute'
                    />
                    <ImageCropper
                        show={showImageModal}
                        setShowImageModal={setShowImageModal}
                        fileDataURL={fileDataURL}
                        //   setCroppedImage={setCroppedImage}
                        setImage={setPresentationImg}
                        aspectRatio={4/3}
                        resizeable={true}
                    />
                </div>
                <div></div>
                <div className='mt-32'>
                    <CustomButton className={'px-20'} label={'Submit'} />
                </div>
            </div>
        </div>
    </div>
  )
}

export default AddCategory