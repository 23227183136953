import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Typography, FormControl,InputLabel, MenuItem, TextField, Box, FormHelperText } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import ReactLoading from 'react-loading';
import addLocation from '../../libs/addLocation';
import { TOAST_SHOW } from '../../Redux/Actions/ToastAction';
import axios from '../../axiosConfig';
import "./AddProduct.css";
import NutritionalValueCalculator from "../Locations/LocationDetails/NutritionalValueCalculator";
import { AddMenuProductAction, EditImageAction } from "../../Redux/Actions/LocationActions";
import DefaultImg from '../../assets/img/default-restaurant-img.svg'
import EditImgModel from "../Locations/LocationDetails/EditImgModal";
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from "../../component/ErrorHandler/Snackbar";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { height, styled } from '@mui/system';

export default function AddProduct({productsEbz,productCategoriesEbz,fetchLatestDataAndMap}) {
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [product, setProductDetails] = useState({});
  const [prefilNutrition, setPrefilNutrition] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [showSnack, setShowSnack] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ingredientsData,portionValue,awsImgUrl } = useSelector((state) => state.Location);
  const { id } = useParams();
  const partnerId = localStorage.getItem('partnerId');
  const ebrizaLinkedId = localStorage.getItem('ebrizaLinkedId');
  const [profileImgOpen, setProfileImgOpen] = useState(false)
  const [showProfileImage, setShowProfileImage] = useState(DefaultImg);
  const [coverImgOpen, setCoverImgOpen] = useState(false);

 
const modules = {
  toolbar: [
    [{ 'font': [] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
    [{ 'align': [] }],
    ['link'],
    ['clean']                                         // remove formatting button
  ],
};


const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link',
  'color', 'background', 'align', 'direction', 'script'
];
  const vatOptions = [
    { value: 0, label: 'Zero 0%' },
    { value: 5, label: 'Social Housing 5%' },
    { value: 9, label: 'Books 9%' },
    { value: 19, label: 'Standard 19%' }
  ]
  const [discountType, setDiscountType] = useState({ value: 'percentage', label: 'Enter Percentage' });

  const options = [
    { value: 'percentage', label: 'Percentage' },
    { value: 'price', label: 'Fixed Price' },
  ];

  const handleDiscountChange = (selectedOption) => {
    setDiscountType(selectedOption);
    formik.setFieldValue('discountType', selectedOption?.value || '');
  };
  const handleNavigateToConf = async (productData)=>{
    console.log("productData..data..",productData);
    if(productData && productData['data']['ProductId']){
      if( formik.values.discountedPrice || formik.values.discountedPercentage){
        let partnerDisPayload = {
            "productId": productData['data']['ProductId'],
            discountedPrice: discountType.value == 'price'?formik.values.discountedPrice:0,
            discountedPercentage: discountType.value == 'percentage'? formik.values.discountedPercentage:0
        
        }

        const addProductDis = await axios.post(`/partners/addProductDiscount`,partnerDisPayload);
        console.log("addProductDis/...",addProductDis);

      }
    }


    fetchLatestDataAndMap()
    dispatch(EditImageAction(''))
    navigate('/configuration');
  }

  const handleEditImage = () => {
    setCoverImgOpen(false)
    setProfileImgOpen(false)
  }

  async function fetchCategories() {
    try {
      const response = await axios.get(`/partners/menu_categories`);
      setCategories(response.data.data);
      const locRes = await axios.get(`/partners/locationsForPickup?ClientId=${partnerId}`);
      setLocations(locRes.data.locations);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }
  async function fetchSku() {
    try {
      const response = await axios.post(`/partners/generateSku`);
      console.log("response.data.sku..",response.data.sku);
      formik.setFieldValue('sku',response.data.sku)
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }
  async function fetchEbrizaData() {
    try {
      setCategories([{ value: 'Select Category', label: 'Select Category' }]);
      const locRes = await axios.get(`/partners/locationsForPickup?ClientId=${partnerId}`);
      setLocations(locRes.data.locations);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }

  useEffect(() => {
    if(!ebrizaLinkedId){
      fetchCategories();
      if (id == 'new') {
        fetchSku()
      }
    }
  }, [partnerId]);

  useEffect(() => {
    console.log("fetchEbrizaData before..",productsEbz,productCategoriesEbz);
    if(ebrizaLinkedId && productsEbz && productCategoriesEbz && productsEbz.length && productCategoriesEbz.length){
      console.log("fetchEbrizaData..");
      fetchEbrizaData();
    }
  }, [productsEbz,productCategoriesEbz]);

  useEffect(() => {
    const fetchProductDetails = async () => {
      if (locations.length > 0 && categories.length > 0) {
        if (id !== 'new') {
          // Fetch product details
          const response = await axios.get(`/partners/menu_products?ProductId=${id}`);
          console.log("product edit data..", response.data);
          setProductDetails(response.data.data[0]);

  
          if(!ebrizaLinkedId){
            let pro = response.data.data[0];
            let catData = categories.find((ele)=>ele.CategoryId == pro.CategoryId)
            
            let locFilterSelect = locations.filter((ele)=>pro.LocationId.includes(ele.LocationId)).map((eleM)=>{
              return {
                'value':eleM.LocationId,
                'label':eleM.name
              }
            })
            console.log("locFilterSelect",locFilterSelect,locations);
            let updatedValues = {
              category: pro.CategoryId? { value: catData.CategoryId, label: catData.name } :{ value: 'Select Category', label: 'Select Category' },
              availability: pro.LocationId?locFilterSelect : '',
              productName: pro.name || '',
              sku: pro.sku || '',
              tag: pro.tags || '',
              unitQuantityDescription: '',
              description: pro.longDescription || '',
              shortDescription: pro.shortDescription || '',
              addition: pro.addition || '',
              price: pro.price || 0,
              vat: vatOptions.find((eleV)=>eleV.value == pro.VATPercent) || 0,
              priceVat: pro.priceVAT || 0,
              isForDelivery: pro.isForDelivery || false,
              isForAdultsOnly: pro.isForAdultsOnly || false,
              guarantee: false,
              imageUrl: pro.imageUrl || ''
            }
            console.log("fromik updated values..",updatedValues);
            formik.setValues(updatedValues);
            
            if(pro.ingredients && pro.ingredients[0]){
              let mapIngre = pro.ingredients.map((eleI)=>{
                return {
                  'IngredientId':eleI.IngredientId,
                  'name': eleI.name,
                  'amount':eleI.amount?eleI.amount:100,
                  'carbohydrates': eleI.carbohydrates?JSON.parse(eleI.carbohydrates).value : "",
                  'energyValue': eleI.energy_value?JSON.parse(eleI.energy_value).value : "",
                  'fats': eleI.fat?JSON.parse(eleI.fat).value : "",
                  'salt': eleI.salt?JSON.parse(eleI.salt).value : "",
                  'sugars': eleI.sugars?JSON.parse(eleI.sugars).value : "",
                  'proteins': eleI.proteins?JSON.parse(eleI.proteins).value : "",
                  'frozen': eleI.frozen=='true'?true:false,
                  'allergens': eleI.allergens? JSON.parse(eleI.allergens) : [],
                  'potential_allergens': eleI.potential_allergens ? JSON.parse(eleI.potential_allergens) : [],
                  'additives': eleI.additives ? JSON.parse(eleI.additives) : [],
                  'fattyAcids': JSON.parse(eleI.saturated_fatty_acid).value || "",
                  
                }
  
              })
              setPrefilNutrition(mapIngre)
            }
  
            setInitialValues(updatedValues);

          }else{
            let productDetFromEbzData = productsEbz.find((ele)=>ele.ProductId == id);
            let pro = response.data.data[0];
            
            let updatedValues = {
              category: { value: productDetFromEbzData['CategoryId'], label: productDetFromEbzData['category'] },
              availability: [{value: locations[0].LocationId ,label: locations[0].name}],
              productName: productDetFromEbzData.name || '',
              sku: productDetFromEbzData.sku || '',
              tag: productDetFromEbzData.tags.join(',') || '',
              unitQuantityDescription: '',
              description: productDetFromEbzData.longDescription || '',
              shortDescription: productDetFromEbzData.shortDescription || '',
              addition: productDetFromEbzData.addition || '',
              price: productDetFromEbzData.price || 0,
              vat: {'value': productDetFromEbzData.VATPercent,'label':`${productDetFromEbzData.VATPercent} Percent`},
              priceVat: productDetFromEbzData.priceVAT || 0,
              isForDelivery: productDetFromEbzData.isForDelivery || false,
              isForAdultsOnly: productDetFromEbzData.isForAdultsOnly || false,
              guarantee: false,
              imageUrl: productDetFromEbzData.imageUrl || ''
            }
            
            console.log("fromik updated values..",updatedValues);
            formik.setValues(updatedValues);

            if(pro && pro.ingredients && pro.ingredients[0]){
              let mapIngre = pro.ingredients.map((eleI)=>{
                return {
                  'IngredientId':eleI.IngredientId,
                  'name': eleI.name,
                  'amount':eleI.amount?eleI.amount:100,
                  'carbohydrates': eleI.carbohydrates?JSON.parse(eleI.carbohydrates).value : "",
                  'energyValue': eleI.energy_value?JSON.parse(eleI.energy_value).value : "",
                  'fats': eleI.fat?JSON.parse(eleI.fat).value : "",
                  'salt': eleI.salt?JSON.parse(eleI.salt).value : "",
                  'sugars': eleI.sugars?JSON.parse(eleI.sugars).value : "",
                  'proteins': eleI.proteins?JSON.parse(eleI.proteins).value : "",
                  'frozen': eleI.frozen=='true'?true:false,
                  'allergens': eleI.allergens? JSON.parse(eleI.allergens) : [],
                  'potential_allergens': eleI.potential_allergens ? JSON.parse(eleI.potential_allergens) : [],
                  'additives': eleI.additives ? JSON.parse(eleI.additives) : [],
                  'fattyAcids': JSON.parse(eleI.saturated_fatty_acid).value || "",
                  
                }
  
              })
              setPrefilNutrition(mapIngre)
            }
          }

          
          const resP = await axios.get(`/partners/getProductDiscount?productId=${id}`);
          console.log("product dis data..", resP.data);

          if(resP.data.locations && resP.data.locations.length){
            let disData = resP.data.locations[0]
            if(disData.discountedPrice){
              setDiscountType(options[1])
              formik.setFieldValue('discountedPrice', disData.discountedPrice)
            }else if(disData.discountedPercentage){
              setDiscountType(options[0])
              formik.setFieldValue('discountedPercentage', disData.discountedPercentage)

            }
          } 

        }
      }
    };
  
    fetchProductDetails();
  }, [locations, categories]);

  const formik = useFormik({
    initialValues: {
      category:  '',
      availability:  [],
      productName:  '',
      sku: '',
      tag: '',
      unitQuantityDescription: '',
      description: '',
      shortDescription: '',
      addition:'',
      price:0,
      vat: 0,
      priceVat:0,
      isForDelivery: false,
      isForAdultsOnly: false,
      guarantee: false,
      imageUrl:'',
      discountedPrice:'',
      discountedPercentage:''
    },
    validationSchema: Yup.object({
      category: Yup.object().required('Category is required'),
      productName: Yup.string().required('Product name is required'),
      sku: Yup.string().required('SKU is required'),
      availability: Yup.array().min(1, 'At least one location is required').required('At least one location is required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      console.log("selected ingredients/...",ingredientsData);
      try {
        if (id === 'new') {
          
          let total_calculated = {
            "carbohydrates":{"value":portionValue['carbohydrates'],"unit":"g"},
            "saturated_fatty_acid":{"value":portionValue['fattyAcids'],"unit":"g"},
            "salt":{"value":portionValue['salt'],"unit":"g"},
            "sugars":{"value":portionValue['sugars'],"unit":"g"},
            "level":'100_g',
            "proteins":{"value":portionValue['proteins'],"unit":"g"},
            "energy_value":{"value":portionValue['energyValue'],"unit":"kcal"},
            "fat":{"value":portionValue['fats'],"unit":"g"}
          }

          let ingredientsDataPayload = []

          ingredientsData.map((ele)=>{
            // total_calculated['carbohydrates']['value'] += parseFloat(ele['carbohydrates'])
            // total_calculated['energy_value']['value'] += parseFloat(ele['energyValue'])
            // total_calculated['saturated_fatty_acid']['value'] += parseFloat(ele['fats'])
            // total_calculated['salt']['value'] += parseFloat(ele['salt'])
            // total_calculated['sugars']['value'] += parseFloat(ele['sugars'])
            // total_calculated['proteins']['value'] += parseFloat(ele['proteins'])
            // total_calculated['fat']['value'] += parseFloat(ele['fats'])

            ingredientsDataPayload.push({
              additives: ele['additives'],
              allergens: ele['allergens'],
              energy_value: {"value":`${ele['energyValue']}`,"unit":"kj"},
              fat:  {"value":`${ele['fats']}`,"unit":"g"},
              frozen: ele['frozen'],
              level: portionValue,
              name: ele['name'],
              potential_allergens: ele['potential_allergens'],
              proteins:  {"value":`${ele['proteins']}`,"unit":"g"},
              salt:  {"value":`${ele['salt']}`,"unit":"g"},
              saturated_fatty_acid:  {"value":`${ele['fattyAcids']}`,"unit":"g"},
              spicy: false,
              sugars: {"value":`${ele['sugars']}`,"unit":"g"},
              'carbohydrates':  {"value":`${ele['carbohydrates']}`,"unit":"g"},
              'amount':ele['amount'],

            })
          })

            total_calculated['carbohydrates']['value'] = total_calculated['carbohydrates']['value'].toString()
            total_calculated['energy_value']['value'] = total_calculated['energy_value']['value'].toString()
            total_calculated['saturated_fatty_acid']['value'] =  total_calculated['saturated_fatty_acid']['value'].toString()
            total_calculated['salt']['value'] = total_calculated['salt']['value'].toString()
            total_calculated['sugars']['value'] = total_calculated['sugars']['value'].toString()
            total_calculated['proteins']['value'] = total_calculated['proteins']['value'].toString()
            total_calculated['fat']['value'] = total_calculated['fat']['value'].toString()

          let allLoc = 
          formik.values.availability.map((eleAL)=>{
            return eleAL.value
          })

          allLoc = allLoc.join(',')
          let payload = {
            name: formik.values.productName,
            CategoryId: formik.values.category.value,
            isForAdultsOnly: formik.values.isForAdultsOnly,
            isForDelivery: formik.values.isForDelivery,
            longDescription: formik.values.description,
            shortDescription: formik.values.shortDescription,
            addition: formik.values.addition,
            price: formik.values.price,
            VATPercent: formik.values.vat.value,
            guarantee: formik.values.guarantee,
            priceVAT: formik.values.priceVat,
            tags: formik.values.tag,
            sku: formik.values.sku,
            // modifier_ids: formik.values.productName,
            imageUrl: awsImgUrl?awsImgUrl:formik.values.imageUrl,
            LocationId: allLoc,
            total_calculated: total_calculated,
            ingredients: ingredientsDataPayload
          };

          dispatch(AddMenuProductAction(payload,handleNavigateToConf));
          dispatch({ type: TOAST_SHOW, payload: { message: 'Product added successfully', type: 'success' } });
        } else {

          // CONTINUE with edit call
          // Call edit function here
          if(!ebrizaLinkedId){

            const changedValues = Object.keys(values).reduce((acc, key) => {
              if (values[key] !== initialValues[key]) {
                acc[key] = values[key];
              }
              return acc;
            }, {});
            
            console.log("changedValues",changedValues);
            
            let total_calculated = {
              "carbohydrates":{"value":portionValue['carbohydrates'],"unit":"g"},
              "saturated_fatty_acid":{"value":portionValue['fattyAcids'],"unit":"g"},
              "salt":{"value":portionValue['salt'],"unit":"g"},
              "sugars":{"value":portionValue['sugars'],"unit":"g"},
              "level":'100_g',
              "proteins":{"value":portionValue['proteins'],"unit":"g"},
              "energy_value":{"value":portionValue['energyValue'],"unit":"kcal"},
              "fat":{"value":portionValue['fats'],"unit":"g"}
            }
  
            let ingredientsDataPayload = []
  
            ingredientsData.map((ele)=>{
              // total_calculated['carbohydrates']['value'] += parseFloat(ele['carbohydrates'])
              // total_calculated['energy_value']['value'] += parseFloat(ele['energyValue'])
              // total_calculated['saturated_fatty_acid']['value'] += parseFloat(ele['fats'])
              // total_calculated['salt']['value'] += parseFloat(ele['salt'])
              // total_calculated['sugars']['value'] += parseFloat(ele['sugars'])
              // total_calculated['proteins']['value'] += parseFloat(ele['proteins'])
              // total_calculated['fat']['value'] += parseFloat(ele['fats'])
  
              ingredientsDataPayload.push({
                additives: ele['additives'],
                allergens: ele['allergens'],
                energy_value: {"value":`${ele['energyValue']}`,"unit":"kj"},
                fat:  {"value":`${ele['fats']}`,"unit":"g"},
                frozen: ele['frozen'],
                level: portionValue,
                name: ele['name'],
                potential_allergens: ele['potential_allergens'],
                proteins:  {"value":`${ele['proteins']}`,"unit":"g"},
                salt:  {"value":`${ele['salt']}`,"unit":"g"},
                saturated_fatty_acid:  {"value":`${ele['fattyAcids']}`,"unit":"g"},
                spicy: false,
                sugars: {"value":`${ele['sugars']}`,"unit":"g"},
                'carbohydrates':  {"value":`${ele['carbohydrates']}`,"unit":"g"},
                'amount':ele['amount'],
              })
            })
  
              total_calculated['carbohydrates']['value'] = total_calculated['carbohydrates']['value'].toString()
              total_calculated['energy_value']['value'] = total_calculated['energy_value']['value'].toString()
              total_calculated['saturated_fatty_acid']['value'] =  total_calculated['saturated_fatty_acid']['value'].toString()
              total_calculated['salt']['value'] = total_calculated['salt']['value'].toString()
              total_calculated['sugars']['value'] = total_calculated['sugars']['value'].toString()
              total_calculated['proteins']['value'] = total_calculated['proteins']['value'].toString()
              total_calculated['fat']['value'] = total_calculated['fat']['value'].toString()
  
  
            const payload = {
              ProductId: [id],
              total_calculated: total_calculated,
              ingredients: ingredientsDataPayload
            };
            if(changedValues.availability){
              let allLoc = 
              formik.values.availability.map((eleAL)=>{
                return eleAL.value
              })
              payload['LocationId'] = allLoc.join(',')
            }
            if(changedValues.productName){
              payload['name'] = changedValues.productName
            }
            if(changedValues.category){
              payload['CategoryId'] = changedValues.category.value
            }
            if(changedValues.isForAdultsOnly){
              payload['isForAdultsOnly'] = changedValues.isForAdultsOnly
            }
            if(changedValues.isForDelivery){
              payload['isForDelivery'] = changedValues.isForDelivery
            }
            if(changedValues.description){
              payload['longDescription'] = changedValues.description
            }
            if(changedValues.shortDescription){
              payload['shortDescription'] = changedValues.shortDescription
            }
            if(changedValues.addition){
              payload['addition'] = changedValues.addition
            }
            if(changedValues.price){
              payload['price'] = changedValues.price
            }
            if(changedValues.vat){
              payload['VATPercent'] = changedValues.vat.value
            }
            if(changedValues.guarantee){
              payload['guarantee'] = changedValues.guarantee
            }
            if(changedValues.priceVat){
              payload['priceVAT'] = changedValues.priceVat
            }
            if(changedValues.tag){
              payload['tags'] = changedValues.tag
            }
            if(changedValues.sku){
              payload['sku'] = changedValues.sku
            }
            if(awsImgUrl){
              payload['imageUrl'] = awsImgUrl
            }
  
            const menuRes = await axios.put(`/partners/menu_products`,payload);
            if(menuRes.data.success){
              dispatch({ type: TOAST_SHOW, payload: { message: 'Product updated sucessfully', type: 'success' } });
              fetchLatestDataAndMap()
              dispatch(EditImageAction(''))

              if( formik.values.discountedPrice || formik.values.discountedPercentage){
                let partnerDisPayload = {
                  "productId": id,
                  discountedPrice: discountType.value == 'price'?formik.values.discountedPrice:0,
                  discountedPercentage: discountType.value == 'percentage'? formik.values.discountedPercentage:0
                
                }
  
  
                const addProductDis = await axios.post(`/partners/addProductDiscount`,partnerDisPayload);
                console.log("addProductDis/...",addProductDis);

              }
              
              navigate('/configuration');
            }else{
  
              dispatch({ type: TOAST_SHOW, payload: { message: 'Error updating product', type: 'error' } });
            }

          }else{
            let total_calculated = {
              "carbohydrates":{"value":portionValue['carbohydrates'],"unit":"g"},
              "saturated_fatty_acid":{"value":portionValue['fattyAcids'],"unit":"g"},
              "salt":{"value":portionValue['salt'],"unit":"g"},
              "sugars":{"value":portionValue['sugars'],"unit":"g"},
              "level":'100_g',
              "proteins":{"value":portionValue['proteins'],"unit":"g"},
              "energy_value":{"value":portionValue['energyValue'],"unit":"kcal"},
              "fat":{"value":portionValue['fats'],"unit":"g"}
            }
  
            let ingredientsDataPayload = []
  
            ingredientsData.map((ele)=>{
              // total_calculated['carbohydrates']['value'] += parseFloat(ele['carbohydrates'])
              // total_calculated['energy_value']['value'] += parseFloat(ele['energyValue'])
              // total_calculated['saturated_fatty_acid']['value'] += parseFloat(ele['fats'])
              // total_calculated['salt']['value'] += parseFloat(ele['salt'])
              // total_calculated['sugars']['value'] += parseFloat(ele['sugars'])
              // total_calculated['proteins']['value'] += parseFloat(ele['proteins'])
              // total_calculated['fat']['value'] += parseFloat(ele['fats'])
  
              ingredientsDataPayload.push({
                additives: ele['additives'],
                allergens: ele['allergens'],
                energy_value: {"value":`${ele['energyValue']}`,"unit":"kj"},
                fat:  {"value":`${ele['fats']}`,"unit":"g"},
                frozen: ele['frozen'],
                level: portionValue,
                name: ele['name'],
                potential_allergens: ele['potential_allergens'],
                proteins:  {"value":`${ele['proteins']}`,"unit":"g"},
                salt:  {"value":`${ele['salt']}`,"unit":"g"},
                saturated_fatty_acid:  {"value":`${ele['fattyAcids']}`,"unit":"g"},
                spicy: false,
                sugars: {"value":`${ele['sugars']}`,"unit":"g"},
                'carbohydrates':  {"value":`${ele['carbohydrates']}`,"unit":"g"},
                'amount':ele['amount'],
              })
            })
  
              total_calculated['carbohydrates']['value'] = total_calculated['carbohydrates']['value'].toString()
              total_calculated['energy_value']['value'] = total_calculated['energy_value']['value'].toString()
              total_calculated['saturated_fatty_acid']['value'] =  total_calculated['saturated_fatty_acid']['value'].toString()
              total_calculated['salt']['value'] = total_calculated['salt']['value'].toString()
              total_calculated['sugars']['value'] = total_calculated['sugars']['value'].toString()
              total_calculated['proteins']['value'] = total_calculated['proteins']['value'].toString()
              total_calculated['fat']['value'] = total_calculated['fat']['value'].toString()
  
            let allLoc = 
            formik.values.availability.map((eleAL)=>{
              return eleAL.value
            })
  
            allLoc = allLoc.join(',')
            let payload = {
              ProductId: id,
              name: formik.values.productName,
              CategoryId: formik.values.category.value,
              isForAdultsOnly: formik.values.isForAdultsOnly,
              isForDelivery: formik.values.isForDelivery,
              longDescription: formik.values.description,
              shortDescription: formik.values.shortDescription,
              addition: formik.values.addition,
              price: formik.values.price,
              VATPercent: formik.values.vat.value,
              guarantee: formik.values.guarantee,
              priceVAT: formik.values.priceVat,
              tags: formik.values.tag,
              sku: formik.values.sku,
              // modifier_ids: formik.values.productName,
              imageUrl:  formik.values.imageUrl,
              LocationId: allLoc,
              total_calculated: total_calculated,
              ingredients: ingredientsDataPayload
            };

            const menuRes = await axios.put(`/partners/menu_products_ebriza`,payload);
            if(menuRes.data.success){
              dispatch({ type: TOAST_SHOW, payload: { message: 'Product updated sucessfully', type: 'success' } });
              fetchLatestDataAndMap()
              dispatch(EditImageAction(''))
              if( formik.values.discountedPrice || formik.values.discountedPercentage){
                let partnerDisPayload = {
                  "productId": id,
                  discountedPrice: discountType.value == 'price'?formik.values.discountedPrice:0,
                  discountedPercentage: discountType.value == 'percentage'? formik.values.discountedPercentage:0
                
                }
  
  
                const addProductDis = await axios.post(`/partners/addProductDiscount`,partnerDisPayload);
                console.log("addProductDis/...",addProductDis);

              }
              navigate('/configuration');
            }else{
  
              dispatch({ type: TOAST_SHOW, payload: { message: 'Error updating product', type: 'error' } });
            }
          }
          //////
        }
      } catch (error) {
        dispatch({ type: TOAST_SHOW, payload: { message: 'Error adding product', type: 'error' } });
        console.error("Error submitting form:", error);
      } finally {
        setShowSnack(true);
        setLoading(false);
        localStorage.removeItem('formData');
      }
    },
  });

  useEffect(() => {
    if(!ebrizaLinkedId){
      // Calculate priceVat when price or vat changes
      const vatMultiplier = formik.values.vat ? formik.values.vat.value : 0;
      const price = parseFloat(formik.values.price) || 0;
      const priceVat = price + (price * vatMultiplier / 100);
  
      formik.setFieldValue('priceVat', priceVat.toFixed(2));
      
    }
  }, [formik.values.price, formik.values.vat]);

  useEffect(() => {
    if(ebrizaLinkedId){
      // Calculate priceVat when price or vat changes
      const vatMultiplier = formik.values.vat ? formik.values.vat.value : 0;
      const priceVat = parseFloat(formik.values.priceVat) || 0;
      const price = priceVat - (priceVat * vatMultiplier / 100);
  
      formik.setFieldValue('price', price.toFixed(2));
      
    }
  }, [formik.values.priceVat, formik.values.vat]);

  const handleAddProductClose = () => {
    dispatch(EditImageAction(''))
    localStorage.removeItem('formData');
    navigate('/configuration');
  };

  useEffect(() => {
    const savedFormData = localStorage.getItem('formData');
    if (savedFormData) {
      formik.setValues(JSON.parse(savedFormData));
    }
  }, []);

  useEffect(() => {
    if (formik.values) {  // Only save if formik values are present
      localStorage.setItem('formData', JSON.stringify(formik.values));
    }
  }, [formik.values]);
  useEffect(() => {
    console.log('Current description value:', formik.values.description);
  }, [formik.values.description]);


  return (
    <>
      <Snackbar alertOpen={showSnack} handleClose={() => setShowSnack(false)} vertical={'top'} autoHideDuration={4000} />
      <div className="data-wrapper">
        <div className="data1">
          <div className="head-parent">
            <div className="head">
              <div className="button-base-wrapper">
                <Button
                  className="button-base15"
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    color: "#222327",
                    fontSize: "14",
                    background: "#ffb800",
                    border: "#ffb800 solid 1px",
                    borderRadius: "8px",
                    "&:hover": { background: "#ffb800" },
                    height: 40,
                  }}
                  onClick={formik.handleSubmit}
                >
                  {loading ? <ReactLoading type="bubbles" color="#fff" height={20} width={20} /> : 'Save'}
                </Button>
              </div>
              <div className="add-product-wrapper">
                <h1 className="add-product">{id === 'new' ? 'Add product' : 'Edit product'}</h1>
              </div>
              <div className="close-button" onClick={handleAddProductClose}>
                <img className="icons31" alt="" src="/icons-92.svg" />
              </div>
            </div>
            <div style={{    display: 'flex',
                justifyContent: 'space-between',
                width: '100%'}}>
              <h3 className="predefined-fields">Predefined fields</h3>
              <EditImgModel coverImgOpen={coverImgOpen} profileImgOpen={profileImgOpen} locationId="" handleClose={handleEditImage} setShowProfileImage={setShowProfileImage}/>
              <Box className='location-img' style={{
                width: '100px',
                marginTop: '-17px'
              }}>
                  <Box>
                      <Box sx={{
                          position: 'relative',
                      }}>
                          <Box className='location-cover-wrap' style={{
                            maxWidth: '100px',
                            width: '100%',
                            height: '100px',
                            borderRadius: '0', 
                            border: '2px solid #FFB800',
                            position: 'relative',
                            overflow: 'hidden'
                          }}>
                              <img src={awsImgUrl?awsImgUrl:(formik.values.imageUrl? formik.values.imageUrl : "/default.svg")} />
                          </Box>
                          { !ebrizaLinkedId && <Box className="edit-icon-box" onClick={() => setProfileImgOpen(true)}>
                              <EditIcon />
                          </Box>}
                      </Box>
                  </Box>
              </Box>
            </div>
            <div className="frame-parent">
              <div className="checkbox-elements-parent">
                <input className="checkbox-elements"
                disabled={ebrizaLinkedId}
                checked = {formik.values.isForDelivery}
                onChange={(event) => formik.setFieldValue('isForDelivery', event.target.checked)}
                type="checkbox" />
                <div className="is-for-delivery">is for delivery</div>
              </div>
              <div className="frame-group">
                <input className="frame-input" 
                disabled={ebrizaLinkedId}
                checked = {formik.values.isForAdultsOnly}
                onChange={(event) => formik.setFieldValue('isForAdultsOnly', event.target.checked)}
                type="checkbox" />
                <div className="over-18-only">over 18 only</div>
              </div>
            </div>
            <div className="inputs">
              <Box mb={2}>
                <Typography variant="body1">Category</Typography>
                <Select
                  isDisabled={ebrizaLinkedId}
                  className="product-add-select"
                  fullWidth
                  options={categories.map((ele) => ({ value: ele.CategoryId, label: ele.name }))}
                  name="category"
                  value = {formik.values.category}
                  onChange={(option) => formik.setFieldValue('category', option)}
                  placeholder="Select Category"
                  isClearable
                />
                {formik.touched.category && formik.errors.category ? (
                  <FormHelperText error>{formik.errors.category}</FormHelperText>
                ) : null}
              </Box>
              <Box mb={2}>
                <Typography variant="body1">Product Name</Typography>
                <TextField
                  disabled={ebrizaLinkedId}
                  fullWidth
                  className="product-add-input"
                  name="productName"
                  value={formik.values.productName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter product name"
                  error={formik.touched.productName && Boolean(formik.errors.productName)}
                  helperText={formik.touched.productName && formik.errors.productName}
                />
              </Box>
              <Box mb={2}>
                <Typography variant="body1">SKU</Typography>
                <TextField
                  disabled={ebrizaLinkedId}
                  className="product-add-input"
                  fullWidth
                  name="sku"
                  value={formik.values.sku}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter SKU"
                  error={formik.touched.sku && Boolean(formik.errors.sku)}
                  helperText={formik.touched.sku && formik.errors.sku}
                />
              </Box>
              <Box mb={2}>
                <Typography variant="body1">Availability</Typography>
                <Select
                  isDisabled={ebrizaLinkedId}
                  className="product-add-select"
                  defaultValue={''}
                  options={locations.map((ele) => ({ value: ele.LocationId, label: ele.name }))}
                  name="availability"
                  value={formik.values.availability}
                  onChange={(selectedOptions) => formik.setFieldValue('availability', selectedOptions )}
                  placeholder="Select Available Locations"
                  isMulti
                />
                {formik.touched.availability && formik.errors.availability ? (
                  <FormHelperText error>{formik.errors.availability}</FormHelperText>
                ) : null}
              </Box>
            </div>
            <Box mb={2}>
                <Typography variant="body1">Tag</Typography>
                <TextField
                  disabled={ebrizaLinkedId}
                  fullWidth
                  className="product-add-input"
                  name="tag"
                  value={formik.values.tag}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter tag"
                  error={formik.touched.tag && Boolean(formik.errors.tag)}
                  helperText={formik.touched.tag && formik.errors.tag}
                />
              </Box>
                <Box  style={{width: '100%'}}>

                  <NutritionalValueCalculator nutritionOpen={true} prefilNutrition={prefilNutrition}/>
                </Box>


            {/* Product price */}

            <div >
            <div >
              <h3 className="predefined-fields">Product price</h3>
              <div >
                <div style={{    display: 'flex',
      justifyContent: 'space-between',
      width: '100%'}}>
                  <div  style={{display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      borderRight: '2px solid #CBCAC8'
      }}>
      

                <Box mb={2}>
                  <Typography variant="body1">Addition %</Typography>
                  <TextField
                  disabled={ebrizaLinkedId}
                    className="product-add-input"
                    name="addition"
                    value={formik.values.addition}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Addition %"
                    error={formik.touched.addition && Boolean(formik.errors.addition)}
                    helperText={formik.touched.addition && formik.errors.addition}
                  />
                </Box>

                <Box mb={2}>
                  <Typography variant="body1">Price</Typography>
                  <TextField
                  disabled={ebrizaLinkedId}
                    className="product-add-input"
                    name="price"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Price"
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    helperText={formik.touched.price && formik.errors.price}
                  />
                </Box>

                <Box mb={2} style={{fontSize: '18px'}}>
                <Typography variant="body1">VAT</Typography>
                <Select
                  isDisabled={ebrizaLinkedId}
                  className="product-add-select"
                  value={formik.values.vat}
                  options={vatOptions}
                  name="vat"
                  onChange={(option) => formik.setFieldValue('vat', option)}
                  placeholder="VAT"
                />
                
                {formik.touched.vat && formik.errors.vat ? (
                  <FormHelperText error>{formik.errors.vat}</FormHelperText>
                ) : null}
              </Box>

              <Box mb={2}>
                  <Typography variant="body1">Price+VAT</Typography>
                  <TextField
                    disabled={ebrizaLinkedId}
                    className="product-add-input"
                    name="priceVat"
                    value={formik.values.priceVat}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Price VAT"
                    error={formik.touched.priceVat && Boolean(formik.errors.priceVat)}
                    helperText={formik.touched.priceVat && formik.errors.priceVat}
                  />
                </Box>
                <div className="inputs" style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
      <Box mb={2} style={{ flexShrink: 0, minWidth: '100px' }}>
        <Typography variant="body1">Discount</Typography>
        <Select
          className="product-add-select"
          options={options}
          value={discountType}
          onChange={handleDiscountChange}
          placeholder="Select Discount type"
          
          styles={{
            container: (provided) => ({
              ...provided,
              flex: '1',
              minWidth: '200px',
            }),
            menu: (provided) => ({
              ...provided,
              zIndex: 9999, // Ensure dropdown is on top
            }),
            valueContainer: (provided) => ({
              ...provided,
              height: '100%', // Ensure the dropdown fits within the container
            }),
            input: (provided) => ({
              ...provided,
              margin: 0, // Adjust for alignment
            }),
            singleValue: (provided) => ({
              ...provided,
              margin: 0, // Adjust for alignment
            }),
          }}
        />
      </Box>
      <div className="inputs">
      <Box mb={2} style={{ flex: '1', minWidth: '200px',
                marginTop: '22px' }}>
        {discountType.value === 'percentage' ? (
          <TextField
            className="product-add-input"
            label="Percentage Discount"
            name="discountedPercentage"
            type="number"
            InputProps={{ inputProps: { min: 0, max: 100 } }}
            placeholder="Enter Percentage"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.discountedPercentage && Boolean(formik.errors.discountedPercentage)}
            helperText={formik.touched.discountedPercentage && formik.errors.discountedPercentage}
            variant="outlined"
            value = {formik.values.discountedPercentage}
                 
            sx={{
              '& .MuiInputLabel-root': {
                top: '-8px',
              }
            }}
          />
        ) : (
          <TextField
             className="product-add-input"
            label=" Discount Amount"
            name="discountedPrice"
            value = {formik.values.discountedPrice}
            type="number"
            placeholder="Enter Price"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.discountedPrice && Boolean(formik.errors.discountedPrice)}
            helperText={formik.touched.discountedPrice && formik.errors.discountedPrice}
            variant="outlined"
                 
            sx={{
              '& .MuiInputLabel-root': {
                top: '-8px'
              }
            }}
          />
        )}
      </Box>

     
      </div>
    </div>
                  </div>
                  <div className="total">
                    <div className="total-cost1">
                      <h3 className="average-purchase-cost">
                        Average purchase cost
                      </h3>
                      <div className="ron">0.00 RON</div>
                    </div>
                    <div className="total-cost2">
                      <h3 className="gross-profit">Gross profit</h3>
                      <div className="ron1">{formik.values.priceVat} RON</div>
                    </div>
                  </div>
                </div>
                {/* <button className="button-base19">
                  <img
                    className="icons35"
                    loading="lazy"
                    alt=""
                    src="/icons-53.svg"
                  />
                  <div className="text49">Add preferential price</div>
                </button> */}
              </div>
            </div>
          </div>
              {/*  */}

            {/* <Box mb={2} style={{width:'100%'}}>
              <Typography variant="body1">Unit Quantity Description</Typography>
              <TextField
                fullWidth
                className="product-add-input"
                name="unitQuantityDescription"
                value={formik.values.unitQuantityDescription}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter unit quantity description"
                error={formik.touched.unitQuantityDescription && Boolean(formik.errors.unitQuantityDescription)}
                helperText={formik.touched.unitQuantityDescription && formik.errors.unitQuantityDescription}
              />
            </Box> */}
            <Box mb={2} style={{ width: '100%' }}>
      <Typography variant="body1">Short Description</Typography>
      <ReactQuill
        value={formik.values.shortDescription}
        onChange={(value) => formik.setFieldValue('shortDescription', value)}
        onBlur={() => formik.setFieldTouched('shortDescription')}
        placeholder="Enter short description"
        readOnly={ebrizaLinkedId}
        modules={modules}  // Include the modules for toolbar configuration
        formats={formats}  // Specify the formats to be used
        className="custom-quill-editor" 
      />
      {formik.touched.shortDescription && formik.errors.shortDescription && (
        <Typography variant="body2" color="error">
          {formik.errors.shortDescription}
        </Typography>
      )}
    </Box>
    <Box mb={2} style={{ width: '100%' }}>
      <Typography variant="body1">Description</Typography>
      <ReactQuill
        value={formik.values.description}
        onChange={(value) => formik.setFieldValue('description', value)}
        onBlur={() => formik.setFieldTouched('description')}
        placeholder="Enter description"
        readOnly={ebrizaLinkedId}
        modules={modules}
        formats={formats}
        className="custom-quill-editor" 
      />
      {formik.touched.description && formik.errors.description && (
        <Typography variant="body2" color="error">
          {formik.errors.description}
        </Typography>
      )}
    </Box>
          </div>
        </div>
      </div>
    </>
  );
}
