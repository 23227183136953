import React, { useEffect, useState } from 'react'
import ConfigurationProductDisplay from './ConfigurationProductDisplay'
import axios from '../../axiosConfig';
import ConfigurationProduct from './ConfigurationProduct';
import ConfigurationModifier from './ConfigurationModifier';
import { useLocation, useParams } from 'react-router-dom';
import AddProduct from './AddProduct';
import AddModifier from './AddModifier';
import { useDispatch } from 'react-redux';
import { TOAST_SHOW } from '../../Redux/Actions/ToastAction';
import Snackbar from '../../component/ErrorHandler/Snackbar';

const index = () => {
  const [products, setProducts] = useState([]);
  const [productModifiers, setProductModifiers] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const ebrizaLinkedId = localStorage.getItem('ebrizaLinkedId')
  const partnerId = localStorage.getItem('partnerId')
  const location = useLocation();
  const [showSnack, setShowSnack] = useState(false);
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(
    location.pathname.includes('/configuration/modifier/')?'modifiers':
    'products')
  const params = useParams();

  const syncToPos = async ()=>{
    if(!ebrizaLinkedId){
      try {
        let allAvailableLocData = await axios.post(`/syncProducts/adhoc-pos/${partnerId}`)
        dispatch({ type: TOAST_SHOW, payload: { message: 'Sync POC Sucessful!', severity: 'success' } });
        setShowSnack(true);
        setTimeout(() => {
          dispatch({ type: TOAST_SHOW, payload: { message: '', severity: 'success' } });
        }, 2000);
        
      } catch (error) {
        dispatch({ type: TOAST_SHOW, payload: { message: 'Some error occured', severity: 'error' } });
        setShowSnack(true);
        setTimeout(() => {
          dispatch({ type: TOAST_SHOW, payload: { message: '', severity: 'error' } });
        }, 2000);
        
      }
      

    }
  }

  const fetchLatestDataAndMap = async () => {
    setProducts([])
    setProductCategories([])
    if (ebrizaLinkedId) {
      let allAvailableLocData = await axios.get(`/partners/locationsForPickup?ClientId=${ebrizaLinkedId}`)
      console.log("allAvailableLocData", allAvailableLocData);
      allAvailableLocData = allAvailableLocData.data.locations;
      let firstLoc = allAvailableLocData[0]
      let ebrizaSyncMenuData = await axios.get(`/syncProducts/${firstLoc['LocationId']}`)
      ebrizaSyncMenuData = ebrizaSyncMenuData.data
      console.log("ebrSyncMenu", ebrizaSyncMenuData);

      mapDataToProductCatAndModifiers(ebrizaSyncMenuData)
    } else {
      //fetch cat with products
      let menuCatWithProducts = await axios.get(`/partners/menu_categories`)
      menuCatWithProducts = menuCatWithProducts.data.data

      console.log("menuCatWithProducts", menuCatWithProducts);
      let products = []
      menuCatWithProducts.map((ele) => {
        if (ele.cat_products && ele.cat_products[0]) {
          ele.cat_products.map((eleP) => {
            eleP['category'] = ele['name']
          })
          products.push(...ele.cat_products)
          ele['totalActive'] = 0
          ele['totalItems'] = ele.cat_products.length 
        }
        if (ele.sub_categories && ele.sub_categories.length>0) {
          ele['totalActive'] = 0
          ele['totalItems'] = ele.sub_categories.length 
          ele.sub_categories.map((eleS)=>{
            if(eleS.cat_products){
              eleS.cat_products.map((eleP) => {
                eleP['category'] = eleS['name']
              })
              products.push(...eleS.cat_products)
              eleS['totalActive'] = 0
              eleS['totalItems'] = eleS.cat_products.length
            }else{
              eleS['totalActive'] = 0
              eleS['totalItems'] = 0
              
            }

          })
        }
      })
      setProductCategories(menuCatWithProducts)
      products.sort((a, b) => b.id - a.id)
      setProducts(products)

      //fetch modifiers
      let menuModi = await axios.get(`/partners/menu_modifiers_all`)
      menuModi = menuModi.data.data

      console.log("received modi..",menuModi);
      setProductModifiers(menuModi)
    }
  }

  useEffect(() => {
    fetchLatestDataAndMap()
  }, []);

  const geEbrizaProductMapData = (ele, ebrizaSyncMenuData, catId, catName, id,isSubCat=false) => {
    return {
      id: id,
      name: ele['Name'],
      ProductId: ele["ID"],
      CategoryId: catId,
      category: catName,
      PartnerId: ebrizaSyncMenuData["ClientID"],
      isForAdultsOnly: false,
      isForDelivery: true,
      longDescription: ele["Description"],
      shortDescription: "",
      addition: "",
      price: ele["Price"],
      VATPercent: ele["VATPercent"],
      guarantee: false,
      priceVAT: ele["Price"],
      tags: ele["Tags"],
      sku: ele["SKU"],
      // modifier_ids: ["modifier1", "modifier2"],
      imageUrl: ele["ImageUrl"],
      ...(isSubCat && {
        'totalItems': ele['Items'].length,
        'totalActive': 0,
      })
    }
  }

  const mapDataToProductCatAndModifiers = (ebrizaSyncMenuData) => {
    let products = []
    let productsCat = []
    let productsModifiers = []
    if (ebrizaSyncMenuData['SystemItems']) {
      let productWithSysId = ebrizaSyncMenuData['SystemItems'].map((ele, index) => {
        return geEbrizaProductMapData(ele, ebrizaSyncMenuData, "system-id", "[SYSTEM]", products.length + 1 + index)
      })
      products.push(...productWithSysId)

      productsCat.push({
        "id": productsCat.length + 1,
        "CategoryId": 'system-id',
        "name": "[SYSTEM]",
        "description": "",
        "imageUrl": "",
        "tileColor": "",
        "partnerId": ebrizaSyncMenuData["ClientID"],
        'totalItems': productWithSysId.length,
        'totalActive': 0,
        'cat_products':productWithSysId,
        'sub_categories':[]
      })
    }

    if (ebrizaSyncMenuData['Items']) {

      ebrizaSyncMenuData['Items'].map((eleCat) => {
        productsCat.push({
          "id": productsCat.length + 1,
          "CategoryId": eleCat['ID'],
          "name": eleCat['Name'],
          "description": eleCat["Description"],
          "imageUrl": eleCat["ImageUrl"].includes("http") ? eleCat["ImageUrl"] : '',
          "tileColor": "",
          "partnerId": ebrizaSyncMenuData["ClientID"],
          'totalItems': eleCat['Items'].length,
          'totalActive': 0,
          'cat_products':[],
          'sub_categories':[]
        })

        let firstItemSubCat = eleCat['Items'].length && eleCat['Items'][0]['IsCategory']

        if(firstItemSubCat){

          let productWithSysId = eleCat['Items'].map((ele, index) => {
            return geEbrizaProductMapData(ele, ebrizaSyncMenuData, ele["ID"], eleCat['Name'], products.length + 1 + index,true)
          })
          productsCat[productsCat.length-1]['sub_categories'] = productWithSysId
          // products.push(...productWithSysId)
  
          // check modifieres
          eleCat['Items'].map((productCat,index) => {
            let productWithSysId = productCat['Items'].map((ele, index) => {
              return geEbrizaProductMapData(ele, ebrizaSyncMenuData, productCat["ID"], productCat['Name'], products.length + 1 + index)
            })
            productsCat[productsCat.length-1]['sub_categories'][index]['cat_products'] = productWithSysId
            products.push(...productWithSysId)
            productCat['Items'].map((product)=>{
              if (product['Modifiers'] && product['Modifiers'].length > 0) {
                let allModLength = 0;
                product['Modifiers'].map((elePM)=>{
                  allModLength+=elePM['Items'].length;
                })
                productsModifiers.push({
                  id: productsModifiers.length + 1,
                  'name': `${product['Name']} Modifiers`,
                  source: 1,
                  choice: allModLength,
                  'products': [{
                    'ProductId': product.ID,
                    'imageUrl': product.ImageUrl,
                    'name': product.Name
                    }
                  ],
                  'modifiers_group': product.Modifiers.map((modiG) => {
                      return {
                        'name': modiG.Name,
                        'id': modiG.ID,
                        'min': modiG.Min,
                        'max': modiG.Max,
                        'modifiers': modiG['Items'].map((modi) => {
                          return {
                            'ProductId': modi.ID,
                            'imageUrl': modi.ImageUrl,
                            'name': modi.Name,
                          }
                        })
                      }
                    })
                })
              }
            })
            
            
          })

        }else{

          let productWithSysId = eleCat['Items'].map((ele, index) => {
            return geEbrizaProductMapData(ele, ebrizaSyncMenuData, eleCat["ID"], eleCat['Name'], products.length + 1 + index)
          })
          productsCat[productsCat.length-1]['cat_products'] = productWithSysId
          products.push(...productWithSysId)
  
          // check modifieres
          eleCat['Items'].map((product) => {
            if (product['Modifiers'] && product['Modifiers'].length > 0) {
              let allModLength = 0;
              product['Modifiers'].map((elePM)=>{
                allModLength+=elePM['Items'].length;
              })
              productsModifiers.push({
                id: productsModifiers.length + 1,
                'name': `${product['Name']} Modifiers`,
                source: 1,
                choice: allModLength,
                'products': [{
                  'ProductId': product.ID,
                  'imageUrl': product.ImageUrl,
                  'name': product.Name
                  }
                ],
                'modifiers_group': product.Modifiers.map((modiG) => {
                    return {
                      'name': modiG.Name,
                      'id': modiG.ID,
                      'min': modiG.Min,
                      'max': modiG.Max,
                      'modifiers': modiG['Items'].map((modi) => {
                        return {
                          'ProductId': modi.ID,
                          'imageUrl': modi.ImageUrl,
                          'name': modi.Name,
                        }
                      })
                    }
                  })
              })
            }
            
            
          })

        }  

      })

    }

    setProducts(products)
    setProductCategories(productsCat)
    setProductModifiers(productsModifiers)

    console.log("products..ebriza", products);
    console.log("productsCat..ebriza", productsCat);
    console.log("productsCat..productsModifiers", productsModifiers);
  }
  return (
    <div>
      <Snackbar alertOpen={showSnack} handleClose={() => setShowSnack(false)} vertical={'top'} autoHideDuration={4000} />
            
      <div className='flex space-x-8'>
        <div onClick={(e) => setSelectedTab(e.currentTarget.ariaLabel)} aria-label='products' className={`${selectedTab == 'products' && 'border-b-2 border-b-primaryColor'} cursor-pointer pb-2`}>Products</div>
        <div onClick={(e) => setSelectedTab(e.currentTarget.ariaLabel)} aria-label='product-display' className={`${selectedTab == 'product-display' && 'border-b-2 border-b-primaryColor'} cursor-pointer pb-2`}>Product Display</div>
        <div onClick={(e) => setSelectedTab(e.currentTarget.ariaLabel)} aria-label='modifiers' className={`${selectedTab == 'modifiers' && 'border-b-2 border-b-primaryColor'} cursor-pointer pb-2`}>Modifiers</div>
      </div>
      <div className='mt-8' style={{
        marginTop: '15px',
        marginLeft: '-20px',
        marginRight: '-20px'
      }}>

        {location.pathname.includes('/configuration/product/') ? (
          <AddProduct
            productsEbz={products}
            productCategoriesEbz={productCategories}
            fetchLatestDataAndMap={fetchLatestDataAndMap}
          />
        ) : location.pathname.includes('/configuration/modifier/') ? (
          <AddModifier
            productModifiers={productModifiers}
            allCatWithProducts={productCategories}
            fetchLatestDataAndMap={fetchLatestDataAndMap}
          />
        ) : (
          <>
            {selectedTab === "products" &&
              <ConfigurationProduct
                products={products}
                fetchLatestDataAndMap={fetchLatestDataAndMap}
                
                syncToPos={syncToPos}
              />
            }
            {selectedTab === "product-display" && (
              <ConfigurationProductDisplay
                products={products}
                productCategories={productCategories}
                fetchLatestDataAndMap={fetchLatestDataAndMap}
                
                syncToPos={syncToPos}
              />
            )}
            {selectedTab === "modifiers" && (
              <ConfigurationModifier
                productModifiers={productModifiers}
                fetchLatestDataAndMap={fetchLatestDataAndMap}
                syncToPos={syncToPos}
              />
            )}
          </>
        )}



      </div>
    </div>
  )
}

export default index