import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce, useErrorHandler } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, InputAdornment, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { DeletePartnerAction, EditPartnerDataAction, GetPartnersAction } from '../../Redux/Actions/PartnerActions';
import AddPartner from './AddPartner';
import SearchIcon from '@mui/icons-material/Search';
import { HiDotsVertical } from 'react-icons/hi';
import ConfirmationDialog from '../../component/Common/ConfirmPopup';
import CustonDataGrid from '../../component/CustomDataGrid';
import refreshImg from '../../assets/img/refresh-icon.png';
import './style.css';
import { getPartnersById,getPartners} from '../../libs/getPartners';
import { TOAST_SHOW } from '../../Redux/Actions/ToastAction';

const Partners = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, getPartnersData: partnersData } = useSelector((state) => state.Partners);
  const { t } = useTranslation();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [partnerToDeleteId, setPartnerToDeleteId] = useState(null);
  const userRole = localStorage.getItem('userRole');
  const [partnerOpen, setPartnerOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [menuOpen, setMenuOpen] = useState(null);
  const [searchedValue, setSearchedValue] = useState('');
  const [selectedPartner, setSelectedPartner] = useState({});
  const debouncedSearch = useDebounce(searchedValue, 300);
  const { setMsgConfig } = useErrorHandler();
  const open = Boolean(menuOpen);
  const handleClick = (event, data) => {
    setMenuOpen(event.currentTarget);
    setSelectedPartner(data); // Store the selected partner data
  };

  const handleMenuClose = () => {
    setMenuOpen(null);
  };

  const handlePartnerClose = () => {
    setRefresh((prev) => !prev);
    setPartnerOpen(false);
    // dispatch(EditPartnerDataAction({},null,null));
  };
  const columns = [
    {
      field: 'partner_name',
      headerName: t('partner_name'),
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      headerClassName: 'bg-gray-50',
      align: 'center',
      renderCell: ({ row }) => (
        <div className="whitespace-normal text-center">{row.partner_name || 'N/A'}</div>
      ),
    },
    {
      field: 'user_name',
      headerName: t('UserName'),
      sortable: false,
      minWidth: 150,
      flex: 1,
      headerClassName: 'bg-gray-50',
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => <div className="whitespace-normal">{row.user_name || 'N/A'}</div>,
    },
    {
      field: 'password',
      headerName: t('Password'),
      sortable: false,
      minWidth: 125,
      flex: 1,
      headerClassName: 'bg-gray-50',
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => <div className="whitespace-normal">{row.password}</div>,
    },
    {
      field: 'email',
      headerName: t('Email'),
      sortable: false,
      minWidth: 125,
      flex: 2,
      headerClassName: 'bg-gray-50',
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => <div className="whitespace-normal">{row.email}</div>,
    },
    {
      field: 'no_of_locations',
      headerName: t('No_of_locations'),
      sortable: false,
      minWidth: 125,
      flex: 2,
      headerClassName: 'bg-gray-50',
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => <div className="whitespace-normal">{row.no_of_locations}</div>,
    },
    {
      field: 'id',
      headerName: 'Action',
      sortable: false,
      minWidth: 20,
      headerClassName: 'bg-gray-50',
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => (
        <>
        {row?.id && <>
          <Button
            id={`basic-button_${row.id}`}
            key={`basic-button_${row.id}`}
            aria-controls={open ? `${`basic-menu_${row.id}`}` : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={(event) => handleClick(event, row)}
            className="!text-black hover:!bg-transparent"
          >
            <HiDotsVertical />
          </Button>
          <Menu
            sx={{
              '.MuiPaper-root': {
                boxShadow: '1px 4px 10px -5px rgba(0,0,0,0.3)',
              },
              '& ul': {
                background: '#ffffff',
                '& li': {
                  fontSize: '12px !important',
                },
              },
            }}
            key={`basic-menu_${row.id}`}
            id={`basic-menu_${row.id}`}
            anchorEl={menuOpen}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <div>
            <MenuItem
              key={`menu-item-edit__${row.id}`}
              id={`menu-item-edit__${row.id}`}
              sx={{ gap: '5px' }}
              onClick={() => handleEdit()}
            >
              Edit
            </MenuItem>
            <MenuItem
              key={`menu-item-delete_${row.id}`}
              id={`menu-item-delete_${row.id}`}
              sx={{ gap: '5px' }}
              onClick={() => handleDelete()}
            >
              Delete
            </MenuItem>
            </div>
          </Menu>
        </>}</>
      ),
    },
  ];

  const handleDelete = () => {
    console.log('handleDelete called with partnerId:', selectedPartner);
    setPartnerToDeleteId(selectedPartner.id);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    dispatch(DeletePartnerAction(partnerToDeleteId));
    setConfirmDialogOpen(false);
    dispatch({ type: TOAST_SHOW, payload: { message: 'Deleted successfully', severity: 'success' } });
  };

  const handleEdit = async () => {
    console.log("handleEdit partner",selectedPartner);
    let partnerResData = await getPartnersById(selectedPartner.id);
    console.log("partnerResData",partnerResData);
    let partnerObj = partnerResData['partner'][0]
    setSelectedPartner(partnerObj);
    setPartnerOpen(true);
    setMenuOpen(null);
  };

  const fetchPartners = async () => {
    try {
      let data = await getPartners();
      setPartnersData(data.partners);
    } catch (error) {
      setMsgConfig({
        alertOpen: true,
        message: 'Message',
        severity: 'Severity',
        autoHideDuration: 2000,
      });
    }
  };

  useEffect(() => {
    if (debouncedSearch.value.length >= 0) {
      dispatch(GetPartnersAction(debouncedSearch.value));
    }
  }, [debouncedSearch.value, dispatch, refresh]);

  useEffect(() => {
    fetchPartners();
  }, [refresh]);

  return (
     
        <>
          <ConfirmationDialog
            open={confirmDialogOpen}
            onClose={() => setConfirmDialogOpen(false)}
            onConfirm={handleConfirmDelete}
            title="Confirm Delete"
            message="Are you sure you want to delete this partner?"
          />
          <AddPartner partnerOpen={partnerOpen} handleClose={handlePartnerClose} selectedPartner={selectedPartner} />
          <Box className="partner-title">
            <Typography variant="h2">{t('partner_title')}</Typography>
          </Box>
          <Box className="partner-detail">
            <Box className="search-box">
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder={`${t('Search')} by name`}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ color: '#000' }}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchedValue(e.target.value)}
              />
            </Box>
            <Box>
              <Button className="refresh-btn" onClick={() => setRefresh(!refresh)}>
                <img src={refreshImg} alt="Refresh" />
                {t('refresh_button')}
              </Button>
              <Button
                className="add-partner"
                onClick={() => {
                  setSelectedPartner({});
                  setPartnerOpen(true);
                }}
              >
                {t('add_partner_btn')}
              </Button>
            </Box>
          </Box>
          {partnersData && partnersData.length > 0 && (
            <CustonDataGrid
              columns={columns}
              rows={partnersData}
              height={300}
              loading={loading}
              disableRowSelectionOnClick
            />
          )}
        </>
  
  );
};

export default Partners;
