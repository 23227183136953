import { Box, Tab, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from 'react-redux';
import CustomButton from './CustomButton';
import Draggable from 'react-draggable';
import tableIcon from '../assets/img/table.png'
import QRCode from "react-qr-code";
import Select from 'react-select';
import { toJpeg, toPng } from 'html-to-image';
import { HiOutlineDotsVertical } from "react-icons/hi";





function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ModalQRGeneratorTable = ({ showModal, locationId, closeModal = () => { } }) => {
  const [configTabValue, setConfigTabValue] = useState(0);
  const [selectedTableName, setSelectedTableName] = useState('');
  const { loading, configurationData } = useSelector((state) => state.Location);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [tableList, setTableList] = useState([]);
  const [selectedTableId, setSelectedTableId] = useState('');
  const [QRValue, setQRValue] = useState('');

  const [activeDrags, setActiveDrags] = useState(0);

  const onStart = () => {
    setActiveDrags((prevActiveDrags) => prevActiveDrags + 1);
  };

  const onStop = () => {
    setActiveDrags((prevActiveDrags) => prevActiveDrags - 1);
  };

  const onDragStop = (e, position) => {
    setTablePosition({ x: position?.x, y: position?.y })
  }

  const dragHandlers = { onStart: onStart, onStop: onStop };


  const handleChangeConfigTab = (newValue) => {
    setConfigTabValue(newValue);
  };

  const handleSelectTable = (e) => {
    console.log("ele sel..", e);
    setSelectedTableName(e.label)
    setSelectedTableId(e.value)

    let isStageUrl = window.location.href.includes('stage') || window.location.href.includes('localhost')
    let urlToMap = ``;
    if (isStageUrl) {
      urlToMap = `https://stage.adhocorder.ro/product/${locationId}?${e.value}`
    } else {
      urlToMap = `https://adhocorder.ro/product/${locationId}?${e.value}`
    }
    setQRValue(urlToMap)
  }

  const handleDownloadJPG = async () => {
    const svgElement = document.getElementById('QRcodeRoomTable');
    if (svgElement) {
      try {
        const dataUrl = await toPng(svgElement, { quality: 0.95 });
        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = `${selectedTableName?selectedTableName:'pickup'}.png`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (error) {
        console.error('Convertion from SVG to JPG failed :', error);
      }
    } else {
      console.error('Not found SVG with ID "QRcodeRoomTable".');
    }
  };

  useEffect(() => {
    if (configurationData.length > 0) {
      const { rooms } = configurationData[0];
      setSelectedRoom(rooms[0]);
    }
  }, [configurationData]);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [showModal])

  useEffect(() => {
    setQRValue('')
    if (selectedRoom) {
      let mappedDt
      if (selectedRoom.tables) {
        mappedDt = selectedRoom?.tables.map(val => ({ value: val.id, label: val.name }))
      } else if (rooms) {
        mappedDt = rooms[0]?.tables.map(val => ({ value: val.id, label: val.name }))
      }
      
      setTableList(mappedDt)
      setSelectedTableName('')
      setSelectedTableId('')

      if (selectedRoom.id == 'pick_del_id') {

        let isStageUrl = window.location.href.includes('stage') || window.location.href.includes('localhost')
        let urlToMap = ``;
        if (isStageUrl) {
          urlToMap = `https://stage.adhocorder.ro/product/${locationId}`
        } else {
          urlToMap = `https://adhocorder.ro/product/${locationId}`
        }
        setQRValue(urlToMap)
      }
    }
  }, [selectedRoom])
  return (
    <div className={`${!showModal && 'hidden'} left-0 top-0 h-full w-full bg-opacity-60 bg-black fixed z-30 flex justify-center items-center`}>
      <div className='bg-white overflow-y-scroll rounded-lg p-6 w-5/6 h-5/6'>
        <div className='lg:grid lg:grid-cols-4 h-full lg:gap-5'>
          <div className='col-span-3'>
            <div className='text-xl lg:text-3xl font-semibold flex justify-between'>
              QR Code Generator for {`${selectedRoom?.name ? selectedRoom.name + ' /' : ''} ${selectedTableName ?? ''}`}
              <span className='lg:hidden'><AiOutlineClose onClick={closeModal} size={20} /></span>
            </div>
            <Box className='room-tab-section mt-5'>
              <div className='room-tab flex space-x-7 '>
                {configurationData[0]?.rooms?.map((room, index) => (
                  <div
                    className={`flex items-center cursor-pointer py-5 ${configTabValue === index && 'text-primary700 border-b-2 border-primary700'}`}
                    key={index}
                    onClick={() => {
                      setSelectedRoom(room);
                      handleChangeConfigTab(index);
                    }}
                  >
                    <div>{room?.name}</div>
                  </div>
                ))}
                <div
                  className={`flex items-center cursor-pointer py-5 ${configTabValue === (configurationData[0]?.rooms?.length || 0) && 'text-primary700 border-b-2 border-primary700'}`}
                  key={configurationData[0]?.rooms?.length || 0}
                  onClick={() => {
                    const selRoomDel = {
                      id: 'pick_del_id',
                      name: "Pickup/Delivery",
                      tables: []
                    };
                    setSelectedRoom(selRoomDel);
                    handleChangeConfigTab(configurationData[0]?.rooms?.length || 0);
                  }}
                >
                  <div>Pickup/Delivery</div>
                </div>
              </div>
              <Box className='room-tab-detail'>
                {configurationData[0]?.rooms?.map((room, roomIndex) => (
                  <CustomTabPanel value={configTabValue} index={roomIndex} key={roomIndex}>
                    {room?.tables?.map((table, tableIndex) => (
                      <Box className='table-box' key={tableIndex}>
                        <Draggable position={{ x: table.x, y: table.y }} {...dragHandlers}>
                          <Box
                            className={`${selectedTableId === table?.id && '!bg-primaryColor'} first-room`}
                            style={{
                              backgroundImage: `url(${tableIcon})`,
                              backgroundSize: '43px',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: '90%',
                              width: `${100 + (table.seats > 2 ? (table.seats - 2) * 5.5 : 0)}px`,
                              height: '65px',
                              minWidth: '100px',
                              maxWidth: '200px'
                            }}
                          >
                            <Typography>{table?.name}</Typography>
                          </Box>
                        </Draggable>
                      </Box>
                    ))}
                  </CustomTabPanel>
                ))}
              </Box>
            </Box>
          </div>
          <div className='w-full'>
            <div className='hidden cursor-pointer lg:flex justify-end'>
              <AiOutlineClose onClick={closeModal} size={30} />
            </div>
            <div className='border mb-3 mt-5 border-[#D9D9D9] rounded-xl p-3 w-full lg:h-[90%] flex flex-col justify-between'>
              <div>
                {QRValue && (
                  <QRCode
                    id='QRcodeRoomTable'
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={QRValue}
                    viewBox={`0 0 256 256`}
                  />
                )}
                {selectedRoom?.id !== 'pick_del_id' && (
                  <Select
                    className='mt-4'
                    name="tableList"
                    options={tableList}
                    placeholder="Select Table name"
                    value={selectedTableId && tableList && tableList.find((ele) => ele.value === selectedTableId)}
                      // className="basic-multi-select"
                      // classNamePrefix="select"
                      onChange={handleSelectTable}
                  />
                )
              }
            </div>
            <div className='lg:hidden h-20' ></div>
            <CustomButton onClickButton={handleDownloadJPG} className={'!w-full justify-center'} label={'Download Png'} customBg={'bg-[#FFB800]'} />
          </div>
          <div className='h-5 lg:hidden'></div>
        </div>
      </div>
    </div>
  </div>
)
};

ModalQRGeneratorTable.propTypes = {
  showModal: PropTypes.bool.isRequired,
  locationId: PropTypes.string.isRequired,
  closeModal: PropTypes.func
};

export default ModalQRGeneratorTable;
