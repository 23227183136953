import "./ConfigurationModifier";
import { useState, useEffect, useMemo } from "react";
import AddProduct from "./AddProduct.jsx";
import {
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import axios from '../../axiosConfig';

export default function ConfigurationModifier({
  productModifiers,
  fetchLatestDataAndMap,
  syncToPos
}) {
  const [productModData, setProductModData] = useState(productModifiers);
  const [modToRemove, setModToRemove] = useState(null);
  const [pageSize, setPageSize] = useState(15);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [menuRowId, setMenuRowId] = useState(null);
  const ebrizaLinkedId = localStorage.getItem('ebrizaLinkedId');
  const navigate = useNavigate();
  useEffect(() => {
    if (productModifiers && productModifiers.length > 0) {
      console.log("productModifiers set..",productModifiers);
      setProductModData(productModifiers);
    }
  }, [productModifiers]);

  function syncPOS(event) {
    event.preventDefault();
    console.log("Sync to POS working!");
  }

  function toggleAddModifierProduct() {
    navigate(`/configuration/modifier/new`)
  }

  const handleMenuClick = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setMenuRowId(rowId);
  };

  const handleEditModifier = (modi) => {
    handleMenuClose();
    navigate(`/configuration/modifier/${modi.id}`)
  };
  const handleRemoveModifier = (modi) => {
    handleMenuClose();
    setModToRemove(modi);
    setOpenConfirmDialog(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuRowId(null);
  };

  async function handleConfirmRemove() {
    console.log("Deleting product:", modToRemove);
    
    try {
      let addMenuCat = await axios.delete(`/partners/menu_modifiers_all/${modToRemove.id}`)
      if (addMenuCat.data.success) {
        fetchLatestDataAndMap()
      }
      
    } catch (error) {
      console.log("Failed to delete product",error.message);
      
    } finally{
      setOpenConfirmDialog(false);

    }
  }


  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "source",
        headerName: "Sources",
        minWidth: 150,
      },
      {
        field: "choice",
        headerName: "Targets",
        minWidth: 150,
      },

      {
        field: "actions",
        headerName: "",
        sortable: false,
        renderCell: (params) => (
          <>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => handleMenuClick(event, params.row.id)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={menuRowId === params.row.id}
              onClose={handleMenuClose}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: "20ch",
                },
              }}
            >
              <MenuItem onClick={() => handleEditModifier(params.row)}>
                Edit
              </MenuItem>
              { !ebrizaLinkedId && (
                <MenuItem onClick={() => handleRemoveModifier(params.row)}>
                  Remove
                </MenuItem>
              )}
            </Menu>
          </>
        ),
        width: 50,
      },
    ],
    [anchorEl, menuRowId]
  );


  const rows = productModData.map((elePM)=>{
    return {
      'id':elePM['id'],
      'name':elePM['name'],
      'source':elePM['source'],
      'choice':elePM['choice']
    }
  })

  return (
    <>
      <div className="topbar-inner">
        <div className="link-parent-parent">
          <form className="data">
            <div className="data-label-parent" style={{justifyContent:"flex-end"}}>
              <div className="actions">
                <div></div>
                <div className="right">
                  {!ebrizaLinkedId && (
                    <button
                      className="button-base1"
                      onClick={toggleAddModifierProduct}
                    >
                      <img className="icons1" alt="" src="/icons-53.svg" />
                    </button>
                  )}

                  {/* <div className="button-base6">
                    <img className="icons6" alt="" src="/icons-101.svg" />
                  </div> */}
                  {/* <button className="button-base7" onClick={toggleSearchBar}>
                    <img className="icons7" alt="" src="/icons-16.svg" />
                  </button> */}
                  <button 
                  style={ ebrizaLinkedId?{cursor:'not-allowed'}:{} }
                  className="button-base8" onClick={(event)=>{
                    event.preventDefault();
                    syncToPos()
                  }}>
                    <img className="icons8" alt="" src="/icons-11.svg" />
                    <div className="text1">Sync to POS</div>
                  </button>
                </div>
              </div>
            </div>
            {/* {toggleSearch && (
              <TextField
                className="input3"
                placeholder="name, source, targets"
                variant="outlined"
                sx={{
                  "& fieldset": { borderColor: "#cbcac8" },
                  "& .MuiInputBase-root": {
                    height: "44px",
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                  },
                  "& .MuiInputBase-input": { color: "#3c3a35" },
                }}
              />
            )} */}
            <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[15, 30, 60]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableRowSelectionOnClick
                // onSelectionModelChange={(newSelection) => {
                //   setSelectedProducts(newSelection.selectionModel);
                // }}
              />
            </div>
          </form>
        </div>
      </div>
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this modifier category?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmRemove} color="primary">Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
