import PropTypes from "prop-types";
import "./ModifierCard.css";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import axios from "../../axiosConfig";

const ModifierCard = ({ group_id,index, name,imgUrl,parentHandleRemove }) => {
  const ebrizaLinkedId = localStorage.getItem('ebrizaLinkedId');

  const handleRemoveItem = () =>{
    parentHandleRemove(group_id,index);
  }


  return (
    <>
    <div className={`category-card8`}>
      <div className="imageactions" style={{backgroundColor:'#ffdea1',
      height: '100%',
    width: '100%'}}>
        <div className="imageProduct">
          <img className="icons55" style={{width:'100%',height:'100%',objectFit:'cover'}} alt="" src={imgUrl?imgUrl:"/default.svg"} />
        </div>
        <div className="top4" style={{width: '91%'}}>
          <div className="editDelCont">
            {!ebrizaLinkedId && <img className="icons57 editDelIcon" style={{cursor:'pointer'}} alt="" src="/icons-58.svg"  onClick={handleRemoveItem}/>}
          </div>
        </div>
      </div>
      <div className="text63" style={{    fontSize: '15px'}}>
        <div className="name7">{name}</div>
      </div>
    </div>
  </>
 
  );
};

ModifierCard.propTypes = {
  className: PropTypes.string,
  name1: PropTypes.string,
};

export default ModifierCard;
