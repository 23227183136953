import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import orderimg from '../../assets/img/order-item.png'
import refresh from '../../assets/img/refresh-icon.png'
import { Label } from '@mui/icons-material';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const OrderSection = () => {
    const [value, setValue] = React.useState(0);
    const [more, setMore] = React.useState(false);
    const [age, setAge] = React.useState('');
    const [showMore, setShowMore] = React.useState(false)
    const navigate = useNavigate()



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleselectChange = (event) => {
        setAge(event.target.value);
    };

    const mockupData = [
        {
            orderId : 352463,
            orderDate: 'May 11, 2022 17:45',
            orderDetails: "Burger 2x French fries 1x coke 1x",
            payment: 'Online',
            orderPrice: '$218.00',
            contactUser: '+1 (438) 555 8999',
            withRemarks: false
        },
        {
            orderId : 353163,
            orderDate: 'May 11, 2022 19:15',
            orderDetails: "Burger 2x French fries 1x coke 1x",
            payment: 'Online',
            orderPrice: '$18.20',
            contactUser: '+1 (438) 555 8999',
            withRemarks: true
        },
        {
            orderId : 354623,
            orderDate: 'May 11, 2022 19:21',
            orderDetails: "Burger 2x French fries 1x coke 1x",
            payment: 'Online',
            orderPrice: '$98.00',
            contactUser: '+1 (438) 555 8999',
            withRemarks: false
        },
        {
            orderId : 31963,
            orderDate: 'May 12, 2022 12:05',
            orderDetails: "Burger 1x French fries 3x coke 1x",
            payment: 'Online',
            orderPrice: '$28.90',
            contactUser: '+1 (438) 222 8999',
            withRemarks: true
        },
        {
            orderId : 158493,
            orderDate: 'May 12, 2022 15:11',
            orderDetails: "Burger 2x coke 1x",
            payment: 'Online',
            orderPrice: '$117.00',
            contactUser: '+1 (438) 555 8080',
            withRemarks: false
        },
        {
            orderId : 902161,
            orderDate: 'May 12, 2022 18:21',
            orderDetails: "Burger 12x French fries 3x coke 3x",
            payment: 'Online',
            orderPrice: '$778.00',
            contactUser: '+1 (438) 555 9090',
            withRemarks: true
        },
        
    ]
    const slicedMockup = mockupData.slice(0, 4)


    return (
        <Box className='order-section' sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pb: 2 }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={t("active_tab")} {...a11yProps(0)} />
                    <Tab label={t("pending_tab")} {...a11yProps(1)} />
                    <Tab label={t("history_tab")} {...a11yProps(2)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0} >
                <Grid container spacing={2} >
                    <Grid item lg={3} md={4} sm={4} xs={12}>
                        <Box className='search-filled'>
                            <TextField fullWidth id="outlined-basic" placeholder='Search' variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" sx={{ cursor: 'pointer', color: "#000", position: 'absolute', left: '15px' }}>
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }} />
                        </Box>
                    </Grid>
                    <Button className="refresh-btn"><img src={refresh} />{t("reset_btn")}</Button>
                </Grid>
                <Grid container spacing={2} >
                    <Grid item lg={3} md={4} sm={4} xs={12}>
                        <FormControl className='payment-dropdown filled-wrap'>
                            <label id="">{t("payment_type")}</label>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Age"
                                onChange={handleselectChange}
                            >
                                <MenuItem value='cash'>{t("cash_option")}</MenuItem>
                                <MenuItem value='check'>{t("check_option")}</MenuItem>
                                <MenuItem value='card'>{t("debit_card")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {(showMore ? mockupData : slicedMockup).map((order, i) => {
                            return (
                            <Grid style={{cursor: 'pointer'}} onClick={() => navigate(`/orders/details/${order.orderId}`)} key={i} item lg={6} md={12} sm={12} xs={12}>
                                <Box onClick={() => console.log('clicked')} className="order-box">
                                    <Box className="order-id-wrap">
                                        <Box className="order-id-detail">
                                            <Typography>{t("order_iD")}</Typography>
                                            <Typography variant='p'>{order.orderId}</Typography>
                                        </Box>
                                        <Box className="order-id-detail">
                                            <Typography>{t("order_date")}</Typography>
                                            <Typography variant='p'>{order.orderDate}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="order-detail-wrap">
                                        <Box className={more == order.orderId ? `order-detail order-detail-more` : "order-detail"}>
                                            <img src={orderimg} />
                                            <Typography>{order.orderDetails}</Typography>
                                            <span 
                                                onClick={() => setMore( prev =>  prev == order.orderId ? '' : order.orderId)}
                                            >{more == order.orderId ? 'less' : 'more'}</span>
                                        </Box>
                                        <Box className="payment-detail">
                                            <Typography>{t("payment_detail") + order.payment}</Typography>
                                            {order.withRemarks && <Typography style={{color: 'red'}}>{t("remarks_detail")}</Typography> }
                                        </Box>
                                    </Box>
                                    <Box className='order-price-wrap'>
                                        <Box>
                                            <Box className='order-price-detail'>
                                                <Typography>{t("order_price")}</Typography>
                                                <Typography variant='p'>{order.orderPrice}</Typography>
                                            </Box>
                                            <Box className='order-price-detail'>
                                                <Typography>{t("contact_user")}</Typography>
                                                <Typography variant='p'>{order.contactUser}</Typography>
                                            </Box>
                                        </Box>
                                        <Box className='order-btn'>
                                            <Button className='reject-btn'>{t("reject_btn")}</Button>
                                            <Button className='confirm-btn'>{t("confirm_btn")}</Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            )
                        })}
                    
                </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={4} sm={4} xs={12}>
                        <Box className='search-filled'>
                            <TextField fullWidth id="outlined-basic" placeholder='Search' variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" sx={{ cursor: 'pointer', color: "#000", position: 'absolute', left: '15px' }}>
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }} />
                        </Box>
                    </Grid>
                    <Button className="refresh-btn"><img src={refresh} />{t("reset_btn")}</Button>
                </Grid>
                <Grid container spacing={2} className='filled-wrap'>
                    <Grid item lg={3} md={4} sm={4} xs={12}>
                        <FormControl className='payment-dropdown'>
                            <label id="">{t("date_type")}</label>
                            <TextField id="outlined-basic" placeholder='' variant="outlined" />
                        </FormControl>
                    </Grid>
                    <Grid item lg={3} md={4} sm={4} xs={12}>
                        <FormControl className='payment-dropdown'>
                            <label id="">{t("payment_type")}</label>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Age"
                                onChange={handleselectChange}
                            >
                                <MenuItem value='cash'>{t("cash_option")}</MenuItem>
                                <MenuItem value='check'>{t("check_option")}</MenuItem>
                                <MenuItem value='card'>{t("debit_card")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={3} md={4} sm={4} xs={12}>
                        <FormControl className='payment-dropdown'>
                            <label id="">{t("status_type")}</label>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Age"
                                onChange={handleselectChange}
                            >
                                <MenuItem value='pending'>{t("pending_option")}</MenuItem>
                                <MenuItem value='in Progrees'>{t("in_progrees")}</MenuItem>
                                <MenuItem value='completed'>{t("completed_option")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <Box className="order-box">
                            <Box className="order-id-wrap">
                                <Box className="order-id-detail">
                                    <Typography>{t("order_iD")}</Typography>
                                    <Typography variant='p'>352463</Typography>
                                </Box>
                                <Box className="order-id-detail">
                                    <Typography>{t("order_date")}</Typography>
                                    <Typography variant='p'>May 11, 2022 17:45</Typography>
                                </Box>
                            </Box>
                            <Box className="order-detail-wrap">
                                <Box className={more ? `order-detail order-detail-more` : "order-detail"}>
                                    <img src={orderimg} />
                                    <Typography>{t("burger_detail")}</Typography>
                                    <span onClick={() => setMore(!more)}>{more ? 'less' : 'more'}</span>
                                </Box>
                                <Box className="payment-detail">
                                    <Typography>{t("payment_detail")}</Typography>
                                    <Typography variant='p'>{t("remarks_detail")}</Typography>
                                </Box>
                            </Box>
                            <Box className='order-price-wrap'>
                                <Box>
                                    <Box className='order-price-detail'>
                                        <Typography>{t("order_price")}</Typography>
                                        <Typography variant='p'>$218.00</Typography>
                                    </Box>
                                    <Box className='order-price-detail'>
                                        <Typography>{t("contact_user")}</Typography>
                                        <Typography variant='p'>+1 (438) 555 8999</Typography>
                                    </Box>
                                </Box>
                                <Box className='order-btn'>
                                    <Button className='reject-btn'>{t("reject_btn")}</Button>
                                    <Button className='confirm-btn'>{t("confirm_btn")}</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <Box className="order-box">
                            <Box className="order-id-wrap">
                                <Box className="order-id-detail">
                                    <Typography>{t("order_iD")}</Typography>
                                    <Typography variant='p'>352463</Typography>
                                </Box>
                                <Box className="order-id-detail">
                                    <Typography>{t("order_date")}</Typography>
                                    <Typography variant='p'>May 11, 2022 17:45</Typography>
                                </Box>
                            </Box>
                            <Box className="order-detail-wrap">
                                <Box className={more ? `order-detail order-detail-more` : "order-detail"}>
                                    <img src={orderimg} />
                                    <Typography>{t("burger_detail")}</Typography>
                                    <span onClick={() => setMore(!more)}>{more ? 'less' : 'more'}</span>
                                </Box>
                                <Box className="payment-detail">
                                    <Typography>{t("payment_detail")}</Typography>
                                    <Typography variant='p'>{t("remarks_detail")}</Typography>
                                </Box>
                            </Box>
                            <Box className='order-price-wrap'>
                                <Box>
                                    <Box className='order-price-detail'>
                                        <Typography>{t("order_price")}</Typography>
                                        <Typography variant='p'>$218.00</Typography>
                                    </Box>
                                    <Box className='order-price-detail'>
                                        <Typography>{t("contact_user")}</Typography>
                                        <Typography variant='p'>+1 (438) 555 8999</Typography>
                                    </Box>
                                </Box>
                                <Box className='order-btn'>
                                    <Button className='reject-btn'>{t("reject_btn")}</Button>
                                    <Button className='confirm-btn'>{t("confirm_btn")}</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <Box className="order-box">
                            <Box className="order-id-wrap">
                                <Box className="order-id-detail">
                                    <Typography>{t("order_iD")}</Typography>
                                    <Typography variant='p'>352463</Typography>
                                </Box>
                                <Box className="order-id-detail">
                                    <Typography>{t("order_date")}</Typography>
                                    <Typography variant='p'>May 11, 2022 17:45</Typography>
                                </Box>
                            </Box>
                            <Box className="order-detail-wrap">
                                <Box className={more ? `order-detail order-detail-more` : "order-detail"}>
                                    <img src={orderimg} />
                                    <Typography>{t("burger_detail")}</Typography>
                                    <span onClick={() => setMore(!more)}>{more ? 'less' : 'more'}</span>
                                </Box>
                                <Box className="payment-detail">
                                    <Typography>{t("payment_detail")}</Typography>
                                    <Typography variant='p'>{t("remarks_detail")}</Typography>
                                </Box>
                            </Box>
                            <Box className='order-price-wrap'>
                                <Box>
                                    <Box className='order-price-detail'>
                                        <Typography>{t("order_price")}</Typography>
                                        <Typography variant='p'>$218.00</Typography>
                                    </Box>
                                    <Box className='order-price-detail'>
                                        <Typography>{t("contact_user")}</Typography>
                                        <Typography variant='p'>+1 (438) 555 8999</Typography>
                                    </Box>
                                </Box>
                                <Box className='order-btn'>
                                    <Button className='reject-btn'>{t("reject_btn")}</Button>
                                    <Button className='confirm-btn'>{t("confirm_btn")}</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <Box className="order-box">
                            <Box className="order-id-wrap">
                                <Box className="order-id-detail">
                                    <Typography>{t("order_iD")}</Typography>
                                    <Typography variant='p'>352463</Typography>
                                </Box>
                                <Box className="order-id-detail">
                                    <Typography>{t("order_date")}</Typography>
                                    <Typography variant='p'>May 11, 2022 17:45</Typography>
                                </Box>
                            </Box>
                            <Box className="order-detail-wrap">
                                <Box className={more ? `order-detail order-detail-more` : "order-detail"}>
                                    <img src={orderimg} />
                                    <Typography>{t("burger_detail")}</Typography>
                                    <span onClick={() => setMore(!more)}>{more ? 'less' : 'more'}</span>
                                </Box>
                                <Box className="payment-detail">
                                    <Typography>{t("payment_detail")}</Typography>
                                    <Typography variant='p'>{t("remarks_detail")}</Typography>
                                </Box>
                            </Box>
                            <Box className='order-price-wrap'>
                                <Box>
                                    <Box className='order-price-detail'>
                                        <Typography>{t("order_price")}</Typography>
                                        <Typography variant='p'>$218.00</Typography>
                                    </Box>
                                    <Box className='order-price-detail'>
                                        <Typography>{t("contact_user")}</Typography>
                                        <Typography variant='p'>+1 (438) 555 8999</Typography>
                                    </Box>
                                </Box>
                                <Box className='order-btn'>
                                    <Button className='reject-btn'>{t("reject_btn")}</Button>
                                    <Button className='confirm-btn'>{t("confirm_btn")}</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={4} sm={4} xs={12}>
                        <Box className='search-filled'>
                            <TextField fullWidth id="outlined-basic" placeholder='Search' variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" sx={{ cursor: 'pointer', color: "#000", position: 'absolute', left: '15px' }}>
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }} />
                        </Box>
                    </Grid>
                    <Button className="refresh-btn"><img src={refresh} />{t("reset_btn")}</Button>
                </Grid>
                <Grid container spacing={2} className='filled-wrap'>
                    <Grid item lg={3} md={4} sm={4} xs={12}>
                        <FormControl className='payment-dropdown'>
                            <label id="">{t("date_type")}</label>
                            <TextField id="outlined-basic" placeholder='' variant="outlined" />
                        </FormControl>
                    </Grid>
                    <Grid item lg={3} md={4} sm={4} xs={12}>
                        <FormControl className='payment-dropdown'>
                            <label id="">{t("payment_type")}</label>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Age"
                                onChange={handleselectChange}
                            >
                                <MenuItem value='cash'>{t("cash_option")}</MenuItem>
                                <MenuItem value='check'>{t("check_option")}</MenuItem>
                                <MenuItem value='card'>{t("debit_card")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={3} md={4} sm={4} xs={12}>
                        <FormControl className='payment-dropdown'>
                            <label id="">{t("status_type")}</label>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Age"
                                onChange={handleselectChange}
                            >
                                <MenuItem value='pending'>{t("pending_option")}</MenuItem>
                                <MenuItem value='in Progrees'>{t("in_progrees")}</MenuItem>
                                <MenuItem value='completed'>{t("completed_option")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <Box className="order-box">
                            <Box className="order-id-wrap">
                                <Box className="order-id-detail">
                                    <Typography>{t("order_iD")}</Typography>
                                    <Typography variant='p'>352463</Typography>
                                </Box>
                                <Box className="order-id-detail">
                                    <Typography>{t("order_date")}</Typography>
                                    <Typography variant='p'>May 11, 2022 17:45</Typography>
                                </Box>
                            </Box>
                            <Box className="order-detail-wrap">
                                <Box className={more ? `order-detail order-detail-more` : "order-detail"}>
                                    <img src={orderimg} />
                                    <Typography>{t("burger_detail")}</Typography>
                                    <span onClick={() => setMore(!more)}>{more ? 'less' : 'more'}</span>
                                </Box>
                                <Box className="payment-detail">
                                    <Typography>{t("payment_detail")}</Typography>
                                    <Typography variant='p'>{t("remarks_detail")}</Typography>
                                </Box>
                            </Box>
                            <Box className='order-price-wrap'>
                                <Box>
                                    <Box className='order-price-detail'>
                                        <Typography>{t("order_price")}</Typography>
                                        <Typography variant='p'>$218.00</Typography>
                                    </Box>
                                    <Box className='order-price-detail'>
                                        <Typography>{t("contact_user")}</Typography>
                                        <Typography variant='p'>+1 (438) 555 8999</Typography>
                                    </Box>
                                </Box>
                                <Box className='order-btn'>
                                    <Button className='reject-btn'>{t("reject_btn")}</Button>
                                    <Button className='confirm-btn'>{t("confirm_btn")}</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <Box className="order-box">
                            <Box className="order-id-wrap">
                                <Box className="order-id-detail">
                                    <Typography>{t("order_iD")}</Typography>
                                    <Typography variant='p'>352463</Typography>
                                </Box>
                                <Box className="order-id-detail">
                                    <Typography>{t("order_date")}</Typography>
                                    <Typography variant='p'>May 11, 2022 17:45</Typography>
                                </Box>
                            </Box>
                            <Box className="order-detail-wrap">
                                <Box className={more ? `order-detail order-detail-more` : "order-detail"}>
                                    <img src={orderimg} />
                                    <Typography>{t("burger_detail")}</Typography>
                                    <span onClick={() => setMore(!more)}>{more ? 'less' : 'more'}</span>
                                </Box>
                                <Box className="payment-detail">
                                    <Typography>{t("payment_detail")}</Typography>
                                    <Typography variant='p'>{t("remarks_detail")}</Typography>
                                </Box>
                            </Box>
                            <Box className='order-price-wrap'>
                                <Box>
                                    <Box className='order-price-detail'>
                                        <Typography>{t("order_price")}</Typography>
                                        <Typography variant='p'>$218.00</Typography>
                                    </Box>
                                    <Box className='order-price-detail'>
                                        <Typography>{t("contact_user")}</Typography>
                                        <Typography variant='p'>+1 (438) 555 8999</Typography>
                                    </Box>
                                </Box>
                                <Box className='order-btn'>
                                    <Button className='reject-btn'>{t("reject_btn")}</Button>
                                    <Button className='confirm-btn'>{t("confirm_btn")}</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <Box className="order-box">
                            <Box className="order-id-wrap">
                                <Box className="order-id-detail">
                                    <Typography>{t("order_iD")}</Typography>
                                    <Typography variant='p'>352463</Typography>
                                </Box>
                                <Box className="order-id-detail">
                                    <Typography>{t("order_date")}</Typography>
                                    <Typography variant='p'>May 11, 2022 17:45</Typography>
                                </Box>
                            </Box>
                            <Box className="order-detail-wrap">
                                <Box className={more ? `order-detail order-detail-more` : "order-detail"}>
                                    <img src={orderimg} />
                                    <Typography>{t("burger_detail")}</Typography>
                                    <span onClick={() => setMore(!more)}>{more ? 'less' : 'more'}</span>
                                </Box>
                                <Box className="payment-detail">
                                    <Typography>{t("payment_detail")}</Typography>
                                    <Typography variant='p'>{t("remarks_detail")}</Typography>
                                </Box>
                            </Box>
                            <Box className='order-price-wrap'>
                                <Box>
                                    <Box className='order-price-detail'>
                                        <Typography>{t("order_price")}</Typography>
                                        <Typography variant='p'>$218.00</Typography>
                                    </Box>
                                    <Box className='order-price-detail'>
                                        <Typography>{t("contact_user")}</Typography>
                                        <Typography variant='p'>+1 (438) 555 8999</Typography>
                                    </Box>
                                </Box>
                                <Box className='order-btn'>
                                    <Button className='reject-btn'>{t("reject_btn")}</Button>
                                    <Button className='confirm-btn'>{t("confirm_btn")}</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <Box className="order-box">
                            <Box className="order-id-wrap">
                                <Box className="order-id-detail">
                                    <Typography>{t("order_iD")}</Typography>
                                    <Typography variant='p'>352463</Typography>
                                </Box>
                                <Box className="order-id-detail">
                                    <Typography>{t("order_date")}</Typography>
                                    <Typography variant='p'>May 11, 2022 17:45</Typography>
                                </Box>
                            </Box>
                            <Box className="order-detail-wrap">
                                <Box className={more ? `order-detail order-detail-more` : "order-detail"}>
                                    <img src={orderimg} />
                                    <Typography>{t("burger_detail")}</Typography>
                                    <span onClick={() => setMore(!more)}>{more ? 'less' : 'more'}</span>
                                </Box>
                                <Box className="payment-detail">
                                    <Typography>{t("payment_detail")}</Typography>
                                    <Typography variant='p'>{t("remarks_detail")}</Typography>
                                </Box>
                            </Box>
                            <Box className='order-price-wrap'>
                                <Box>
                                    <Box className='order-price-detail'>
                                        <Typography>{t("order_price")}</Typography>
                                        <Typography variant='p'>$218.00</Typography>
                                    </Box>
                                    <Box className='order-price-detail'>
                                        <Typography>{t("contact_user")}</Typography>
                                        <Typography variant='p'>+1 (438) 555 8999</Typography>
                                    </Box>
                                </Box>
                                <Box className='order-btn'>
                                    <Button className='reject-btn'>{t("reject_btn")}</Button>
                                    <Button className='confirm-btn'>{t("confirm_btn")}</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </CustomTabPanel>
            
            {!showMore && (
                <Box className="show-more-btn">
                    <Button onClick={() => setShowMore(true)}>Show more</Button>
                </Box>
            )}
        </Box>
    );
}
export default OrderSection