import React, { useEffect, useState } from 'react';
import CustomButton from '../../component/CustomButton';
import { AiOutlineClose } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { t } from 'i18next';
import { Box, FormControlLabel, FormHelperText, Switch, TextField, Typography } from '@mui/material';
import moment from 'moment';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import ReactLoading from 'react-loading';
import addLocation from '../../libs/addLocation';
import { useDispatch } from 'react-redux';
import { TOAST_SHOW } from '../../Redux/Actions/ToastAction';
import {editLocation} from '../../libs/editLocation';
import Snackbar from '../../component/ErrorHandler/Snackbar';
import commonFunctions from '../../common/functions';
import EditImgModel from '../Locations/LocationDetails/EditImgModal';
import DefaultImg from '../../assets/img/default-restaurant-img.svg'

const LocationConfigurationTemplate = () => {
    const location = useLocation();
    const selectedLocation = location.state || {};
    const PartnerId = localStorage.getItem('partnerId');
    const [loadingSave, setLoadingSave] = useState(false);
    const [showSnack, setShowSnack] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const [coverImgOpen, setCoverImgOpen] = useState(false);
    const [profileImgOpen, setProfileImgOpen] = useState(false)
    const [currentImageField, setCurrentImageField] = useState('')
    const posDropdownVal = [
        { value: 'Ebriza', label: 'Ebriza' },
        { value: 'Adhoc', label: 'Adhoc' }
    ]
    const discountProDropdownVal = [
        { value: 'profixcard', label: 'Profixcard' },
        { value: 'adhoc', label: 'Adhoc' }
    ]
    
  const [showProfileImage, setShowProfileImage] = useState(DefaultImg);

    const handleImageUpload = (formField)=>{
        setProfileImgOpen(true)
        setCurrentImageField(formField)
    }

    const handleEditImage = async (uploadedUrl) => {
        setCoverImgOpen(false)
        setProfileImgOpen(false)
        if(uploadedUrl){
          console.log("uploadedUrl cat..",uploadedUrl);
          formik.setFieldValue(currentImageField,uploadedUrl)
        }
    
      }

    const defaultSchedule = [
        { day: t('day_monday'), openingHour: '08:00', closingHour: '17:00' },
        { day: t('day_tuesday'), openingHour: '08:00', closingHour: '17:00' },
        { day: t('day_wednesday'), openingHour: '08:00', closingHour: '17:00' },
        { day: t('day_thursday'), openingHour: '08:00', closingHour: '17:00' },
        { day: t('day_friday'), openingHour: '08:00', closingHour: '17:00' },
        { day: t('day_saturday'), openingHour: '08:00', closingHour: '17:00' },
        { day: t('day_sunday'), openingHour: '08:00', closingHour: '17:00' }
    ];

    useEffect(() => {
        if (location.state) {
            formik.setValues({
                LocationId: selectedLocation.LocationId || '',
                PartnerId: PartnerId || '',
                name: selectedLocation.name || '',
                address: selectedLocation.address || '',
                phone: selectedLocation.phone || '',
                posSystem: selectedLocation.posSystem ? { value: selectedLocation.posSystem, label: selectedLocation.posSystem } : { value: 'Ebriza', label: 'Ebriza' },
                autoOrders: selectedLocation.autoOrders || false,
                onlinePayment: selectedLocation.onlinePayment || false,
                openHours: selectedLocation.openHours ? selectedLocation.openHours.map((item, index) => ({
                    ...item,
                    day: defaultSchedule[index].day,
                    active: item.active?item.active:false
                })) : defaultSchedule,
                image: selectedLocation.image || '',
                back_image: selectedLocation.back_image || '',
                color_code: selectedLocation.color_code || '',
                ebriza_user: selectedLocation.ebriza_user || '',
                sendToPreparation: selectedLocation.sendToPreparation || false,
                cash_orders: selectedLocation.cash_orders || false,
                stripeAccount: selectedLocation.stripeAccount || '',
                fee: selectedLocation.fee || '',
                report_email: selectedLocation.report_email || '',
                logo_image: selectedLocation.logo_image || '',
                contact_email: selectedLocation.contact_email || '',
                table_serving: selectedLocation.table_serving || false,
                printer: selectedLocation.printer || '',
                website_url: selectedLocation.website_url || '',
                tags: Array.isArray(selectedLocation.tags) ? selectedLocation.tags.join(',') : selectedLocation.tags || '',
                report_frequency: selectedLocation.report_frequency || '',
                report_hour: selectedLocation.report_hour || '',
                extra: selectedLocation.extra || '',
                clear_table: selectedLocation.clear_table || false,
                call_waiter: selectedLocation.call_waiter || false,
                ask_bill: selectedLocation.ask_bill || false,
                delivery: selectedLocation.delivery || false,
                pickup: selectedLocation.pickup || false,
                paylater_supported: selectedLocation.paylater_supported || false,
                segway: selectedLocation.segway || '',
                pin_url: selectedLocation.pin_url || '',
                facebook: selectedLocation.facebook || '',
                twitter: selectedLocation.twitter || '',
                youtube: selectedLocation.youtube || '',
                tiktok: selectedLocation.tiktok || '',
                linkedin: selectedLocation.linkedin || '',
                instagram: selectedLocation.instagram || '',
                google_review_url: selectedLocation.google_review_url || '',
                review_url: selectedLocation.review_url || '',
                orderLimitNumber: selectedLocation.orderLimitNumber || 0,
                orderLimitTimeMin: selectedLocation.orderLimitTimeMin || 0,
                email_subject_received: selectedLocation.email_subject_received || '',
                email_subject_in_prep: selectedLocation.email_subject_in_prep || '',
                email_subject_completed: selectedLocation.email_subject_completed || '',
                available_translation: selectedLocation.available_translation || '',
                help_instructions: selectedLocation.help_instructions || false,
                help_instructions_pages:selectedLocation.help_instructions_pages || '',
                discount:selectedLocation.discount || '',
                discount_provider: selectedLocation.discount_provider ? discountProDropdownVal.find((ele)=>ele.value == selectedLocation.discount_provider) : discountProDropdownVal[1],
                discount_promo_text:selectedLocation.discount_promo_text||''
            });
        }
    }, [location.state]);


    const formik = useFormik({
        initialValues: {
            LocationId: selectedLocation.LocationId || '',
            PartnerId: PartnerId || '',
            name: selectedLocation.name || '',
            address: selectedLocation.address || '',
            phone: selectedLocation.phone || '',
            posSystem: selectedLocation.posSystem ? { value: selectedLocation.posSystem, label: selectedLocation.posSystem } : { value: 'Ebriza', label: 'Ebriza' },
            autoOrders: selectedLocation.autoOrders || false,
            onlinePayment: selectedLocation.onlinePayment || false,
            openHours: selectedLocation.openHours ? selectedLocation.openHours.map((item, index) => ({
                ...item,
                day: defaultSchedule[index].day,
                active: item.active?item.active:false
            })) : defaultSchedule,
            image: selectedLocation.image || '',
            back_image: selectedLocation.back_image || '',
            color_code: selectedLocation.color_code || '',
            ebriza_user: selectedLocation.ebriza_user || '',
            sendToPreparation: selectedLocation.sendToPreparation || false,
            cash_orders: selectedLocation.cash_orders || false,
            stripeAccount: selectedLocation.stripeAccount || '',
            fee: selectedLocation.fee || '',
            report_email: selectedLocation.report_email || '',
            logo_image: selectedLocation.logo_image || '',
            contact_email: selectedLocation.contact_email || '',
            table_serving: selectedLocation.table_serving || false,
            printer: selectedLocation.printer || '',
            website_url: selectedLocation.website_url || '',
            tags: Array.isArray(selectedLocation.tags) ? selectedLocation.tags.join(',') : selectedLocation.tags || '',
            report_frequency: selectedLocation.report_frequency || '',
            report_hour: selectedLocation.report_hour || '',
            extra: selectedLocation.extra || '',
            clear_table: selectedLocation.clear_table || false,
            call_waiter: selectedLocation.call_waiter || false,
            ask_bill: selectedLocation.ask_bill || false,
            delivery: selectedLocation.delivery || false,
            pickup: selectedLocation.pickup || false,
            paylater_supported: selectedLocation.paylater_supported || false,
            segway: selectedLocation.segway || '',
            pin_url: selectedLocation.pin_url || '',
            facebook: selectedLocation.facebook || '',
            twitter: selectedLocation.twitter || '',
            youtube: selectedLocation.youtube || '',
            tiktok: selectedLocation.tiktok || '',
            linkedin: selectedLocation.linkedin || '',
            instagram: selectedLocation.instagram || '',
            google_review_url: selectedLocation.google_review_url || '',
            review_url: selectedLocation.review_url || '',
            orderLimitNumber: selectedLocation.orderLimitNumber || 0,
            orderLimitTimeMin: selectedLocation.orderLimitTimeMin || 0,
            email_subject_received: selectedLocation.email_subject_received || '',
            email_subject_in_prep: selectedLocation.email_subject_in_prep || '',
            email_subject_completed: selectedLocation.email_subject_completed || '',
            available_translation: selectedLocation.available_translation || '',
            help_instructions: selectedLocation.help_instructions || false,
            help_instructions_pages:selectedLocation.help_instructions_pages || '',
            discount:selectedLocation.discount || '',
            discount_provider: selectedLocation.discount_provider ? discountProDropdownVal.find((ele)=>ele.value == selectedLocation.discount_provider) : discountProDropdownVal[1],
            discount_promo_text:selectedLocation.discount_promo_text||''
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required('Location name is required'),
            address: Yup.string().required('Full address is required'),
            // report_email: Yup.string().required('Email address is required'),
            phone: Yup.string().required('Contact is required'),
            color_code: Yup.string().required('Color theme is required')
        }),
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: async (values) => {
            const payload = {
                ...values,
                posSystem: values.posSystem.value,
                discount_provider: values.discount_provider.value,
                tags: values.tags.split(',')
            };

            if (selectedLocation.LocationId) {
                await handleEditLocation(payload, selectedLocation.LocationId);
            } else {
                await handleAddLocation(payload);
            }
        }
    });
function handleAiOutlineClose(){
    localStorage.removeItem('formData');
    navigate(-1)

}

    const handleChangePOS = (val) => {
        formik.setFieldValue('posSystem', val);
    };

    const handleImage = async (e, field) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async () => {
                const uploadedUrl = await commonFunctions.uploadImageToAws(reader.result);
                formik.setFieldValue(field, uploadedUrl);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleAddLocation = async (payload) => {
        setLoadingSave(true);
        try {
            let res = await addLocation(payload);
            if (res.success) {
                dispatch({ type: TOAST_SHOW, payload: { message: 'New Location saved!', severity: 'success' } });
            }
        } catch (error) {
            dispatch({ type: TOAST_SHOW, payload: { message: 'New Location failed!', severity: 'error' } });
        } finally {
            setShowSnack(true);
            setLoadingSave(false);
            localStorage.removeItem('formData');
        }
    };

    const handleEditLocation = async (payload, LocationId) => {
        setLoadingSave(true);
        try {
            let res = await editLocation(payload, LocationId);
            if (res) {
                dispatch({ type: TOAST_SHOW, payload: { message: 'Update Location saved!', severity: 'success' } });
            }
        } catch (error) {
            dispatch({ type: TOAST_SHOW, payload: { message: 'Update Location failed!', severity: 'error' } });
        } finally {
            setShowSnack(true);
            setLoadingSave(false);
        }
    };
    const parseTime = (time) => {
        if (!time) return new Date();
        const [hours, minutes] = time.split(':').map(Number);
        const date = new Date();
        date.setHours(hours, minutes);
        return date;
    };

    useEffect(() => {
        const savedFormData = localStorage.getItem('formData');
        if (savedFormData) {
          formik.setValues(JSON.parse(savedFormData));
        }
      }, []);
    
      useEffect(() => {
        if (formik.values) {  // Only save if formik values are present
          localStorage.setItem('formData', JSON.stringify(formik.values));
        }
      }, [formik.values]);
    return (
        <>
            <EditImgModel coverImgOpen={coverImgOpen} profileImgOpen={profileImgOpen} locationId="" handleClose={handleEditImage} setShowProfileImage={setShowProfileImage}/>
      
            <Snackbar alertOpen={showSnack} handleClose={() => setShowSnack(false)} vertical={'top'} autoHideDuration={4000} />
            <div className='rounded-xl border py-10 px-2 lg:px-5'>
                <div className='border-b pb-5 flex justify-between items-center'>
                    <CustomButton
                        onClickButton={formik.handleSubmit}
                        label={loadingSave ? <ReactLoading height={'25px'} width={'25px'} type={'spokes'} color='black' /> : t('save_btn')}
                        customBg={'bg-primary700'}
                        disabled={!formik.isValid || formik.isSubmitting}
                    />
                    <div className='text-3xl text-center lg:text-4xl font-semibold'>{selectedLocation.LocationId ? t('edit_location') : t('new_location')}</div>
                    <AiOutlineClose onClick={() => handleAiOutlineClose()} className='cursor-pointer' size={25} />
                </div>
                <div className='mt-6 space-y-6'>
                    <div className='text-2xl lg:text-3xl font-semibold'>{t('location_tab')}</div>
                    <div className='grid lg:grid-cols-3 gap-6'>
                        <Box className='location-filled-inner'>
                            <label>{t('name')}</label>
                            <TextField
                                placeholder='Location Name'
                                variant='outlined'
                                name='name'
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.name && formik.errors.name && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.name}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('address')}</label>
                            <TextField
                                placeholder='Full Address'
                                variant='outlined'
                                name='address'
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.address && formik.errors.address && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.address}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('contact_number')}</label>
                            <TextField
                                placeholder='Contact Number'
                                variant='outlined'
                                name='phone'
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.phone && formik.errors.phone && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.phone}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('pos_system')}</label>
                            <Select
                                className='w-full'
                                name='posSystem'
                                options={posDropdownVal}
                                value={posDropdownVal.find((ele)=>ele.value ==  formik.values.posSystem.value)}
                                placeholder='Select POS system...'
                                onChange={(selectedOption) => {
                                    formik.setFieldValue('posSystem', selectedOption ? selectedOption : '');
                                }}
                            />
                        </Box>
                        {formik.values.posSystem.value == 'Ebriza' && (
                        <Box className='location-filled-inner'>
                            <label>{t('ebriza_user')}</label>
                            <TextField
                                placeholder='Your Ebriza user...'
                                variant='outlined'
                                name='ebriza_user'
                                value={formik.values.ebriza_user}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.ebriza_user && formik.errors.ebriza_user && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.ebriza_user}
                                </FormHelperText>
                            )}
                        </Box>

                        )}
                        <Box className='location-filled-inner'>
                            <label>{t('color_theme')}</label>
                            <TextField
                                placeholder='e.g #FFFFFF'
                                variant='outlined'
                                name='color_code'
                                value={formik.values.color_code}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.color_code && formik.errors.color_code && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.color_code}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>Back Image</label>
                            <div style={{'position':'relative'}}>
                            <TextField
                                placeholder=''
                                variant='outlined'
                                name='back_image'
                                value={formik.values.back_image}
                                onChange={formik.handleChange}
                            />
                            
                            <button className='upload-btn' onClick={()=>handleImageUpload('back_image')}>Upload</button>

                            </div>
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>Image</label>
                            <div style={{'position':'relative'}}>
                                <TextField
                                    placeholder=''
                                    variant='outlined'
                                    name='image'
                                    value={formik.values.image}
                                    onChange={formik.handleChange}
                                />
                                <button className='upload-btn' onClick={()=>handleImageUpload('image')}>Upload</button>

                            </div>
                        </Box>
                        <Box className='location-filled-inner'>

                            <label>Logo Image</label>
                        <div style={{'position':'relative'}}>
                            <TextField
                                placeholder=''
                                variant='outlined'
                                name='logo_image'
                                value={formik.values.logo_image}
                                onChange={formik.handleChange}
                            />
                            <button className='upload-btn' onClick={()=>handleImageUpload('logo_image')}>Upload</button>

                        </div>
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>Contact Email</label>
                            <TextField
                                placeholder=''
                                variant='outlined'
                                name='contact_email'
                                value={formik.values.contact_email}
                                onChange={formik.handleChange}
                            />
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>Available Translation</label>
                            <TextField
                                placeholder=''
                                variant='outlined'
                                name='available_translation'
                                value={formik.values.available_translation}
                                onChange={formik.handleChange}
                            />
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('Email')}</label>
                            <TextField
                                placeholder='Email'
                                variant='outlined'
                                name='report_email'
                                value={formik.values.report_email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.report_email && formik.errors.report_email && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.report_email}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('segway')}</label>
                            <TextField
                                placeholder='Segway'
                                variant='outlined'
                                name='segway'
                                value={formik.values.segway}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.segway && formik.errors.segway && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.segway}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('pin_url')}</label>
                            <TextField
                                placeholder='Pin URL'
                                variant='outlined'
                                name='pin_url'
                                value={formik.values.pin_url}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.pin_url && formik.errors.pin_url && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.pin_url}
                                </FormHelperText>
                            )}
                        </Box>

                        <Box className='location-filled-inner'>
                            <label>{t('email_subject_completed')}</label>
                            <TextField
                                placeholder='Email Subject Completed'
                                variant='outlined'
                                name='email_subject_completed'
                                value={formik.values.email_subject_completed}
                                onChange={formik.handleChange}
                            />
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('email_subject_in_prep')}</label>
                            <TextField
                                placeholder='Email Subject In Prep'
                                variant='outlined'
                                name='email_subject_in_prep'
                                value={formik.values.email_subject_in_prep}
                                onChange={formik.handleChange}
                            />
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('email_subject_received')}</label>
                            <TextField
                                placeholder='Email Subject Received'
                                variant='outlined'
                                name='email_subject_received'
                                value={formik.values.email_subject_received}
                                onChange={formik.handleChange}
                            />
                        </Box>

                        <Box className='location-filled-inner'>
                            <label>{t('stripeAccount')}</label>
                            <TextField
                                placeholder='Stripe Account'
                                variant='outlined'
                                name='stripeAccount'
                                value={formik.values.stripeAccount}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.stripeAccount && formik.errors.stripeAccount && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.stripeAccount}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('tags')}</label>
                            <TextField
                                placeholder='Tags'
                                variant='outlined'
                                name='tags'
                                value={formik.values.tags}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.tags && formik.errors.tags && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.tags}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('website_url')}</label>
                            <TextField
                                placeholder='Website URL'
                                variant='outlined'
                                name='website_url'
                                value={formik.values.website_url}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.website_url && formik.errors.website_url && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.website_url}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('linkedin')}</label>
                            <TextField
                                placeholder='LinkedIn'
                                variant='outlined'
                                name='linkedin'
                                value={formik.values.linkedin}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.linkedin && formik.errors.linkedin && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.linkedin}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('twitter')}</label>
                            <TextField
                                placeholder='Twitter'
                                variant='outlined'
                                name='twitter'
                                value={formik.values.twitter}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.twitter && formik.errors.twitter && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.twitter}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('instagram')}</label>
                            <TextField
                                placeholder='Instagram'
                                variant='outlined'
                                name='instagram'
                                value={formik.values.instagram}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.instagram && formik.errors.instagram && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.instagram}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('review_url')}</label>
                            <TextField
                                placeholder='Review URL'
                                variant='outlined'
                                name='review_url'
                                value={formik.values.review_url}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.review_url && formik.errors.review_url && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.review_url}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>Order Limit Number</label>
                            <TextField
                                placeholder='Review URL'
                                variant='outlined'
                                name='orderLimitNumber'
                                type='number'
                                value={formik.values.orderLimitNumber}
                                onChange={formik.handleChange}
                            />
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>Order Limit Time(Min)</label>
                            <TextField
                                placeholder='Review URL'
                                variant='outlined'
                                name='orderLimitTimeMin'
                                type='number'
                                value={formik.values.orderLimitTimeMin}
                                onChange={formik.handleChange}
                            />
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('facebook')}</label>
                            <TextField
                                placeholder='Facebook'
                                variant='outlined'
                                name='facebook'
                                value={formik.values.facebook}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.facebook && formik.errors.facebook && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.facebook}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('google_review_url')}</label>
                            <TextField
                                placeholder='Google Review URL'
                                variant='outlined'
                                name='google_review_url'
                                value={formik.values.google_review_url}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.google_review_url && formik.errors.google_review_url && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.google_review_url}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('youtube')}</label>
                            <TextField
                                placeholder='YouTube'
                                variant='outlined'
                                name='youtube'
                                value={formik.values.youtube}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.youtube && formik.errors.youtube && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.youtube}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('tiktok')}</label>
                            <TextField
                                placeholder='TikTok'
                                variant='outlined'
                                name='tiktok'
                                value={formik.values.tiktok}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.tiktok && formik.errors.tiktok && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.tiktok}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('fee')}</label>
                            <TextField
                                placeholder='Fee'
                                variant='outlined'
                                name='fee'
                                value={formik.values.fee}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.fee && formik.errors.fee && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.fee}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('printer')}</label>
                            <TextField
                                placeholder='Printer'
                                variant='outlined'
                                name='printer'
                                value={formik.values.printer}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.printer && formik.errors.printer && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.printer}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('report_frequency')}</label>
                            <TextField
                                placeholder='Report Frequency'
                                variant='outlined'
                                name='report_frequency'
                                value={formik.values.report_frequency}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.report_frequency && formik.errors.report_frequency && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.report_frequency}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('report_hour')}</label>
                            <TextField
                                placeholder='Report Hour'
                                variant='outlined'
                                name='report_hour'
                                value={formik.values.report_hour}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.report_hour && formik.errors.report_hour && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.report_hour}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('extra')}</label>
                            <TextField
                                placeholder='Extra'
                                variant='outlined'
                                name='extra'
                                value={formik.values.extra}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.extra && formik.errors.extra && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.extra}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className='location-filled-inner'>
                            <label>{t('help_instructions_pages')}</label>
                            <TextField
                                placeholder='Help Instructions Pages'
                                variant='outlined'
                                name='help_instructions_pages'
                                value={formik.values.help_instructions_pages}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.help_instructions_pages && formik.errors.help_instructions_pages && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.help_instructions_pages}
                                </FormHelperText>
                            )}
                        </Box>
                        

                        <Box className='location-filled-inner'>
                            <label>{"Discount Provider"}</label>
                            <Select
                                className='w-full'
                                name='discount_provider'
                                options={discountProDropdownVal}
                                value={discountProDropdownVal.find((ele)=>ele.value ==  formik.values.discount_provider.value)}
                                placeholder='Discount Provider'
                                onChange={(selectedOption) => {
                                    formik.setFieldValue('discount_provider', selectedOption ? selectedOption : '');
                                }}
                            />
                        </Box>

                        <Box className='location-filled-inner'>
                            <label>{"Discount Promo Text"}</label>
                            <TextField
                                placeholder='Discount Promo Text'
                                variant='outlined'
                                name='discount_promo_text'
                                value={formik.values.discount_promo_text}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.discount_promo_text && formik.errors.discount_promo_text && (
                                <FormHelperText className='error' style={{ color: 'red', textAlign: 'start', margin: '5px' }}>
                                    {formik.errors.discount_promo_text}
                                </FormHelperText>
                            )}
                        </Box>


                    </div>
                    <div className='flex flex-wrap lg:space-x-7'>
                        <div>
                            <div className='flex items-center'>
                                <input
                                    name='autoOrders'
                                    onChange={formik.handleChange}
                                    checked={formik.values.autoOrders}
                                    id='autoOrders'
                                    type='checkbox'
                                    value=''
                                    className='w-4 h-4 accent-primary700'
                                />
                                <label htmlFor='autoOrders' className='ml-2 w-32 lg:w-auto text-sm text-gray-900'>
                                    {t('accpet_orders')}
                                </label>
                            </div>
                            <div className='flex items-center mt-5'>
                                <input
                                    name='onlinePayment'
                                    onChange={formik.handleChange}
                                    checked={formik.values.onlinePayment}
                                    id='onlinePayment'
                                    type='checkbox'
                                    value=''
                                    className='w-4 h-4 accent-primary700'
                                />
                                <label htmlFor='onlinePayment' className='ml-2 w-32 lg:w-auto text-sm text-gray-900'>
                                    {t('online_payment')}
                                </label>
                            </div>
                            <div className='flex items-center mt-5'>
                                <input
                                    name='table_serving'
                                    onChange={formik.handleChange}
                                    checked={formik.values.table_serving}
                                    id='table_serving'
                                    type='checkbox'
                                    value=''
                                    className='w-4 h-4 accent-primary700'
                                />
                                <label htmlFor='table_serving' className='ml-2 w-32 lg:w-auto text-sm text-gray-900'>
                                    Table Serving
                                </label>
                            </div>
                        </div>
                        <div>
                            <div className='flex items-center'>
                                <input
                                    name='sendToPreparation'
                                    onChange={formik.handleChange}
                                    checked={formik.values.sendToPreparation}
                                    id='sendToPreparation'
                                    type='checkbox'
                                    value=''
                                    className='w-4 h-4 accent-primary700'
                                />
                                <label htmlFor='sendToPreparation' className='ml-2 w-32 lg:w-auto text-sm text-gray-900'>
                                    {t('send_to_prep')}
                                </label>
                            </div>
                            <div className='flex items-center mt-5'>
                                <input
                                    name='cash_orders'
                                    onChange={formik.handleChange}
                                    checked={formik.values.cash_orders}
                                    id='cash_orders'
                                    type='checkbox'
                                    value=''
                                    className='w-4 h-4 accent-primary700'
                                />
                                <label htmlFor='cash_orders' className='ml-2 w-32 lg:w-auto text-sm text-gray-900'>
                                    {t('cash_payment')}
                                </label>
                            </div>

                            <div className='flex items-center mt-5'>
                                <input
                                    name='discount'
                                    onChange={formik.handleChange}
                                    checked={formik.values.discount}
                                    id='discount'
                                    type='checkbox'
                                    value=''
                                    className='w-4 h-4 accent-primary700'
                                />
                                <label htmlFor='discount' className='ml-2 w-32 lg:w-auto text-sm text-gray-900'>
                                    {t('Discount')}
                                </label> </div>
                        </div>
                        
                        <div className='mt-5 lg:mt-0'>
                            <div className='flex items-center'>
                                <input
                                    name='delivery'
                                    onChange={formik.handleChange}
                                    checked={formik.values.delivery}
                                    id='delivery'
                                    type='checkbox'
                                    value=''
                                    className='w-4 h-4 accent-primary700'
                                />
                                <label htmlFor='delivery' className='ml-2 w-32 lg:w-auto text-sm text-gray-900'>
                                    {t('delivery')}
                                </label>
                            </div>
                            <div className='flex items-center mt-5'>
                                <input
                                    name='pickup'
                                    onChange={formik.handleChange}
                                    checked={formik.values.pickup}
                                    id='pickup'
                                    type='checkbox'
                                    value=''
                                    className='w-4 h-4 accent-primary700'
                                />
                                <label htmlFor='pickup' className='ml-2 w-32 lg:w-auto text-sm text-gray-900'>
                                    {t('pickup')}
                                </label>
                            </div>
                            <div className='flex items-center mt-5'>
                                <input
                                    name='paylater_supported'
                                    onChange={formik.handleChange}
                                    checked={formik.values.paylater_supported}
                                    id='paylater_supported'
                                    type='checkbox'
                                    value=''
                                    className='w-4 h-4 accent-primary700'
                                />
                                <label htmlFor='paylater_supported' className='ml-2 w-32 lg:w-auto text-sm text-gray-900'>
                                    {"Paylater Supported"}
                                </label>
                            </div>
                        </div>
                        <div className='mt-5 lg:mt-0'>
                            <div className='flex items-center'>
                                <input
                                    name='clear_table'
                                    onChange={formik.handleChange}
                                    checked={formik.values.clear_table}
                                    id='clear_table'
                                    type='checkbox'
                                    value=''
                                    className='w-4 h-4 accent-primary700'
                                />
                                <label htmlFor='clear_table' className='ml-2 w-32 lg:w-auto text-sm text-gray-900'>
                                    {t('clear_table')}
                                </label>
                            </div>
                            <div className='flex items-center mt-5'>
                                <input
                                    name='call_waiter'
                                    onChange={formik.handleChange}
                                    checked={formik.values.call_waiter}
                                    id='call_waiter'
                                    type='checkbox'
                                    value=''
                                    className='w-4 h-4 accent-primary700'
                                />
                                <label htmlFor='call_waiter' className='ml-2 w-32 lg:w-auto text-sm text-gray-900'>
                                    {t('call_waiter')}
                                </label>
                            </div>
                           
                        </div>
                        <div className='mt-5 lg:mt-0'>
                            <div className='flex items-center'>
                                <input
                                    name='ask_bill'
                                    onChange={formik.handleChange}
                                    checked={formik.values.ask_bill}
                                    id='ask_bill'
                                    type='checkbox'
                                    value=''
                                    className='w-4 h-4 accent-primary700'
                                />
                                <label htmlFor='ask_bill' className='ml-2 w-32 lg:w-auto text-sm text-gray-900'>
                                    {t('ask_bill')}
                                </label>
                            </div>
                            <div className='flex items-center mt-5'>
                                <input
                                    name='help_instructions'
                                    onChange={formik.handleChange}
                                    checked={formik.values.help_instructions}
                                    id='help_instructions'
                                    type='checkbox'
                                    value=''
                                    className='w-4 h-4 accent-primary700'
                                />
                                <label htmlFor='help_instructions' className='ml-2 w-32 lg:w-auto text-sm text-gray-900'>
                                    {t('help_instructions')}
                                </label>

                                </div>

                                
                        </div>
                    </div>
                    <div>
                        <div className='text-3xl font-semibold'>{t('open_hours')}</div>
                        <div className='grid lg:grid-cols-2 mt-4'>
                            <div>
                                <Box className='day-schedule space-y-2'>
                                    {formik.values.openHours?.map((schedule, index) => (
                                        <Box className='flex space-x-2 lg:space-x-5 items-center' key={index}>
                                            <Typography>{defaultSchedule[index].day}</Typography>
                                            <div className='flex items-center space-x-3'>
                                                <DatePicker
                                                    selected={parseTime(schedule?.openingHour)}
                                                    onChange={(date) => {
                                                        formik.setFieldValue(`openHours.${index}.openingHour`, moment(date).format('HH:mm'));
                                                    }}
                                                    id={`openingHour_${index}`}
                                                    showTimeSelectOnly
                                                    autoComplete='off'
                                                    dateFormat='HH:mm'
                                                    showTimeSelect
                                                    timeFormat='HH:mm'
                                                    timeIntervals={60}
                                                    className={'flighttime-picker w-16 text-center border-2 p-1 rounded-lg'}
                                                    placeholderText='Select time'
                                                />

                                                <DatePicker
                                                    selected={parseTime(schedule?.closingHour)}
                                                    onChange={(date) => {
                                                        formik.setFieldValue(`openHours.${index}.closingHour`, moment(date).format('HH:mm'));
                                                    }}
                                                    id={`closingHour_${index}`}
                                                    showTimeSelectOnly
                                                    autoComplete='off'
                                                    dateFormat='HH:mm'
                                                    showTimeSelect
                                                    timeFormat='HH:mm'
                                                    timeIntervals={60}
                                                    className={'flighttime-picker w-16 text-center border-2 p-1 rounded-lg'}
                                                    placeholderText='Select time'
                                                />
                                            </div>
                                            <Switch
                                                {...label}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                defaultChecked={schedule?.active}
                                                id={`active${index}`}
                                                onChange={(e) => {
                                                    formik.setFieldValue(`openHours.${index}.active`, e.target.checked);
                                                }}
                                                color='warning'
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default LocationConfigurationTemplate;
