import { Box, Button, Grid, InputAdornment, Switch, TextField, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import React from 'react'
import { useTranslation } from 'react-i18next';
import food from '../../assets/img/food-img.png';
import { useNavigate } from 'react-router-dom';

const index = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const mockData = [
    {
        bannerName : 'Canadian',
        startDate : 'May 28, 2024',
        endDate : 'June 28, 2024',
        image : food
    },
    {
        bannerName : 'Canadian',
        startDate : 'May 28, 2024',
        endDate : 'June 28, 2024',
        image : food
    },
    {
        bannerName : 'Canadian',
        startDate : 'May 28, 2024',
        endDate : 'June 28, 2024',
        image : food
    },
    {
        bannerName : 'Canadian',
        startDate : 'May 28, 2024',
        endDate : 'June 28, 2024',
        image : food
    }
  ]
  return (
    <div>
        <div className='text-xl font-semibold'>Banners</div>
        <Box className="location-detail mt-5">
            <Box className="search-box">
                <TextField id="outlined-basic" variant="outlined" placeholder={t("Search")} InputProps={{
                    startAdornment: (
                        <InputAdornment position="start" sx={{ color: "#000" }}>
                            <SearchIcon />
                        </InputAdornment>
                    )
                }} />
            </Box>
            <Box>
                <Button className="add-partner" onClick={() => navigate('/banners/add-banner')}>{'Add Banner'}</Button>
            </Box>
        </Box>
        <Grid container spacing={3}>
            {mockData.length > 0 && mockData.map((item, index) =>
                <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                    <Box className="food-box" onClick={() => {}}>
                        <img src={food} alt='banner_image' />
                        {/* <img src={location?.image} alt='location_image' /> */}
                        <Box className='food-detail'>
                            <Box className='food-name'>
                                {/* <Typography variant='h5'>{t("asian_food")}</Typography> */}
                                <Typography variant='h5'>{item?.bannerName}</Typography>
                                <Switch defaultChecked />
                            </Box>
                            <Typography>Start Date: {item?.startDate}</Typography>
                            <Typography>End Date: {item?.endDate}</Typography>
                        </Box>
                    </Box>
                </Grid>
            )}
        </Grid>
    </div>
  )
}

export default index