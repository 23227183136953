import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import combined from '../../assets/img/combined.png'
import './style.css'
import { Stack } from '@mui/material';
import CancelIcon from "@mui/icons-material/Cancel";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

// const names = [
//     combined,
//     combined,
//     combined,
//     combined,
//     combined,
// ];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const MultipleSelect = ({ values, title, onChange, selectedData }) => {
    const [selectedNames, setSelectedNames] = React.useState([]);

    React.useEffect(() => {
        if (selectedData && selectedData.length) {
            setSelectedNames(selectedData);
        }
    }, [selectedData]);

    const handleSelectChange = (e) => {
        const newSelectedNames = e.target.value;
        setSelectedNames(newSelectedNames);
        onChange(newSelectedNames); // Use the updated value directly
    };

    return (
        <div>
            <FormControl className='allergens-dropdown' sx={{ m: 1, width: 200 }}>
                <InputLabel id="demo-multiple-chip-label">Select {title}</InputLabel>
                
                <Select
                    multiple
                    value={selectedNames}
                    onChange={handleSelectChange} // Use the handler function
                    input={<OutlinedInput label="Multiple Select" />}
                    placeholder={title}
                    sx={{
                        '& .MuiFormLabel-root': {
                            top: '-8px',
                        }
                    }}
                >
                    {values.map((value, index) => (
                        <MenuItem key={index} value={value}>
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};
export default MultipleSelect