import { Route, Routes } from "react-router-dom";
import React from "react";
import Login from "../page/Login";
import { Layout } from "../page/layout";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";
import Partners from "../page/Partners";
import { ViewDetails } from "../page/Partners/ViewDetails";
import { PartnerLocation } from "../page/Partners/PartnerLocation";
import Dashboard from "../page/Dashboard";
import Orders from "../page/Orders";
import Locations from "../page/Locations";
import Users from "../page/Users";
import LocationDetails from "../page/Locations/LocationDetails";
import ForgetPassword from "../page/ForgetPassword";
import CreateNewPassword from "../page/ForgetPassword/CreateNewPassword";
import OrderDetails from "../page/Orders/OrderDetails";
import Menu from "../page/Menu"
import AddCategory from "../page/Menu/AddCategory";
import AddEditProduct from "../page/Menu/AddEditProduct";
import Restaurants from "../page/Restaurants"
import AddRestaurant from "../page/Restaurants/AddRestaurant";
import Drivers from '../page/Drivers'
import AddDriver from "../page/Drivers/AddDriver";
import Banners from "../page/Banners"
import AddBanner from "../page/Banners/AddBanner";
import GenerateQR from "../page/GenerateQR"
import Settings from "../page/Settings"
import Configuration from "../page/Configuration"
import GlobalLayout from "../contexts/GlobalContext";
import AddLocation from "../page/Settings/AddLocation";
import AddProduct from "../page/Configuration/AddProduct";
import AddModifier from "../page/Configuration/AddModifier";
import Reports from "../page/Reports";

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<PublicRoute />} >
        <Route path={`/login`} element={<Login />} />
        <Route path={`/forget-password`} element={<ForgetPassword />} />
        <Route path={`/create-new-password`} element={<CreateNewPassword />} />
      </Route>
      <Route element={<PrivateRoute />} >
        <Route path={`/`} element={<Layout />} >
          <Route index element={<Dashboard />} />
          <Route path={`/banners`} element={<Banners />} />
          <Route path={`/menu`} element={<Menu />} />
          <Route path={`/menu/add-category`} element={<AddCategory />} />
          <Route path={`/menu/:action/product`} element={<AddEditProduct />} />
          <Route path={`/partners`} element={<Partners />} />
          <Route path={`/partners/view-details`} element={<ViewDetails />} />
          <Route path={`/partners/locations`} element={<PartnerLocation />} />
          <Route path={`/restaurants`} element={<Restaurants />} />
          <Route path={`/restaurants/add-restaurant`} element={<AddRestaurant />} />

          <Route path={`/orders`} element={<Orders />} />
          <Route path={`/orders/details/:id`} element={<OrderDetails />} />
          <Route path={`/locations`} element={<Locations />} />
          <Route path={`/location-details`} element={<LocationDetails />} />
          <Route path={`/drivers`} element={<Drivers />} />
          <Route path={`/drivers/add-driver`} element={<AddDriver />} />
          <Route path={`/banners/add-banner`} element={<AddBanner />} />
          <Route path="/reports" element={<Reports />} />
          {/* <Route path={`/locations`} element={<LocationDetails />} /> */}
          <Route path={`/users`} element={<Users />} />
          <Route path={`/generate-qr`} element={<GenerateQR />} />
          <Route path={`/settings`} element={<Settings />} />
          <Route path={`/settings/add-location`} element={<AddLocation />} />
          <Route path={`/configuration`} element={<Configuration />} >
            <Route path="product/:id" element={<AddProduct />} />
            <Route path="modifier/:id" element={<AddModifier />} />
          </Route>
          <Route />
        </Route>
      </Route>
      <Route path={`*`} element={<>Page Not Found!</>} />
    </Routes>
  );
};

export default AppRoutes;
