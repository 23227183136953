import { useSelector } from "react-redux";
import { CustomSnackbar, CustomAlert } from "./styled";

const Snackbar = (props) => {
  const { message, severity } = useSelector((state) => state.Toast);
  const {
    alertOpen,
    handleClose,
    vertical = "bottom",
    horizontal = "center",
    autoHideDuration = 2000,
  } = props;

  return !!message ? (
    <CustomSnackbar
      anchorOrigin={{ vertical, horizontal }}
      open={alertOpen}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <CustomAlert
        onClose={handleClose}
        severity={severity}
        sx={{ width: "100%" }}
      >
        {message}
      </CustomAlert>
    </CustomSnackbar>
  ) : (
    ""
  );
};

export default Snackbar;
