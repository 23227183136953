import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, FormControl, FormControlLabel, FormGroup, FormLabel, MenuItem, Modal, Radio, RadioGroup, Select, Switch, TextField, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import '../style.css'
import MultipleSelect from "../../../component/MultipleSelect";
import CustonDataGrid from "../../../component/CustomDataGrid";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { UpdateNutrition, UpdatePortion } from "../../../Redux/Actions/LocationActions";
import ReactSelect from "react-select";
import { Allergens } from "../../../common/data";
import { Additives } from "../../../common/data";
const key = process.env.REACT_APP_RapidAPI_Key;
const host = process.env.REACT_APP_RapidAPI_Host;

const NutritionalValueCalculator = ({ nutritionOpen, handleClose , prefilNutrition }) => {
    const dispatch = useDispatch();
    // const { loading, ingredientsData } = useSelector((state) => state.Location);
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const [age, setAge] = React.useState(10);
    const [nutritionalData, setNutritionalData] = React.useState([]);
    const [nutrientData, setNutrientData] = React.useState( prefilNutrition?prefilNutrition:[]);
    const [amount, setAmount] = React.useState(1);
    const [loadingNutrition, setLoadingNutrition] = useState(false)
    const [portionValue, setPortionValue] = useState('100 g');
    const [totalNutritionalValues, setTotalNutritionalValues] = useState({
      energyValue: 0,
      fats: 0,
      fattyAcids: 0,
      proteins: 0,
      sugars: 0,
      salt: 0,
      carbohydrates: 0
  });


    const handlePortionChange = (event) => {
      // setPortionValue(event.target.value);
      // dispatch(UpdatePortion(portionValue));
    };

    useEffect(()=>{
      setNutrientData(prefilNutrition)
      calculateTotalValues(prefilNutrition);
    },[prefilNutrition])
    

    useEffect(() => {
      calculateTotalValues(nutrientData);
  }, [nutrientData]);

    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
          func(...args);
        }, delay);
      };
    };

    const getNutritionalData = debounce(async(value) => {
      if(!value){
        setNutritionalData([])
      }
        const options = {
        method: 'GET',
        url: 'https://nutritional-data.p.rapidapi.com/',
        params: {name: value},
        headers: {
            'X-RapidAPI-Key': "f3f0064049mshf029ffdba3fb288p1f6ba4jsn8f5e7c1c1b13",
            'X-RapidAPI-Host': "nutritional-data.p.rapidapi.com"
        }
        };
        try {
            const response = await axios.request(options);
            setNutritionalData([...response.data.result]);
        } catch (error) {
            console.error(error);
        }finally{
          setLoadingNutrition(false)
        }
    }, 2000);

    const handleNutrients = (ingredient) => {
      const data = {
        id: nutrientData.length,
        name: ingredient.name?ingredient.name:'',
        amount: 100,
        energyValue: ingredient.calories?ingredient.calories:'',
        carbohydrates: ingredient.carbohidrates?ingredient.carbohidrates:'',
        fats: ingredient.fat?ingredient.fat:'',
        proteins: ingredient.proteins?ingredient.proteins:'',
        sugars: ingredient.sugar?ingredient.sugar:'',
        salt: ingredient.salt?ingredient.salt:'',
        IngredientId: uuid(),
        allergens: [],
        potential_allergens: [],
        additives: [],
        frozen:false
      }
        // nutrient["IngredientId"] = uuid();
        setNutrientData([...nutrientData, data]);
        console.log(data)
        dispatch(UpdateNutrition([...nutrientData, data]));
        setNutritionalData([]);
    };

    const handleDelete = (ingredientId) => {
        const filteredNutrients = nutrientData.filter((nutrient) => {
            return nutrient.IngredientId !== ingredientId;
        });
        dispatch(UpdateNutrition(filteredNutrients));
        setNutrientData([...filteredNutrients]);
    };

    const handleAllergensChange = (ingredientId,newAllergens) => {
      const updatedNutrients = nutrientData.map((nutrient) => {
        if (nutrient.IngredientId === ingredientId) {
              // Update the allergens field
              return { ...nutrient, allergens: newAllergens };
          }
          return nutrient;
      });

      // Update the state with the new nutrients data
      dispatch(UpdateNutrition(updatedNutrients));
      setNutrientData(updatedNutrients);
    };
    
    const handleFrozenChange = (ingredientId,event) => {
      const updatedNutrients = nutrientData.map((nutrient) => {
        if (nutrient.IngredientId === ingredientId) {
              // Update the allergens field
              const newValue = event.target.checked;
              return { ...nutrient, frozen: newValue  };
          }
          return nutrient;
      });

      // Update the state with the new nutrients data
      dispatch(UpdateNutrition(updatedNutrients));
      setNutrientData(updatedNutrients);
    };

    const handlePotentialAllergensChange = (ingredientId,newAllergens) => {
      const updatedNutrients = nutrientData.map((nutrient) => {
        if (nutrient.IngredientId === ingredientId) {
              // Update the allergens field
              return { ...nutrient, potential_allergens: newAllergens };
          }
          return nutrient;
      });

      // Update the state with the new nutrients data
      dispatch(UpdateNutrition(updatedNutrients));
      setNutrientData(updatedNutrients);
    };
    const handleAdditivesChange = (ingredientId,newAllergens) => {
      const updatedNutrients = nutrientData.map((nutrient) => {
        if (nutrient.IngredientId === ingredientId) {
              // Update the allergens field
              return { ...nutrient, additives: newAllergens };
          }
          return nutrient;
      });
      console.log("updatedNutrients..",updatedNutrients);
      
      // Update the state with the new nutrients data
      dispatch(UpdateNutrition(updatedNutrients));
      setNutrientData(updatedNutrients);
    };

    const processRowEdit = (newRow) => {
      console.log("newRow..edit..", newRow);
      
      // Find and replace the matching object in nutrientData based on IngredientId
      const updatedNutrients = nutrientData.map((nutrient) => {
          if (nutrient.IngredientId === newRow.IngredientId) {
              return newRow;  // Replace with the new row data
          }
          return nutrient;
      });
  
      console.log("updatedNutrients..", updatedNutrients);
  
      // Update the state and dispatch the new nutrients data
      dispatch(UpdateNutrition(updatedNutrients));
      setNutrientData(updatedNutrients);
  
      return newRow; // Returning newRow can be used for further processing if needed
  };

    const handleChangeAmount = (amt, ingredient) => {
      if (amt >= 0) {
        const updatedNutrients = nutrientData.map((nutrient) => {
            if (nutrient.IngredientId === ingredient.IngredientId) {
                return { ...nutrient, amount: amt };
            }
            return nutrient;
        });
        setNutrientData(updatedNutrients);
        dispatch(UpdateNutrition(updatedNutrients));
      }
    };

    const calculateTotalValues = (nutrients) => {
      let totals = {
          energyValue: 0,
          fats: 0,
          fattyAcids: 0,
          proteins: 0,
          sugars: 0,
          salt: 0,
          carbohydrates: 0
      };

      nutrients.forEach((nutrient) => {
          const factor = nutrient.amount / 100;
          totals.energyValue += nutrient.energyValue * factor;
          totals.fats += nutrient.fats * factor;
          totals.fattyAcids += (nutrient.fattyAcids || 0) * factor;
          totals.proteins += nutrient.proteins * factor;
          totals.sugars += nutrient.sugars * factor;
          totals.salt += nutrient.salt * factor;
          totals.carbohydrates += nutrient.carbohydrates * factor;
      });

      // Round off each value to 2 decimal places and convert to float
      const roundToTwoDecimalPlaces = (value) => parseFloat(value.toFixed(2));

      Object.keys(totals).forEach((key) => {
          totals[key] = roundToTwoDecimalPlaces(totals[key]);
      });

      setTotalNutritionalValues(totals);
      dispatch(UpdatePortion(totals));
  };

    const columns = [
      {
          field: 'Action', headerName: t("actions"), flex: 4, minWidth: 100, headerAlign: "center", align: 'center',
          renderCell: (params) => (
              <DeleteOutlinedIcon sx={{ color: '#EA252D' }} onClick={() => {handleDelete(params.row.IngredientId)}}/>
          ),
      },
      {
        field: "name",
        headerName: t("name"),
        flex: 1,
        minWidth: 250,
        headerAlign: "center",
        align: "center",
        editable: true,
        renderCell: (params) => (
          <React.Fragment>{params.value || "N/A"}</React.Fragment>
        ),
      },
      {
        field: "amount",
        headerName: "Amount *",
        flex: 3,
        minWidth: 250,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <>
            <Box className="amount-textfield">
              <TextField
                id="standard-basic"
                defaultValue={params? params.value : amount}
                onChange={(e) => {
                  handleChangeAmount(e.target.value, params.row)
                }}
                variant="standard"
                type="number"
                sx={{
                  "& .MuiInputBase-root": {
                    "&::before": {
                      display: "none !important",
                    },
                    "&::after": {
                      display: "none !important",
                    },
                  },
                }}
              />
              <FormControl className="grams-dropdown">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={"grams"}
                >
                  <MenuItem value={"grams"}>
                    {"grams"}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </>
        ),
      },
      {
        field: "energyValue",
        headerName: t("kcl_value"),
        flex: 3,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        editable: true,
        renderCell: (params) => (
          <React.Fragment>{params.value? params.value : 0}</React.Fragment>
        ),
      },
      {
        field: "fats",
        headerName: t("fats_value"),
        flex: 3,
        minWidth: 100,
        headerAlign: "center",
        align: "center",
        editable: true,
        renderCell: (params) => (
          <React.Fragment>{params.value? params.value : 0}</React.Fragment>
        ),
      },
      {
        field: "fattyAcids",
        headerName: t("fatty_value"),
        flex: 3,
        minWidth: 100,
        headerAlign: "center",
        align: "center",
        editable: true,
        renderCell: (params) => (
          <React.Fragment>{params.value? params.value : 0}</React.Fragment>
        ),
      },
      {
        field: "carbohydrates",
        headerName: t("carbo_value"),
        flex: 3,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        editable: true,
        renderCell: (params) => (
          <React.Fragment>{params.value? params.value : 0}</React.Fragment>
        ),
      },
      {
        field: "sugars",
        headerName: t("sugars_value"),
        flex: 3,
        minWidth: 100,
        headerAlign: "center",
        align: "center",
        editable: true,
        renderCell: (params) => (
          <React.Fragment>{params.value? params.value : 0}</React.Fragment>
        ),
      },
      {
        field: "proteins",
        headerName: t("protiens_label"),
        flex: 3,
        minWidth: 100,
        headerAlign: "center",
        align: "center",
        editable: true,
        renderCell: (params) => (
          <React.Fragment>{params.value? params.value : 0}</React.Fragment>
        ),
      },
      {
        field: "salt",
        headerName: t("sare_label"),
        flex: 3,
        minWidth: 100,
        headerAlign: "center",
        align: "center",
        editable: true,
        renderCell: (params) => (
          <React.Fragment>{params.value? params.value : 0}</React.Fragment>
        ),
      },
      {
        field: "frozen",
        headerName: t("frozen_label"),
        flex: 2,
        minWidth: 100,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Switch {...label} defaultChecked={params?.value}   onChange={(event)=>handleFrozenChange(params.row.IngredientId,event)}/>
        ),
      },
      {
        field: "allergens",
        headerName: t("allergen_label"),
        flex: 3,
        minWidth: 250,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          
          let allergensOptions = Allergens
          let opData = allergensOptions.map((ele)=>ele.value)
              
          return (
            <MultipleSelect
              onChange={(data)=>handleAllergensChange(params.row.IngredientId,data)}
              values={opData}
              selectedData={params.value}
              title='Allergens'
            />
          );
        },
      },
      {
        field: "potential_allergens",
        headerName: t("potential_label"),
        flex: 2,
        minWidth: 250,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => {
          
          let allergensOptions = Allergens
          let opData = allergensOptions.map((ele)=>ele.value)
              
          return (
            <MultipleSelect
              onChange={(data)=>handlePotentialAllergensChange(params.row.IngredientId,data)}
              values={opData}
              title='Potential Allergens'
              selectedData={params.value}
            />
          );
        },
      },
      {
        field: "additives",
        headerName: t("additive_label"),
        flex: 2,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderCell: (params) =>  {
          
          let allergensOptions = Additives
          let opData = allergensOptions.map((ele)=>ele.value)
              
          return (
            <MultipleSelect
              onChange={(data)=>handleAdditivesChange(params.row.IngredientId,data)}
              values={opData}
              title='Additives'
              selectedData={params.value}
            />
          );
        },
      },
    ];

    return (
        <>
                <Box >
                    <Box>
                        <Box className='nutritional-modal'>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {t("nutritional_value")}
                            </Typography>
                            {/* {loading ? */}
                                <>
                                    <Box className='search-ingredient-main'>
                                        <Box className='search-ingredient'>
                                        <FormControl className="!max-w-full grams-dropdown">
                                            <TextField
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                placeholder="Search Ingredient"
                                                // defaultValue={inputNutrition? inputNutrition: ""}
                                                onChange={(e) => {
                                                    setLoadingNutrition(true)
                                                    getNutritionalData(e.target.value)
                                                }}
                                            >
                                            </TextField>
                                            <div className="lg:max-h-60 max-h-48 overflow-y-scroll">
                                              {loadingNutrition && <ReactLoading className="mx-auto" type={"spokes"} height="45px" width="45px" color="#FFB800" /> }
                                              {nutritionalData && !loadingNutrition &&
                                              nutritionalData.map((nutrient) => (
                                                  <MenuItem value={nutrient} key={nutrient.name} onClick={() => handleNutrients(nutrient)}>{nutrient.name}</MenuItem>
                                              ))
                                              }
                                            </div>
                                        </FormControl>
                                        </Box>
                                        <Box className='add-icon' onClick={()=>handleNutrients({})}>
                                            <AddIcon />
                                        </Box>
                                    </Box>
                                    <CustonDataGrid
                                        columns={columns}
                                        rows={nutrientData.length && nutrientData}
                                        getRowId={(rows) => rows?.IngredientId}
                                        height={300}
                                        processRowEdit={processRowEdit}
                                        hideFooter
                                    />
                                    <Box className='recipe-values'>
                                        <Box className='recipe-values-wrap'>
                                            <FormControl>
                                                <FormLabel id="demo-radio-buttons-group-label">{t("values_detail")}</FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="net"
                                                    name="radio-buttons-group"
                                                    value={portionValue}
                                                    onChange={handlePortionChange}
                                                >
                                                    {/* <FormControlLabel value="brute_portion" control={<Radio />} label={t("brute_label")} />
                                                    <FormControlLabel value="net_portion" control={<Radio />} label={t("portion_label")} /> */}
                                                    <FormControlLabel value="100 g" control={<Radio />} label="100 g" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>
                                      
                                        <Box className='values-detail'>
                                            <label>Values per {portionValue}</label>
                                            <Stack direction="row" spacing={1}>
                                                <Chip label={`${t('energy_value')}: ${totalNutritionalValues.energyValue.toFixed(2)} kcal/kj`} />
                                                <Chip label={`${t("fat_value")}: ${totalNutritionalValues.fats.toFixed(2)} g`} />
                                                <Chip label={`${t("fatty_acid_value")}: ${totalNutritionalValues.fattyAcids.toFixed(2)} g`} />
                                                <Chip label={`${t("protiens_value")}: ${totalNutritionalValues.proteins.toFixed(2)} g`} />
                                                <Chip label={`${t("sugar_value")}: ${totalNutritionalValues.sugars.toFixed(2)} g`} />
                                                <Chip label={`${t("sugar_value")}: ${totalNutritionalValues.salt.toFixed(2)} g`} />
                                                <Chip label={`${t("carbohydrate_value")}: ${totalNutritionalValues.carbohydrates.toFixed(2)} g`} />
                                            </Stack>
                                        </Box>
                      
                                    </Box>
                                </>
                        </Box>
                    </Box>
                </Box>
           
        </>
    )
}
export default NutritionalValueCalculator
