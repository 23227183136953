

import React, { useState } from "react";
import { Box, Button, FormHelperText, Grid, Modal, TextField, Typography } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useFormik } from "formik";
import * as Yup from 'yup';
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import '../style.css'
import { useErrorHandler } from "../../../hooks";
import { AddMenumodifiertAction } from "../../../Redux/Actions/LocationActions";

const AddNewModifier = ({ modifierOpen, handleClose }) => {
    const dispatch = useDispatch();
    const { setMsgConfig } = useErrorHandler();
    const { loading } = useSelector((state) => state.Location);
    const [mockupData, setMockupData] = useState([
        {
            name: 'Onions',
            items: [
                {name: 'Red Onion', price: 1.2},
                {name: 'White Onion', price: 1.1}
            ]
        },
        {
            name: 'Side dish',
            items: [
                {name: 'French Fries', price: 1.5},
                {name: 'Potato Wedges', price: 1.55},
                {name: 'Salad', price: 1.52}
            ]
        },
        {
            name: 'Veggie',
            items: [
                {name: 'Red bean', price: 2.1},
                {name: 'Cabbage', price: 2.2}
            ]
        }
    ])
    const formik = useFormik({
        initialValues: {
            first_modifier: "",
            modifier_name:"",
            modifier_price: 0
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            first_modifier: Yup.string().required("please select first modifier is required"),
        }),
        onSubmit: (values, { resetForm }) => {
            const payload = {
                "ProductID": "98480a7c-299f-4e62-b1f1-c45a90bcf04f",
                "name": formik.values.first_modifier
            }
            dispatch(AddMenumodifiertAction(payload));
            setMsgConfig({
                alertOpen: true,
                message: "Message",
                severity: "Severity",
                autoHideDuration: 2000,
            });
            resetForm();
            handleClose();
        },
    });
    
    return (
        <>
            <Modal
                open={modifierOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='modal-body add-category-modal max-h-[85%] overflow-y-scroll'>
                    <Box className='add-new-modifier-modal'>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {t("add_new_modifier")}
                        </Typography>
                        <Grid container spacing={2}>
                            {mockupData.map((data, i) => {
                                return (
                                <Grid key={i} item lg={6} md={6} sm={6} xs={12}>
                                    <Box className='modifier-category'>
                                        <Box className='modifier-detail'>
                                            <Typography>{data.name}</Typography>
                                            <EditOutlinedIcon />
                                        </Box>
                                        <Box className='modifier-category-detail'>
                                            {data.items?.map((item, j) => {
                                                return (
                                                <Box key={j} sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    borderBottom: '1px solid #D9D9D978',
                                                    paddingBottom: '5px',
                                                    marginBottom: '5px'
                                                }}>
                                                    <Typography>{item.name}</Typography>
                                                    <Typography sx={{ fontWeight: '600 !important' }}>{item.price} RON</Typography>
                                                </Box>

                                                )
                                            })}
                                        </Box>
                                    </Box>
                                </Grid>

                                )
                            })}
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box className='input-field-title'>
                                    <Typography>Name</Typography>
                                    <Box className='modifier-detail'>
                                        <TextField name="first_modifier" fullWidth placeholder="First Modifier Category" value={formik.values.first_modifier} onChange={formik.handleChange} />
                                        <Box 
                                            onClick={() => {
                                                setMockupData(prev => [...prev, {name:formik.values.first_modifier, items:[]}])
                                            }} 
                                            className='add-icon-box'
                                            >
                                            <AddIcon />
                                        </Box>
                                    </Box>
                                    {formik.touched.first_modifier && formik.errors.first_modifier && (
                                        <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '0px 5px' }}>
                                            {formik.errors.first_modifier}
                                        </FormHelperText>
                                    )}
                                    <Box className='modifier-detail'>
                                        <Box 
                                            onClick={() => {
                                                let idx = mockupData.findIndex(item => item.name === formik.values.first_modifier);
                                                const updatedMockupData = mockupData.map((item, index) => {
                                                        if (index === idx) {
                                                            return {
                                                                ...item,
                                                                items: [
                                                                    ...item.items,
                                                                    { name: formik.values.modifier_name, price: formik.values.modifier_price }
                                                                ]
                                                            };
                                                        }
                                                        return item;
                                                    });
                                                    setMockupData(updatedMockupData)
                                            }}
                                            className='add-icon-box'
                                        >
                                            <AddIcon />
                                        </Box>
                                        <TextField name="modifier_name" onChange={formik.handleChange} fullWidth placeholder="First Modifier Category" />
                                        <TextField name="modifier_price" onChange={formik.handleChange} placeholder="Price" />
                                    </Box>
                                    <Box className='modifier-detail'>
                                        <Box className='add-icon-box'>
                                            <RemoveIcon />
                                        </Box>
                                        <TextField fullWidth placeholder="First Modifier Category" />
                                        <TextField placeholder="Price" />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box className='modal-btn'>
                            <Button className="cancel-btn" onClick={handleClose}>{t("cancel_btn")}</Button>
                            <Button className="submit-btn" onClick={formik.handleSubmit}>
                                {loading ?
                                    <ReactLoading
                                        type={"spin"}
                                        height="30px"
                                        width="30px"
                                        color="white"
                                    /> : t("save_changes")}</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal >
        </>
    )
}
export default AddNewModifier
