import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, IconButton, Menu, MenuItem, Switch, Tab, Tabs, TextField, Typography } from '@mui/material';
import "react-datepicker/dist/react-datepicker.css"
import AddIcon from '@mui/icons-material/Add';
import { t } from 'i18next';
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import tableIcon from '../../assets/img/table.png'
import './style.css'
import { MdAutorenew } from "react-icons/md";
// import QRGenerator from './QRGenerator';
import Draggable from 'react-draggable';
import { AddConfigurationstAction, AddRoomAction, AddTableAction, GetConfigurationstAction, MoveTableAction, RemoveRoomAction, RemoveTableAction, TabIdAction, UpdateRoomNameAction, UpdateTableAction } from '../../Redux/Actions/LocationActions';
import CustomButton from '../../component/CustomButton';
import { FaPlus } from "react-icons/fa6";
import ModalQRGeneratorTable from '../../component/ModalQRGeneratorTable';
import { v4 as uuid } from 'uuid'
import saveRoomTableConfig from '../../libs/saveRoomTableConfig';
import { TOAST_SHOW, ToastAction, ToastShow } from '../../Redux/Actions/ToastAction';
import Snackbar from '../../component/ErrorHandler/Snackbar';
import { FaEllipsisV } from 'react-icons/fa';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RoomConfigTemplate = ({ locationId }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const ebrizaLinkedId = localStorage.getItem('ebrizaLinkedId')
  const partnerId = localStorage.getItem('partnerId')
  const { loading, configurationData } = useSelector((state) => state.Location);
  const [rooms, setRooms] = useState()
  const [configTabValue, setConfigTabValue] = useState(0);
  const [qrGeneratorOpen, setQrGeneratorOpen] = useState(false);
  const [valueSelected, setValueSelected] = useState('')
  const [activeDrags, setActiveDrags] = useState(0)
  const [currentRooms, setCurrentRooms] = useState(rooms)
  const [selectedRoom, setSelectedRoom] = useState()
  const [showSnack, setShowSnack] = useState(false)
  const [deltaPosition, setDeltaPosition] = useState({
    x: 0, y: 0
  })
  const [controlledPosition, setControlledPosition] = useState({
    x: -400, y: 200
  })
  const [openDialog, setOpenDialog] = useState(false);
  const [tablePosition, setTablePosition] = useState({ x: 0, y: 0 });
  const [selectedTableName, setSelectedTableName] = useState('');
  const [selectedTableId, setSelectedTableId] = useState('');
  const [selectedRoomForRename, setSelectedRoomForRename] = useState('');

  const unique_id = uuid();
  const handleDrag = (e, ui) => {
    const { x, y } = deltaPosition;
    setDeltaPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    })
  };

  const [anchorEls, setAnchorEls] = useState([]);

  const handleOpenMenu = (event, index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
  };

  const handleCloseMenu = (index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
  };

  const onStart = () => {
    setActiveDrags((prevActiveDrags) => prevActiveDrags + 1);
  };

  const onStop = () => {
    setActiveDrags((prevActiveDrags) => prevActiveDrags - 1);
  };

  const onDragStop = (e, position, roomId, tableIndex) => {
    if(!ebrizaLinkedId){
      setTablePosition({ x: position?.x, y: position?.y })
      dispatch(MoveTableAction({ roomId, x: position?.x, y: position?.y, tableIndex }))
    }
  }

  const handleRenameOpen = (room,index) => {
    handleCloseMenu(index)
    setSelectedRoomForRename(room);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRoomForRename('');
  };

  const handleRoomNameChange = (event) => {
    const updatedRoom = { ...selectedRoomForRename, name: event.target.value };
    setSelectedRoomForRename(updatedRoom);
  }

  const handleRoomRenameUpdate = ()=>{
    if (selectedRoomForRename && selectedRoomForRename.id) {
      dispatch(UpdateRoomNameAction(selectedRoomForRename));
      setOpenDialog(false);  
    }
  }

  const handleTableClick = (table, room) => {
    if(selectedTableId == table.id){
      if(table){
        formik.setValues({
          table_name:"",
          sit_count:""
  
        })
      }
      setSelectedTableName(null);
      setSelectedTableId(null);
    }else{
      if(table){
        formik.setValues({
          table_name:table.name,
          sit_count:table.seats
  
        })
      }
      setSelectedRoom(room)
      // Set the selected table name when a table is clicked
      setSelectedTableName(table.name);
      setSelectedTableId(table.id);

    }
  };

  const handleChangeConfigTab = (event, newValue) => {
    setConfigTabValue(newValue);
  };
  const formik = useFormik({
    initialValues: {
      table_name: "",
      sit_count: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      table_name: Yup.string().required("Required"),
      sit_count: Yup.string().required("Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(TabIdAction(configTabValue))
      dispatch(AddTableAction({ id: uuid(), name: `${formik.values.table_name}`, seats: Number(formik.values.sit_count), x: 0, y: 0, roomID: rooms[configTabValue].id, roomName: rooms[configTabValue].name, horizontal: false, occupied: false }))
      resetForm();
    },
  });
  const handleAddRooms = () => {
    let lastIndex;
    let finalRoomNo;
    if (rooms?.length === 0 || !rooms) {
      lastIndex = 0
      finalRoomNo = 1
    } else {
      lastIndex = rooms?.length - 1
      const finalRoomValue = rooms[lastIndex]
      const fullName = finalRoomValue?.name;
      const parts = fullName?.split(' ');
      const lastPart = parts[parts?.length - 1];
      finalRoomNo = Number(lastPart) + 1
    }
    dispatch(AddRoomAction({ name: `Room ${finalRoomNo !== undefined ? finalRoomNo : 1}`, tables: [], position: 0, width: 768, height: 904, id: unique_id, walls: [], isForDelivery: false, isForTabs: false }))
  }

  const handleRemoveRoom = (selectedRoom, index) => {
    handleCloseMenu(index)
    dispatch(RemoveRoomAction(selectedRoom))
  }
  const handleDeleteTable = () => {
    dispatch(RemoveTableAction(selectedTableId))
  }

  const handleRotate = () => {

  }

  const handleSave = async () => {
    try {
      let dataReq = {
        ClientId: partnerId,
        LocationId: locationId,
        room_table_config: {
          occupancyRate: 25,
          rooms: configurationData[0].rooms
        }
      }
      // dispatch(ToastShow({message: 'TEST', severity: 'success'}))
      let res = await saveRoomTableConfig(dataReq)
      if(res) {
        dispatch({type: TOAST_SHOW, payload:  { message: "Room Configuration saved !", severity: "success" }})
        setShowSnack(true)
      }
      console.log('res of save>>', res)
    } catch (error) {

    }
  }

  useEffect(() => {
    console.log("configurationData,locationId", configurationData, locationId);
    if (configurationData && configurationData.length && locationId) {
      // let rm = configurationData.find((dt) => dt.LocationId == locationId)
      // if(rm){
      console.log("roomConfigFiiund..");
      setRooms(configurationData[0].rooms)
      if(configTabValue && configurationData[0].rooms.length == configTabValue){
        setConfigTabValue(configurationData[0].rooms.length-1);
      }
    


      setAnchorEls(Array(configurationData[0].rooms.length).fill(null));
      // }
    }
  }, [locationId, configurationData])

  const handleTableUpdate = (event)=>{
    const { name, value } = event.target;
    formik.setFieldValue(name, value);

    if(selectedTableId){
      dispatch(UpdateTableAction({ roomId:selectedRoom.id,name,value ,tableIndex: selectedRoom.tables.findIndex((ele)=>ele.id == selectedTableId)}))
    }
    console.log("selectedTableId..",selectedTableId,name,value,selectedRoom);
    // formik.handleChange()
  }

  useEffect(() => {
    if (locationId) {
      dispatch(GetConfigurationstAction({
        ClientId: partnerId,
        LocationId: locationId,
        type: ebrizaLinkedId ? 'ebriza' : 'adhoc'
      }))
    }
  }, [locationId])

  const dragHandlers = { onStart: onStart, onStop: onStop };


  return (
    <>
     <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{'Rename Room'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="roomName"
            label="Room Name"
            type="text"
            fullWidth
            variant="standard"
            value={selectedRoomForRename.name}
            onChange={handleRoomNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleRoomRenameUpdate}>
            { 'Rename'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        alertOpen={showSnack}
        handleClose={() => setShowSnack(false)}
      />
      {/* <QRGenerator qrGeneratorOpen={qrGeneratorOpen} handleClose={handleQrGeneratorClose} rooms={rooms} /> */}
      <ModalQRGeneratorTable locationId={locationId} showModal={qrGeneratorOpen} closeModal={() => setQrGeneratorOpen(false)} />
      <div className='flex justify-end mb-3 pb-3 border-b space-x-3'>
        <CustomButton onClickButton={() => setQrGeneratorOpen(true)} className={'px-8'} label={'Download QR'} secondary />

      { !ebrizaLinkedId && (
        <CustomButton onClickButton={handleSave} className={'px-8'} label={'Save'} customBg={'bg-[#FFB800]'} />
      )}
      </div>
      <Box className='configuration-tab'>
        <Box className='add-room-section'>
          <Box className='add-table-detail'>
            <Box className='table-btn'>
              {
                !ebrizaLinkedId && (

                  <Button className='add-table-btn' onClick={formik.handleSubmit}><AddIcon /> {t("add_table")}</Button>
                )
              }
              {/* <Button className='add-table-btn'><AddIcon />{t("add_object")}</Button> */}
            </Box>
            <Box className='add-table-filled'>
              <label>{t("table-name")}</label>
              <TextField placeholder='table name' type='text' name='table_name' value={formik.values.table_name} onChange={handleTableUpdate} />
              {formik.touched.table_name && formik.errors.table_name && (
                <FormHelperText style={{ color: 'red' }}>
                  {formik.errors.table_name}
                </FormHelperText>
              )}
            </Box>
            <Box className='add-table-filled'>
              <label>{t("seats_number")}</label>
              <TextField placeholder='site count' type='number' name='sit_count' value={formik.values.sit_count} onChange={handleTableUpdate} />
              {formik.touched.sit_count && formik.errors.sit_count && (
                <FormHelperText style={{ color: 'red' }}>
                  {formik.errors.sit_count}
                </FormHelperText>
              )}
            </Box>
            <div className='flex space-x-4 items-center'>
              {/* <CustomButton label={'Rotate'} secondary startIcon={<MdAutorenew />} /> */}
              {
                selectedTableId && !ebrizaLinkedId &&  (
          
                  <CustomButton onClickButton={handleDeleteTable} label={'Delete'} customBg={'bg-red-700'} textColor={'text-white'} />
                )
              }
            </div>
            {/* <Button className='download-btn' onClick={() => setQrGeneratorOpen(true)}>
              {loading ?
                <ReactLoading
                  type={"spin"}
                  height="30px"
                  width="30px"
                  color="white"
                />
                : t("download_qr")}</Button> */}
            {/* <Button className='remove-btn' onClick={() => handleRemoveRoom(configTabValue)}>{t("remove_btn")}</Button> */}
          </Box>
        </Box>
        {loading ? (
          <div className='flex items-center justify-center w-full'>
            <ReactLoading
              type={"spokes"}
              height="55px"
              width="55px"
              color="#FFB800"
            />
          </div>
        ) : (
          <Box className='room-tab-section'>
            <>
              <Tabs
                className='room-tab'
                value={rooms?.length === configTabValue ? 0 : configTabValue}
                onChange={handleChangeConfigTab}
                aria-label="basic tabs example"
              >
             {rooms?.length && rooms?.map((room, index) => (
              <Tab
                key={index}
                label={
                  <Box display="flex" alignItems="center" style={{width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between'}}>
                    <span>{room?.name}</span>
                    <IconButton
                      aria-controls={`simple-menu-${index}`}
                      aria-haspopup="true"
                      onClick={(event) => handleOpenMenu(event, index)}
                    >
                      <FaEllipsisV  style={{ fontSize: '16px'}} />
                    </IconButton>
                    {!ebrizaLinkedId && (

                    <Menu
                      id={`simple-menu-${index}`}
                      anchorEl={anchorEls[index]}
                      keepMounted
                      open={Boolean(anchorEls[index])}
                      onClose={() => handleCloseMenu(index)}
                    >
                      <MenuItem onClick={() => handleRenameOpen(room, index)}>Edit</MenuItem>
                      <MenuItem onClick={() => handleRemoveRoom(room, index)}>Remove</MenuItem>
                    </Menu>
                    )}
                  </Box>
                }
              />
            ))}
            {
              !ebrizaLinkedId && (

                <CustomButton onClickButton={handleAddRooms} className={'my-2'} secondary label={'Add room'} startIcon={<FaPlus />} />
              )
            }
              </Tabs>
              <Box className='room-tab-detail' >
                {rooms?.map((room, roomIndex) => (
                  <CustomTabPanel value={configTabValue} index={roomIndex} key={roomIndex} >
                    {room?.tables?.map((table, tableIndex) => (
                      <Box className={`table-box`} key={tableIndex}>
                        <Draggable
                         position={{ x: table.x, y: table.y }} {...dragHandlers} onStop={(e, p) => onDragStop(e, p, room.id, tableIndex)} onDrag={() => { }}>
                          <Box className={`${selectedTableId == table?.id && '!bg-primaryColor'} first-room`} 
                          onClick={() => handleTableClick(table, room)}
                          onTouchStart ={() => handleTableClick(table, room)}
                            style={{
                              backgroundImage: `url(${tableIcon})`,
                              backgroundSize: '43px',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: '90%',
                              width: `${100+(table.seats>2?(table.seats-2)*5.5:0)}px`,
                              height: '65px',
                              minWidth:'100px',
                              maxWidth:'200px'
                              // touchAction: 'none'
                            }}
                          >
                            <Typography>{table?.name}</Typography>
                            {/* <img src={tableIcon} alt={`Table ${table?.name}`} /> */}
                          </Box>
                        </Draggable>
                        {/* <Typography>{table?.name}</Typography> */}
                      </Box>
                    ))}
                  </CustomTabPanel>
                ))}
              </Box>
            </>
          </Box>

        )}
      </Box>
    </>
  );
};

export default RoomConfigTemplate;
