import { combineReducers } from "redux";
import { ToastReducer } from "./ToastReducer";
import { AuthReducer } from "./AuthReducer";
import { LocationReducer } from "./LocationReducer";
import { PartnerReducer } from "./PartnersReducer";

const rootReducer = combineReducers({
  Toast: ToastReducer,
  Auth: AuthReducer,
  Location: LocationReducer,
  Partners: PartnerReducer
});

export default rootReducer;
