import React from 'react'
import { Box, Breadcrumbs, Button, InputAdornment, Link, TextField, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CustonDataGrid from "../../component/CustomDataGrid";
import refresh from '../../assets/img/refresh-icon.png'
import rightarrow from '../../assets/img/view-right-arrow.png';
import Switch from '@mui/material/Switch';
import './style.css'
import { useTranslation } from 'react-i18next';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const PartnerLocation = () => {
    const { t } = useTranslation();
    const columns = [
        { field: 'id', headerName: t('ID'), flex: 1, minWidth: 100, headerAlign: "center", align: 'center' },
        { field: 'Location_ID', headerName: t('Location_ID'), flex: 3, minWidth: 200, headerAlign: "center", align: 'center' },
        { field: 'Location_Secret', headerName: t('Location_Secret'), flex: 3, minWidth: 250, headerAlign: "center", align: 'center' },
        { field: 'LocationContact', headerName: t('Location_Contact'), flex: 2, minWidth: 200, headerAlign: "center", align: 'center' },
        { field: 'Processing_Fees', headerName: t('Processing_Fees'), flex: 2, minWidth: 200, headerAlign: "center", align: 'center' },
        {
            field: 'Online_Payment', headerName: t('Online_Payment'), flex: 2, minWidth: 100, headerAlign: "center", align: 'center',
            renderCell: () => (
                <Switch {...label} defaultChecked />
            ),
        },
        {
            field: 'Nutritional_Values_Calculator', headerName: t('Nutritional_Values_Calculator'), flex: 2, minWidth: 100, headerAlign: "center", align: 'center',
            renderCell: () => (
                <Switch {...label} defaultChecked />
            ),
        },
        {
            field: 'SMS', headerName: t('SMS'), headerAlign: "center", minWidth: 100, align: 'center',
            renderCell: () => (
                <Switch {...label} defaultChecked />
            ),
        },

        {
            field: 'Revenue', headerName: t('Revenue'), flex: 2, headerAlign: "center", minWidth: 200, align: 'center',
        },
        {
            field: 'Action', headerName: t('Action'), flex: 4, minWidth: 200, headerAlign: "center", align: 'center',
            renderCell: () => (
                <Button className="view-detail-btn">
                    {t('View_Analytics')}
                    <img src={rightarrow} />
                </Button>
            ),
        },
    ];
    const rows = [
        {
            id: 1, Location_ID: 'Wok & roll', Location_Secret: '110 Courtneypark Dr E, Mississauga, ON L5T 2Y3', LocationContact: `+1 (467)433 8998`, Processing_Fees: '10%', Password: '*******', Email: "woks@gmail.com", Delivery_commission: '10%', Pick_up_commission: '20%', Revenue: '$100222.34'
        },
        {
            id: 2, Location_ID: 'Wok & roll', Location_Secret: '110 Courtneypark Dr E, Mississauga, ON L5T 2Y3', LocationContact: `+1 (467)433 8998`, Processing_Fees: '10%', Password: '*******', Email: "woks@gmail.com", Delivery_commission: '10%', Pick_up_commission: '20%', Revenue: '$100222.34'
        },
        {
            id: 3, Location_ID: 'Wok & roll', Location_Secret: '110 Courtneypark Dr E, Mississauga, ON L5T 2Y3', LocationContact: `+1 (467)433 8998`, Processing_Fees: '10%', Password: '*******', Email: "woks@gmail.com", Delivery_commission: '10%', Pick_up_commission: '20%', Revenue: '$100222.34'
        },
    ];

    const handleClick = (event) => {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    const breadcrumbs = [
        <Link underline="hover" key="1" color="#FF7A00" href="/" onClick={handleClick}>
            {t("registered_res")}
        </Link>,
        <Typography key="2" color="#000">
            {t("blocked_res")}
        </Typography>,
    ];

    return (
        <>
            <Box className="partner-title">
                <Typography variant='h2'>{t("Partner_locations")}</Typography>
            </Box>
            <Box className="partner-detail">
                <Box className="search-box">
                    <TextField id="outlined-basic" variant="outlined" placeholder={t("Search")} InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" sx={{ color: "#000" }}>
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }} />
                </Box>
                <Box>
                    <Button className="refresh-btn"><img src={refresh} />{t("refresh_button")}</Button>
                    <Button className="add-partner">{t("add_location_btn")}</Button>
                </Box>
            </Box>
            <CustonDataGrid
                columns={columns}
                rows={rows}
                height={300}
            />
        </>
    );
}
