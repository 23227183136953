import React from 'react'
import { FaPlus } from "react-icons/fa6";
import { BiPencil } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';


const CategoryList = ({title = 'Categories', list = []}) => {
    const navigate = useNavigate()
  return (
    <div className='w-full bg-white drop-shadow-lg rounded-xl pb-3'>
        <div className='flex justify-between items-center bg-primaryColor rounded-xl p-5 drop-shadow-md'>
            <div className='text-xl font-semibold'>{title}</div>
            <div onClick={() => navigate('/menu/add/product')} className='bg-white p-2 rounded-full cursor-pointer'>
                <FaPlus />
            </div>
        </div>
        <div className='h-[400px] overflow-y-scroll px-5 pt-7 pb-5 space-y-7'>
            {list.map((val, i) => {
                return (
                    <div key={i} className='flex justify-between items-center'>
                        <div className='font-semibold'>{val}</div>
                        <BiPencil onClick={() => navigate('/menu/edit/product')} className='cursor-pointer' />
                    </div>
                )
            })}
        </div>
    </div>
  )
}

export default CategoryList