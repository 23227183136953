import PropTypes from "prop-types";
import "./ProductCard.css";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import axios from "../../axiosConfig";

const ProductCard = ({ product,className = "", name1,price,imgUrl="" ,fetchLatestDataAndMap }) => {
  const navigate = useNavigate();
  const ebrizaLinkedId = localStorage.getItem('ebrizaLinkedId');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const onEditClicked = () => {
    console.log("edit clicked");
    navigate(`/configuration/product/${product.ProductId}`)
  };

  const handleRemoveProduct = (product) =>{
    setOpenConfirmDialog(true);

  }


  async function handleConfirmRemove() {
    let productToRemove = product
    console.log("Deleting product:", productToRemove);
    
    try {
      let addMenuCat = await axios.delete(`/partners/menu_products/${productToRemove.id}`)
      if (addMenuCat.data) {
        fetchLatestDataAndMap()
      }
      
    } catch (error) {
      console.log("Failed to delete product",error.message);
      
    } finally{
      setOpenConfirmDialog(false);

    }
  }


  return (
    <>
    <div className={`category-card8 ${className}`}>
      <div className="imageactions" style={{backgroundColor:'#ffdea1',
      height: '100%',
    width: '100%'}}>
        <div className="imageProduct">
          <img className="icons55" style={{width:'100%',height:'100%',objectFit:'cover'}} alt="" src={imgUrl?imgUrl:"/default.svg"} />
        </div>
        <div className="top4" style={{width: '91%'}}>
          <div className="priceTag">
            {price.toFixed(2)}
          </div>
          <div className="editDelCont">
            <img className="icons56 editDelIcon"  style={{cursor:'pointer'}} alt="" src="/icons-54.svg"  onClick={onEditClicked} />
            {!ebrizaLinkedId && <img className="icons57 editDelIcon" style={{cursor:'pointer'}} alt="" src="/icons-58.svg"  onClick={handleRemoveProduct}/>}
          </div>
        </div>
      </div>
      <div className="text63" style={{    fontSize: '15px'}}>
        <div className="name7">{name1}</div>
      </div>
    </div>
    <Dialog
    open={openConfirmDialog}
    onClose={() => setOpenConfirmDialog(false)}
  >
    <DialogTitle>Confirm Delete</DialogTitle>
    <DialogContent>Are you sure you want to delete this product?</DialogContent>
    <DialogActions>
      <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
        Cancel
      </Button>
      <Button onClick={handleConfirmRemove} color="primary">
        Delete
      </Button>
    </DialogActions>
  </Dialog>
  </>
 
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  name1: PropTypes.string,
};

export default ProductCard;
