// This page contains axois config with Authorization

import axios from "axios";
const apiBaseUrl = process.env.REACT_APP_BASE_URL;
const withAuthInstance = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    "Content-Type": "application/json",
    // 'Content-Type': 'multipart/form-data',
  },
});

withAuthInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      localStorage.clear(); // use this if needed. this will remove all items from localstorage
      delete withAuthInstance.defaults.headers.common.Authorization;
    }
    if (config?.data instanceof FormData) {
      config.headers = {
        ...config?.headers,
        "Content-Type": "multipart/form-data",
      };
    }
    return config;
  },
  (error) => console.error(error)
);
withAuthInstance.interceptors.response.use(
  (response) => {
    if (response.status === 401 || response.status === 403) {
      localStorage.clear();
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default withAuthInstance;
