import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  CircularProgress,
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from '../../../axiosConfig';

const CategoryModifierPopup = ({ open, onClose, categories, preSelectedProductsPopup, onSave }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [categoriesS, setCategoriesS] = useState([]);
  const [subCatSet, setSubCatSet] = useState('');
  const [loading, setLoading] = useState(false);
  const [viewingProducts, setViewingProducts] = useState(false);
  const [currentCategoryProducts, setCurrentCategoryProducts] = useState([]);

  useEffect(()=>{
    let filteredProductCat = preSelectedProductsPopup.filter((eleFF)=>!eleFF.parent_cat_id)
      
    setSelectedProducts(filteredProductCat)

    let filteredProductCatC = categories.filter((eleFF)=>!eleFF.parent_cat_id)
      
    setCategoriesS(filteredProductCatC)
  },[preSelectedProductsPopup])

  const handleCategoryToggle = (category) => {
    console.log("category.cat_product..",category);
    if(category.cat_products     && category.cat_products[0]){
      setCurrentCategoryProducts(category.cat_products);
      setViewingProducts(true);
      
    }else if(category.sub_categories  && category.sub_categories[0]){
      setSubCatSet(category)
      setCategoriesS(category.sub_categories)
    }else{
      setViewingProducts(true);
      setCurrentCategoryProducts([]);
    }
  };

  const handleProductToggle = (product) => {
    const currentIndex = selectedProducts.indexOf(product);
    const newChecked = [...selectedProducts];

    if (currentIndex === -1) {
      newChecked.push(product);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedProducts(newChecked);
  };

  const handleSave = async () => {
    let mapSelectedData = []
    selectedProducts.map((ele)=>{
      mapSelectedData.push(
        {
          'ProductId': ele.ProductId,
          'imageUrl': ele.imageUrl,
          'name': ele.name,
        }
      )
    })
    onSave(mapSelectedData)
    onClose();
    
  };

  const handleBackButton = ()=>{
    if(viewingProducts){
      setViewingProducts(false)
    }else{
      setSubCatSet('')
      let filteredProductCat = categories.filter((eleFF)=>!eleFF.parent_cat_id)
      
      setCategoriesS(filteredProductCat)

    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {(viewingProducts || subCatSet) ? (
          <IconButton onClick={() => handleBackButton() }>
            <ArrowBackIcon />
          </IconButton>
        ) : null}
        {viewingProducts ? 'Select Products' : 'Select Categories'}
      </DialogTitle>
      <DialogContent>
        <List>
          {viewingProducts
            ? currentCategoryProducts.map((product) => (
                <ListItem key={product.id} button onClick={() => handleProductToggle(product)}>
                  <ListItemText primary={product.name} />
                  <Checkbox
                    edge="end"
                    checked={selectedProducts.indexOf(product) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItem>
              ))
            : categoriesS.map((category) => (
                <ListItem key={category.id} button onClick={() => handleCategoryToggle(category)}>
                  <ListItemText primary={category.name} />
                  {/* <Checkbox
                    edge="end"
                    checked={selectedCategories.indexOf(category) !== -1}
                    tabIndex={-1}
                    disableRipple
                    onClick={() => {
                      const currentIndex = selectedCategories.indexOf(category);
                      const newChecked = [...selectedCategories];

                      if (currentIndex === -1) {
                        newChecked.push(category);
                      } else {
                        newChecked.splice(currentIndex, 1);
                      }

                      setSelectedCategories(newChecked);
                    }}
                  /> */}
                </ListItem>
              ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>Cancel</Button>
        <Button onClick={handleSave} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CategoryModifierPopup;
