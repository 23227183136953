import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CustomButton from '../../component/CustomButton'
import OrderDetailContainer from '../../component/OrderDetailContainer'


const OrderDetails = () => {
    const { id } = useParams()
    const navigate = useNavigate()
  return (
    <div>
        <CustomButton onClickButton={() => navigate(-1)} backButton label={'Back'} />
        <OrderDetailContainer className={'mt-8'} id={id} />
    </div>
  )
}

export default OrderDetails