import React, { useState } from "react";
import { Box, Button, FormHelperText, InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import * as Yup from "yup";
import logo from '../../assets/img/logo.svg'
import "./style.css"
import { useNavigate } from "react-router-dom";
import useToggle from "../../hooks/useToggle";

const CreateNewPassword = () => {
    const { i18n, t } = useTranslation();
    const [passwordUpdated, setPasswordUpdated] = useState(false)
    const [isNewPasswordVisible, toggleNewPasswordVisiblity] = useToggle();
    const [isConfirmPasswordVisible, toggleConfirmPasswordVisiblity] = useToggle();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            new_password: "",
            confirm_password: "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            new_password: Yup.string().required("New Password Is Required").matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
            confirm_password: Yup
                .string()
                .required('Confirm Password Is Required.')
                .oneOf([Yup.ref('new_password')], 'Your passwords do not match.'),
        }),
        onSubmit: ({ resetForm }) => {
            if (!passwordUpdated) {
                setPasswordUpdated(true)
            } else {
                navigate('/login')
            }
            resetForm();
        },
    });
    const LANGUAGES = [
        { label: "EN", code: "en" }, //English
        { label: "RO", code: "ro" }, //Romanian
        { label: "HU", code: "hu" }, //Hungarian
        { label: "DE", code: "de" }, //German
        { label: "ES", code: "es" }, //Spanish
        { label: "BE", code: "be" }, //French
    ];
    const onChangeLang = (e) => {
        const lang_code = e.target.value;
        i18n.changeLanguage(lang_code);
    };
    return (
        <>
            <Box className="login-info">
                <Box className="language-dropdown">
                    <Select
                        inputProps={{
                            id: "uncontrolled-native",
                        }}
                        defaultValue={i18n.language}
                        onChange={onChangeLang}
                    >
                        {LANGUAGES.map((language, index) => (
                            <MenuItem key={index} value={language.code} sx={{ fontSize: '12px', fontWeight: '500' }}>
                                {language.label}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box className="login-bg">
                    <img src={logo} />
                    {!passwordUpdated ?
                        <Typography sx={{ margin: { lg: '40px 0px !important', md: '40px 0px !important', sm: '25px 0px !important', xs: '20px 0px !important' } }}>
                            {t("reset_password")}
                        </Typography>
                        : <Typography>
                            {t("password_changed")}
                        </Typography>
                    }
                    {!passwordUpdated ?
                        <>
                            <Box className="username-detail">
                                <label>{t("new_passwords")}</label>
                                <TextField id="outlined-basic" variant="outlined"
                                    name='new_password' onChange={formik.handleChange} value={formik.values.new_password}
                                    type={isNewPasswordVisible ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" sx={{ cursor: 'pointer', color: "#000", position: 'absolute', right: '15px' }}>
                                                {isNewPasswordVisible ? <VisibilityOffIcon onClick={toggleNewPasswordVisiblity} /> : <VisibilityIcon onClick={toggleNewPasswordVisiblity} />}
                                            </InputAdornment>
                                        )
                                    }} />
                                {formik.touched.new_password && formik.errors.new_password && (
                                    <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                        {formik.errors.new_password}
                                    </FormHelperText>
                                )}
                            </Box>
                            <Box className="username-detail">
                                <label>{t("confirm_password")}</label>
                                <TextField id="outlined-basic" variant="outlined"
                                    name='confirm_password' onChange={formik.handleChange} value={formik.values.confirm_password}
                                    type={isConfirmPasswordVisible ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" sx={{ cursor: 'pointer', color: "#000", position: 'absolute', right: '15px' }}>
                                                {isConfirmPasswordVisible ? <VisibilityOffIcon onClick={toggleConfirmPasswordVisiblity} /> : <VisibilityIcon onClick={toggleConfirmPasswordVisiblity} />}
                                            </InputAdornment>
                                        )
                                    }} />
                                {formik.touched.confirm_password && formik.errors.confirm_password && (
                                    <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                        {formik.errors.confirm_password}
                                    </FormHelperText>
                                )}
                            </Box>
                        </>

                        :
                        <Typography>{t("change_successfully")}</Typography>
                    }
                    <Button className="sign-in-btn" onClick={() => formik.handleSubmit()}>{passwordUpdated ? t("log_in") : t("reset_password")}</Button>
                </Box>
            </Box>
        </>
    );
};
export default CreateNewPassword;
