import React from 'react'

const DefaultInput = ({label, inputName, width = 'w-full', height = 'h-10', defaultValue = '', onChange = () => {}}) => {
  return (
    <div>
        {label && <div className='font-semibold'>{label}</div>}
        <input 
            name={inputName}
            className={`${height} ${width} p-3 rounded-lg drop-shadow-sm border`}
            defaultValue={defaultValue}
            onChange={onChange}
        />
    </div>
  )
}

export default DefaultInput