import { put, call } from "redux-saga/effects";
import axios from "../../axiosConfig";
import { TOAST_HIDE, TOAST_SHOW } from "../Actions/ToastAction";
import { ADD_PARTNERS_FAILED, ADD_PARTNERS_SUCCESS, DELETE_PARTNER_FAILED, DELETE_PARTNER_SUCCESS, GET_PARTNERS_FAILED, GET_PARTNERS_REQUEST, GET_PARTNERS_SUCCESS } from "../Actions/PartnerActions";

export function* GetPartnersSaga(action) {
    try {
        const response = yield call(axios.get, action?.payload ? `admins/partners?name=${action?.payload}` : `admins/partners`);
        const partners  = response?.data?.partners.map(x=>{return {...x,partnerId:x.id}});
        console.log(`partners =====>`,partners)
        yield put({ type: GET_PARTNERS_SUCCESS, payload: { partners } });
    } catch (e) {
        yield put({ type: GET_PARTNERS_FAILED, payload: { message: e.response.data.message } });
        return yield put({
            type: TOAST_SHOW,
            payload: {
                message: e.response.data.message,
                severity: "error",
            },
        });
    }
}
export function* AddPartnerSaga(action) {
    try {
        const response = yield call(axios.post, `admins/partners/add`, action.payload);
        const { success } = response?.data;
        const message = success && 'Partner details added Successfully !';
        yield put({ type: ADD_PARTNERS_SUCCESS, payload: { message } });
        yield put({
            type: TOAST_SHOW,
            payload: { message: 'Partner details added Successfully !', severity: "success" },
        });
        yield put({ type: GET_PARTNERS_REQUEST });
        action.loadingButton(false);
        action.closeModel()
    } catch (e) {
        const message = e.response.data.message
        yield put({ type: ADD_PARTNERS_FAILED, payload: { message } });
        
        action.loadingButton(false);
        return yield put({
            type: TOAST_SHOW,
            payload: {
                message: e.response.data.message,
                severity: "error",
            },
        }); 
    }
}

export function* EditPartnerSaga(action) {
    try {
        console.log(`action.payload => `,action.payload)
        const response = yield call(axios.put, `admins/partners/edit`, action.payload);
        const { success } = response?.data;
        const message = success && 'Partner details updated Successfully !';
        yield put({ type: ADD_PARTNERS_SUCCESS, payload: { message } });
        yield put({
            type: TOAST_SHOW,
            payload: { message: 'Partner details updated Successfully !', severity: "success" },
        });
        action.loadingButton(false)
        action.closeModel()
        yield put({ type: GET_PARTNERS_REQUEST });
    } catch (e) {
        action.loadingButton(false)
        const message = e.response.data.message
        yield put({ type: ADD_PARTNERS_FAILED, payload: { message } });
        return yield put({
            type: TOAST_SHOW,
            payload: {
                message: e.response.data.message,
                severity: "error",
            },
        });
    }
}

export function* DeletePartnerSaga(action) {
    try {
        console.log(`action.payload => `,action.payload)
        const response = yield call(axios.delete, `admins/partners`,{ data: action.payload });
        const { success } = response?.data;
        const message = success && 'Partner deleted successfully!';
        yield put({ type: DELETE_PARTNER_SUCCESS, payload: { message, id: action.payload } });
        yield put({
            type: TOAST_SHOW,
            payload: { message: 'Partner deleted successfully!', severity: "success" },
        });
        yield put({ type: GET_PARTNERS_REQUEST });
    } catch (e) {
        const message = e.response.data.message;
        yield put({ type: DELETE_PARTNER_FAILED, payload: { message } });
        return yield put({
            type: TOAST_SHOW,
            payload: {
                message: e.response.data.message,
                severity: "error",
            },
        });
    }
}