import React, { useState } from "react";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { ToastAction } from "../Redux/Actions/ToastAction";
import { errorHandlerContext } from "../contexts";

export const useProvideErrorHandler = () => {
  const dispatch = useDispatch();
  const handleClose = () => {
    setMsgConfig({
      ...msgConfig,
      alertOpen: false,
    });
    dispatch(ToastAction({ hide: true }));
  };

  const defaultMsgConfig = {
    alertOpen: false,
    handleClose,
    message: "Loading...",
    severity: "info",
  };

  const [msgConfig, setMsgConfig] = useState(defaultMsgConfig);

  return {
    msgConfig,
    setMsgConfig,
    handleClose,
  };
};
export const useErrorHandler = () => {
  return useContext(errorHandlerContext);
};
