import { ADD_PARTNERS_FAILED, ADD_PARTNERS_REQUEST, ADD_PARTNERS_SUCCESS, DELETE_PARTNER_FAILED, DELETE_PARTNER_REQUEST, DELETE_PARTNER_SUCCESS, EDIT_PARTNER_DATA_REQUEST, GET_PARTNERS_FAILED, GET_PARTNERS_REQUEST, GET_PARTNERS_SUCCESS } from "../Actions/PartnerActions";

const PartnerState = {
    loading: false,
    currentAction: "INIT",
    token: "",
    success: false,
    message: "",
    getPartnersData: [],
    selectedPartnerRecord: {}
};

export const PartnerReducer = (state = PartnerState, action) => {
    switch (action.type) {
        case GET_PARTNERS_REQUEST:
            return {
                ...state,
                loading: true,
                currentAction: action.type,
            };

        case GET_PARTNERS_SUCCESS:
            return {
                ...state,
                loading: false,
                currentAction: action.type,
                message: action.payload.message,
                getPartnersData: action?.payload?.partners
            };

        case GET_PARTNERS_FAILED:
            return {
                ...state,
                loading: false,
                message: action.payload.message,
            };

        case ADD_PARTNERS_REQUEST:
            return {
                ...state,
                loading: true,
                currentAction: action.type,
            };

        case ADD_PARTNERS_SUCCESS:
            return {
                ...state,
                loading: false,
                currentAction: action.type,
                message: action.payload.message
            };

        case ADD_PARTNERS_FAILED:
            return {
                ...state,
                loading: false,
                message: action.payload.message
            };
        case EDIT_PARTNER_DATA_REQUEST:
            return {
                ...state,
                selectedPartnerRecord: action.payload,
            };
            case DELETE_PARTNER_REQUEST:
                return {
                    ...state,
                    loading: true,
                    currentAction: action.type,
                };
    
            case DELETE_PARTNER_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    currentAction: action.type,
                    message: action.payload.message,
                    getPartnersData: state.getPartnersData.filter(partner => partner.id !== action.payload.id)
                };
    
            case DELETE_PARTNER_FAILED:
                return {
                    ...state,
                    loading: false,
                    message: action.payload.message,
                };
        default:
            return state;
    }
};
