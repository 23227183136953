import PropTypes from "prop-types";
import "./CategoryCard.css";

const CategoryCard = ({ className = "", name1,totalItems,totalSeen,imgUrl="",  onCardMenuClick,onCardClick  }) => {
  let backImg = imgUrl?imgUrl:"/icons-131.svg"
  const ebrizaLinkedId = localStorage.getItem('ebrizaLinkedId');
  return (
    <div className={`category-card8-1 new-updates`} style={{ backgroundImage: `url(${backImg})` ,backgroundSize:`${imgUrl?'cover':'inherit'}`,backgroundRepeat:'no-repeat'}}>
      <div className="imageactions" onClick={onCardClick}>
        {/* <div className="image" style={{marginTop: '39px',borderTop:'none'}} onClick={onCardClick}>
          <img className="icons55" style={imgUrl?{width:'100%',height:'100%',objectFit: 'cover'}:{}} alt="" src={imgUrl?imgUrl:"/icons-131.svg"} />
        </div> */}
        </div>
        <div style={{width:'100%',backgroundColor:'#000',
    height: '0%',
    backgroundColor: 'rgb(0, 0, 0, 0.3)'}}>
          <div className="top4" style={{width:'50%',top:0}}>
            <img className="icons56"  style={{cursor:'pointer'}} alt="" src="/icons-141.svg"  onClick={onCardMenuClick}/>
            {
              !ebrizaLinkedId && (
                <img className="icons57" style={{cursor:'pointer'}} alt="" src="/icons-151.svg"  onClick={onCardMenuClick}/>
              )
            }
          </div>

        
      </div>
      <div onClick={onCardClick} className="text63" style={{backgroundColor: 'rgba(0, 0, 0, 0.3)',
    fontSize: '14px',
    color: '#fff',
    padding: '0',gap:0}}>
        <div className="name7">{name1}</div>
        <div className="tags" style={{marginBottom: '5px'}}>
          <div className="tag" style={{    height: '14px',
    width: '14px',
    position: 'relative',
    flexShrink: '0',
    minHeight: '0'}}>
            <div className="empty-tag">{totalItems?totalItems:0}</div>
            <img className="icons58" alt="" src="/icons-161.svg" />
          </div>
          <div className="tag1" style={{    height: '14px',
    width: '14px',
    position: 'relative',
    flexShrink: '0',
    minHeight: '0'}}>
            <div className="div3">{totalSeen?totalSeen:0}</div>
            <img className="icons59" alt="" src="/icons-17.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};

CategoryCard.propTypes = {
  className: PropTypes.string,
  name1: PropTypes.string,
};

export default CategoryCard;
