import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from "i18next";
import LocationConfigurationTemplate from './LocationConfigurationTemplate';
import RoomConfigTemplate from './RoomConfigTemplate';
import { useLocation } from 'react-router-dom';
import Reviews from './Reviews';


const AddLocation = () => {
    const [selectedTab, setSelectedTab] = useState('location-config')
    const location = useLocation()
    const selectedLocation = location.state

    useEffect(()=>{
      window.scrollTo({
        top: 0,
        behavior: "smooth" 
      });
    },[])

  return (
    <Box>
         <div className='flex space-x-8 overflow-x-scroll'>
            <div onClick={(e) => setSelectedTab(e.currentTarget.ariaLabel)} aria-label='location-config' className={`${selectedTab == 'location-config' && 'border-b-2 border-b-primaryColor'} cursor-pointer pb-2`}>{t("location_config")}</div>
            {/* <div onClick={(e) => setSelectedTab(e.currentTarget.ariaLabel)} aria-label='products' className={`${selectedTab == 'products' && 'border-b-2 border-b-primaryColor'} cursor-pointer pb-2`}>{t('products')}</div>
            <div onClick={(e) => setSelectedTab(e.currentTarget.ariaLabel)} aria-label='product-display' className={`${selectedTab == 'product-display' && 'border-b-2 border-b-primaryColor'} cursor-pointer pb-2`}>{t('product_display')}</div>
            <div onClick={(e) => setSelectedTab(e.currentTarget.ariaLabel)} aria-label='modifiers' className={`${selectedTab == 'modifiers' && 'border-b-2 border-b-primaryColor'} cursor-pointer pb-2`}>{t('modifiers_title')}</div> */}
            <div onClick={(e) => setSelectedTab(e.currentTarget.ariaLabel)} aria-label='reviews' className={`${selectedTab == 'reviews' && 'border-b-2 border-b-primaryColor'} cursor-pointer pb-2`}>{t('rating_tab')}</div>
            {selectedLocation && (
              <div onClick={(e) => setSelectedTab(e.currentTarget.ariaLabel)} aria-label='rooms-tables' className={`${selectedTab == 'rooms-tables' && 'border-b-2 border-b-primaryColor'} cursor-pointer pb-2`}>{t('rooms_tables')}</div>
            )}
            
        </div>
        <div className='mt-8'>
            {selectedTab == 'location-config' && <LocationConfigurationTemplate selectedLocation={selectedLocation} /> }
            {selectedTab == 'products' && <div>Coming soon!</div>}
            {selectedTab == 'product-display' && <div>Coming soon!</div> }
            {selectedTab == 'modifiers' && <div>Coming soon!</div>}
            {selectedTab == 'reviews' && <Reviews locationId={selectedLocation?.LocationId} />}
            {selectedTab == 'rooms-tables' && <RoomConfigTemplate locationId={selectedLocation?.LocationId} />}

        </div>
    </Box>
  )
}

export default AddLocation