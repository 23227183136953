import { ADD_CONFIGURATIONS_FAILED, ADD_CONFIGURATIONS_REQUEST, ADD_CONFIGURATIONS_SUCCESS, ADD_MENU_CATEGORY_FAILED, ADD_MENU_CATEGORY_REQUEST, ADD_MENU_CATEGORY_SUCCESS, ADD_MENU_FAILED, ADD_MENU_MODIFIER_FAILED, ADD_MENU_MODIFIER_REQUEST, ADD_MENU_MODIFIER_SUCCESS, ADD_MENU_PRODUCT_FAILED, ADD_MENU_PRODUCT_REQUEST, ADD_MENU_PRODUCT_SUCCESS, ADD_MENU_REQUEST, ADD_MENU_SUCCESS, GET_NUTRI_AND_CALC_FAILED, GET_NUTRI_AND_CALC_REQUEST, GET_NUTRI_AND_CALC_SUCCESS, ADD_REVIEWS_FAILED, ADD_REVIEWS_REQUEST, ADD_REVIEWS_SUCCESS, EDIT_IMAGE_FAILED, EDIT_IMAGE_REQUEST, EDIT_IMAGE_SUCCESS, GET_CONFIGURATIONS_FAILED, GET_CONFIGURATIONS_REQUEST, GET_CONFIGURATIONS_SUCCESS, GET_LOCATION_FAILED, GET_LOCATION_REQUEST, GET_LOCATION_SUCCESS, GET_REVIEWS_FAILED, GET_REVIEWS_REQUEST, GET_REVIEWS_SUCCESS, ADD_LOCATION_REQUEST, ADD_LOCATION_SUCCESS, ADD_LOCATION_FAILED, ADD_ROOM_REQUEST, ADD_ROOM_SUCCESS, ADD_ROOM_FAILED, ADD_TABLE_REQUEST, MOVE_TABLE_POSITION, REMOVE_TABLE_REQUEST, TAB_ID_REQUEST, REMOVE_ROOM_REQUEST, UPDATE_NUTRITION_DATA, GET_LOCATION_INFO, LOADING_TRUE, UPDATE_NUTRITION_PORTION, UPDATE_TABLE_DATA, UPDATE_ROOM_NAME_REQUEST } from "../Actions/LocationActions";

const LocationState = {
  loading: false,
  currentAction: "INIT",
  success: false,
  message: "",
  tabId: 0,
  locationData: [],
  reviewData: [],
  configurationData: [],
  ingredientsData: [],
  portionValue: '',
  locationInfo: '',
  awsImgUrl:''
};

export const LocationReducer = (state = LocationState, action) => {
  switch (action.type) {
    case GET_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
        currentAction: action.type,
      };

    case GET_LOCATION_SUCCESS:

      return {
        ...state,
        loading: false,
        currentAction: action.type,
        locationData: action.payload.locations
      };

    case GET_LOCATION_INFO:
      return {
        ...state,
        locationInfo: action.payload.data
      }

    case GET_LOCATION_FAILED:
      return { ...state, loading: false };

    case ADD_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
        currentAction: action.type,
      };

    case ADD_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        currentAction: action.type,
        message: action.payload.message
      };

    case ADD_LOCATION_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message
      };

    case EDIT_IMAGE_REQUEST:
      return {
        ...state,
        loading: false,
        awsImgUrl: action.payload
      };

    case EDIT_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentAction: action.type,
        message: action.payload.message
      };

    case EDIT_IMAGE_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message
      };

    case ADD_MENU_REQUEST:
      return {
        ...state,
        loading: true,
        currentAction: action.type,
      };

    case ADD_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        currentAction: action.type,
        message: action.payload.message
      };

    case ADD_MENU_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message
      };

    case ADD_MENU_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        currentAction: action.type,
      };

    case ADD_MENU_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        currentAction: action.type,
        message: action.payload.message
      };

    case ADD_MENU_CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message
      };

    case ADD_MENU_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        currentAction: action.type,
      };

    case ADD_MENU_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentAction: action.type,
        message: action.payload.message
      };

    case ADD_MENU_PRODUCT_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message
      };

    case ADD_MENU_MODIFIER_REQUEST:
      return {
        ...state,
        loading: true,
        currentAction: action.type,
      };

    case ADD_MENU_MODIFIER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentAction: action.type,
        message: action.payload.message
      };

    case ADD_MENU_MODIFIER_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message
      };

    case GET_NUTRI_AND_CALC_REQUEST:
      return {
        ...state,
        loading: true,
        currentAction: action.type,
      };

    case GET_NUTRI_AND_CALC_SUCCESS:
      return {
        ...state,
        loading: false,
        currentAction: action.type,
        ingredientsData: action.payload.ingredients
      };

    case GET_NUTRI_AND_CALC_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message
      };

    case UPDATE_NUTRITION_DATA:
      return {
        ...state,
        ingredientsData: action.payload,
      };
    case UPDATE_NUTRITION_PORTION:
      return {
        ...state,
        portionValue: action.payload,
      };

    case ADD_REVIEWS_REQUEST:
      return {
        ...state,
        loading: true,
        currentAction: action.type,
      };

    case ADD_REVIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentAction: action.type,
        message: action.payload.message
      };

    case ADD_REVIEWS_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message
      };

    case GET_REVIEWS_REQUEST:
      return {
        ...state,
        loading: true,
        currentAction: action.type,
      };

    case GET_REVIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentAction: action.type,
        reviewData: action.payload.reviews
      };

    case GET_REVIEWS_FAILED:
      return { ...state, loading: false };

    case ADD_ROOM_REQUEST:
      const tempData = state?.configurationData[0]?.rooms ? [...state?.configurationData[0]?.rooms, action?.payload] : [action?.payload]
      const newConfigurationData = [{ ConfigurationId: state?.configurationData[0]?.ConfigurationId, ClientId: state?.configurationData[0]?.ClientId, LocationId: state?.configurationData[0]?.LocationId,  rooms: tempData }]
      return {
        ...state,
        configurationData: newConfigurationData,
        currentAction: action.type,
      };

    case REMOVE_ROOM_REQUEST:
      const { configurationData } = state;
      const payload = action?.payload;
      const updatedRooms = configurationData[0]?.rooms.filter((room, index) => {
        return !(room['id'] === payload['id']);
      });
      const removeConfigurationData = [{ ConfigurationId: state?.configurationData[0]?.ConfigurationId, rooms: updatedRooms }]
      return {
        ...state,
        configurationData: removeConfigurationData,
        currentAction: action.type,
      };
    case UPDATE_ROOM_NAME_REQUEST:
      const  configurationData1  = state?.configurationData;
      const payload1 = action?.payload;
      console.log("configurationData1..",configurationData1,state);
      const selectedRoom = configurationData1[0]?.rooms.find((room, index) => {
        return (room['id'] === payload1['id']);
      });

      if(selectedRoom){
        selectedRoom['name'] = payload1['name']
        selectedRoom['tables'].map((ele)=>ele['roomName'] = payload1['name'])
      }
      
      const removeConfigurationData1 = [{ ConfigurationId: state?.configurationData[0]?.ConfigurationId, rooms: configurationData1[0]?.rooms }]
      return {
        ...state,
        configurationData: removeConfigurationData1,
        currentAction: action.type,
      };

    case TAB_ID_REQUEST:
      return {
        ...state,
        tabId: action.payload,
      };

    case ADD_TABLE_REQUEST:
      const roomData = state?.configurationData[0]?.rooms[state?.tabId]
      const updatedTableData = [...roomData.tables, action.payload]
      const updatedRoomTab = { ...roomData, tables: updatedTableData }
      const updatedConfigurationData = [...state?.configurationData];
      updatedConfigurationData[0].rooms[state?.tabId] = updatedRoomTab;
      return {
        ...state,
        // loading: true,
        configurationData: updatedConfigurationData,
        currentAction: action.type,
      };
    case MOVE_TABLE_POSITION:
      let roomPosition;
      let configData = state.configurationData
      const roomIdToFind = action.payload.roomId;
      for (const config of configData) {
          for (const room of config.rooms) {
              if (room.id === roomIdToFind) {
                  roomPosition = room;
                  break;
              }
          }
      }

      const index = configData.findIndex(config => {
        return config.rooms.some(room => room.id === roomIdToFind);
      });

      if (index !== -1) {
        const roomIndex = configData[index].rooms.findIndex(room => room.id === roomIdToFind);
        if (roomIndex !== -1) {
            roomPosition.tables[action.payload.tableIndex].x = action.payload.x; 
            roomPosition.tables[action.payload.tableIndex].y = action.payload.y; 
            configData[index].rooms[roomIndex] = roomPosition;
            return {
              ...state,
              configurationData : configData
            }
        } else {
            console.log("Room not found in configurationData.");
        }
      } else {
        console.log("room id not found in configurationData.");
      }

    case UPDATE_TABLE_DATA:
      let roomPosition1;
      let configData1 = state.configurationData
      const roomIdToFind1 = action.payload.roomId;
      for (const config of configData1) {
          for (const room of config.rooms) {
              if (room.id === roomIdToFind1) {
                  roomPosition1 = room;
                  break;
              }
          }
      }

      const index1 = configData1.findIndex(config => {
        return config.rooms.some(room => room.id === roomIdToFind1);
      });

      if (index1 !== -1) {
        const roomIndex = configData1[index1].rooms.findIndex(room => room.id === roomIdToFind1);
        if (roomIndex !== -1) {
          if(action.payload.name == 'table_name'){
            roomPosition1.tables[action.payload.tableIndex].name = action.payload.value; 
          }else if(action.payload.name == 'sit_count'){
            roomPosition1.tables[action.payload.tableIndex].seats = action.payload.value; 
            
          }
            configData1[index1].rooms[roomIndex] = roomPosition1;
            return {
              ...state,
              configurationData : configData1
            }
        } else {
            console.log("Room not found in configurationData.");
        }
      } else {
        console.log("room id not found in configurationData.");
      }

    case REMOVE_TABLE_REQUEST:
      const deletedTable = action?.payload
      const roomDt = state?.configurationData[0]?.rooms[state?.tabId]
      const updatedTableDt =   roomDt.tables.filter((table, index) => { 
        return !(table.id == deletedTable)
      }) 
        //[...roomDt.tables, action.payload] // logic to remove table
      const uptRoomTab = { ...roomDt, tables: updatedTableDt }
      const uptConfigurationData = [...state?.configurationData];
      uptConfigurationData[0].rooms[state?.tabId] = uptRoomTab;
      return {
        ...state,
        // loading: true,
        configurationData: uptConfigurationData,
        currentAction: action.type,
      };

    case ADD_CONFIGURATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        currentAction: action.type,
      };

    case ADD_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentAction: action.type,
        message: action.payload.message
      };

    case ADD_CONFIGURATIONS_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message
      };

    case GET_CONFIGURATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        currentAction: action.type,
      };

    case GET_CONFIGURATIONS_SUCCESS:
      console.log("GET_CONFIGURATIONS_SUCCESS..",action);
      return {
        ...state,
        loading: false,
        currentAction: action.type,
        configurationData: action.payload
      };

    case GET_CONFIGURATIONS_FAILED:
      return { ...state, loading: false };

    case LOADING_TRUE:
      return {...state, loading: true}

    default:
      return state;
  }
};
