import { useMemo } from "react";

export default function TipsReport({ payloadData }) {
    const processedData = useMemo(() => {
        if (!payloadData || !payloadData['total_tips']) return 0;
    
        return payloadData['total_tips'];
    }, [payloadData]);

    return (
        <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden' // Hide overflow text
        }}>
            <h1 style={{
                width: '100%',
                height: 'auto', // Adjust height based on content
                fontSize: '10vw', // Responsive font size
                fontWeight: 'bold',
                textAlign: 'center',
                whiteSpace: 'nowrap', // Prevent text wrapping
                overflow: 'hidden', // Hide overflow text
                textOverflow: 'ellipsis' // Add ellipsis if text overflows
            }}>
                {processedData}
            </h1>
        </div>
    );
}
