import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";

export default function ControlledOpenSelect({ placeholder, onDateChange, selectedIndex }) {
  const [period, setPeriod] = useState("Today");
  const [open, setOpen] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleChange = (event) => {
    const value = event.target.value;
    setPeriod(value);

    // Get today's date
    const today = dayjs();

    let newFromDate = null;
    let newToDate = null;

    switch (value) {
      case "Today":
        newFromDate = today;
        newToDate = today;
        break;
      case "Yesterday":
        newFromDate = today.subtract(1, "day");
        newToDate = today.subtract(1, "day");
        break;
      case "Last week":
        newFromDate = today.subtract(1, "week").startOf("week");
        newToDate = today.subtract(1, "week").endOf("week");
        break;
      case "Last month":
        newFromDate = today.subtract(1, "month").startOf("month");
        newToDate = today.subtract(1, "month").endOf("month");
        break;
      case "Custom":
        break;
      default:
        break;
    }

    setFromDate(newFromDate);
    setToDate(newToDate);

    // Trigger the parent's onDateChange function
    if (onDateChange) {
      onDateChange(newFromDate, newToDate);
    }
  };

  useEffect(() => {
    handleChange({
      'target': {
        'value': "Today"
      }
    })
  }, [selectedIndex])

  const handleDateChange = (newValue, isFrom) => {
    const updatedFromDate = isFrom ? newValue : fromDate;
    const updatedToDate = !isFrom ? newValue : toDate;

    setFromDate(updatedFromDate);
    setToDate(updatedToDate);

    // Trigger the parent's onDateChange function
    if (onDateChange) {
      onDateChange(updatedFromDate, updatedToDate);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div style={{display: 'flex'
    }}>
      {period === "Custom" && (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <div style={{ display: "flex", gap: "1rem",alignItems:'center',width: '250px' }}>
      <DatePicker
        label="From"
        value={fromDate}
        onChange={(newValue) => handleDateChange(newValue, true)}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              '& .MuiInputBase-root': {
                height: 30,
              },
              width: 120,
              '& .MuiInputBase-input': {
                fontSize: '12px', // Adjust font size if needed
              },
              '& .MuiInputLabel-root': {
                fontSize: '12px', // Adjust label font size if needed
              },
              '& .MuiInputLabel-root': {
                fontSize: '15px', 
                lineHeight: '13px' // Adjust label position if needed
              }
            }}
          />
        )}
      />
      <DatePicker
        label="To"
        value={toDate}
        onChange={(newValue) => handleDateChange(newValue, false)}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              '& .MuiInputBase-root': {
                height: 30,
                width: 120,
              },
             
              '& .MuiInputBase-input': {
                fontSize: '12px', // Adjust font size if needed
              },
              '& .MuiInputLabel-root': {
                fontSize: '12px', // Adjust label font size if needed
              },
              '& .MuiInputLabel-root': {
                fontSize: '15px', 
                lineHeight: '13px' // Adjust label position if needed
              }
            }}
          />
        )}
      />
    </div>
  </LocalizationProvider>
)}

      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={period}
          onChange={handleChange}
          sx={{
            height: "39px", 
          }}
        >
          <MenuItem value="Today">Today</MenuItem>
          <MenuItem value="Yesterday">Yesterday</MenuItem>
          <MenuItem value="Last week">Last week</MenuItem>
          <MenuItem value="Last month">Last month</MenuItem>
          <MenuItem value="Custom">Custom</MenuItem>
        </Select>
      </FormControl>

      {/* Conditionally render based on the selected period */}
     
    </div>
  );
}
