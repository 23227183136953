import React from 'react';
import './style.css';
import { Box, Typography } from '@mui/material';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            display: false
        },
    },
    scales: {
        y: {
            display: false, // Set display to false to hide the y-axis labels
        },
    }
};

const labels = ['May 05', 'May 06', 'May 07', 'May 08', 'May 09', 'May 10'];

// Function to generate random data
function generateRandomData() {
    return labels.map(() => Math.floor(Math.random() * 1000));
}

export const data = {
    labels,
    datasets: [
        {
            data: generateRandomData(),
            backgroundColor: '#FFB800',
        },
    ],
};

const Graph = ({ title, subtitle, count }) => {
    return (
        <Box className="graph-box">
            <Box className='graph-title'>
                <Typography variant='h5'>{title}</Typography>
                <Box sx={{
                    display: 'flex',
                    gap: '5px'
                }}>
                    <Typography>{count}</Typography>
                    <Typography variant='p'>{subtitle}</Typography>
                </Box>
            </Box>
            <Bar options={options} data={data} />
        </Box>
    );
};

export default Graph;
