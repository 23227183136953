import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Typography, FormControlLabel, Switch, TextField, Box, FormHelperText, Snackbar, Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import ReactLoading from 'react-loading';
import addLocation from '../../libs/addLocation';
import { TOAST_SHOW } from '../../Redux/Actions/ToastAction';
import axios from '../../axiosConfig';
import "./AddModifier.css";
import ModifierCard from "../../component/ConfigurationsPage/ModifierCard";
import CategoryModifierPopup from "./child/CategoryModifierPopup";

export default function AddModifier({ productModifiers, allCatWithProducts, fetchLatestDataAndMap }) {
  const [productModData, setProductModData] = useState(productModifiers);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const partnerId = localStorage.getItem('partnerId');
  const ebrizaLinkedId = localStorage.getItem('ebrizaLinkedId');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [preSelectedProductsPopup, setPreSelectedProductsPopup] = useState([]);
  const [selectedProdModId, setSelectedProdModId] = useState('');
  const [currentArrayHelper, setCurrentArrayHelper] = useState('');
  const [selectedProdModifier, setSelectedProdModifier] = useState('');

  useEffect(() => {
    if (productModifiers && productModifiers.length > 0) {
      setProductModData(productModifiers);
      if(id != 'new'){
        let selectedDetils = productModifiers.find((ele)=>ele.id == parseInt(id))
        if(selectedDetils){
          setSelectedProdModifier(selectedDetils);
          formik.setValues(
            {
              parent_group_name: selectedDetils['name'],
              source_products: selectedDetils['products'],
              modifiers_options: selectedDetils['modifiers_group'],
            }
          )
        }
      }
    }
  }, [productModifiers]);
  


  const handleNavigateToConf = () => {
    fetchLatestDataAndMap()
    navigate('/configuration');
  }

  const handleAddModifierClose = () => {
    localStorage.removeItem('formData');
    navigate('/configuration');
  };

  const parentHandleRemove = (group_id, index) => {
    if (group_id == 'products') {
      // remove product fromik source_products data
      formik.values.source_products.splice(index, 1)
    } else {
      // remove particular group id modifiers_options data
      formik.values.modifiers_options[group_id].modifiers.splice(index, 1)
    }
  }
  const openCategoryModal = (mode, group_id) => {
    setPopupOpen(true)
    setSelectedProdModId(group_id)
    if (group_id == 'products') {
      // remove open cat model for product and update 
      if (mode == 'edit') {
        setPreSelectedProductsPopup(formik.values.source_products)
      } else {
        setPreSelectedProductsPopup([])
      }
    } else {
      // remove particular group id modifiers_options data
      if (mode == 'edit') {
        setPreSelectedProductsPopup(formik.values.modifiers_options[group_id]['modifiers'])
      } else {
        setPreSelectedProductsPopup([])
      }
    }
  }

  const handleOptionsAdd = (arrayHelpers) => {
    setCurrentArrayHelper(arrayHelpers)
    openCategoryModal('add', arrayHelpers.length)
  }


  const formik = useFormik({
    initialValues: {
      parent_group_name: '',
      source_products: [],
      modifiers_options: []
    },
    onSubmit: async (values) => {
      if(!ebrizaLinkedId){
        console.log(values);
        setLoading(true)
        try {
          let url = `/partners/menu_modifiers_all/${id}`
  
          const menuRes = await axios.post(url, values);
          if (menuRes.data.success) {
            fetchLatestDataAndMap()
            navigate('/configuration');
          }
        } catch (error) {
          console.log("error..",error.message);
        } finally {
          setLoading(false)
          localStorage.removeItem('formData');
          setOpenSnackbar(true);
        }
        
      }else{
        navigate('/configuration');

      }
    },
  });

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handlePopupSave = (selectedData) => {
    console.log(" handlePopupSave selectedData", selectedData);
    setPopupOpen(false);
    // 
    if (selectedProdModId == 'products') {
      formik.setFieldValue('source_products', selectedData)

    } else {
      let currentFormikData = formik.values.modifiers_options
      let group_id = selectedProdModId
      if (currentFormikData && currentFormikData[group_id]) {
        currentFormikData[group_id]['modifiers'] = selectedData

        formik.setFieldValue('modifiers_options', currentFormikData)

      } else {
        // currentFormikData = []
        // currentFormikData.push({
        //   'name': `Option ${currentFormikData.length + 1}`,
        //   // 'id': modiG.ID,
        //   'min': 0,
        //   'max': 1,
        //   'modifiers': selectedData
        // })
        let currentIdx = currentFormikData && currentFormikData.length + 1 || 1
        currentArrayHelper.push({
          'name': `Option ${currentIdx}`,
          // 'id': modiG.ID,
          'min': 0,
          'max': 1,
          'modifiers': selectedData
        })
      }
    }
  };

  useEffect(() => {
    const savedFormData = localStorage.getItem('formData');
    if (savedFormData) {
      formik.setValues(JSON.parse(savedFormData));
    }
  }, []);

  useEffect(() => {
    if (formik.values) {  // Only save if formik values are present
      localStorage.setItem('formData', JSON.stringify(formik.values));
    }
  }, [formik.values]);
  return (
    <div className="data-wrapper">
      <CategoryModifierPopup
        open={popupOpen}
        onClose={handleClosePopup}
        categories={allCatWithProducts}
        preSelectedProductsPopup={preSelectedProductsPopup}
        onSave={handlePopupSave}
      />
      <div className="data1">
        <div className="head-parent">
          <div className="head">
            <div className="button-base-wrapper">
              <Button
    
                className="button-base15"
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "#222327",
                  fontSize: "14",
                  background: "#ffb800",
                  border: "#ffb800 solid 1px",
                  borderRadius: "8px",
                  "&:hover": { background: "#ffb800" },
                  height: 40,
                }}
                onClick={formik.handleSubmit}
              >
                {loading ? <ReactLoading type="bubbles" color="#fff" height={20} width={20} /> : 'Save'}
              </Button>
            </div>
            <div className="add-product-wrapper">
              <h1 className="add-product">{id === 'new' ? 'Add Modifier' : 'Edit Modifier'}</h1>
            </div>
            <div className="close-button" onClick={handleAddModifierClose}>
              <img className="icons31" alt="" src="/icons-92.svg" />
            </div>
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}>
            <h3 className="predefined-fields">Modifier details</h3>
          </div>
        </div>
        <FormikProvider value={formik}>
          <form style={{ width: '100%' }}>
            <Box mb={2}>
              <Typography variant="body1">Name</Typography>
              <TextField
                disabled={ebrizaLinkedId}
                fullWidth
                className="product-add-input"
                name="parent_group_name"
                value={formik.values.parent_group_name}
                onChange={(event) => formik.setFieldValue('parent_group_name', event.target.value)}
                onBlur={formik.handleBlur}
                placeholder="Enter modifier group name"
                error={formik.touched.parent_group_name && Boolean(formik.errors.parent_group_name)}
                helperText={formik.touched.parent_group_name && formik.errors.parent_group_name}
              />
            </Box>
            {formik.values.source_products.length != 0 ? (
              <>
                <div style={{ marginTop: '15px' }}>
                  <div className="modi-product-cont">
                    <h3 className="predefined-fields">{formik.values.parent_group_name}</h3>
                    <Button
                      disabled={ebrizaLinkedId}
                      type="button"
                      onClick={() => openCategoryModal('edit', 'products')}
                      variant="contained"
                      color="primary"
                    >
                      Edit
                    </Button>
                  </div>
                  <div className="modi-product-cards">
                    {formik.values.source_products.map((product, index) => (
                      <ModifierCard
                        group_id={'products'}
                        index={index}
                        parentHandleRemove={parentHandleRemove}
                        imgUrl={product.imageUrl}
                        name={product.name}
                      />
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                {
                  formik.values.parent_group_name ? (
                    <Button
                    disabled={ebrizaLinkedId}
                      style={{ marginTop: '15px' }}
                      type="button"
                      onClick={() => openCategoryModal('add', 'products')}
                      variant="contained"
                      color="primary"
                      className="btn-yellow"
                    >
                      + Add Source
                    </Button>
                  ) : (
                    <></>
                  )
                }
              </>

            )}
            {formik.values.source_products.length != 0 ? (
              <>
                <FieldArray
                  name="modifiers_options"
                  render={(arrayHelpers) => (
                    <div>
                      {formik.values.modifiers_options && formik.values.modifiers_options.map((modifierG, index) => (
                        <>
                          <div>
                            <div className="options-cont">
                              <div className="left">
                                <TextField
                                  disabled={ebrizaLinkedId}
                                  className="product-add-input modiInput"
                                  name={`modifiers_options.${index}.name`}
                                  value={modifierG.name}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  placeholder="Enter modifier category name"
                                />
                                {/* <Box mb={1}>
                                </Box> */}
                                <Button
                                disabled={ebrizaLinkedId}
                                  className="edit-btn"
                                  
                                  style={ebrizaLinkedId?{backgroundColor:'rgba(0, 0, 0, 0.26)'}:{}}
                                  type="button"
                                  onClick={() => openCategoryModal('edit', index)}
                                  variant="contained"
                                  color="primary"
                                >
                                  Edit
                                </Button>
                                <Button
                                  disabled={ebrizaLinkedId}
                                  style={ebrizaLinkedId?{backgroundColor:'rgba(0, 0, 0, 0.26)'}:{}}
                                  className="rem-btn"
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)}
                                  variant="contained"
                                  color="primary"
                                >
                                  Remove
                                </Button>
                              </div>
                              <div className="right">
                                <span>can be selected between</span>
                                <Box mb={0}>
                                  <TextField
                                    disabled={ebrizaLinkedId}

                                    className="product-add-input modiInputS"
                                    name={`modifiers_options.${index}.min`}
                                    value={modifierG.min}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="Enter min qty"
                                  />
                                </Box>
                                <span> and </span>
                                <Box mb={0}>
                                  <TextField
                                    disabled={ebrizaLinkedId}

                                    className="product-add-input modiInputS"
                                    name={`modifiers_options.${index}.max`}
                                    value={modifierG.max}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="Enter Max qty"
                                  />
                                </Box>
                              </div>
                            </div>
                            <div className="modi-product-cards">
                              {modifierG.modifiers.map((product, indexC) => (
                                <ModifierCard
                                  group_id={index}
                                  index={indexC}
                                  parentHandleRemove={parentHandleRemove}
                                  imgUrl={product.imageUrl}
                                  name={product.name}
                                />
                              ))}
                            </div>
                          </div>
                        </>

                      ))}
                      <Button
                      disabled={ebrizaLinkedId}
                        className="btn-yellow"
                        type="button"
                        onClick={() => handleOptionsAdd(arrayHelpers)}
                        variant="contained"
                        color="primary"
                      >
                        + Add Option
                      </Button>
                    </div>
                  )}
                />
              </>
            ) : (
              <></>
            )}

          </form>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
              Form submitted successfully!
            </Alert>
          </Snackbar>
        </FormikProvider>
      </div>
    </div>

  );
}
