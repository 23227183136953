import React from 'react'
import profile from '../assets/img/profile-img.png'
import FoodList from './FoodList.js'
import CustomButton from './CustomButton.js'


const OrderDetailContainer = ({className, id, status = "pending"}) => {
  return (
    <div className={`${className} p-5 bg-secondaryColor rounded-xl`}>
        <div className='flex justify-between'>
            <div>
                <div className='font-semibold lg:text-xl'>Order Detail: <span className='text-base'>{id}</span></div>
                <div className='text-green-500'>Payment : Online</div>
            </div>
            <div className={`px-4 content-center text-center font-semibold lg:text-xl ${status == 'pending' ? 'bg-[#20A1FF] text-white' : 'bg-primaryColor'} rounded-full`}>
                {status == 'confirmed' ? 'Confirmed' : 'Pending'}
            </div>
        </div>
        <div className='grid lg:grid-cols-10 gap-5 mt-10'>
            <div className='lg:col-span-7 '>
                {status == 'confirmed' ? (
                    <>
                    <div className='grid grid-cols-2 lg:grid-cols-3 gap-6 bg-white rounded-xl drop-shadow-sm p-5 h-max'>
                        <div>
                            <div className='lg:text-xl font-semibold'>Order Date</div>
                            <p>May 11, 2022 5:56:24PM</p>
                        </div>
                        <div>
                            <div className='lg:text-xl font-semibold'>Order Expected</div>
                            <p>May 11, 2022 6:30:24PM</p>
                        </div>
                        <div>
                            <div className='lg:text-xl font-semibold'>Payment Mode</div>
                            <p>Online</p>
                        </div>
                        <div>
                            <div className='lg:text-xl font-semibold'>Restaurant Name</div>
                            <p>Pizzzaria</p>
                        </div>
                        <div className='col-span-2'>
                            <div className='lg:text-xl font-semibold'>Branch Address</div>
                            <p>Pizzzaria, Leo Londen 27, Mississauga 3445 CA</p>
                        </div>
                        
                    </div>
                    <div className='lg:block hidden'>
                        <div className='text-xl font-semibold mb-5 mt-8'>Food Items</div>
                        <div>
                            <FoodList foodName={'Burger'} total={'5'} price={111} />
                            <FoodList foodName={'Pizza'} total={'1'} price={21} />
                            <FoodList foodName={'Coke'} total={'5'} price={11} />
                        </div>
                    </div> 
                    </>
                ) : (
                    <>
                    <div className='overflow-y-scroll h-96'>
                            <FoodList foodName={'Burger'} total={'5'} price={111} />
                            <FoodList foodName={'Pizza'} total={'1'} price={21} />
                            <FoodList foodName={'Coke'} total={'5'} price={11} />
                            <FoodList foodName={'Pizza'} total={'1'} price={21} />
                            <FoodList foodName={'Coke'} total={'5'} price={11} />
                    </div> 
                    <div className='mt-9'>
                        <div className='text-xl font-semibold'>Remarks</div>
                        <textarea style={{resize: 'none'}} disabled defaultValue={"No oniion"} rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg drop-shadow"></textarea>
                    </div>
                    <div className='mt-12'>
                        <div>Preparation Time</div>
                        <div className='flex space-x-20 mt-2'>
                            <div className='bg-white py-2 px-5 rounded-lg drop-shadow-lg h-max'>00:20:00</div>
                            <div className='py-2 px-5 cursor-pointer border-2 border-primaryColor rounded-full bg-white'>Add Preparation Time</div>
                        </div>
                    </div>
                    <div className='mt-8 flex space-x-5'>
                        <CustomButton secondary label={'Reject'} />
                        <CustomButton label={'Confirm'} />
                    </div>
                    </>
                )
            }
            </div>
            <div className='lg:col-span-3 '>
                <div className='flex justify-between bg-white rounded-xl drop-shadow-sm p-5 h-max'>
                    <div className='space-y-5'>
                        <div>
                            <div className='text-xl font-semibold'>
                                User Name
                            </div>
                            <p>Alice</p>
                        </div>
                        <div>
                            <div className='text-xl font-semibold'>
                                User Mobile
                            </div>
                            <p>+1 (438) 555 8999</p>
                        </div>
                        <div>
                            <div className='text-xl font-semibold'>
                                User Address
                            </div>
                            <p>110 Courtneypark Dr E, Mississauga, ON L5T 2Y3</p>
                        </div>
                    </div>
                    <div>
                        <img src={profile} width={80} height={80}/>
                    </div>
                </div>
                <div className=' bg-white rounded-xl drop-shadow-sm p-5 h-max space-y-5 mt-5'>
                    <div className='flex justify-between'>
                        <div>Order Price</div>
                        <div>$70</div>
                    </div>
                    <div className='flex justify-between'>
                        <div>Discount Amount</div>
                        <div>$70</div>
                    </div>
                    <div className='flex justify-between'>
                        <div>Promo: DRA44</div>
                        <div>-$4.88</div>
                    </div>
                    <div className='flex justify-between'>
                        <div>Delivery Charges</div>
                        <div>$70</div>
                    </div>
                    <div className='flex justify-between'>
                        <div>Tax</div>
                        <div>$70</div>
                    </div>
                    <div className='flex justify-between'>
                        <div>Delivery Tip</div>
                        <div>$70</div>
                    </div>
                    <div className='flex justify-between font-semibold'>
                        <div>Total Price</div>
                        <div>$332</div>
                    </div>
                </div>
                {status == 'confirmed' && (
                    <div className='lg:hidden'>
                        <div className='text-xl font-semibold mb-5 mt-8'>Food Items</div>
                        <div>
                            <FoodList foodName={'Burger'} total={'5'} price={111} />
                            <FoodList foodName={'Pizza'} total={'1'} price={21} />
                            <FoodList foodName={'Coke'} total={'5'} price={11} />
                        </div>
                    </div>
                )}
            </div>
        </div>

    </div>
  )
}

export default OrderDetailContainer