import React, { useState } from "react";
import { Box, Button, FormHelperText, InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import logo from '../../assets/img/logo.svg'
import "./style.css"
import useToggle from "../../hooks/useToggle";
import { Link } from "react-router-dom";
import { useErrorHandler } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { AdminLoginAction, LoginAction } from "../../Redux/Actions/AuthActions";
import ReactLoading from "react-loading";

const Login = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const { setMsgConfig } = useErrorHandler();
  const { loading, message } = useSelector((state) => state.Auth);
  const [isPasswordVisible, togglePasswordVisiblity] = useToggle();
  const [error, setError] = useState('');
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      if (formik.values.email && formik.values.password) {
        const payload = {
          username: formik.values.email,
          password: formik.values.password,
        };
        // if (formik.values.email === 'BNikolov' && formik.values.password === '12345') {
        //   dispatch(AdminLoginAction(payload));
        // } else {
        dispatch(LoginAction(payload));
        // }
        setMsgConfig({
          alertOpen: true,
          message: "Message",
          severity: "Severity",
          autoHideDuration: 2000,
        });
        setError('')
        resetForm();
      } else {
        setError('Either your password or username is incorrect')
      }
    },
  });
  const LANGUAGES = [
    { label: "EN", code: "en" }, //English
    { label: "RO", code: "ro" }, //Romanian
    { label: "HU", code: "hu" }, //Hungarian
    { label: "DE", code: "de" }, //German
    { label: "ES", code: "es" }, //Spanish
    { label: "BE", code: "be" }, //French
  ];
  const onChangeLang = (e) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  };
  return (
    <>
      <Box className="login-info">
        <Box className="language-dropdown">
          <Select
            inputProps={{
              id: "uncontrolled-native",
            }}
            defaultValue={i18n.language}
            onChange={onChangeLang}
          >
            {LANGUAGES.map((language, index) => (
              <MenuItem key={index} value={language.code} sx={{ fontSize: '12px', fontWeight: '500' }}>
                {language.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="login-bg">
          <div style={{    width: '100%',
            display: 'flex',
            justifyContent: 'center'}}>
            <img src={logo} alt='logo' />
          </div>
          {error ? (
            <FormHelperText className="error" >
              {error}
            </FormHelperText>
          ) :
            <Typography>{t("login_title")}</Typography>}
          <Box className="username-detail">
            <label>{t("username_title")}</label>
            <TextField id="outlined-basic" variant="outlined" name='email' onChange={formik.handleChange} value={formik.values.email} />
          </Box>
          <Box className="username-detail"  >
            <label>{t("password_title")}</label>
            <TextField id="outlined-basic" variant="outlined"
              name='password' onChange={formik.handleChange} value={formik.values.password}
              type={isPasswordVisible ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ cursor: 'pointer', color: "#000", position: 'absolute', right: '15px' }}>
                    {isPasswordVisible ? <VisibilityOffIcon onClick={togglePasswordVisiblity} /> : <VisibilityIcon onClick={togglePasswordVisiblity} />}
                  </InputAdornment>
                )
              }} />
          </Box>
          <Link to='/forget-password' className="forget_pwd">{t("forgot_password")}</Link>
          <Button className="sign-in-btn" onClick={() => formik.handleSubmit()}>{loading ? (
            <ReactLoading
              type={"spin"}
              height="30px"
              width="30px"
              color="white"
            />
          ) : t("sign_in")}</Button>
        </Box>
      </Box>
    </>
  );
};
export default Login;
