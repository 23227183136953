import { Box, Button, InputAdornment, Switch, TextField } from '@mui/material'
import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import refreshImg from '../../assets/img/refresh-icon.png';
import { useTranslation } from 'react-i18next';
// import DefaultTable from '../../component/DefaultTable';
import CustomButton from '../../component/CustomButton';
import { useNavigate } from 'react-router-dom';
import CustonDataGrid from '../../component/CustomDataGrid';



const index = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [selectedTab, setSelectedTab] = useState('registered')
    const [loading, setLoading] = useState(false)
    const mockData = [
        {id: 87,restaurant_name:'Wok-&-roll', address: '110 Courtneypark Dr E, Mississauga, ON L5T 2Y3', phone:'+1 (467)433 8998', email:'woks@gmail.com', delivery_commission:'10%', pickup_commission:'5%', revenue:'$100222.34', status: true},
        {id: 99,restaurant_name:'Wok-&-roll', address: '110 Courtneypark Dr E, Mississauga, ON L5T 2Y3', phone:'+1 (467)433 8998', email:'woks@gmail.com', delivery_commission:'10%', pickup_commission:'5%', revenue:'$100222.34', status: true},
        {id: 88,restaurant_name:'Wok-&-roll', address: '110 Courtneypark Dr E, Mississauga, ON L5T 2Y3', phone:'+1 (467)433 8998', email:'woks@gmail.com', delivery_commission:'10%', pickup_commission:'5%', revenue:'$100222.34', status: false},
        {id: 123,restaurant_name:'Wok-&-roll', address: '110 Courtneypark Dr E, Mississauga, ON L5T 2Y3', phone:'+1 (467)433 8998', email:'woks@gmail.com', delivery_commission:'10%', pickup_commission:'5%', revenue:'$100222.34', status: true},
        {id: 311,restaurant_name:'Wok-&-roll', address: '110 Courtneypark Dr E, Mississauga, ON L5T 2Y3', phone:'+1 (467)433 8998', email:'woks@gmail.com', delivery_commission:'10%', pickup_commission:'5%', revenue:'$100222.34', status: false}
    ]

    const columns = [
        {
            field: 'id', headerName: 'ID', minWidth: 50, flex: 1, headerAlign: "center", align: 'center', renderCell: ({row}) => (
              <React.Fragment>
                {row.id || "N/A"}
              </React.Fragment>
            )
          },
        {
          field: 'restaurant_name', headerName: 'Restauran Name', sortable: false, minWidth: 150, flex: 1, headerAlign: "center", align: 'center', renderCell: ({row}) => (
            <div className='whitespace-normal'>
              {row.restaurant_name}
            </div>
          )
        },
        {
          field: 'address', headerName: 'Address', minWidth: 120, flex: 2, headerAlign: "center", align: 'center', renderCell: ({row}) => (
            <div className={'whitespace-normal'}>
              {row.address || "N/A"}
            </div>
          )
        },
        {
          field: 'phone', headerName: 'Phone', sortable: false, minWidth: 100, flex: 1, headerAlign: "center", align: 'center', renderCell: ({row}) => (
            // <Tooltip title={params.value || "Not Available"}>
              <div className={'whitespace-normal'}>
                {row.phone || "N/A"}
              </div>
            // </Tooltip>
          )
        },
        {
          field: 'email', headerName: t('Email'), sortable: false, minWidth: 125, flex: 2, headerAlign: "center", align: 'center', renderCell: ({row}) => (
            <div className={'whitespace-normal'}>
              {row.email || "N/A"}
            </div>
          )
        },
        {
          field: 'delivery_commission', headerName: 'Delivery Commission', minWidth: 105, flex: 1, headerAlign: "center", align: 'center', renderCell: ({row}) => (
            <React.Fragment>
              {row.delivery_commission || "N/A"}
            </React.Fragment>
          )
        },
        {
          field: 'pickup_commission', headerName: 'Pick-Up Commission', sortable: false, minWidth: 105, flex: 1, headerAlign: "center", align: 'center', renderCell: ({row}) => (
            <div className={'whitespace-normal'}>
              {row.pickup_commission || "N/A"}
            </div>
          )
        },
        {
            field: 'revenue', headerName: 'Revenue', minWidth: 100, flex: 1, headerAlign: "center", align: 'center', renderCell: ({row}) => (
              <React.Fragment>
                ${row.revenue || "N/A"}
              </React.Fragment>
            )
          },
          {
            field: 'status', sortable: false, headerName: 'Status', minWidth: 80, flex: 1, headerAlign: "center", align: 'center', renderCell: ({row}) => (
              <React.Fragment>
                <Switch checked={row.status} color="warning" disabled />
              </React.Fragment>
            )
          },
        {
          field: 'Action', headerName: t('Action'), sortable: false, minWidth: 130, flex: 2, headerAlign: "center", align: 'center',
          renderCell: (row) => (
            // <Button className="view-detail-btn" onClick={() => userRole === 'ADMIN' ? navigate('/partners/locations') : navigate('/partners/view-details')}>
            //   {t('View_Details')}
            //   <img src={rightarrow} />
            // </Button>
            <>
                <CustomButton secondary label={'View Analytics'} />
              
            </>
          ),
        },
    ]

   
  return (
    <div>
        <div className='flex font-semibold text-base lg:text-xl space-x-4 mb-11'>
            <div onClick={() => setSelectedTab('registered')} className={`${selectedTab == 'registered' && 'text-primaryColor'} cursor-pointer`}>Registered Restaurants</div>
            <div onClick={() => setSelectedTab('blocked')} className={`${selectedTab == 'blocked' && 'text-primaryColor'} cursor-pointer`} >Blocked Restaurants</div>
        </div>
        <Box className="location-detail">
            <Box className="search-box">
                <TextField id="outlined-basic" variant="outlined" placeholder={t("Search")} InputProps={{
                    startAdornment: (
                        <InputAdornment position="start" sx={{ color: "#000" }}>
                            <SearchIcon />
                        </InputAdornment>
                    )
                }} />
            </Box>
            <Box>
                <Button className="refresh-btn" onClick={() => {}}><img src={refreshImg} />{t("refresh_button")}</Button>
                <Button className="add-partner" onClick={() => navigate('/restaurants/add-restaurant')}>{'Add Restaurant'}</Button>
            </Box>
        </Box>
        <div>
            {/* <DefaultTable 
                headCols={['ID','Restaurant Name', 'Address', 'Phone', 'Email', 'Delivery Commission', 'Pick-Up Commission', 'Revenue', 'Status', 'Actions']}
                data={selectedTab == 'registered' ? mockData : []}
            /> */}
            <CustonDataGrid
                columns={columns}
                rows={mockData}//getPartnersData?.length && getPartnersData}
                getRowId={(row) => row?.id}
                height={300}
                loading={loading}
                rowHeight={70}
                getRowClassName={(param) => {
                    if(param.indexRelativeToCurrentPage % 2 !== 0){
                        return 'bg-secondaryColor'
                    }
                }}
                disableRowSelectionOnClick
                autoHeight
                headerHeight={150}
                sx={{
                    '& .MuiDataGrid-columnHeaderTitle': {
                        textOverflow: "clip",
                        whiteSpace: "break-spaces",
                        lineHeight: 1
                    }
                }}
            />
        </div>
    </div>
  )
}

export default index