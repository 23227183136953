import React, { useEffect, useState } from 'react'
import CustomButton from '../../component/CustomButton'
import { RxPencil1 } from "react-icons/rx";
import { FaSave } from "react-icons/fa";
import { MdOutlineCameraAlt } from "react-icons/md";
import { HiDotsVertical } from "react-icons/hi";
import ImageCropper from '../../component/ImageCropper';
import DefaultInput from '../../component/DefaultInput';
import CustonDataGrid from '../../component/CustomDataGrid';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { t } from "i18next";
import { Button, Menu, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import getLocations from '../../libs/getLocations';
import toast from 'react-hot-toast';
import ReactLoading from "react-loading";
import ConfirmationDialog from '../../component/Common/ConfirmPopup';
import { deleteLocation } from '../../libs/editLocation';




const CompanyTemplate = () => {
    const [companyLogo, setCompanyLogo] = useState()
    const [showImgLogo, setShowImgLogo] = useState(false)
    const [fileDataURL, setFileDataURL] = useState()
    const [locationsList, setLocationsList] = useState([])
    const [loading, setLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [menuOpen, setMenuOpen] = useState(null);
    const [selectedRow,setSelectedRow]=useState(null);
    const ebrizaLinkedId = localStorage.getItem('ebrizaLinkedId');
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const open = Boolean(menuOpen);

    const navigate = useNavigate()

    const handleClickAction = (event, row) => {
      setMenuOpen(event.currentTarget);
      setSelectedRow(row)
    };
    const handleActionClose = () => {
      setMenuOpen(null);
    };
    
    const redirectToAddEdit=(row)=>{
      console.log(`row => `,row)
      navigate('/settings/add-location', {
        state: selectedRow,
      })
    }

    const handleConfirmDelete = async () => {
      setLoading(true)
      setConfirmDialogOpen(false);
      try {
        let res = await deleteLocation(selectedRow.LocationId)
        toast.success('Location Deleted Sucessfully', {
          position: 'top-center',
        })
        const partnerId = localStorage.getItem('partnerId')
        if(partnerId) {
          fetchLocations(partnerId)
        }
      } catch (error) {
        toast.error('An error has occurred while get locations', {
          position: 'bottom-right',
        })
      } finally {
        setLoading(false)
      }
    };

    const handleDelete = () => {
      setMenuOpen(null);
      console.log('handleDelete called with locId:', selectedRow);
      setConfirmDialogOpen(true);
    };

    const fetchLocations = async (id) => {
      setLoading(true)
      try {
        let res = await getLocations(id)
        if(res.success) {
          setLocationsList(res.locations)
        }
      } catch (error) {
        toast.error('An error has occurred while get locations', {
          position: 'bottom-right',
        })
      } finally {
        setLoading(false)
      }
    }

    useEffect(() => {
      const partnerId = localStorage.getItem('partnerId')
      if(partnerId) {
        fetchLocations(partnerId)
      }
    },[])

    const formik = useFormik({
        initialValues: {
            posSystem: '-', 
            companyId: '-', 
            name: '-' ,
            address: '-',
            phone: '-',
            email: '-',
            fax: '-',
            regNo: '-',
            vatNo: '-',
            bank: '-',
            iban:'-',
            website: '-',
            socialCapital: '- RON'
          
        },
        enableReinitialize: false,
        // validationSchema: Yup.object({
        //   location_name: Yup.string().required("Location name is required"),
        //   full_address: Yup.string().required("Full address is required"),
        //   contact: Yup.string().required("Contact is required"),
        //   pos: Yup.string().required("POS system is required"),
        //   location_id: Yup.string().required("Location Id is required"),
        // }),
        onSubmit: (values, { resetForm }) => {
          const payload = {
            
          };
        //   dispatch(AddLocationAction(payload));
          setMsgConfig({
            alertOpen: true,
            message: "Message",
            severity: "Severity",
            autoHideDuration: 2000,
          });
          setIsEdit(false)
        //   resetForm();
        //   handleClose();
        },
      });


      const columns = [
        {
          field: 'LocationId',
          headerName: "LocationId",
          minWidth: 100,
          flex: 1,
          headerAlign: "center",
          headerClassName: 'bg-gray-50',
          align: 'center',
          renderCell: ({ row }) => (
            <div className={'whitespace-normal text-center'}>
              {row.LocationId || "N/A"}
            </div>
          )
        },
        {
          field: 'name',
          headerName: t('name'),
          minWidth: 100,
          flex: 1,
          headerAlign: "center",
          headerClassName: 'bg-gray-50',
          align: 'center',
          renderCell: ({ row }) => (
            <div className={'whitespace-normal text-center'}>
              {row.name || "N/A"}
            </div>
          )
        },
        {
          field: 'address',
          headerName: t('Address'),
          sortable: false,
          minWidth: 150,
          flex: 2,
          headerClassName: 'bg-gray-50',
          headerAlign: "center",
          align: 'center',
          renderCell: ({ row }) => (
            <div className={'whitespace-normal'}>
              {row.address || "N/A"}
            </div>
          )
        },
        {
          field: 'invoicingDetails',
          headerName: t('invoicing_details'),
          sortable: false,
          minWidth: 125,
          flex: 2,
          headerClassName: 'bg-gray-50',
          headerAlign: "center",
          align: 'center',
          renderCell: ({ row }) => (
            <div className={'whitespace-normal'}>
              {`${row.contact_email || row.report_email}, ${row.phone}`}
            </div>
          )
        },
        {
          field: 'Action',
          headerName: '',
          sortable: false,
          minWidth: 20,
          headerClassName: 'bg-gray-50',
          headerAlign: "center",
          align: 'center',
          renderCell: ({ row }) => (
            <>
              <Button
                id={`basic-button_${row.LocationId}`}
                key={`basic-button__${row.LocationId}`}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(event) => handleClickAction(event, row)}
                className='!text-black hover:!bg-transparent'
              >
                <HiDotsVertical />
              </Button>
              <Menu
                sx={{
                  '.MuiPaper-root': {
                    boxShadow: '1px 4px 10px -5px rgba(0,0,0,0.3)'
                  },
                  '& ul': {
                    background: '#ffffff',
                    '& li': {
                      fontSize: '12px !important',
                    },
                  },
                }}
                key={`basic-menu__${row.LocationId}`}
                id={`basic-menu_${row.LocationId}`}
                anchorEl={menuOpen}
                open={open}
                onClose={handleActionClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                key={`menu-item__${row.LocationId}`}
                  id={`menu-item__${row.LocationId}`}
                  sx={{ gap: '5px' }}
                  onClick={() => redirectToAddEdit(row)}
                >
                  Edit
                </MenuItem>


                {
                !ebrizaLinkedId && (
                  <MenuItem
                  key={`menu-item__${row.LocationId}`}
                    id={`menu-item__${row.LocationId}`}
                    sx={{ gap: '5px' }}
                    onClick={() => handleDelete(row)}
                  >
                    Delete
                  </MenuItem>
                )
              }
                
              </Menu>
            </>
          ),
        },
      ];

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            const img = new Image();
            img.onload = async () => {
              console.log(img.naturalWidth, img.naturalHeight, "natural");
                // const uploadedUrl = await commonFunctions.uploadImageToAws(
                //   reader.result
                // );
                // setImage(uploadedUrl);
                // setProfilePic(reader.result)
                setShowImgLogo(true);
                setFileDataURL(reader.result);
              
                // formik.setTouched({ image: true }, true);
            };
            img.src = reader.result;
          };
          reader.readAsDataURL(file);
        }
      };
  return (
    <>
        <div className='rounded-xl border py-10 px-5'>
            <div className='lg:text-4xl text-3xl font-semibold pb-5 text-center border-b'>{t('detalii_companie')}</div>
            <div className='mt-7 lg:grid lg:grid-cols-4'>
                <div className='mx-auto mb-5 lg:mb-0 lg:mr-10'>
                    <div>Company logo</div>
                    <div className='my-5'>
                        {companyLogo ? (
                            <img src={companyLogo} height={224} width={224} />
                        ) : (
                            <div className='bg-primary50 flex items-center justify-center w-56 h-56'>
                                <MdOutlineCameraAlt color='#DEA104' size={94}/>
                            </div>

                        )}
                    </div>
                    <div>
                        <label htmlFor='upload-company-logo'>
                            <CustomButton label={'Upload image'} secondary className={'!w-full justify-center'} />
                        </label>
                        <input
                            type="file"
                            id="upload-company-logo"
                            name="company-logo"
                            accept="image/*"
                            onChange={(e) => handleImageChange(e)}
                            className='opacity-0 -z-10 absolute'
                        />
                        <ImageCropper
                            show={showImgLogo}
                            setShowImageModal={setShowImgLogo}
                            fileDataURL={fileDataURL}
                            //   setCroppedImage={setCroppedImage}
                            setImage={setCompanyLogo}
                            aspectRatio={1/1}
                            resizeable={true}
                        />
                    </div>
                </div>
                <div className='col-span-2'>
                    <div className='text-xl font-semibold mb-5'>Details</div>
                    <div className='grid grid-cols-2 gap-y-3 '>
                        <div>POS System</div><div>{formik.values.posSystem ?? '-'}</div> 
                        <div>Company ID</div>
                        { !isEdit ? <div>{formik.values.companyId ?? '-'}</div> : <DefaultInput inputName={'companyId'} defaultValue={formik.values.companyId} />}
                        <div>Name</div>
                        { !isEdit ? <div>{formik.values.name ?? '-'}</div> : <DefaultInput inputName={'name'} defaultValue={formik.values.name} />}
                        <div>Address</div>
                        { !isEdit ? <div>{formik.values.address ?? '-'}</div> : <DefaultInput inputName={'address'} defaultValue={formik.values.address} />}
                        <div>Phone</div>
                        { !isEdit ? <div>{formik.values.phone ?? '-'}</div> : <DefaultInput inputName={'phone'} defaultValue={formik.values.phone} />}
                        <div>Email</div>
                        { !isEdit ? <div className='line-clamp-1'>{formik.values.email ?? '-'}</div> : <DefaultInput inputName={'email'} defaultValue={formik.values.email} />}
                        <div>FAX</div>
                        { !isEdit ? <div>{formik.values.fax ?? '-'}</div> : <DefaultInput inputName={'fax'} defaultValue={formik.values.fax} />}
                        <div>Reg. No.</div>
                        { !isEdit ? <div>{formik.values.regNo ?? '-'}</div> : <DefaultInput inputName={'regNo'} defaultValue={formik.values.regNo} />}
                        <div>Vat. No.</div>
                        { !isEdit ? <div>{formik.values.vatNo ?? '-'}</div> : <DefaultInput inputName={'vatNo'} defaultValue={formik.values.vatNo} />}
                        <div>Bank</div>
                        { !isEdit ? <div>{formik.values.bank ?? '-'}</div> : <DefaultInput inputName={'bank'} defaultValue={formik.values.bank} />}
                        <div>Iban</div>
                        { !isEdit ? <div>{formik.values.iban ?? '-'}</div> : <DefaultInput inputName={'iban'} defaultValue={formik.values.iban} />}
                        <div>Website</div>
                        { !isEdit ? <div>{formik.values.website ?? '-'}</div> : <DefaultInput inputName={'website'} defaultValue={formik.values.website} />}
                        <div>Social capital</div>
                        { !isEdit ? <div>{formik.values.socialCapital ?? '-'}</div> : <DefaultInput inputName={'socialCapital'} defaultValue={formik.values.socialCapital} />}
                    </div>
                </div>
                <div>
                    <div className='flex space-x-4'>
                        <CustomButton onClickButton={() => setIsEdit(prev => !prev)} secondary label={isEdit ? 'Save' : 'Edit'} startIcon={isEdit ? <FaSave /> : <RxPencil1/>}/>
                        <CustomButton secondary label={'Change password'} />
                    </div>
                </div>
            </div>
        </div>
        {/* VAT group */}
        <div className='rounded-xl border py-10 px-5 mt-3'>
            <div className='lg:text-4xl text-3xl font-semibold pb-5 border-b flex justify-center items-center'>VAT Group <span className=' ml-8 text-xs'><CustomButton label={'Add VAT Group'} secondary /></span></div>
            <div className='mt-5'>
                <div className='grid lg:grid-cols-2 gap-5'>
                    <div className='flex items-center space-x-5'>
                        <DefaultInput 
                            label={'Standard'}
                            defaultValue={'- %'}
                            width='w-60'
                        />
                        <p className='font-semibold text-xs text-red-800'>Remove</p>
                    </div>
                    <div className='flex items-center space-x-5'>
                        <DefaultInput 
                            label={'Books,Pharma,Medical,Hotels'}
                            defaultValue={'- %'}
                            width='w-60'
                        />
                        <p className='font-semibold text-xs text-red-800'>Remove</p>
                    </div>
                    <div className='flex items-center space-x-5'>
                        <DefaultInput 
                            label={'Social Housing'}
                            defaultValue={'- %'}
                            width='w-60'
                        />
                        <p className='font-semibold text-xs text-red-800'>Remove</p>
                    </div>
                    <div className='flex items-center space-x-5'>
                         <DefaultInput 
                            label={'Zero'}
                            defaultValue={'- %'}
                            width='w-60'
                        />
                        <p className='font-semibold text-xs text-red-800'>Remove</p>
                    </div>
                </div>
            </div>
        </div>
        {/* Locations */}
        <ConfirmationDialog
            open={confirmDialogOpen}
            onClose={() => setConfirmDialogOpen(false)}
            onConfirm={handleConfirmDelete}
            title="Confirm Delete"
            message="Are you sure you want to delete this Location?"
          />
        <div className='rounded-xl border py-10 px-5 mt-3'>
            <div className='lg:text-4xl text-3xl font-semibold pb-5 border-b flex justify-center items-center mb-5'>{t("locations_title")} 
              
              {
                !ebrizaLinkedId && (
                <span className=' ml-8 text-xs'>
                  <CustomButton onClickButton={() => navigate('/settings/add-location')} label={t('add')} secondary />
                </span>
                )
              }
              
                
              </div>
            {loading ? <ReactLoading type={"spin"} height="50px" width="50px" color="black" className='mx-auto py-8' />
              :
              locationsList.length > 0 ? (
                  <CustonDataGrid
                      columns={columns}
                      rows={locationsList?.length > 0 && locationsList}
                      // getRowId={(row) => row?.id}
                      height={300}
                      // loading={loading}
                      rowHeight={80}
                      // getRowClassName={(param) => {
                      //     if(param.indexRelativeToCurrentPage % 2 !== 0){
                      //         return 'bg-secondaryColor'
                      //     }
                      // }}
                      sx={{
                          '.MuiDataGrid-columnSeparator': {
                              display: 'none',
                          },
                          '.MuiDataGrid-columnHeader--withRightBorder': {
                              borderRightWidth: 0
                          },
                          '.MuiDataGrid-cell--withRightBorder': {
                              borderRightWidth: 0
                          }
                      }}
                      disableRowSelectionOnClick
                  />
                ) : (
                  <div className='text-xl text-center py-8 text-gray-400'>{t('no_data')}</div>
                )
            }
        </div>
    </>
  )
}

export default CompanyTemplate