import { put, call } from "redux-saga/effects";
import axios from "../../axiosConfig";
import { TOAST_SHOW } from "../Actions/ToastAction";
import { ADD_CONFIGURATIONS_FAILED, ADD_CONFIGURATIONS_SUCCESS, ADD_MENU_CATEGORY_FAILED, ADD_MENU_CATEGORY_SUCCESS, ADD_MENU_FAILED, ADD_MENU_MODIFIER_FAILED, ADD_MENU_MODIFIER_SUCCESS, ADD_MENU_PRODUCT_FAILED, ADD_MENU_PRODUCT_SUCCESS, ADD_MENU_SUCCESS, GET_NUTRI_AND_CALC_FAILED, GET_NUTRI_AND_CALC_SUCCESS, ADD_REVIEWS_FAILED, ADD_REVIEWS_SUCCESS, GET_CONFIGURATIONS_FAILED, GET_CONFIGURATIONS_SUCCESS, GET_LOCATION_FAILED, GET_LOCATION_SUCCESS, GET_REVIEWS_FAILED, GET_REVIEWS_SUCCESS, ADD_LOCATION_SUCCESS, ADD_LOCATION_FAILED, EDIT_IMAGE_SUCCESS, EDIT_IMAGE_FAILED, LOADING_TRUE } from "../Actions/LocationActions";
import { useNavigate } from "react-router-dom";

export function* GetLocationSaga() {
  try {
    const response = yield call(axios.get, `/partners/locations`);
    const { locations } = response?.data;
    yield put({ type: GET_LOCATION_SUCCESS, payload: { locations } });
  } catch (e) {
    yield put({ type: GET_LOCATION_FAILED });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: e.response.data.message,
        severity: "error",
      },
    });
  }
}
export function* AddLocationSaga(action) {
  try {
    const response = yield call(axios.post, `/partners/locations/detail`, action.payload);
    const { success } = response?.data;
    const message = success && 'Location details added Successfully !';
    yield put({ type: ADD_LOCATION_SUCCESS, payload: { message } });
    yield put({
      type: TOAST_SHOW,
      payload: { message: 'Location details added Successfully !', severity: "success" },
    });
  } catch (e) {
    const message = e.response.data.message
    yield put({ type: ADD_LOCATION_FAILED, payload: { message } });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: e.response.data.message,
        severity: "error",
      },
    });
  }
}
export function* EditImageSaga(action) {
  try {
    console.log(action,'action result =================================================================');
    const response = yield call(axios.post, `/partners/locations/detail/editimg`, action.payload);
    console.log(response,'this response');
    const { success } = response?.data;
    const message = success && 'Image updated Successfully !';
    yield put({ type: EDIT_IMAGE_SUCCESS, payload: { message } });
    yield put({
      type: TOAST_SHOW,
      payload: { message: 'Image updated Successfully !', severity: "success" },
    });
  } catch (e) {
    const message = e.response.data.message
    yield put({ type: EDIT_IMAGE_FAILED, payload: { message } });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: e.response.data.message,
        severity: "error",
      },
    });
  }
}
export function* AddMenuSaga(action) {
  try {
    const response = yield call(axios.post, `/partners/locations/menu`, action.payload);
    const { message } = response?.data;
    yield put({ type: ADD_MENU_SUCCESS, payload: { message } });
    yield put({
      type: TOAST_SHOW,
      payload: { message: 'Menu added Successfully !', severity: "success" },
    });
  } catch (e) {
    const message = e.response.data.message
    yield put({ type: ADD_MENU_FAILED, payload: { message } });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: e.response.data.message,
        severity: "error",
      },
    });
  }
}
export function* AddMenuCategorySaga(action) {
  try {
    const response = yield call(axios.post, `/partners/locations/menu/addcategory`, action.payload);
    const { success } = response?.data;
    const message = success && 'Category added Successfully !';
    yield put({ type: ADD_MENU_CATEGORY_SUCCESS, payload: { message } });
    yield put({
      type: TOAST_SHOW,
      payload: { message: 'Category added Successfully !', severity: "success" },
    });
  } catch (e) {
    const message = e.response.data.message
    yield put({ type: ADD_MENU_CATEGORY_FAILED, payload: { message } });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: e.response.data.message,
        severity: "error",
      },
    });
  }
}
export function* AddMenuProductSaga(action) {
 
  try {
    const response = yield call(axios.post, `/partners/menu_products`, action.payload.payload);
    const { message } = response?.data;
    yield put({ type: ADD_MENU_PRODUCT_SUCCESS, payload: { message } });
    yield put({
      type: TOAST_SHOW,
      payload: { message: 'Product added Successfully !', severity: "success" },
    });
    action.payload.handleNavigateToConf(response?.data)
    console.log("AddMenuProductSaga..Added");
  } catch (e) {
    const message = e.response.data.message
    console.log("AddMenuProductSaga..Error",e.message);
    yield put({ type: ADD_MENU_PRODUCT_FAILED, payload: { message } });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: e.response.data.message,
        severity: "error",
      },
    });
  }
}
export function* AddMenumodifiertSaga(action) {
  try {
    const response = yield call(axios.post, `/partners/locations/menu/addmodifier`, action.payload);
    const { message } = response?.data;
    yield put({ type: ADD_MENU_MODIFIER_SUCCESS, payload: { message } });
    yield put({
      type: TOAST_SHOW,
      payload: { message: 'Modifier added Successfully !', severity: "success" },
    });
  } catch (e) {
    const message = e.response.data.message
    yield put({ type: ADD_MENU_MODIFIER_FAILED, payload: { message } });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: e.response.data.message,
        severity: "error",
      },
    });
  }
}
export function* GetNutriAndCalcSaga() {
  try {
    const response = yield call(axios.get, `/partners/locations/menu/nutr_values_calc`);
    const { ingredients } = response?.data;
    yield put({ type: GET_NUTRI_AND_CALC_SUCCESS, payload: { ingredients } });
  } catch (e) {
    const message = e.response.data.message
    yield put({ type: GET_NUTRI_AND_CALC_FAILED, payload: { message } });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: message,
        severity: "error",
      },
    });
  }
}
export function* AddReviewsSaga(action) {
  try {
    const response = yield call(axios.post, `/partners/locations/rating_reviews`, action.payload);
    const { message } = response?.data;
    yield put({ type: ADD_REVIEWS_SUCCESS, payload: { message } });
    yield put({
      type: TOAST_SHOW,
      payload: { message: 'Review added Successfully !', severity: "success" },
    });
  } catch (e) {
    const message = e.response.data.message
    yield put({ type: ADD_REVIEWS_FAILED, payload: { message } });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: e.response.data.message,
        severity: "error",
      },
    });
  }
}
export function* GetReviewsSaga() {
  try {
    const response = yield call(axios.get, `/partners/locations/rating_reviews`);
    const { reviews } = response?.data;
    yield put({ type: GET_REVIEWS_SUCCESS, payload: { reviews } });
  } catch (e) {
    yield put({ type: GET_REVIEWS_FAILED });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: e.response.data.message,
        severity: "error",
      },
    });
  }
}
export function* AddConfigurationstSaga(action) {
  try {
    const response = yield call(axios.post, `/partners/locations/configuration`, action.payload);
    const { message } = response?.data;
    yield put({ type: ADD_CONFIGURATIONS_SUCCESS, payload: { message } });
    yield put({
      type: TOAST_SHOW,
      payload: { message: 'Configurations added Successfully !', severity: "success" },
    });
  } catch (e) {
    const message = e.response.data.message
    yield put({ type: ADD_CONFIGURATIONS_FAILED, payload: { message } });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: e.response.data.message,
        severity: "error",
      },
    });
  }
}
export function* GetConfigurationstSaga(action) {
    yield put({type: LOADING_TRUE })
  try {
    const response = yield call(axios.get, `/admins/room_table_config?ClientId=${action.payload.ClientId}&LocationId=${action.payload.LocationId}&type=${action.payload.type}`);
    const { rooms } = response?.data;
    yield put({ type: GET_CONFIGURATIONS_SUCCESS, payload: [{ 
      'rooms': rooms[0]['room_table_config'].rooms
     }] });
  } catch (e) {
    const message = e.response.data.message
    yield put({ type: GET_CONFIGURATIONS_FAILED, payload: { message } });
    return yield put({
      type: TOAST_SHOW,
      payload: {
        message: e.response.data.message,
        severity: "error",
      },
    });
  }
}