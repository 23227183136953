import React, { useState, useMemo, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Pagination,Button } from "@mui/material";

export default function OrderListReport({ payload, onPageChange,page,downloadReport }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [ordersData, setOrdersData] = useState([]);
    // Columns definition
    const columns = useMemo(
        () => [
            { field: "orderNo", headerName: "ORDER NO", minWidth: 150, flex: 1 },
            { field: "order_date_time", headerName: "ORDER DATE AND TIME", minWidth: 150, flex: 1 },
            { field: "totalProductSum", headerName: "ORDER TOTAL SUM", minWidth: 150, flex: 1 },
            { field: "tips", headerName: "TIPS", minWidth: 150, flex: 1 },
            { field: "paymentMethod", headerName: "CASH/ONLINE", minWidth: 150, flex: 1 },
            // { field: "table_pickup_delivery", headerName: "TABLE, PICKUP/DELIVERY", minWidth: 150, flex: 1 },
            { field: "tableIds", headerName: "TABLE ID", minWidth: 150, flex: 1 },
        ],
        []
    );



    useEffect(()=>{
        if(payload && payload.orders){
            payload.orders.map((ele)=>{
                ele['order_date_time'] = new Date(ele['order_date_time']).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                }) + ' ' + new Date(ele['order_date_time']).toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                });
        
                // Round off totalProductSum to 2 decimal places
                ele['totalProductSum'] = parseFloat(ele['totalProductSum']).toFixed(2);
            })
            setOrdersData(payload.orders);
                setCurrentPage(page)
            
            if(payload.totalPages != null){
                setTotalPages(payload.totalPages)
            }
        }
    },[payload])

    // Handling page change
    const handlePageChange = (event, newPage) => {
        console.log("page change clicked...", newPage);
        setCurrentPage(newPage)
        onPageChange(newPage);
    };

    return (
        <>
           <Button onClick={downloadReport} color="primary">
                    Download Excel
                </Button>
        <div style={{ height: 600, width: "100%" }}>
            <DataGrid
                rows={ordersData}
                columns={columns}
                hideFooter={true} 
            />
             <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                className="pagination"
                style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
                />
        </div>
        </>
    );
}
