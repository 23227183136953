import React, { useState } from 'react';
import './style.css';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, List, ListItem, Rating, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StarIcon from '@mui/icons-material/Star';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import ReviewDetailsModal from '../../page/Locations/LocationDetails/ReviewDetailsModal';
import { useDispatch, useSelector } from 'react-redux';
// import { useErrorHandler } from '../../hooks';
import { useEffect } from 'react';
import ReactLoading from "react-loading";
import { GetReviewstAction } from '../../Redux/Actions/LocationActions';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    invertedAccordion: {
        transform: 'rotate(180deg) !important',
    },
    invertedAccordionSummary: {
        transform: 'rotate(180deg)',
    },
}));
const ReviewCard = () => {
    const dispatch = useDispatch();
    const { loading, reviewData } = useSelector((state) => state.Location);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [selectedReview, setSelectedReview] = useState([]);
    const [reviewOpen, setReviewOpen] = useState(false);
    const classes = useStyles();
    const handleClose = () => setReviewOpen(false);
    useEffect(() => {
        dispatch(GetReviewstAction())
    }, [GetReviewstAction])
    return (
        <>
            <ReviewDetailsModal reviewOpen={reviewOpen} handleClose={handleClose} reviewDetail={selectedReview} />
            {loading ?
                <Box sx={{ position: 'absolute', top: '60%', left: '60%', transform: 'translate(-60%,-60%)' }}>
                    <ReactLoading
                        type={"spokes"}
                        height="45px"
                        width="45px"
                        color="#FFB800"
                    />
                </Box> :
                <>
                    <Grid container spacing={2}>
                        {reviewData.length > 0 ?
                            reviewData.map((reviews, index) =>
                                <Grid item lg={6} md={6} sm={6} xs={12} key={index}>
                                    <Box className='review-card'>
                                        <Box className="review-card-detail">
                                            <Box className='user-review-profile' onClick={() => { setReviewOpen(true); setSelectedReview(reviews) }}>
                                                <AccountCircleIcon />
                                            </Box>
                                            <Box>
                                                <Typography>{moment(reviews?.reviewDate).format('MMM D, YYYY')}</Typography>
                                                <List className='review-star'>
                                                    <Rating
                                                        name="hover-feedback"
                                                        value={reviews?.reviewRating}
                                                        precision={0.5}
                                                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                    />
                                                    {reviews?.reviewRating !== null && (
                                                        <Box sx={{ ml: 2 }}>{`${Number(reviews?.reviewRating)}/5`}</Box >
                                                    )}
                                                </List>
                                                <Accordion className={classes.invertedAccordion} onChange={() => setIsAccordionOpen(!isAccordionOpen)}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className={classes.invertedAccordion}
                                                    >
                                                        <Typography>{!isAccordionOpen ? 'View review' : 'Show less'}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails className={classes.invertedAccordionSummary}>
                                                        <Typography>
                                                            {reviews?.reviewText}
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            ) :
                            <Typography sx={{ margin: '0 auto', paddingTop: '15px' }}>No data found</Typography>}
                        {/* <Box className="show-more-btn">
                            <Button>Show more</Button>
                        </Box> */}
                    </Grid >
                </>
            }
        </>
    );
};

export default ReviewCard;
