import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  List,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import "react-datepicker/dist/react-datepicker.css";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import crown from "../../../assets/img/crown.png";
import crowns from "../../../assets/img/crown-small.png";
import DeleteModal from "./DeleteMenu";
import AddCategory from "./AddCategory";
import AddProduct from "./AddProduct";
import { t } from "i18next";
import AddLocationModel from "./AddLocationModel";

const MenuTab = () => {
  const [age, setAge] = useState(10);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [productOpen, setProductOpen] = useState(false);
  const [locationModelOpen, setLocationModelOpen] = useState(false);
  const freemiumUser = false;
  const handleSelect = (event) => {
    setAge(event.target.value);
  };
  const handleLocation = () => {
    setLocationModelOpen(false);
  };
  const handleDelete = () => {
    setDeleteOpen(false);
  };
  const handleCategoryClose = () => {
    setCategoryOpen(false);
  };
  const handleProductClose = () => {
    setProductOpen(false);
  };

  return (
    <>
      <DeleteModal deleteOpen={deleteOpen} handleClose={handleDelete} />
      <AddCategory
        categoryOpen={categoryOpen}
        handleClose={handleCategoryClose}
      />
      <AddProduct productOpen={productOpen} handleClose={handleProductClose} />
      <AddLocationModel
        locationModelOpen={locationModelOpen}
        handleClose={handleLocation}
      />
      <Box className="menu-option-detail">
        <FormControl>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            onChange={handleSelect}
          >
            <MenuItem value={10} selected>
              Ten
            </MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>
        <Box className="menu-list">
          <List>
            <ListItem onClick={() => setLocationModelOpen(true)}>
              <AddIcon /> {t("new_detail")}
            </ListItem>
            <ListItem>
              <EditIcon />
              {t("edit_detail")}{" "}
            </ListItem>
            <ListItem onClick={() => setDeleteOpen(true)}>
              <DeleteIcon /> Remove
            </ListItem>
          </List>
          <Button className="sync-btn">
            <AutorenewIcon />
            {t("sync_btn")}
          </Button>
        </Box>
      </Box>
      <Box className={freemiumUser && "categories-list-main"}>
        {freemiumUser && (
          <Box className="premium-modal">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img src={crown} />
              <Typography variant="h5">Upgrade to premium</Typography>
            </Box>
            <Typography>
              Contact support to add and edit your menu with out a limit Lorem
              ipsum dolor sit
            </Typography>
            <Button className="premium-btn">
              <img src={crowns} /> Upgrade to premium
            </Button>
          </Box>
        )}
        <Box className="categories-list">
          <Typography>{t("categories_detail")}</Typography>
          <Grid container spacing={2}>
            <Grid item lg={3} md={4} sm={4} xs={12}>
              <Box
                className="categories-box categories-box-active"
                onClick={() => setCategoryOpen(true)}
              >
                <AddIcon />
                <Typography>{t("new_category")}</Typography>
              </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={4} xs={12}>
              <Box
                className="categories-box categories-box-active"
                onClick={() => setProductOpen(true)}
              >
                <AddIcon />
                <Typography>{t("new_product")}</Typography>
              </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={4} xs={12}>
              <Box className="categories-box"></Box>
            </Grid>
            <Grid item lg={3} md={4} sm={4} xs={12}>
              <Box className="categories-box"></Box>
            </Grid>
            <Grid item lg={3} md={4} sm={4} xs={12}>
              <Box className="categories-box"></Box>
            </Grid>
            <Grid item lg={3} md={4} sm={4} xs={12}>
              <Box className="categories-box"></Box>
            </Grid>
            <Grid item lg={3} md={4} sm={4} xs={12}>
              <Box className="categories-box"></Box>
            </Grid>
            <Grid item lg={3} md={4} sm={4} xs={12}>
              <Box className="categories-box"></Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className="location-detail-btn">
        <Button className="cancel-btn">{t("cancel_btn")}</Button>
        <Button className="submit-btn">{t("save_btn")}</Button>
      </Box>
    </>
  );
};

export default MenuTab;
