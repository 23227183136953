import React, { useEffect } from 'react'
import { AiOutlineClose } from "react-icons/ai";


const SmallModal = ({children, showModal = false, title, closeModal = () => {}}) => {

    useEffect(() => {
        if (showModal) {
            document.body.classList.add('overflow-hidden');
          } else {
            document.body.classList.remove('overflow-hidden');
          }
    }, [showModal])

  return (
    <div className={`${!showModal && 'hidden'} left-0 top-0 h-full w-full bg-opacity-60 bg-black fixed z-30 flex justify-center items-center`}>
        <div className='bg-white rounded-lg p-6 w-max h-max'>
            <div onClick={closeModal} className='flex justify-end'><AiOutlineClose/></div>
            <div className='font-semibold text-center text-xl mb-5'>{title}</div>
            {children}
        </div>
    </div>
  )
}

export default SmallModal