import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';

const DeleteModal = ({ handleClose, deleteOpen }) => {
    return (
        <>
            <Modal
                open={deleteOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='modal-body delete-modal'>
                    <Box className='warning-icon'>
                        <Box className='warning-icon-box'>
                            <WarningIcon />
                        </Box>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Delete Menu?
                        </Typography>
                        <Box className='close-icon' onClick={handleClose}>
                            <CloseIcon />
                        </Box>
                    </Box>
                    <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis non congue dui. Suspendisse mollis cursus libero ut pharetra. In quis elit ut sem tincidunt
                    </Typography>
                    <Box className='modal-btn'>
                        <Button className="cancel-btn" onClick={handleClose}>Cancel</Button>
                        <Button className="submit-btn">Delete</Button>
                    </Box>
                </Box>
            </Modal >

        </>
    )

}
export default DeleteModal;