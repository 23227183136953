import React, { useEffect, useState } from 'react'
import { Box, List, ListItem } from '@mui/material'
import restaurant from '../../assets/img/restaurant-icon.svg'
import selRestaurant from '../../assets/img/sel-resIcon.svg'
import speed from '../../assets/img/dashboard.svg'
import selectedDashboard from '../../assets/img/selected-dashboard.svg'
import adbanner from '../../assets/img/banners-icon.svg'
import pos from '../../assets/img/pos.svg'
import selPos from '../../assets/img/pos-selected.svg'
import qrcode from '../../assets/img/qrcode-icon.svg'
import user from '../../assets/img/users-icon.svg'
import order from '../../assets/img/orders-icon.svg'
import selOrder from '../../assets/img/sel-orderIcon.svg'
import menu from '../../assets/img/menu-icon.svg'
import conf from '../../assets/img/configuration.svg'
import selConf from '../../assets/img/sel-configuration.svg'
import rep from '../../assets/img/reports.svg'
import selRep from '../../assets/img/sel-reports.svg'
import mar from '../../assets/img/market.svg'
import selMar from '../../assets/img/sel-market.svg'
import LogoutIcon from '@mui/icons-material/Logout';
import settingsIcon from '../../assets/img/settings.svg'
import selectedSettingsIcon from '../../assets/img/selected-settings.svg'
import './style.css'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useErrorHandler } from '../../hooks'
import { LogoutAction } from '../../Redux/Actions/AuthActions'
import { useDispatch } from 'react-redux'
import { LuBarChart3 } from "react-icons/lu";


const Sidebar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setMsgConfig } = useErrorHandler();
  const userRole = localStorage.getItem('userRole');
  const [selectedTab, setSelectedTab] = useState(window.location.pathname);

  useEffect(() => {
    const handleLocationChange = () => {
      setSelectedTab(window.location.pathname);
    };
    window.addEventListener("popstate", handleLocationChange);
    return () => {
      window.removeEventListener("popstate", handleLocationChange);
    };
  }, []);

  const partnersSidebarItems = [
    {
      label: "Dashboard",
      image: speed,
      selectedImage: selectedDashboard,
      path: "/",
    },
    {
      label: "Configuration",
      image: conf,
      selectedImage: selConf,
      path: "/configuration",
    },
    // {
    //   label: "Orders",
    //   image: order,
    //   selectedImage:selOrder,
    //   path: "/orders",
    // },
    // {
    //   label: "Locations",
    //   image: restaurant,
    //   selectedImage:selRestaurant,
    //   path: "/locations",
    // },
    // {
    //   label: "POS",
    //   image: pos,
    //   selectedImage:selPos,
    //   path: "/pos",
    // },
    {
      label: "Reports",
      image: rep,
      selectedImage:selRep,
      path: "/reports",
    },
    // {
    //   label: "Marketplace",
    //   image: mar,
    //   selectedImage:selMar,
    //   path: "/marketplace",
    // },
    
    {
      label: "Settings",
      image: settingsIcon,
      selectedImage: selectedSettingsIcon,
      path: "/settings",
    },
    {
      label: "Logout",
      image: qrcode,
    },
  ];
  const adminsSidebarItems = [
    {
      label: "Dashboard",
      image: speed,
      path: "/",
    },
    {
      label: "Partners",
      image: restaurant,
      path: "/partners",
    },
    {
      label: "Users",
      image: user,
      path: "/users",
    },
    {
      label: "Logout",
      image: qrcode,
    },
  ];
  const userSidebarItems = [
    {
      label: "Dashboard",
      image: speed,
      path: "/",
    },
    {
      label: "Locations",
      image: restaurant,
      path: "/locations",
    },
    {
      label: "Orders",
      image: order,
      path: "/orders",
    },
    {
      label: "Logout",
      image: qrcode,
    },
  ];

  const handleTab = (item) => {
    setSelectedTab(item?.label);
    navigate(item?.path);
  };

  useEffect(() => {
    if (selectedTab === "") {
      if (userRole === 'ADMIN') {
        handleTab(adminsSidebarItems[0]);
      } else {
        handleTab(partnersSidebarItems[0]);
      }
    }
  }, []);

  const handleLogout = () => {
    // localStorage.clear();
    dispatch(LogoutAction());
    setMsgConfig({
      alertOpen: true,
      message: "Message",
      severity: "Severity",
      autoHideDuration: 2000,
    });
    localStorage.clear()
    navigate("/login");
  };
  return (
    <>
      <Box className={`sidebar-wrap`}>
        <List>
          {userRole === 'ADMIN' ?
            adminsSidebarItems.map((item, index) => (
              <Box
                className={
                  item?.label === selectedTab || item?.path === selectedTab
                    ? `sidebar-menu-active` : 'sidebar-menu'
                }
                onClick={() => item?.label === 'Logout' ? handleLogout() : handleTab(item)}
              >
                <ListItem
                  key={index}
                  href="#"
                >
                  <span>{item?.label === 'Logout' ? <LogoutIcon /> : <img src={item?.image} />}</span>
                  {/* {item?.label} */}
                  {t(item?.label)}
                </ListItem>
              </Box>
            )) :
            userRole === 'PARTNER' ? partnersSidebarItems.map((item, index) => (
              <Box
                className={
                  item?.label === selectedTab || item?.path === selectedTab
                    ? `sidebar-menu-active` : 'sidebar-menu'
                }
                onClick={() => item?.label === 'Logout' ? handleLogout() : handleTab(item)}
              >
                <ListItem
                  key={index}
                  href="#"
                >
                  <span>{item?.label === 'Logout' ? <LogoutIcon /> : (item?.label === selectedTab || item?.path === selectedTab) ? <img src={item?.selectedImage} /> : <img src={item?.image} />}</span>
                  {/* {item?.label} */}
                  {t(item?.label)}
                </ListItem>
              </Box>
            )) : userSidebarItems.map((item, index) => (
              <Box
                className={
                  item?.label === selectedTab || item?.path === selectedTab
                    ? `sidebar-menu-active` : 'sidebar-menu'
                }
                onClick={() => item?.label === 'Logout' ? handleLogout() : handleTab(item)}
              >
                <ListItem
                  key={index}
                  href="#"
                >
                  <span>{item?.label === 'Logout' ? <LogoutIcon /> : <img src={item?.image} />}</span>
                  {/* {item?.label} */}
                  {t(item?.label)}
                </ListItem>
              </Box>
            ))}
        </List>
      </Box>
    </>
  )
}

export default Sidebar