export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAILED = "ADMIN_LOGIN_FAILED";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

export const RESET_LINK_REQUEST = "RESET_LINK_REQUEST";
export const RESET_LINK_SUCCESS = "RESET_LINK_SUCCESS";
export const RESET_LINK_FAILED = "RESET_LINK_FAILED";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

export const LoginAction = (data) => {
  return { type: LOGIN_REQUEST, payload: data };
};

export const AdminLoginAction = (data) => {
  return { type: ADMIN_LOGIN_REQUEST, payload: data };
};

export const ResetLinkAction = (data) => {
  return { type: RESET_LINK_REQUEST, payload: data };
};

export const ChangePasswordAction = (data) => {
  return { type: CHANGE_PASSWORD_REQUEST, payload: data };
};

export const LogoutAction = () => {
  return { type: LOGOUT };
};
