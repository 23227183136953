import React from 'react'
import CustomButton from '../../component/CustomButton'
import { useNavigate } from 'react-router-dom'
import FormAddRestaurant from './FormAddRestaurant'

const AddRestaurant = () => {
    const navigate = useNavigate()
  return (
    <div>
        <div className='flex justify-between items-center'>
            <div className='text-2xl font-semibold'>Restaurant Registration</div>
            <CustomButton onClickButton={() => navigate(-1)} backButton label={'Back'} />
        </div>
        <div className='mt-5'>
            <FormAddRestaurant />
        </div>
    </div>
  )
}

export default AddRestaurant