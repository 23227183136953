import React, { useState } from 'react'
import CustomButton from '../../component/CustomButton'
import DefaultProfile from '../../assets/img/default-profile-pic.png'
import { useFormik } from 'formik'
import { useErrorHandler } from '../../hooks'
import commonFunctions from "../../common/functions";
import ImageCropper from '../../component/ImageCropper'
import { useLocation, useNavigate } from 'react-router-dom'


const AddDriver = () => {
    const location = useLocation();
    const selectedDriver = location.state
    const navigate = useNavigate()
    const [profilePic, setProfilePic] = useState(DefaultProfile)
    const { setMsgConfig } = useErrorHandler();
    const [showImageModal, setShowImageModal] = useState(false)
    const [fileDataURL, setFileDataURL] = useState(null);
    const formik = useFormik({
        initialValues: {
          name: selectedDriver?.name || "",
          email: selectedDriver?.email || "",
          phone: selectedDriver?.phone || "",
          occupation: selectedDriver?.occupation|| "",
          id: selectedDriver?.id || "",
          commission: selectedDriver?.commission || "",
          access: selectedDriver?.restaurant || "All Restaurant",
          
        },
        enableReinitialize: false,
        // validationSchema: Yup.object({
        //   location_name: Yup.string().required("Location name is required"),
        //   full_address: Yup.string().required("Full address is required"),
        //   contact: Yup.string().required("Contact is required"),
        //   pos: Yup.string().required("POS system is required"),
        //   location_id: Yup.string().required("Location Id is required"),
        // }),
        onSubmit: (values, { resetForm }) => {
          const payload = {
            name: formik.values.name,
            id: formik.values.id,
            email: formik.values.email,
            phone: formik.values.phone,
            occupation: formik.values.occupation,
            commission: formik.values.commission,
            access: formik.values.access,
            image: profilePic,
          };
        //   dispatch(AddLocationAction(payload));
          setMsgConfig({
            alertOpen: true,
            message: "Message",
            severity: "Severity",
            autoHideDuration: 2000,
          });
          resetForm();
        //   handleClose();
          navigate('/drivers')
        },
      });

    const handleDriverAccess = (e) => {
        formik.setFieldValue('access', e.target.value)
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            const img = new Image();
            img.onload = async () => {
              console.log(img.naturalWidth, img.naturalHeight, "natural");
                // const uploadedUrl = await commonFunctions.uploadImageToAws(
                //   reader.result
                // );
                // setImage(uploadedUrl);
                // setProfilePic(reader.result)
                setShowImageModal(true);
                setFileDataURL(reader.result);
                // formik.setTouched({ image: true }, true);
            };
            img.src = reader.result;
          };
          reader.readAsDataURL(file);
        }
      };
  return (
    <div>
        <ImageCropper
            show={showImageModal}
            setShowImageModal={setShowImageModal}
            fileDataURL={fileDataURL}
            //   setCroppedImage={setCroppedImage}
            setImage={setProfilePic}
            aspectRatio={1/1}
        />
        <CustomButton onClickButton={() => navigate(-1)} backButton label={'Back'} />
        <div className='mt-5 px-5 py-12 bg-secondaryColor rounded-lg drop-shadow-sm'>
            <div className='flex justify-center'>
                <div className='text-center'>
                    <img className='rounded-full border-2 border-black' src={profilePic} width={180} height={180} />
                    <label htmlFor='upload-photo' onClick={() => {}} className='text-primaryColor mt-2 cursor-pointer'>Add Profile Picture</label>
                    <input
                    type="file"
                    id="upload-photo"
                    name="photo"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e)}
                    className='opacity-0 -z-10 absolute'
                  />
                </div>
            </div>
            <div className='mt-4'>
                <div>
                    <div className='text-xl font-semibold'>Driver Access</div>
                    <div className='flex mt-3'>
                        <div class="flex items-center mr-4">
                            <input onChange={handleDriverAccess} checked={formik.values.access == 'All Restaurant'} id="default-radio-1" type="radio" value="All Restaurant" name="default-radio" className="w-4 h-4 accent-primaryColor " />
                            <label for="default-radio-1" className="ms-2 text-sm font-medium text-gray-900 ">All Restaurant</label>
                        </div>
                        <div className="flex items-center">
                            <input onChange={handleDriverAccess} checked={formik.values.access == 'Choose Restaurant'} id="default-radio-2" type="radio" value="Choose Restaurant" name="default-radio" className="w-4 h-4 accent-primaryColor" />
                            <label for="default-radio-2" className="ms-2 text-sm font-medium text-gray-900 ">Choose Restaurant</label>
                        </div>
                    </div>
                </div>
                <div className='grid lg:grid-cols-2 lg:gap-x-64 gap-y-5 mt-5'>
                    <div>
                        <div className=' font-semibold'>
                            Name
                        </div>
                        <input name='name' value={formik.values.name} onChange={formik.handleChange} className='h-10 p-3 rounded-lg drop-shadow-sm w-full ' />
                    </div>
                    <div>
                        <div className=' font-semibold'>
                            Email
                        </div>
                        <input name='email' value={formik.values.email} onChange={formik.handleChange} className='h-10 p-3 rounded-lg drop-shadow-sm w-full ' />
                    </div>
                    <div>
                        <div className=' font-semibold'>
                            Occupation
                        </div>
                        <input name='occupation' value={formik.values.occupation} onChange={formik.handleChange} className='h-10 p-3 rounded-lg drop-shadow-sm w-full ' />
                    </div>
                    <div>
                        <div className=' font-semibold'>
                            Phone Number
                        </div>
                        <input name='phone' value={formik.values.phone} onChange={formik.handleChange} className='h-10 p-3 rounded-lg drop-shadow-sm w-full ' />
                    </div>
                    <div>
                        <div className=' font-semibold'>
                            ID
                        </div>
                        <input name='id' value={formik.values.id} onChange={formik.handleChange} className='h-10 p-3 rounded-lg drop-shadow-sm w-full ' />
                    </div>
                    <div>
                        <div className=' font-semibold'>
                            Commission (%)
                        </div>
                        <input name='commission' value={formik.values.commission} onChange={formik.handleChange} className='h-10 p-3 rounded-lg drop-shadow-sm w-full ' />
                    </div>
                    <CustomButton onClickButton={formik.submitForm} label={'Save'} className={'px-14'} />
                </div>
            </div>
        </div>
    </div>
  )
}

export default AddDriver