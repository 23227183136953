import React, { useState, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const SearchComponent = ({ items, onSearchChange }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    onSearchChange(term);
  };

  return (
    <div>
      <TextField
        variant="outlined"
        placeholder="Search report"
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          sx: {
            height: '42px', // Adjust the height of the search box
          },
        }}
        fullWidth
        sx={{ mb: 2 }} // Add margin-bottom for spacing
      />
    </div>
  );
};

export default SearchComponent;
