import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, InputAdornment, Switch, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import refreshImg from '../../assets/img/refresh-icon.png';
import food from '../../assets/img/food-img.png';
import './style.css'
import { useDispatch, useSelector } from 'react-redux';
import { GetLocationAction } from '../../Redux/Actions/LocationActions';
import AddLocation from './AddLocation';

const Locations = () => {
    const mockData = [
        {
            name: "Adhoc Bar",
            address: 'str Izlazului 10, Cluj-Napoca, Cluj, 400609',
            email: 'test@mail.com',
            base_delivery_charge: '10',
            delivery_mode: 'delivery',
            delivery_commission: '5%',
            min_order: '100',
            license_number: 'qwerty88',
            delivery_charge: '5%',
            pickup_commission: '0%',
            phone: '+40 747 999 666',
            LocationId: '03903467-98d3-4188-9d9c-8078d970adc3',
            autoOrders: true,
            onlinePayment: true,
            delivery_radius: '10km',
            openHours: [
                {
                  "closingHour": "23:59",
                  "active": true,
                  "openingHour": "10:00",
                  "day": "luni"
                },
                {
                  "closingHour": "23:59",
                  "active": false,
                  "openingHour": "09:00",
                  "day": "marți"
                },
                {
                  "closingHour": "23:00",
                  "active": true,
                  "openingHour": "10:00",
                  "day": "miercuri"
                },
                {
                  "closingHour": "23:59",
                  "active": true,
                  "openingHour": "09:00",
                  "day": "joi"
                },
                {
                  "closingHour": "23:00",
                  "active": true,
                  "openingHour": "09:00",
                  "day": "vineri"
                },
                {
                  "closingHour": "23:00",
                  "active": true,
                  "openingHour": "09:00",
                  "day": "sâmbătă"
                },
                {
                  "closingHour": "22:00",
                  "active": true,
                  "openingHour": "11:00",
                  "day": "duminică"
                }
              ]
        },
        {
            name: "Adhoc Sky Lounge",
            address: 'str Izlazului 10, Cluj-Napoca, Cluj, 400609',
            email: 'test@mail.com',
            base_delivery_charge: '10',
            delivery_mode: 'delivery',
            delivery_commission: '5%',
            min_order: '100',
            license_number: 'qwerty88',
            delivery_charge: '5%',
            pickup_commission: '0%',
            phone: '+40 747 999 666',
            LocationId: '03903467-98d3-4188-9d9c-8078d970adc3',
            autoOrders: true,
            onlinePayment: true,
            delivery_radius: '10km',
            openHours: [
                {
                  "closingHour": "23:59",
                  "active": true,
                  "openingHour": "10:00",
                  "day": "luni"
                },
                {
                  "closingHour": "23:59",
                  "active": false,
                  "openingHour": "09:00",
                  "day": "marți"
                },
                {
                  "closingHour": "23:00",
                  "active": true,
                  "openingHour": "10:00",
                  "day": "miercuri"
                },
                {
                  "closingHour": "23:59",
                  "active": true,
                  "openingHour": "09:00",
                  "day": "joi"
                },
                {
                  "closingHour": "23:00",
                  "active": true,
                  "openingHour": "09:00",
                  "day": "vineri"
                },
                {
                  "closingHour": "23:00",
                  "active": true,
                  "openingHour": "09:00",
                  "day": "sâmbătă"
                },
                {
                  "closingHour": "22:00",
                  "active": true,
                  "openingHour": "11:00",
                  "day": "duminică"
                }
              ]
        },
        {
            name: "Adhoc Restaurant",
            address: 'str Izlazului 10, Cluj-Napoca, Cluj, 400609',
            email: 'test@mail.com',
            base_delivery_charge: '10',
            delivery_mode: 'delivery',
            delivery_commission: '5%',
            min_order: '100',
            license_number: 'qwerty88',
            delivery_charge: '5%',
            pickup_commission: '0%',
            phone: '+40 747 999 666',
            LocationId: '03903467-98d3-4188-9d9c-8078d970adc3',
            autoOrders: true,
            onlinePayment: true,
            delivery_radius: '10km',
            openHours: [
                {
                  "closingHour": "23:59",
                  "active": true,
                  "openingHour": "10:00",
                  "day": "luni"
                },
                {
                  "closingHour": "23:59",
                  "active": false,
                  "openingHour": "09:00",
                  "day": "marți"
                },
                {
                  "closingHour": "23:00",
                  "active": true,
                  "openingHour": "10:00",
                  "day": "miercuri"
                },
                {
                  "closingHour": "23:59",
                  "active": true,
                  "openingHour": "09:00",
                  "day": "joi"
                },
                {
                  "closingHour": "23:00",
                  "active": true,
                  "openingHour": "09:00",
                  "day": "vineri"
                },
                {
                  "closingHour": "23:00",
                  "active": true,
                  "openingHour": "09:00",
                  "day": "sâmbătă"
                },
                {
                  "closingHour": "22:00",
                  "active": true,
                  "openingHour": "11:00",
                  "day": "duminică"
                }
              ]
        },
        {
            name: "Adhoc Cafe",
            address: 'str Izlazului 10, Cluj-Napoca, Cluj, 400609',
            email: 'test@mail.com',
            base_delivery_charge: '10',
            delivery_mode: 'delivery',
            delivery_commission: '5%',
            min_order: '100',
            license_number: 'qwerty88',
            delivery_charge: '5%',
            pickup_commission: '0%',
            phone: '+40 747 999 666',
            LocationId: '03903467-98d3-4188-9d9c-8078d970adc3',
            autoOrders: true,
            onlinePayment: true,
            delivery_radius: '10km',
            openHours: [
                {
                  "closingHour": "23:59",
                  "active": true,
                  "openingHour": "10:00",
                  "day": "luni"
                },
                {
                  "closingHour": "23:59",
                  "active": false,
                  "openingHour": "09:00",
                  "day": "marți"
                },
                {
                  "closingHour": "23:00",
                  "active": true,
                  "openingHour": "10:00",
                  "day": "miercuri"
                },
                {
                  "closingHour": "23:59",
                  "active": true,
                  "openingHour": "09:00",
                  "day": "joi"
                },
                {
                  "closingHour": "23:00",
                  "active": true,
                  "openingHour": "09:00",
                  "day": "vineri"
                },
                {
                  "closingHour": "23:00",
                  "active": true,
                  "openingHour": "09:00",
                  "day": "sâmbătă"
                },
                {
                  "closingHour": "22:00",
                  "active": true,
                  "openingHour": "11:00",
                  "day": "duminică"
                }
              ]
        },
        {
            name: "Strada Bar",
            address: 'str Izlazului 10, Cluj-Napoca, Cluj, 400609',
            email: 'test@mail.com',
            base_delivery_charge: '10',
            delivery_mode: 'delivery',
            delivery_commission: '5%',
            min_order: '100',
            license_number: 'qwerty88',
            delivery_charge: '5%',
            pickup_commission: '0%',
            phone: '+40 747 999 666',
            LocationId: '03903467-98d3-4188-9d9c-8078d970adc3',
            autoOrders: true,
            onlinePayment: true,
            delivery_radius: '10km',
            openHours: [
                {
                  "closingHour": "23:59",
                  "active": true,
                  "openingHour": "10:00",
                  "day": "luni"
                },
                {
                  "closingHour": "23:59",
                  "active": false,
                  "openingHour": "09:00",
                  "day": "marți"
                },
                {
                  "closingHour": "23:00",
                  "active": true,
                  "openingHour": "10:00",
                  "day": "miercuri"
                },
                {
                  "closingHour": "23:59",
                  "active": true,
                  "openingHour": "09:00",
                  "day": "joi"
                },
                {
                  "closingHour": "23:00",
                  "active": true,
                  "openingHour": "09:00",
                  "day": "vineri"
                },
                {
                  "closingHour": "23:00",
                  "active": true,
                  "openingHour": "09:00",
                  "day": "sâmbătă"
                },
                {
                  "closingHour": "22:00",
                  "active": true,
                  "openingHour": "11:00",
                  "day": "duminică"
                }
              ]
        },
    ]
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState(false);
    const [locationOpen, setLocationOpen] = useState(false);
    const { loading, locationData } = useSelector((state) => state.Location);
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const handleLocationClose = () => {
        setLocationOpen(false)
    }
    useEffect(() => {
        dispatch(GetLocationAction())
    }, [refresh])
    return (
        <>
            <AddLocation locationOpen={locationOpen} handleClose={handleLocationClose} />
            <Box>
                <Box className="partner-title">
                    <Typography variant="h2">{t("location_detail")}</Typography>
                </Box>
                <Box className="location-detail">
                    <Box className="search-box">
                        <TextField id="outlined-basic" variant="outlined" placeholder={t("Search")} InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" sx={{ color: "#000" }}>
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }} />
                    </Box>
                    <Box>
                        <Button className="refresh-btn" onClick={() => setRefresh(!refresh)}><img src={refreshImg} />{t("refresh_button")}</Button>
                        {/* <Button className="add-partner">{t("add_partner_btn")}</Button> */}
                        <Button className="add-partner" onClick={() => setLocationOpen(true)}>{'Add Location'}</Button>
                    </Box>
                </Box>
                <Grid container spacing={3}>
                    {loading ?
                        <Box sx={{ position: 'absolute', top: '60%', left: '55%', transform: 'translate(-60%,-55%)' }}>
                            <ReactLoading
                                type={"spokes"}
                                height="45px"
                                width="45px"
                                color="#FFB800"
                            />
                        </Box> :
                        <>
                            {mockData.length > 0 && mockData.map((location, index) =>
                                <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                                    <Box className="food-box" onClick={() => navigate('/location-details', { state: location })}>
                                        <img src={food} alt='location_image' />
                                        {/* <img src={location?.image} alt='location_image' /> */}
                                        <Box className='food-detail'>
                                            <Box className='food-name'>
                                                {/* <Typography variant='h5'>{t("asian_food")}</Typography> */}
                                                <Typography variant='h5'>{location?.name}</Typography>
                                                <Switch {...label} defaultChecked />
                                            </Box>
                                            <Typography>{t("asian_delicious_food")}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                        </>
                    }
                </Grid>
            </Box>
        </>
    );
};

export default Locations;
