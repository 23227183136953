import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import './style.css'

export const Layout = () => {
  return (
    <Box>
      <Header />
      <Sidebar />
      <Box className="partner-wrap">
        <Outlet />
      </Box>
    </Box>
  );
};
