import React, { useEffect } from 'react';
import { Box, Button, FormControlLabel, FormGroup, FormHelperText, Grid, Switch, TextField, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { t } from 'i18next';
import ReactLoading from "react-loading";
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import locationfood from '../../../assets/img/location-bg.png'
import EditImgModel from './EditImgModal';
import { AddLocationAction, GetLocationAction } from '../../../Redux/Actions/LocationActions';
// import { useErrorHandler } from '../../../hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomButton from '../../../component/CustomButton';

const LocationDetailTab = () => {
    const location = useLocation();
    const selectedLocation = location.state;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [coverImgOpen, setCoverImgOpen] = React.useState(false);
    const [profileImgOpen, setProfileImgOpen] = React.useState(false);
    const [showProfileImage, setShowProfileImage] = React.useState("");
    const { loading } = useSelector((state) => state.Location);
    const handleEditImage = () => {
        setCoverImgOpen(false)
        setProfileImgOpen(false)
    }

    useEffect(() => {
        dispatch(GetLocationAction())
    }, [])

    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const formik = useFormik({
        initialValues: {
            restaurant_name: "" || selectedLocation?.name,
            full_address: "" || selectedLocation?.address,
            email:"" || selectedLocation?.email,
            base_deliv_charge: "" || selectedLocation?.base_delivery_charge,
            delivery_mode: "" || selectedLocation?.delivery_mode,
            delivery_commission: "" || selectedLocation?.delivery_commission,
            min_order: "" || selectedLocation?.min_order,
            license_number: "" || selectedLocation?.license_number,
            delivery_charge: "" || selectedLocation?.delivery_charge,
            pickup_commission: "" || selectedLocation?.pickup_commission,
            contact: "" || selectedLocation?.phone,
            pos: "" || selectedLocation?.PartnerID,
            location_id: "" || selectedLocation?.LocationId,
            auto_order: false || selectedLocation?.autoOrders,
            online_payment: false || selectedLocation?.onlinePayment,
            delivery_radius: "" || selectedLocation?.delivery_radius,
            schedule: [
                { "day": "Monday", "openingHour": selectedLocation?.openHours[0]?.openingHour, "closingHour": selectedLocation?.openHours[0]?.closingHour, "active": selectedLocation?.openHours[0]?.active },
                { "day": "Tuesday", "openingHour": selectedLocation?.openHours[1]?.openingHour, "closingHour": selectedLocation?.openHours[1]?.closingHour, "active": selectedLocation?.openHours[1]?.active },
                { "day": "Wednesday", "openingHour": selectedLocation?.openHours[2]?.openingHour, "closingHour": selectedLocation?.openHours[2]?.closingHour, "active": selectedLocation?.openHours[2]?.active },
                { "day": "Thursday", "openingHour": selectedLocation?.openHours[3]?.openingHour, "closingHour": selectedLocation?.openHours[3]?.closingHour, "active": selectedLocation?.openHours[3]?.active },
                { "day": "Friday", "openingHour": selectedLocation?.openHours[4]?.openingHour, "closingHour": selectedLocation?.openHours[4]?.closingHour, "active": selectedLocation?.openHours[4]?.active },
                { "day": "Saturday", "openingHour": selectedLocation?.openHours[5]?.openingHour, "closingHour": selectedLocation?.openHours[5]?.closingHour, "active": selectedLocation?.openHours[5]?.active },
                { "day": "Sunday", "openingHour": selectedLocation?.openHours[6]?.openingHour, "closingHour": selectedLocation?.openHours[6]?.closingHour, "active": selectedLocation?.openHours[6]?.active }
            ] || selectedLocation?.onlinePayment,
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            location_name: Yup.string().required("Location name is required"),
            full_address: Yup.string().required("Full address is required"),
            contact: Yup.string().required("Contact is required"),
            pos: Yup.string().required("POS system is required"),
            location_id: Yup.string().required("Location Id is required"),
        }),
        onSubmit: (values, { resetForm }) => {
            // const payload = {
            //     "name": formik.values.location_name,
            //     "PartnerID": "273785ba-1027-4d25-8314-7aabf0b7e3b4",
            //     "address": formik.values.full_address,
            //     "phone": formik.values.contact,
            //     "posSystem": formik.values.pos,
            //     "autoOrders": formik.values.auto_order,
            //     "onlinePayment": formik.values.online_payment,
            //     "image": "1700060372461-001_4873-PS.jpg",
            //     "openHours": formik.values?.schedule
            // }
            // dispatch(AddLocationAction(payload));
            // setMsgConfig({
            //     alertOpen: true,
            //     message: "Message",
            //     severity: "Severity",
            //     autoHideDuration: 2000,
            // });
            // resetForm();
        },
    });
    return (
        <>
            {loading ?
                <Box sx={{
                    "& div": {
                        margin: '100px auto'
                    }
                }}>
                    <ReactLoading
                        type={"spokes"}
                        height="45px"
                        width="45px"
                        color="#FFB800"
                    />
                </Box> :
                <>
                <div className='bg-secondaryColor p-5 grid lg:grid-cols-2 lg:gap-x-52 rounded-lg drop-shadow-sm mt-8'>
                    <div className='space-y-5'>
                        <div>
                            <div className='font-semibold'>Restaurant Name</div>
                            <input 
                                className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                                value={formik.values.restaurant_name}
                            />
                            {formik.touched.restaurant_name && formik.errors.restaurant_name && (
                                <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                    {formik.errors.restaurant_name}
                                </FormHelperText>
                            )}
                        </div>
                        <div>
                            <div className='font-semibold'>Email</div>
                            <input 
                                className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                                value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email && (
                                <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                    {formik.errors.email}
                                </FormHelperText>
                            )}
                        </div>
                        <div>
                            <div className='font-semibold'>Delivery Mode</div>
                            <input 
                                className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                                value={formik.values.delivery_mode}
                            />
                            {formik.touched.delivery_mode && formik.errors.delivery_mode && (
                                <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                    {formik.errors.delivery_mode}
                                </FormHelperText>
                            )}
                        </div>
                        <div>
                            <div className='font-semibold'>Minimum Order Amount</div>
                            <input 
                                className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                                value={formik.values.min_order}
                            />
                            {formik.touched.min_order && formik.errors.min_order && (
                                <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                    {formik.errors.min_order}
                                </FormHelperText>
                            )}
                        </div>
                        <div>
                            <div className='font-semibold'>Phone</div>
                            <input 
                                className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                                value={formik.values.contact}
                            />
                            {formik.touched.contact && formik.errors.contact && (
                                <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                    {formik.errors.contact}
                                </FormHelperText>
                            )}
                        </div>
                        <div>
                            <div className='font-semibold'>Pick-Up Commission (%)</div>
                            <input 
                                className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                                value={formik.values.pickup_commission}
                            />
                            {formik.touched.pickup_commission && formik.errors.pickup_commission && (
                                <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                    {formik.errors.pickup_commission}
                                </FormHelperText>
                            )}
                        </div>
                    </div>
                    <div className='space-y-5'>
                        <div>
                            <div className='font-semibold'>Address</div>
                            <input 
                                className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                                value={formik.values.full_address}
                            />
                            {formik.touched.full_address && formik.errors.full_address && (
                                <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                    {formik.errors.full_address}
                                </FormHelperText>
                            )}
                        </div>
                        <div>
                            <div className='font-semibold'>Base Delivery Charge</div>
                            <input 
                                className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                                value={formik.values.base_deliv_charge}
                            />
                            {formik.touched.base_deliv_charge && formik.errors.base_deliv_charge && (
                                <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                    {formik.errors.base_deliv_charge}
                                </FormHelperText>
                            )}
                        </div>
                        <div>
                            <div className='font-semibold'>Delivery Commission (%)</div>
                            <input 
                                className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                                value={formik.values.delivery_commission}
                            />
                            {formik.touched.delivery_commission && formik.errors.delivery_commission && (
                                <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                    {formik.errors.delivery_commission}
                                </FormHelperText>
                            )}
                        </div>
                        <div>
                            <div className='font-semibold'>License Number</div>
                            <input 
                                className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                                value={formik.values.license_number}
                            />
                            {formik.touched.license_number && formik.errors.license_number && (
                                <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                    {formik.errors.license_number}
                                </FormHelperText>
                            )}
                        </div>
                        <div>
                            <div className='font-semibold'>Delivery Charge (per KM)</div>
                            <input 
                                className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                                value={formik.values.delivery_charge}
                            />
                            {formik.touched.delivery_charge && formik.errors.delivery_charge && (
                                <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                    {formik.errors.delivery_charge}
                                </FormHelperText>
                            )}
                        </div>
                    </div>
                </div>
                <div className='p-5 bg-secondaryColor rounded-lg drop-shadow-sm mt-10 grid lg:grid-cols-2'>
                    <div>
                        <div className='font-semibold'>Delivery Area</div>
                        <p className='text-sm'>Enter a Radius from Store's central location in which you want to deliver.</p>
                        <div className='flex my-4 space-x-3 items-center'>
                            <div className='font-semibold'>Enter Delivery Radius (KM)</div>
                            <div>
                                <input 
                                    className='h-10 p-3 rounded-lg drop-shadow-sm w-full'
                                    value={formik.values.delivery_radius}
                                />

                            </div>
                        </div>
                        <p className='text-sm mb-3'>Note that your store will serve within the radius you enter.</p>
                        <CustomButton label={'Save'} className={'px-8'} />
                    </div>
                </div>
                {/* <Box className='location-detail-wrap'>
                    <Grid container spacing={3}>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Box className="location-filled">
                                <Typography variant='h5'>{t("location_tab")}</Typography>
                                <Box className='location-filled-inner'>
                                    <label>{t("location_name")}</label>
                                    <TextField placeholder='Caffe Bar' variant="outlined" name='location_name' value={formik.values.restaurant_name} onChange={formik.handleChange} />
                                    {formik.touched.restaurant_name && formik.errors.restaurant_name && (
                                        <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                            {formik.errors.restaurant_name}
                                        </FormHelperText>
                                    )}
                                </Box>
                                <Box className='location-filled-inner'>
                                    <label>{t("full_address")}
                                        <Tooltip title="Full Address" placement="top">
                                            <InfoOutlinedIcon />
                                        </Tooltip>
                                    </label>
                                    <TextField placeholder='Str. Maxim Nr. 10 cladirea A Cluj-Nampoca, Cluj, RO' variant="outlined" name='full_address' value={formik.values.full_address} onChange={formik.handleChange} />
                                    {formik.touched.full_address && formik.errors.full_address && (
                                        <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                            {formik.errors.full_address}
                                        </FormHelperText>
                                    )}
                                </Box>
                                <Box className='location-filled-inner'>
                                    <label>{t("contact_number")}
                                        <Tooltip title="Contact Number" placement="top">
                                            <InfoOutlinedIcon />
                                        </Tooltip>
                                    </label>
                                    <TextField placeholder='0744111333' variant="outlined" name='contact' value={formik.values.contact} onChange={formik.handleChange} />
                                    {formik.touched.contact && formik.errors.contact && (
                                        <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                            {formik.errors.contact}
                                        </FormHelperText>
                                    )}
                                </Box>
                                <Box className='location-filled-inner'>
                                    <label>{t("pos_system")}
                                        <Tooltip title="POS System" placement="top">
                                            <InfoOutlinedIcon />
                                        </Tooltip>
                                    </label>
                                    <TextField placeholder='Ebriza' variant="outlined" name='pos' value={formik.values.pos} onChange={formik.handleChange} />
                                    {formik.touched.pos && formik.errors.pos && (
                                        <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                            {formik.errors.pos}
                                        </FormHelperText>
                                    )}
                                </Box>
                                <Box className='location-filled-inner'>
                                    <label>{t("location_id")}
                                        <Tooltip title="Location ID" placement="top">
                                            <InfoOutlinedIcon />
                                        </Tooltip></label>
                                    <TextField placeholder='location id' variant="outlined" name='location_id' value={formik.values.location_id} onChange={formik.handleChange} disabled />
                                    {formik.touched.location_id && formik.errors.location_id && (
                                        <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                            {formik.errors.location_id}
                                        </FormHelperText>
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <FormGroup className='payment-accept-detail'>
                                <FormControlLabel control={<Switch name='auto_order' onChange={formik.handleChange} defaultChecked={formik.values.auto_order} />} label={t("accpet_orders")} />
                                <FormControlLabel required control={<Switch name='online_payment' onChange={formik.handleChange} defaultChecked={formik.values.online_payment} />} label={t("online_payment")} />
                            </FormGroup>
                        </Grid>
                        {selectedLocation?.openHours != undefined &&
                            <>
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="day-schedule">
                                        <Typography className='hours-title'>{t("open_hours")}</Typography>
                                        {formik.values.schedule?.map((schedule, index) =>
                                            <Box className='day-schedule-inner' key={index}>
                                                <Typography>{schedule?.day} </Typography>
                                                <DatePicker
                                                    selected={new Date(`${moment().format("YYYY-MM-DD")}T${schedule?.openingHour}`)}
                                                    onChange={(date) => {
                                                        formik.setFieldValue(`schedule.${index}.openingHour`, moment(date).format('hh:mm'));
                                                    }}
                                                    id={`openingHour_${index}`}
                                                    showTimeSelectOnly
                                                    autoComplete='off'
                                                    dateFormat="hh:mm"
                                                    showTimeSelect
                                                    timeFormat="hh:mm"
                                                    timeIntervals={60}
                                                    className={'flighttime-picker'}
                                                    placeholderText="Select time"
                                                />
                                                <Typography>{t("day_to")}</Typography>
                                                <DatePicker
                                                    selected={new Date(`${moment().format("YYYY-MM-DD")}T${schedule?.closingHour}`)}
                                                    onChange={(date) => {
                                                        formik.setFieldValue(`schedule.${index}.closingHour`, moment(date).format('hh:mm'));
                                                    }}
                                                    id={`closingHour_${index}`}
                                                    showTimeSelectOnly
                                                    autoComplete='off'
                                                    dateFormat="hh:mm"
                                                    showTimeSelect
                                                    timeFormat="hh:mm"
                                                    timeIntervals={60}
                                                    className={'flighttime-picker'}
                                                    placeholderText="Select time"
                                                />
                                                <Switch
                                                    {...label}
                                                    defaultChecked={schedule?.active} id={`active${index}`}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`schedule.${index}.active`, e.target.checked);
                                                    }} />
                                            </Box>
                                        )}
                                    </Box>
                                </Grid>
                            </>
                        }
                        <Box className='location-detail-btn'>
                            <Button className='cancel-btn' onClick={() => navigate('/locations')}> {t("cancel_btn")}</Button>
                            <Button className='submit-btn' onClick={formik.handleSubmit}>{t("save_btn")}</Button>
                        </Box>
                    </Grid >
                </Box > */}
                </>
            }
        </>
    );
};

export default LocationDetailTab;