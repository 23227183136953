import AWS from "aws-sdk";
const Buffer = require("buffer").Buffer;

const uploadImageToAws = async (image) => {
  const bucket = new AWS.S3({
    accessKeyId: "AKIAXKG5WUQM7IIS5HMZ",
    secretAccessKey: "3kXVdNAcwo9+4CeuToYZOyhx4+rnWfvMebZ4EHJn",
    region: "eu-central-2",
  });
  let buf = Buffer.from(
    image.replace(/^data:image\/\w+;base64,/, ""),
    "base64"
  );
  
  const isStageOrLocal = window.location.href.includes("stage") || window.location.href.includes("localhost");

  const params = {
    Bucket: isStageOrLocal ?  "adhocorder-stage-frontend-assets":"adhocorder-prod-frontend-assets",
    Key: "Images/" + new Date().getTime() + ".jpg",
    Body: buf,
    // ACL: "public-read",
    ContentEncoding: "base64",
    ContentType: "image/jpeg",
  };
  
  const uploadedImage = await bucket.upload(params).promise();
  return uploadedImage.Location;
};

export default { uploadImageToAws };
