import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ReactLoading from "react-loading";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ImageCropper(props) {
  const cropperRef = useRef(null);
  const [croppedImage, setCroppedImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(0); // Default to 1:1

  const handleClose = () => props.setShowImageModal(false);

  const handleSave = async () => {
    setLoading(true);
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const croppedDataUrl = cropper.getCroppedCanvas().toDataURL();
    props.setImage(croppedDataUrl);
    if (props.setShowProfileImage) props.setShowProfileImage(croppedDataUrl);
    setLoading(false);
    handleClose();
  };

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCroppedImage(cropper.getCroppedCanvas().toDataURL());
  };

  // const handleAspectRatioChange = (ratio) => {
  //   setAspectRatio(ratio);
  // };

  return (
    <div>
      <Modal
        open={props.show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="cropper-modals">
          <Cropper
            src={props.fileDataURL}
            guides={false}
            ref={cropperRef}
            viewMode={1}
            crop={onCrop}
            responsive={true}
            aspectRatio={aspectRatio} // Dynamically set aspect ratio
            checkOrientation={false}
            zoomable={true}
            cropBoxResizable={true}
            dragMode="none" // Disable drag mode (no image movement)
            movable={false} // Disable image movement
            toggleDragModeOnDblclick={false}
            style={{ maxHeight: 500 }}
          />
          
          {/* <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Button onClick={() => handleAspectRatioChange(1)}>
              1:1
            </Button>
            <Button onClick={() => handleAspectRatioChange(16 / 9)}>
              16:9
            </Button>
            <Button onClick={() => handleAspectRatioChange(4 / 3)}>
              4:3
            </Button>
            <Button onClick={() => handleAspectRatioChange(0)}> // Freeform cropping
              Free
            </Button>
          </Box> */}

          <Box className="modal-btn" sx={{ justifyContent: "center", mb: 0 }}>
            <Button className="cancel-btn" onClick={handleClose}>
              Cancel
            </Button>
            <Button className="submit-btn" onClick={handleSave}>
              Save
              {loading && (
                <ReactLoading
                  type={"spin"}
                  height="30px"
                  width="30px"
                  color="white"
                />
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
