import React from "react";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { AddLocationAction } from "../../Redux/Actions/LocationActions";
import { useDispatch, useSelector } from "react-redux";
import { useErrorHandler } from "../../hooks";
import ReactLoading from "react-loading";
import CloseIcon from "@mui/icons-material/Close";

const label = { inputProps: { "aria-label": "Switch demo" } };
const AddLocation = ({ locationOpen, handleClose }) => {
  const dispatch = useDispatch();
  const { setMsgConfig } = useErrorHandler();
  const { loading } = useSelector((state) => state.Location);
  const currentHour = moment().format("HH:mm");
  const partnerId = localStorage.getItem('id');
  const formik = useFormik({
    initialValues: {
      location_name: "",
      full_address: "",
      contact: "",
      pos: "",
      location_id: "",
      auto_order: false,
      online_payment: false,
      schedule: [
        {
          day: "Monday",
          openingHour: currentHour,
          closingHour: currentHour,
          active: true,
        },
        {
          day: "Tuesday",
          openingHour: currentHour,
          closingHour: currentHour,
          active: true,
        },
        {
          day: "Wednesday",
          openingHour: currentHour,
          closingHour: currentHour,
          active: false,
        },
        {
          day: "Thursday",
          openingHour: currentHour,
          closingHour: currentHour,
          active: false,
        },
        {
          day: "Friday",
          openingHour: currentHour,
          closingHour: currentHour,
          active: false,
        },
        {
          day: "Saturday",
          openingHour: currentHour,
          closingHour: currentHour,
          active: false,
        },
        {
          day: "Sunday",
          openingHour: currentHour,
          closingHour: currentHour,
          active: false,
        },
      ],
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      location_name: Yup.string().required("Location name is required"),
      full_address: Yup.string().required("Full address is required"),
      contact: Yup.string().required("Contact is required"),
      pos: Yup.string().required("POS system is required"),
      location_id: Yup.string().required("Location Id is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      const payload = {
        name: formik.values.location_name,
        PartnerId: partnerId,
        address: formik.values.full_address,
        phone: formik.values.contact,
        posSystem: formik.values.pos,
        autoOrders: formik.values.auto_order,
        onlinePayment: formik.values.online_payment,
        image: "1700060372461-001_4873-PS.jpg",
        openHours: formik.values?.schedule,
      };
      dispatch(AddLocationAction(payload));
      setMsgConfig({
        alertOpen: true,
        message: "Message",
        severity: "Severity",
        autoHideDuration: 2000,
      });
      handleClose();
      resetForm();
    },
  });

  return (
    <>
      <Modal
        open={locationOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="add-product-modal-box"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Box className="modal-body add-category-modal add-location-modal">
            <Box className="add-product-modal add-partner-modal">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {/* {t("add_partner_btn")} */}
                  Add New Location
                </Typography>
                <CloseIcon
                  onClick={handleClose}
                  sx={{ color: "#ffb800", cursor: "pointer", fontSize: "30px" }}
                />
              </Box>
              <Box className="location-detail-wrap">
                <Grid
                  container
                  spacing={2}
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item lg={5} md={5} sm={12} xs={12}>
                    <Box className="location-filled">
                      <Box className="location-filled-inner">
                        <label>{t("location_name")}</label>
                        <TextField
                          placeholder="Location Name"
                          variant="outlined"
                          name="location_name"
                          value={formik.values.location_name}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.location_name &&
                          formik.errors.location_name && (
                            <FormHelperText
                              className="error"
                              style={{
                                color: "red",
                                textAlign: "start",
                                margin: " 5px",
                              }}
                            >
                              {formik.errors.location_name}
                            </FormHelperText>
                          )}
                      </Box>
                      <Box className="location-filled-inner">
                        <label>{t("full_address")}</label>
                        <TextField
                          placeholder="Full Address"
                          variant="outlined"
                          name="full_address"
                          value={formik.values.full_address}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.full_address &&
                          formik.errors.full_address && (
                            <FormHelperText
                              className="error"
                              style={{
                                color: "red",
                                textAlign: "start",
                                margin: " 5px",
                              }}
                            >
                              {formik.errors.full_address}
                            </FormHelperText>
                          )}
                      </Box>
                      <Box className="location-filled-inner">
                        <label>{t("contact_number")}</label>
                        <TextField
                          placeholder="Contact Number"
                          variant="outlined"
                          name="contact"
                          value={formik.values.contact}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.contact && formik.errors.contact && (
                          <FormHelperText
                            className="error"
                            style={{
                              color: "red",
                              textAlign: "start",
                              margin: " 5px",
                            }}
                          >
                            {formik.errors.contact}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box className="location-filled-inner">
                        <label>{t("pos_system")} </label>
                        <TextField
                          placeholder="POS System"
                          variant="outlined"
                          name="pos"
                          value={formik.values.pos}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.pos && formik.errors.pos && (
                          <FormHelperText
                            className="error"
                            style={{
                              color: "red",
                              textAlign: "start",
                              margin: " 5px",
                            }}
                          >
                            {formik.errors.pos}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box className="location-filled-inner">
                        <label>{t("location_id")}</label>
                        <TextField
                          placeholder="Location Id"
                          variant="outlined"
                          name="location_id"
                          value={formik.values.location_id}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.location_id &&
                          formik.errors.location_id && (
                            <FormHelperText
                              className="error"
                              style={{
                                color: "red",
                                textAlign: "start",
                                margin: " 5px",
                              }}
                            >
                              {formik.errors.location_id}
                            </FormHelperText>
                          )}
                      </Box>
                      <FormGroup className="payment-accept-detail">
                        <FormControlLabel
                          control={
                            <Switch
                              name="auto_order"
                              onChange={formik.handleChange}
                              defaultChecked={formik.values.auto_order}
                            />
                          }
                          label={t("accpet_orders")}
                        />
                        <FormControlLabel
                          required
                          control={
                            <Switch
                              name="online_payment"
                              onChange={formik.handleChange}
                              defaultChecked={formik.values.online_payment}
                            />
                          }
                          label={t("online_payment")}
                        />
                      </FormGroup>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={5} sm={8} xs={12}>
                    <Box className="day-schedule">
                      <Typography className="hours-title">
                        {t("open_hours")}
                      </Typography>
                      {formik.values.schedule?.map((schedule, index) => (
                        <Box className="day-schedule-inner" key={index}>
                          <Typography>{schedule?.day} </Typography>
                          <DatePicker
                            selected={
                              new Date(
                                `${moment().format("YYYY-MM-DD")}T${
                                  schedule?.openingHour
                                }`
                              )
                            }
                            onChange={(date) => {
                              formik.setFieldValue(
                                `schedule.${index}.openingHour`,
                                moment(date).format("hh:mm")
                              );
                            }}
                            id={`openingHour_${index}`}
                            showTimeSelectOnly
                            autoComplete="off"
                            dateFormat="hh:mm"
                            showTimeSelect
                            timeFormat="hh:mm"
                            timeIntervals={60}
                            className={"flighttime-picker"}
                            placeholderText="Select time"
                          />
                          <Typography>{t("day_to")}</Typography>
                          <DatePicker
                            selected={
                              new Date(
                                `${moment().format("YYYY-MM-DD")}T${
                                  schedule?.closingHour
                                }`
                              )
                            }
                            onChange={(date) => {
                              formik.setFieldValue(
                                `schedule.${index}.closingHour`,
                                moment(date).format("hh:mm")
                              );
                            }}
                            id={`closingHour_${index}`}
                            showTimeSelectOnly
                            autoComplete="off"
                            dateFormat="hh:mm"
                            showTimeSelect
                            timeFormat="hh:mm"
                            timeIntervals={60}
                            className={"flighttime-picker"}
                            placeholderText="Select time"
                          />
                          <Switch
                            {...label}
                            inputProps={{ "aria-label": "controlled" }}
                            defaultChecked={schedule?.active}
                            id={`active${index}`}
                            onChange={(e) => {
                              formik.setFieldValue(
                                `schedule.${index}.active`,
                                e.target.checked
                              );
                            }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box className="modal-btn">
                <Button className="cancel-btn" onClick={handleClose}>
                  {t("cancel_btn")}
                </Button>
                <Button className="submit-btn" onClick={formik.handleSubmit}>
                  {" "}
                  {loading ? (
                    <ReactLoading
                      type={"spin"}
                      height="30px"
                      width="30px"
                      color="white"
                    />
                  ) : (
                    t("save_btn")
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddLocation;
