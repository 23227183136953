import React from "react";
import { Box, Modal, Typography } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";

const AddLocationModel = ({ locationModelOpen, handleClose }) => {
  return (
    <>
      <Modal
        open={locationModelOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          position: "absolute",
          top: "10%",
          left: "10%",
          overflow: "scroll",
          height: "100%",
          display: "block",
        }}
      >
        <Box className="modal-body add-category-modal">
          <Box className="add-category-modal-inner">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add New Location
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddLocationModel;
