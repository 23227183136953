import { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import CategoryCard from '../../component/ConfigurationsPage/CategoryCard.jsx';
import './ConfigurationProductDisplay.css';
import ConfigurationProductDisplayCatDetails from './child/ConfigurationProductDisplayCatDetails.jsx';
import axios from '../../axiosConfig';
import EditImgModel from '../Locations/LocationDetails/EditImgModal.jsx';
import DefaultImg from '../../assets/img/default-restaurant-img.svg'


const ConfigurationProductDisplay = ({ products, productCategories, fetchLatestDataAndMap,syncToPos }) => {
  const [activeCard, setActiveCard] = useState(null);
  const [view, setView] = useState('categories'); // New state to manage the current view
  const [selectedCategory, setSelectedCategory] = useState(null); // New state to manage the selected category
  const [selectedSubCategory, setSelectedSubCategory] = useState(null); // New state to manage the selected category
  const [isCatProductAdd, setIsCatProductAdd] = useState(false); // New state to manage the selected category
  const [openAddPopup, setOpenAddPopup] = useState(false); // New state to manage the selected category
  const [openDialog, setOpenDialog] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [isRenaming, setIsRenaming] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [categories, setCategories] = useState(productCategories);
  
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  ;
  const [productToRemove, setProductToRemove] = useState(null);
  
  const ebrizaLinkedId = localStorage.getItem('ebrizaLinkedId');
  const [coverImgOpen, setCoverImgOpen] = useState(false);
  const [profileImgOpen, setProfileImgOpen] = useState(false)
  const [showProfileImage, setShowProfileImage] = useState(DefaultImg);
  const cardRefs = useRef([]);
  useEffect(()=>{
    if(products && productCategories){
      let filteredProductCat = productCategories.filter((eleFF)=>!eleFF.parent_cat_id)
      setCategories(filteredProductCat)
    }
  },[products,productCategories])



  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside any of the card menus
      if (activeCard !== null && !cardRefs.current[activeCard]?.contains(event.target)) {
        setActiveCard(null);
      }
    };

    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Cleanup event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeCard]);

  const handleEditImage = async (uploadedUrl) => {
    setCoverImgOpen(false)
    setProfileImgOpen(false)
    if(uploadedUrl){
      console.log("uploadedUrl cat..",uploadedUrl);
      let payload = {
        'CategoryId':selectedCategoryId,
        'imageUrl': uploadedUrl
      }
      let addMenuCat = await axios.put(`/partners/menu_categories`,payload)
      if (addMenuCat.data) {
        fetchLatestDataAndMap()
      }
    }

  }

  const editCatImage = (categoryId)=>{
    setActiveCard(null)
    setSelectedCategoryId(categoryId);
    setProfileImgOpen(true)
  }

  const handleCardClick = (cardIndex, category) => {
    if((category.cat_products && category.cat_products.length>0) || selectedSubCategory){
      setSelectedCategory(category); // Set the selected category
      setView('details'); // Change view to details
    }else if(category.sub_categories && category.sub_categories.length>0){
      setSelectedCategory(category);
      setSelectedSubCategory(category)
      setCategories(category.sub_categories)
    }else {
      setSelectedCategory(category);
      setCategories([])
      setIsCatProductAdd(true)
    }
  };

  const handleCardMenuClick = (cardIndex) => {
    setActiveCard(activeCard === cardIndex ? null : cardIndex);
  };

  const handleBreadcrumbClick = (subCat=false) => {
    setView('categories'); // Change view back to categories
    if(subCat){
      setCategories(selectedSubCategory.sub_categories)
      setSelectedCategory(selectedSubCategory); // Clear the selected category
      
      setIsCatProductAdd(false)
    }else{
      let filteredProductCat = productCategories.filter((eleFF)=>!eleFF.parent_cat_id)
      setCategories(filteredProductCat)
      setSelectedCategory(null); // Clear the selected category
      setSelectedSubCategory(null)
      setIsCatProductAdd(false)
    }
  };

  const handleOpenDialog = () => {
    if (!ebrizaLinkedId) {
      setOpenDialog(true);
      setIsRenaming(false);
      setCategoryName('');
    }
  };

  const handleAddProductOption = () => {
    setView('details');
    setOpenAddPopup(true)
  };

  const handleRenameOpen = (categoryId, currentName) => {
    setActiveCard(null)
    setSelectedCategoryId(categoryId);
    setCategoryName(currentName);
    setIsRenaming(true);
    setOpenDialog(true);
  };

  const handleCatDelete = (categoryId, currentName) => {
    setActiveCard(null)
    setProductToRemove(categoryId);
    setOpenConfirmDialog(true);
  };

  async function handleConfirmRemove() {
    console.log("Deleting product:", productToRemove);
    
    try {
      let addMenuCat = await axios.delete(`/partners/menu_categories`,{data:{'CategoryId':productToRemove}})
      if (addMenuCat.data) {
        fetchLatestDataAndMap()
      }
      
    } catch (error) {
      console.log("Failed to delete product",error.message);
      
    } finally{
      setOpenConfirmDialog(false);
      setProductToRemove(null);

    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCategoryName('');
  };

  const handleCategoryNameChange = (event) => {
    setCategoryName(event.target.value);
  };

  const handleAddCategory = async () => {
    try {
      if(categoryName){
        let payload = {
          'name':categoryName,
          'description':'',
          'imageUrl':'',
          'tileColor':'',
          'parent_cat_id':selectedCategory?selectedCategory.id:null
        }
        let addMenuCat = await axios.post(`/partners/menu_categories`,payload)
        
        if (addMenuCat.data) {
          console.log("addMenuCat.",addMenuCat.data);
          const newCategory = addMenuCat.data.data[0];
          setCategories([...categories, newCategory]);
          handleCloseDialog();
          setIsCatProductAdd(false)
        } else {
          console.error('Failed to add category');
        }
      }

    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleRenameCategory = async () => {
    try {

      if(categoryName){
        let payload = {
          'name':categoryName,
          'CategoryId':selectedCategoryId,
        }
        let updateMenuCat = await axios.put(`/partners/menu_categories`,payload)
        
        if (updateMenuCat.data.success) {
          console.log("updateMenuCat.",updateMenuCat.data);
          let newData = categories.map(cat => {
            if(cat.id === selectedCategoryId){
              cat['name'] = categoryName
            }
            return cat;
          })
          setCategories(newData);
          handleCloseDialog();
        } else {
          console.error('Failed to add category');
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const renderCards = () => (
    <>
      <EditImgModel coverImgOpen={coverImgOpen} profileImgOpen={profileImgOpen} locationId="" handleClose={handleEditImage} setShowProfileImage={setShowProfileImage}/>
      
      <div className="cards">
        {categories.map((category, index) => (
          <div
            key={index}
            className="category-card-1"
            style={{ cursor: 'pointer' }}
            ref={(el) => (cardRefs.current[index] = el)} 
          >
            <CategoryCard
              name1={category.name}
              totalItems={category.totalItems}
              totalSeen={category.totalActive}
              imgUrl={category.imageUrl}
              onCardMenuClick={() => handleCardMenuClick(index)}
              onCardClick={() => handleCardClick(index, category)}
            />
            {activeCard === index && !ebrizaLinkedId &&  (
              <div className="card-actions">
                <div className="actions2">
                  <div className="option" onClick={() => handleRenameOpen(category.CategoryId, category.name)}>
                    <div className="left">
                      <img className="icons23" alt="" src="/icons-54.svg" />
                      <div className="edit">Rename</div>
                    </div>
                    <img className="icons24" alt="" src="/icons2.svg" />
                  </div>
                  {/* <div className="option1">
                    <div className="left1">
                      <img className="icons25" alt="" src="/icons-55.svg" />
                      <div className="edit1">Change color</div>
                    </div>
                    <img className="icons26" alt="" src="/icons2.svg" />
                  </div> */}
                  <div className="option2" onClick={() => editCatImage(category.CategoryId)}>
                    <div className="left2">
                      <img className="icons27" alt="" src="/icons-56.svg" />
                      <div className="edit2">Change icon</div>
                    </div>
                    <img className="icons28" alt="" src="/icons2.svg" />
                  </div>
                  {/* <div className="option2">
                    <div className="left2">
                      <img className="icons27" alt="" src="/icons-57.svg" />
                      <div className="edit2">Move to...</div>
                    </div>
                    <img className="icons28" alt="" src="/icons2.svg" />
                  </div> */}
                  <div className="option4" onClick={() => handleCatDelete(category.CategoryId, category.name)}>
                    <div className="left3">
                      <img className="icons29" alt="" src="/icons-58.svg" />
                      <div className="edit3">Delete</div>
                    </div>
                    <img className="icons30" alt="" src="/icons2.svg" />
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
        <div
          onClick={handleOpenDialog}
          style={ebrizaLinkedId ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
          className="category-card71"
        >
          <img className="icons22" alt="" src="/icons1.svg" />
          <div className="add-category7">Add category</div>
        </div>
        {
          isCatProductAdd && (
            <div
              onClick={handleAddProductOption}
              style={ebrizaLinkedId ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
              className="category-card71"
            >
              <img className="icons22" alt="" src="/icons1.svg" />
              <div className="add-category7">Add Product</div>
            </div>

          )
        }
      </div>
    
    </>
  );

  return (
    <section className="top-bar2">
      <div className="inner-content-wrapper">
        <div className="inner-content">
          <div className="content3" style={{    display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'}}>
            <div className="actions1">
              <div className="buttons1">
                <div className="input-dropdown-base">
                  <div className="input-with-label">
                    <FormControl
                      className="input"
                      variant="standard"
                      sx={{
                        borderTopWidth: '1px',
                        borderRightWidth: '1px',
                        borderBottomWidth: '1px',
                        borderLeftWidth: '1px',
                        borderRadius: '8px',
                        width: '100%',
                        height: '44px',
                        m: 0,
                        p: 0,
                        '& .MuiInputBase-root': {
                          m: 0,
                          p: 0,
                          minHeight: '44px',
                          justifyContent: 'center',
                          display: 'inline-flex',
                        },
                        '& .MuiInputLabel-root': {
                          m: 0,
                          p: 0,
                          minHeight: '44px',
                          display: 'inline-flex',
                        },
                        '& .MuiMenuItem-root': {
                          m: 0,
                          p: 0,
                          height: '44px',
                          display: 'inline-flex',
                        },
                        '& .MuiSelect-select': {
                          m: 0,
                          p: 0,
                          height: '44px',
                          alignItems: 'center',
                          display: 'inline-flex',
                        },
                        '& .MuiInput-input': { m: 0, p: 0 },
                        '& .MuiInputBase-input': {
                          color: '#3c3a35',
                          fontSize: 16,
                          fontWeight: 'Semi Bold',
                          fontFamily: 'Inter',
                          textAlign: 'left',
                          p: '0 !important',
                        },
                      }}
                    >
                      <InputLabel color="primary" />
                      <Select
                        color="primary"
                        disableUnderline
                        displayEmpty
                        IconComponent={() => (
                          <img
                            width="20px"
                            height="20px"
                            src="/chevrondown.svg"
                            style={{ marginRight: '3px' }}
                          />
                        )}
                      >
                        <MenuItem>Nomenclator</MenuItem>
                      </Select>
                      <FormHelperText />
                    </FormControl>
                  </div>
                </div>
                {/* <div className="button-base11">
                  <img className="icons11" alt="" src="/icons-91.svg" />
                  <a className="text6">New</a>
                </div> */}
              </div>
            </div>
            <button  style={ ebrizaLinkedId?{cursor:'not-allowed'}:{} } className="button-base12" onClick={(event)=>{
                      event.preventDefault();
                      syncToPos()
                    }}>
                <img className="icons12" alt="" src="/icons-11.svg" />
                <div className="text7">Sync to POS</div>
              </button>
            </div>
            <div className="categories">
              <div className="bread-countsactions">
                <div className="bread-counts">
                  <a className={view != 'details' && !selectedSubCategory?"active":"categories1"} onClick={()=>handleBreadcrumbClick(false)}>
                    Categories
                  </a>
                  {
                    selectedSubCategory && (
                      <>
                      <span style={{ margin: '0 10px 0 10px'}}> &gt; </span>
                    <a className={view != 'details' && !isCatProductAdd?"active":"categories1"} onClick={()=>handleBreadcrumbClick(true)}>
                      {selectedSubCategory?.name}
                    </a>
                      </>
                    )
                  }
                  {(view === 'details' || isCatProductAdd) && (
                    <>
                      <span style={{ margin: '0 10px 0 10px'}}> &gt; </span>
                      <a className="active">{selectedCategory?.name}</a>
                    </>
                  )}
                </div>
                <div className="buttons2 sync-button-cat">
                  {/* <div className="button-base13">
                    <img className="icons13" alt="" src="/icons-111.svg" />
                  </div> */}
                  <div style={{cursor:'pointer'}} onClick={fetchLatestDataAndMap} className="button-base14">
                    <img className="icons14" alt="" src="/icons-121.svg" />
                  </div>
                </div>
              </div>
            
              {view === 'categories' ? renderCards() : 
              <ConfigurationProductDisplayCatDetails 
                selectedCategory={selectedCategory}
                products={products}
                allCatWithProducts = {productCategories}
                fetchLatestDataAndMap = {fetchLatestDataAndMap}
                openAddPopup = {openAddPopup}
              />}
          </div>
        </div>
      </div>

      {/* Add/Rename Category Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{isRenaming ? 'Rename Category' : 'Add Category'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="categoryName"
            label="Category Name"
            type="text"
            fullWidth
            variant="standard"
            value={categoryName}
            onChange={handleCategoryNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={isRenaming ? handleRenameCategory : handleAddCategory}>
            {isRenaming ? 'Rename' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete this category?</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmRemove} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default ConfigurationProductDisplay;
