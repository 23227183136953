import React, { useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function MUIVirtualizedTable({ payloadData }) {
  // Define the columns
  const columns = useMemo(
    () => [
      {
        field: "total_gross_sale_bd",
        headerName: "GROSS SALES",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "discount",
        headerName: "DISCOUNT",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "total_charged_inc_tip",
        headerName: "CHARGED (INCL. TIP)",
        minWidth: 250,
        flex: 1,
      },
      {
        field: "total_collected_without_vat",
        headerName: "COLLECTED WITHOUT VAT",
        minWidth: 300,
        flex: 1,
      },
      {
        field: "tips",
        headerName: "TIPS",
        minWidth: 150,
        flex: 1,
      },
    ],
    []
  );

  // Preprocess payloadData to replace null/undefined with "0.00"
  const processedData = useMemo(() => {
    if (!payloadData) return null;

    return Object.keys(payloadData).reduce((acc, key) => {
      acc[key] = payloadData[key] ?? "0.00";
      return acc;
    }, {});
  }, [payloadData]);

  // Create rows from processed data
  const rows = useMemo(() => {
    if (processedData) {
      return [{ id: 1, ...processedData }];
    }
    return []; // Return an empty array if payloadData is undefined or null
  }, [processedData]);

  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
      />
    </div>
  );
}
