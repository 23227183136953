import React , {useState } from "react";
import { Box, Button, FormHelperText, Modal, Typography } from "@mui/material";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import '../style.css'
import { useFormik } from "formik";
import * as Yup from 'yup';
import { t } from "i18next";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
import { useErrorHandler } from "../../../hooks";
import { EditImageAction } from "../../../Redux/Actions/LocationActions";
import ImageCropper from "../../../component/ImageCropper";
import commonFunctions from "../../../common/functions";

const EditImgModel = ({ coverImgOpen, profileImgOpen, locationId, handleClose, setShowProfileImage }) => {
    const dispatch = useDispatch();
    const { setMsgConfig } = useErrorHandler();
    // const { loading } = useSelector((state) => state.Location);
    const [loadingBtn,setLoadingBtn ] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [fileDataURL, setFileDataURL] = useState(null);
    const [image, setImage] = useState("");

    const formik = useFormik({
        initialValues: {
            image: image,
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            image: Yup.string().required("Website image is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            setLoadingBtn(true)
            const uploadedUrl = await commonFunctions.uploadImageToAws(
                formik.values.image
              );
              setImage(uploadedUrl);
            //   setShowProfileImage(uploadedUrl);
            // const payload = {
            //     "LocationId": locationId,
            //     "image": formik.values.image
            // }
            dispatch(EditImageAction(uploadedUrl));
            setMsgConfig({
                alertOpen: true,
                message: "Message",
                severity: "Severity",
                autoHideDuration: 2000,
            });
            handleClose(uploadedUrl);
            resetForm();
            handleModalClose();
            setLoadingBtn(false)
        },
    });
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const img = new Image();
                img.onload = async () => {
                  console.log(img.naturalWidth, img.naturalHeight, "natural");
                //   if ((img.naturalWidth / img.naturalHeight).toFixed(2) === 1) {
                //     const uploadedUrl = await commonFunctions.uploadImageToAws(
                //       reader.result
                //     );
                //     setImage(uploadedUrl);
                //     setShowProfileImage(uploadedUrl);
                //   } else {
                    console.log((img.naturalWidth / img.naturalHeight).toFixed(2));
                    setFileDataURL(reader.result);
                    setShowImageModal(true);
                    formik.setTouched({ image: true }, true);
                //   }
                };
                img.src = reader.result;
              };
              reader.readAsDataURL(file);
        }
    };

    const handleModalClose = () => {
        setImage("");
        setFileDataURL(null);
        handleClose();
    };

    return (
        <>
            <Modal
                open={coverImgOpen || profileImgOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='modal-body user-edit-modal'>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {`Edit ${coverImgOpen ? "Cover" : "Profile"} Image`}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                        {t("website_image")}
                    </Typography>
                    <Box className='website-image-box'>
                        {
                            formik.values.image ?
                                <img src={formik.values.image} style={{ maxWidth: '100%', maxHeight: '100%' }} /> :
                                <>
                                    <input type="file" id="file" name='image' accept="image/*" onChange={handleImageChange} />
                                    <label for="file"><ImageOutlinedIcon /></label >
                                </>
                        }
                    </Box>
                    {formik.touched.image && formik.errors.image && (
                        <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                            {formik.errors.image}
                        </FormHelperText>
                    )}
                    {/* <Typography variant="p">{t("aspect_ratio")}- 1:1</Typography> */}
                    <Box className='modal-btn'>
                        <Button className="cancel-btn" onClick={handleModalClose}>{t("cancel_btn")}</Button>
                        <Button className="submit-btn" onClick={formik.handleSubmit}>
                            {loadingBtn ?
                                <ReactLoading
                                    type={"spin"}
                                    height="30px"
                                    width="30px"
                                    color="white"
                                />
                                : t("save_btn")
                            }</Button>
                    </Box>
                </Box>
            </Modal >

            <ImageCropper
        show={showImageModal}
        setShowImageModal={setShowImageModal}
        fileDataURL={fileDataURL}
        //   setCroppedImage={setCroppedImage}
        setImage={setImage}
        setShowProfileImage={setShowProfileImage}
        aspectRatio={0}
      />
        </>
    )
}
export default EditImgModel