

import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, FormControl, FormControlLabel, FormHelperText, Grid, List, ListItem, MenuItem, Modal, Radio, RadioGroup, Select, TextField, TextareaAutosize, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { t } from "i18next";
import ReactLoading from "react-loading";
import CloseIcon from '@mui/icons-material/Close';
import '../style.css'
import NutritionalValueCalculator from "./NutritionalValueCalculator";
import AddNewModifier from "./AddNewModifier";
import { useErrorHandler } from "../../../hooks";
import { AddMenuProductAction, AddNutriAndCalcAction } from "../../../Redux/Actions/LocationActions";

const AddProduct = ({ productOpen, handleClose }) => {
    const dispatch = useDispatch();
    const { setMsgConfig } = useErrorHandler();
    const { loading } = useSelector((state) => state.Location);
    const [modifierOpen, setModifierOpen] = useState(false);
    const [nutritionOpen, setNutritionOpen] = useState(false);
    const modifiersList = [
        { label: 'First Modifier Category' },
        { label: 'Second Modifier Category' },
        { label: 'third Modifier Category' },
    ];
    const [modifierValue, setModifierValue] = useState(modifiersList[0]?.label);
    const [age, setAge] = React.useState(10);
    const handleAutocompleteChange = (event, newValue) => {
        if (newValue) {
            setModifierValue(newValue.label);
        }
    };
    const handleModifierClose = () => {
        setModifierOpen(false)
    }
    const handleNutritionClose = () => {
        setNutritionOpen(false)
    }
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    useEffect(() => {
        if (nutritionOpen) {
            dispatch(AddNutriAndCalcAction());
        }
    }, [nutritionOpen])

    const formik = useFormik({
        initialValues: {
            category: "",
            product_name: "",
            Greutate_neta: "",
            Pret_vanzare: "",
            descreiere_scurta: "",
            descreiere_lunga: "",
            modifiers: "",
            nutritional_values: "",

        },
        enableReinitialize: true,
        // validationSchema: Yup.object({
        //     category: Yup.string().required("please select category is required"),
        //     product_name: Yup.string().required("product name is required"),
        //     Greutate_neta: Yup.string().required("Greutate neta is required"),
        //     Pret_vanzare: Yup.string().required("Pret vanzare is required"),
        //     descreiere_scurta: Yup.string().required("descreiere scurta name is required"),
        //     descreiere_lunga: Yup.string().required("descreiere lunga name is required"),
        //     modifiers: Yup.string().required("modifiers is required"),
        //     nutritional_values: Yup.string().required("nutritional values is required"),
        // }),
        onSubmit: (values, { resetForm }) => {
            const payload = {
                "MenuID": "e9d1b1ad-d80b-4eac-a3d8-f878133f6b86",
                "CategoryID": "dfd41cf4-1014-4aed-929b-8fdaf3f2f167",
                "name": formik.values.product_name,
                "image": "1700060372461-001_4873-PS.jpg",
                "weight": 275,
                "price": 220,
                "ingredients": [
                    {
                        "name": "Salam chorizo",
                        "amount": 50,
                        "unit": "grams",
                        "energyValue": "200.5 kcal/843.7kj",
                        "fats": 16.35,
                        "fattyAcids": 8.6,
                        "carbohydrates": 37,
                        "sugars": 55,
                        "proteins": 12,
                        "salt": 2.3,
                        "isFrozen": false,
                        "allergens": ["Allergen 1", "Allergen 2"],
                        "potentialAllergens": ["Allergen 1", "Allergen 2"],
                        "additives": ["Allergen 1", "Allergen 2"]
                    }, {
                        "name": "Oil",
                        "amount": 20,
                        "unit": "grams",
                        "energyValue": "200.5 kcal/843.7kj",
                        "fats": 16.35,
                        "fattyAcids": 8.6,
                        "carbohydrates": 37,
                        "sugars": 55,
                        "proteins": 12,
                        "salt": 2.3,
                        "isFrozen": false,
                        "allergens": ["Allergen 1", "Allergen 2"],
                        "potentialAllergens": ["Allergen 1", "Allergen 2"],
                        "additives": ["Allergen 1", "Allergen 2"]
                    }
                ],
                "additives": ["Coca-cola", "Sprite", "Water"],
                "shortDescription": "Short description 1",
                "longDescription": "Menu Product 3 Long Description 1",
                "isForAdultsOnly": false,
                "isFrozen": false
            }
            dispatch(AddMenuProductAction(payload));
            setMsgConfig({
                alertOpen: true,
                message: "Message",
                severity: "Severity",
                autoHideDuration: 2000,
            });
            resetForm();
            handleClose()
        },
    });

    return (
        <>
            <AddNewModifier modifierOpen={modifierOpen} handleClose={handleModifierClose} />
            <NutritionalValueCalculator nutritionOpen={nutritionOpen} handleClose={handleNutritionClose} />
            <Modal
                className="add-product-modal-box"
                open={productOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: "100vh"
                }}>
                    <Box className='modal-body add-category-modal'>
                        <Box className='add-product-modal'>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    {t("add_product")}
                                </Typography>
                                <CloseIcon onClick={handleClose}
                                    sx={{ color: '#ffb800', cursor: 'pointer', fontSize: '30px' }} />
                            </Box>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                className="radio-buttons"
                            >
                                <FormControlLabel value="female" control={<Radio />} label={t("doar_peste_label")} />
                                <FormControlLabel value="male" control={<Radio />} label={t("inghetat_label")} />
                            </RadioGroup>
                            <Grid container spacing={2} sx={{
                                alignItems: 'baseline'
                            }}>
                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <Box className='input-field-title'>
                                        <FormControl fullWidth>
                                            <label id="demo-simple-select-label">{t("category_label")}</label>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="category"
                                                value={formik.values.category}
                                                onChange={formik.handleChange}
                                            >
                                                <MenuItem value={10}>{t("ebriza_option")}</MenuItem>
                                                <MenuItem value={10}>{t("ebriza_option")}</MenuItem>
                                                <MenuItem value={10}>{t("ebriza_option")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {formik.touched.category && formik.errors.category && (
                                            <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                                {formik.errors.category}
                                            </FormHelperText>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <Box className='input-field-title'>
                                        <label>{t("name_produs_label")}</label>
                                        <TextField name="product_name" fullWidth placeholder="40a25638-5eb0-4a4e-b4..." value={formik.values.product_name} onChange={formik.handleChange} />
                                        {formik.touched.product_name && formik.errors.product_name && (
                                            <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                                {formik.errors.product_name}
                                            </FormHelperText>
                                        )}
                                    </Box>

                                </Grid>
                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <Box className='input-field-title'>
                                        <label>{t("greutate_neta")}</label>
                                        <TextField name="Greutate_neta" fullWidth placeholder="200" value={formik.values.Greutate_neta} onChange={formik.handleChange} />
                                        {formik.touched.Greutate_neta && formik.errors.Greutate_neta && (
                                            <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                                {formik.errors.Greutate_neta}
                                            </FormHelperText>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item lg={3} md={3} sm={6} xs={12} sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    '& .input-field-title': {
                                        width: '100%'
                                    }
                                }}>
                                    <Box className='input-field-title'>
                                        <label>{t("pret_vanzare")}</label>
                                        <TextField name="Pret_vanzare" fullWidth placeholder="200" value={formik.values.Pret_vanzare} onChange={formik.handleChange} />
                                        {formik.touched.Pret_vanzare && formik.errors.Pret_vanzare && (
                                            <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                                {formik.errors.Pret_vanzare}
                                            </FormHelperText>
                                        )}
                                    </Box>
                                    <Typography sx={{
                                        marginTop: '20px !important'
                                    }}>{t("ron_number")}</Typography>
                                </Grid>
                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                    <Box className='input-field-title'>
                                        <label>{t("descreiere_scurta")}</label>
                                        <TextareaAutosize name="descreiere_scurta" fullWidth placeholder="write here ..." value={formik.values.descreiere_scurta} onChange={formik.handleChange} />
                                        {formik.touched.descreiere_scurta && formik.errors.descreiere_scurta && (
                                            <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                                {formik.errors.descreiere_scurta}
                                            </FormHelperText>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item lg={9} md={9} sm={12} xs={12}>
                                    <Box className='input-field-title'>
                                        <label>{t("descriere_lunga")}</label>
                                        <TextareaAutosize name="descreiere_lunga" fullWidth placeholder="40a25638-5eb0-4a4e-b4..."
                                            value={formik.values.descreiere_lunga} onChange={formik.handleChange}
                                        />
                                        {formik.touched.descreiere_lunga && formik.errors.descreiere_lunga && (
                                            <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                                {formik.errors.descreiere_lunga}
                                            </FormHelperText>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box className='modifier-accordion'>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon sx={{ color: '#FFB800' }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>{t("modifiers_title")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box className='input-field-title modifier-field'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={modifiersList}
                                                value={modifiersList.find((option) => option.label === modifierValue)}
                                                getOptionLabel={(modifiersList) => modifiersList.label}
                                                renderInput={(params) => <TextField {...params} />}
                                                onChange={handleAutocompleteChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <SearchIcon />
                                                    ),
                                                }}
                                                renderOption={(modifiersList) => (
                                                    <Box className='modifier-category-wrap'>
                                                        <Box className='modifier-category-title'>
                                                            <Typography>{t("modifier_category")}</Typography>
                                                            <EditOutlinedIcon />
                                                            <DeleteOutlinedIcon sx={{ color: '#EA252D' }} />
                                                        </Box>
                                                        <Box className='category-list-wrap'>
                                                            <List>
                                                                <ListItem>
                                                                    <Typography>Modifier Category</Typography>
                                                                    <Typography variant="span">Price RON</Typography>
                                                                </ListItem>
                                                                <ListItem>
                                                                    <Typography>Modifier Category</Typography>
                                                                    <Typography variant="span">Price RON</Typography>
                                                                </ListItem>
                                                                <ListItem>
                                                                    <Typography>Modifier Category</Typography>
                                                                    <Typography variant="span">Price RON</Typography>
                                                                </ListItem>
                                                                <ListItem>
                                                                    <Typography>Modifier Category</Typography>
                                                                    <Typography variant="span">Price RON</Typography>
                                                                </ListItem>
                                                            </List>
                                                        </Box>
                                                    </Box>,
                                                    <Box className='modifier-category-wrap'>
                                                        <Box className='modifier-category-title'>
                                                            <Typography>First Modifier Category</Typography>
                                                            <EditOutlinedIcon />
                                                            <DeleteOutlinedIcon sx={{ color: '#EA252D' }} />
                                                        </Box>
                                                        <Box className='category-list-wrap'>
                                                            <List>
                                                                <ListItem>
                                                                    <Typography>Modifier Category</Typography>
                                                                    <Typography variant="span">Price RON</Typography>
                                                                </ListItem>
                                                                <ListItem>
                                                                    <Typography>Modifier Category</Typography>
                                                                    <Typography variant="span">Price RON</Typography>
                                                                </ListItem>
                                                                <ListItem>
                                                                    <Typography>Modifier Category</Typography>
                                                                    <Typography variant="span">Price RON</Typography>
                                                                </ListItem>
                                                                <ListItem>
                                                                    <Typography>Modifier Category</Typography>
                                                                    <Typography variant="span">Price RON</Typography>
                                                                </ListItem>
                                                            </List>
                                                        </Box>
                                                    </Box>
                                                )}
                                            />
                                            <Button className="add-icon-btn">
                                                <AddIcon />
                                            </Button>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon sx={{ color: '#FFB800' }} />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>{t("nutritional_values")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TextareaAutosize name="nutritional_values" placeholder="bla bla bla" value={formik.values.nutritional_values} onChange={formik.handleChange} />
                                        {formik.touched.nutritional_values && formik.errors.nutritional_values && (
                                            <FormHelperText className="error" style={{ color: 'red', textAlign: 'start', margin: '10px 5px' }}>
                                                {formik.errors.nutritional_values}
                                            </FormHelperText>
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                            <Box className='modal-btn'>
                                <Button className="cancel-btn" onClick={() => { setModifierOpen(true); handleClose() }}>{t("add_modifier_btn")}</Button>
                                <Button className="cancel-btn" onClick={() => { setNutritionOpen(true); handleClose() }}>{t("nutritional_btn")}</Button>
                                <Button className="submit-btn" onClick={formik.handleSubmit}>{loading ? <ReactLoading
                                    type={"spin"}
                                    height="30px"
                                    width="30px"
                                    color="white"
                                /> : t("submit_btn")}</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal >
        </>
    )
}
export default AddProduct