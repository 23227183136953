import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Modal,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import uploadImg from "../../../assets/img/upload.svg";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
import "../style.css";
import { useErrorHandler } from "../../../hooks";
import { AddMenuCategoryAction } from "../../../Redux/Actions/LocationActions";
import commonFunctions from "../../../common/functions";
import "cropperjs/dist/cropper.css";
import ImageCropper from "../../../component/ImageCropper";

const AddCategory = ({ categoryOpen, handleClose }) => {
  const dispatch = useDispatch();
  const { setMsgConfig } = useErrorHandler();
  const { loading } = useSelector((state) => state.Location);
  const [image, setImage] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [description, setDescription] = useState("");
  const [fileDataURL, setFileDataURL] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      category_name: categoryName,
      description: description,
      image: image,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      category_name: Yup.string().required("Category Name is required"),
      description: Yup.string().required("Description is required"),
      image: Yup.string().required("Website Image is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      const payload = {
        MenuID: "e9d1b1ad-d80b-4eac-a3d8-f878133f6b86",
        name: values.category_name,
        description: values.description,
        image: values.image,
      };
      dispatch(AddMenuCategoryAction(payload));
      setMsgConfig({
        alertOpen: true,
        message: "Message",
        severity: "Severity",
        autoHideDuration: 2000,
      });
      handleClear();
    },
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = async () => {
          console.log(img.naturalWidth, img.naturalHeight, "natural");
          if ((img.naturalWidth / img.naturalHeight).toFixed(2) === 1.34) {
            const uploadedUrl = await commonFunctions.uploadImageToAws(
              reader.result
            );
            setImage(uploadedUrl);
          } else {
            console.log((img.naturalWidth / img.naturalHeight).toFixed(2));
            setFileDataURL(reader.result);
            setShowImageModal(true);
            formik.setTouched({ image: true }, true);
          }
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClear = () => {
    setCategoryName("");
    setDescription("");
    setImage(null);
    handleClose();
  };

  return (
    <>
      <Modal
        open={categoryOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          position: "absolute",
          top: "10%",
          left: "10%",
          overflow: "scroll",
          height: "100%",
          display: "block",
        }}
      >
        <Box className="modal-body add-category-modal">
          <Box className="add-category-modal-inner">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("add_category")}
            </Typography>
            <Box className="category-list">
              <Box className="category-list-inner">
                <Box className="category-filled">
                  <label>{t("category_name")}</label>
                  <TextField
                    name="category_name"
                    fullWidth
                    placeholder="Lorem ipsum"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                  {formik.touched.category_name &&
                    formik.errors.category_name && (
                      <FormHelperText
                        className="error"
                        style={{
                          color: "red",
                          textAlign: "start",
                          margin: "2px 5px",
                        }}
                      >
                        {formik.errors.category_name}
                      </FormHelperText>
                    )}
                </Box>
                <Box className="category-filled">
                  <label>{t("description_title")}</label>
                  <TextareaAutosize
                    placeholder="write something here......."
                    name="description"
                    minRows={5}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <FormHelperText
                      className="error"
                      style={{
                        color: "red",
                        textAlign: "start",
                        margin: "2px 5px",
                      }}
                    >
                      {formik.errors.description}
                    </FormHelperText>
                  )}
                </Box>
              </Box>
              <Box className="upload-image-box">
                <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                  {t("website_image")}
                </Typography>
                <Box className="website-image-box">
                  <input
                    type="file"
                    id="file"
                    name="image"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e)}
                  />
                  <label htmlFor="file">
                    {image ? (
                      <img
                        src={image}
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    ) : (
                      <>
                        <Box sx={{ width: 64, margin: "auto" }}>
                          <img
                            src={uploadImg}
                            alt="upload"
                            className="img-fluid"
                          />
                        </Box>
                        <Typography>Drop Image</Typography>
                        <Typography
                          sx={{
                            fontSize: "12px !important",
                            color: "#00000030 !important",
                          }}
                        >
                          or
                        </Typography>
                        <Button
                          variant="outlined"
                          className="browse-btn"
                          sx={{ mt: 1 }}
                        >
                          Browse
                        </Button>
                      </>
                    )}
                  </label>
                </Box>
                {formik.touched.image && formik.errors.image && (
                  <FormHelperText
                    className="error"
                    style={{
                      color: "red",
                      textAlign: "start",
                      margin: "10px 5px",
                    }}
                  >
                    {formik.errors.image}
                  </FormHelperText>
                )}
                <Typography
                  sx={{
                    fontSize: "12px !important",
                    color: "#403C3C !important",
                  }}
                >
                  {t("aspect_ratio")} - 4:3
                </Typography>
              </Box>
            </Box>
            <Box className="modal-btn">
              <Button className="cancel-btn" onClick={() => handleClear()}>
                {t("cancel_btn")}
              </Button>
              <Button className="submit-btn" onClick={formik.handleSubmit}>
                {loading ? (
                  <ReactLoading
                    type={"spin"}
                    height="30px"
                    width="30px"
                    color="white"
                  />
                ) : (
                  t("save_btn")
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <ImageCropper
        show={showImageModal}
        setShowImageModal={setShowImageModal}
        fileDataURL={fileDataURL}
        //   setCroppedImage={setCroppedImage}
        setImage={setImage}
      />
    </>
  );
};

export default AddCategory;
