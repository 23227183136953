import "./ConfigurationProduct.css";
import { useState, useEffect, useMemo } from "react";
import AddProduct from "./AddProduct.jsx";
import { TextField, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from '../../axiosConfig';
import { useNavigate } from 'react-router-dom';

export default function ConfigurationProduct({ products,fetchLatestDataAndMap,syncToPos }) {
  const [addingProduct, setAddingProduct] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(15);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuRowId, setMenuRowId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [productToRemove, setProductToRemove] = useState(null);
  const ebrizaLinkedId = localStorage.getItem('ebrizaLinkedId')
  const navigate = useNavigate();
  useEffect(() => {
    if (products && products.length > 0) {
      setIsLoading(false);
    }
  }, [products]);

  function syncPOS(event) {
    event.preventDefault();
    console.log("Sync to POS working!");
  }

  function handleAddProduct(event) {
    event.preventDefault();
    console.log("Add Product Logic!");

    navigate('/configuration/product/new')
  }

  function toggleAddingProduct() {
    setAddingProduct(!addingProduct);
  }

  function toggleSearchBar(event) {
    event.preventDefault();
    setToggleSearch(!toggleSearch);
  }

  function handleBulkEdit(event) {
    event.preventDefault();
    console.log("Bulk Edit logic missing!");
  }

  function handleSelectAll(event) {
    if (event.target.checked) {
      setSelectedProducts(products.map((product) => product.id));
    } else {
      setSelectedProducts([]);
    }
  }

  function handleProductSelect(productId) {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(productId)
        ? prevSelected.filter((id) => id !== productId)
        : [...prevSelected, productId]
    );
  }

  const handleMenuClick = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setMenuRowId(rowId);
  };

  const handleEditProduct = (product) =>{
    let prod = products.find((ele)=>ele.id == product.id)
    console.log("edit product..",product);
    handleMenuClose()
    navigate(`/configuration/product/${prod.ProductId}`)
  }
  const handleRemoveProduct = (product) =>{
    handleMenuClose()
    setProductToRemove(product);
    setOpenConfirmDialog(true);

  }


  async function handleConfirmRemove() {
    console.log("Deleting product:", productToRemove);
    
    try {
      let addMenuCat = await axios.delete(`/partners/menu_products/${productToRemove.id}`)
      if (addMenuCat.data) {
        fetchLatestDataAndMap()
      }
      
    } catch (error) {
      console.log("Failed to delete product",error.message);
      
    } finally{
      setOpenConfirmDialog(false);
      setProductToRemove(null);

    }
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuRowId(null);
  };

  const columns = useMemo(
    () => [
      {
        field: "category",
        headerName: "Category",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "name",
        headerName: "Name",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "tag",
        headerName: "Tag",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "priceVAT",
        headerName: "Price+VAT",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "vat",
        headerName: "VAT",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "actions",
        headerName: "",
        sortable: false,
        renderCell: (params) => (
          <>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => handleMenuClick(event, params.row.id)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={menuRowId === params.row.id}
              onClose={handleMenuClose}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: '20ch',
                },
              }}
            >
              <MenuItem onClick={()=> handleEditProduct(params.row)}>Edit</MenuItem>
              {!ebrizaLinkedId &&<MenuItem onClick={() => handleRemoveProduct(params.row)}>Remove</MenuItem>}
            </Menu>
          </>
        ),
        width: 50,
      },
    ],
    [selectedProducts, anchorEl, menuRowId]
  );

  const rows = useMemo(
    () =>
      products.map((product) => ({
        id: product.id,
        category: product.category,
        name: product.name,
        tag: product.tags,
        priceVAT: product.priceVAT,
        vat: product.VATPercent,
      })),
    [products]
  );

  return (
    <>
      <div className="topbar-inner">
       
          <div className="link-parent-parent">
            <div className="link-parent">
              <div className="links">
                <div className="link">
                  <div className="div">Product list</div>
                </div>
                {/* <div className="second-link">
                  <div className="link1">
                    <a className="a">Quick edit</a>
                  </div>
                </div> */}
              </div>
            </div>
            <form className="data">
              <div className="data-label-parent">
                <div className="data-label">{Math.min(pageSize,products.length)}/{products.length}</div>
                <div className="actions">
                  {/* <button className="button-base" onClick={handleBulkEdit}>
                    <img className="icons" alt="" src="/icons-9.svg" />
                    <div className="text">Bulk editing</div>
                  </button> */}
                  <div className="right">
                    {!ebrizaLinkedId && (
                    <button
                      className="button-base1"
                      onClick={handleAddProduct}
                    >
                      <img className="icons1" alt="" src="/icons-53.svg" />
                    </button>

                    )}
                    {/* <div className="button-base2">
                      <img className="icons2" alt="" src="/icons-11.svg" />
                    </div>
                    <div className="button-base3">
                      <img className="icons3" alt="" src="/icons-12.svg" />
                    </div>
                    <div className="button-base4">
                      <img className="icons4" alt="" src="/icons-13.svg" />
                    </div>
                    <div className="button-base5">
                      <img className="icons5" alt="" src="/icons-14.svg" />
                    </div>
                    <div className="button-base6">
                      <img className="icons6" alt="" src="/icons-15.svg" />
                    </div>
                    <button
                      className="button-base7"
                      onClick={toggleSearchBar}
                    >
                      <img className="icons7" alt="" src="/icons-16.svg" />
                    </button> */}
                    <button  style={ ebrizaLinkedId?{cursor:'not-allowed'}:{} } className="button-base8" onClick={(event)=>{
                      event.preventDefault();
                      syncToPos()
                    }}>
                      <img className="icons8" alt="" src="/icons-11.svg" />
                      <div className="text1">Sync to POS</div>
                    </button>
                  </div>
                </div>
              </div>
              {toggleSearch && (
                <TextField
                  className="input3"
                  placeholder="name, tag, category, product recipe, active / inactive, delivery, external code"
                  variant="outlined"
                  sx={{
                    "& fieldset": { borderColor: "#cbcac8" },
                    "& .MuiInputBase-root": {
                      height: "44px",
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                    },
                    "& .MuiInputBase-input": { color: "#3c3a35" },
                  }}
                />
              )}
              <div style={{ height: 600, width: "100%" }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={pageSize}
                  pagination
                  rowsPerPageOptions={[5, 10, 20]}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  checkboxSelection
                  disableRowSelectionOnClick
                  onSelectionModelChange={(newSelection) => {
                    setSelectedProducts(newSelection.selectionModel);
                  }}
                />
              </div>
            </form>
          </div>
        
        
      </div>
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete this product?</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmRemove} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
