export const GET_LOCATION_REQUEST = "GET_LOCATION_REQUEST";
export const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS";
export const GET_LOCATION_FAILED = "GET_LOCATION_FAILED";
export const GET_LOCATION_INFO = "GET_LOCATION_INFO";

export const ADD_LOCATION_REQUEST = "ADD_LOCATION_REQUEST";
export const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS";
export const ADD_LOCATION_FAILED = "ADD_LOCATION_FAILED";

export const EDIT_IMAGE_REQUEST = "EDIT_IMAGE_REQUEST";
export const EDIT_IMAGE_SUCCESS = "EDIT_IMAGE_SUCCESS";
export const EDIT_IMAGE_FAILED = "EDIT_IMAGE_FAILED";

export const ADD_MENU_REQUEST = "ADD_MENU_REQUEST";
export const ADD_MENU_SUCCESS = "ADD_MENU_SUCCESS";
export const ADD_MENU_FAILED = "ADD_MENU_FAILED";

export const ADD_MENU_CATEGORY_REQUEST = "ADD_MENU_CATEGORY_REQUEST";
export const ADD_MENU_CATEGORY_SUCCESS = "ADD_MENU_CATEGORY_SUCCESS";
export const ADD_MENU_CATEGORY_FAILED = "ADD_MENU_CATEGORY_FAILED";

export const ADD_MENU_PRODUCT_REQUEST = "ADD_MENU_PRODUCT_REQUEST";
export const ADD_MENU_PRODUCT_SUCCESS = "ADD_MENU_PRODUCT_SUCCESS";
export const ADD_MENU_PRODUCT_FAILED = "ADD_MENU_PRODUCT_FAILED";

export const ADD_MENU_MODIFIER_REQUEST = "ADD_MENU_MODIFIER_REQUEST";
export const ADD_MENU_MODIFIER_SUCCESS = "ADD_MENU_MODIFIER_SUCCESS";
export const ADD_MENU_MODIFIER_FAILED = "ADD_MENU_MODIFIER_FAILED";

export const GET_NUTRI_AND_CALC_REQUEST = "GET_NUTRI_AND_CALC_REQUEST";
export const GET_NUTRI_AND_CALC_SUCCESS = "GET_NUTRI_AND_CALC_SUCCESS";
export const GET_NUTRI_AND_CALC_FAILED = "GET_NUTRI_AND_CALC_FAILED";

export const ADD_REVIEWS_REQUEST = "ADD_REVIEWS_REQUEST";
export const ADD_REVIEWS_SUCCESS = "ADD_REVIEWS_SUCCESS";
export const ADD_REVIEWS_FAILED = "ADD_REVIEWS_FAILED";

export const GET_REVIEWS_REQUEST = "GET_REVIEWS_REQUEST";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_FAILED = "GET_REVIEWS_FAILED";

export const ADD_CONFIGURATIONS_REQUEST = "ADD_CONFIGURATIONS_REQUEST";
export const ADD_CONFIGURATIONS_SUCCESS = "ADD_CONFIGURATIONS_SUCCESS";
export const ADD_CONFIGURATIONS_FAILED = "ADD_CONFIGURATIONS_FAILED";

export const GET_CONFIGURATIONS_REQUEST = "GET_CONFIGURATIONS_REQUEST";
export const GET_CONFIGURATIONS_SUCCESS = "GET_CONFIGURATIONS_SUCCESS";
export const GET_CONFIGURATIONS_FAILED = "GET_CONFIGURATIONS_FAILED";
export const LOADING_TRUE = "LOADING_TRUE"

export const ADD_ROOM_REQUEST = "ADD_ROOM_REQUEST";
export const UPDATE_ROOM_NAME_REQUEST = "UPDATE_ROOM_NAME_REQUEST";
export const REMOVE_ROOM_REQUEST = "REMOVE_ROOM_REQUEST";

export const ADD_TABLE_REQUEST = "ADD_TABLE_REQUEST";
export const MOVE_TABLE_POSITION= "MOVE_TABLE_POSITION"
export const UPDATE_TABLE_DATA= "UPDATE_TABLE_DATA"
export const REMOVE_TABLE_REQUEST = "REMOVE_TABLE_REQUEST";

export const TAB_ID_REQUEST = "TAB_ID_REQUEST";

export const UPDATE_NUTRITION_DATA = 'UPDATE_NUTRITION_DATA';
export const UPDATE_NUTRITION_PORTION = 'UPDATE_NUTRITION_PORTION';

export const GetLocationAction = () => {
    return { type: GET_LOCATION_REQUEST };
};
export const AddLocationAction = (data) => {
    return { type: ADD_LOCATION_REQUEST, payload: data };
};
export const EditImageAction = (data) => {
    return { type: EDIT_IMAGE_REQUEST, payload: data };
};
export const AddMenuAction = (data) => {
    return { type: ADD_MENU_REQUEST, payload: data };
};
export const AddMenuCategoryAction = (data) => {
    return { type: ADD_MENU_CATEGORY_REQUEST, payload: data };
};
export const AddMenuProductAction = (data,handleNavigateToConf) => {
    return { type: ADD_MENU_PRODUCT_REQUEST, payload: {'payload':data,'handleNavigateToConf':handleNavigateToConf} };
};
export const AddMenumodifiertAction = (data) => {
    return { type: ADD_MENU_MODIFIER_REQUEST, payload: data };
};
export const AddNutriAndCalcAction = (data) => {
    return { type: GET_NUTRI_AND_CALC_REQUEST, payload: data };
};
export const UpdateNutrition = (data) => {
    console.log(data, "action")
    return { type: UPDATE_NUTRITION_DATA, payload: data };
};
export const UpdatePortion = (data) => {
    console.log(data, "action")
    return { type: UPDATE_NUTRITION_PORTION, payload: data };
};
export const AddReviewstAction = (data) => {
    return { type: ADD_REVIEWS_REQUEST, payload: data };
};
export const GetReviewstAction = () => {
    return { type: GET_REVIEWS_REQUEST };
};
export const AddConfigurationsAction = (data) => {
    return { type: ADD_CONFIGURATIONS_REQUEST, payload: data };
};
export const AddRoomAction = (data) => {
    return { type: ADD_ROOM_REQUEST, payload: data };
};
export const UpdateRoomNameAction = (data) => {
    return { type: UPDATE_ROOM_NAME_REQUEST, payload: data };
};
export const RemoveRoomAction = (data) => {
    return { type: REMOVE_ROOM_REQUEST, payload: data };
};
export const AddTableAction = (data) => {
    return { type: ADD_TABLE_REQUEST, payload: data };
};
export const MoveTableAction = (data) => {
    return { type: MOVE_TABLE_POSITION, payload: data };
};
export const UpdateTableAction = (data) => {
    return { type: UPDATE_TABLE_DATA, payload: data };
};
export const RemoveTableAction = (data) => {
    return { type: REMOVE_TABLE_REQUEST, payload: data };
};
export const TabIdAction = (data) => {
    return { type: TAB_ID_REQUEST, payload: data };
};
export const GetConfigurationstAction = (data) => {
    return { type: GET_CONFIGURATIONS_REQUEST , payload: data };
};