export const Allergens = [
  { value: "Ouă", label: "Ouă" },
  { value: "Soia", label: "Soia" },
  { value: "Lapte", label: "Lapte" },
  { value: "Țelină", label: "Țelină" },
  { value: "Lupin", label: "Lupin" },
  { value: "Moluște", label: "Moluște" },
  { value: "Beef", label: "Beef" },
  { value: "Frosted", label: "Frosted" },
  { value: "Banana", label: "Banana" },
  { value: "Pork", label: "Pork" },
  { value: "Mushroom", label: "Mushroom" },
  { value: "Gluten", label: "Gluten" },
  { value: "Spicy", label: "Spicy" },
  { value: "Susan", label: "Susan" },
  { value: "Muștar", label: "Muștar" },
  { value: "Vitamin", label: "Vitamin" },
  { value: "Chicken", label: "Chicken" },
  { value: "Peste", label: "Peste" },
  { value: "Arahide", label: "Arahide" },
  { value: "Crustacee", label: "Crustacee" },
  { value: "Sulfiti", label: "Sulfiti" },
];

export const Additives = [
  { value: "Curcumină", label: "Curcumină - E 100" },
  { value: "Riboflavine", label: "Riboflavine - E 101" },
  { value: "Tartrazină", label: "Tartrazină - E 102" },
  { value: "Galben de chinolină", label: "Galben de chinolină - E 104" },
  {
    value: "Galben Sunset (sunset yellow) FCF/galben portocaliu S",
    label: "Galben Sunset (sunset yellow) FCF/galben portocaliu S - E 110",
  },
  {
    value: "Coșenilă, acid carminic, carmine",
    label: "Coșenilă, acid carminic, carmine - E 120",
  },
  { value: "Azorubină, carmoizină", label: "Azorubină, carmoizină - E 122" },
  { value: "Amarant", label: "Amarant - E 123" },
  {
    value: "Ponceau 4R, Roșu coșenilă A",
    label: "Ponceau 4R, Roșu coșenilă A - E 124",
  },
  { value: "Eritrozină", label: "Eritrozină - E 127" },
  { value: "Roșu Allura AC", label: "Roșu Allura AC - E 129" },
  { value: "Albastru patent V", label: "Albastru patent V - E 131" },
  {
    value: "Indigotină, carmin indigo",
    label: "Indigotină, carmin indigo - E 132",
  },
  { value: "Albastru briliant FCF", label: "Albastru briliant FCF - E 133" },
  {
    value: "Clorofile și clorofiline",
    label: "Clorofile și clorofiline - E 140",
  },
  {
    value: "Complecși de cupru ai clorofilelor și clorofilinelor",
    label: "Complecși de cupru ai clorofilelor și clorofilinelor - E 141",
  },
  { value: "Verde S", label: "Verde S- E 142" },
  { value: "Caramel simplu", label: "Caramel simplu - E 150a" },
  {
    value: "Caramel de sulfit caustic",
    label: "Caramel de sulfit caustic - E 150b",
  },
  { value: "Caramel amoniacal", label: "Caramel amoniacal - E 150c" },
  {
    value: "Caramel cu sulfit de amoniu",
    label: "Caramel cu sulfit de amoniu - E 150d",
  },
  { value: "Negru briliant PN", label: "Negru briliant PN - E 151" },
  { value: "Cărbune vegetal", label: "Cărbune vegetal - E 153" },
  { value: "Brun HT", label: "Brun HT - E 155" },
  { value: "Caroteni", label: "Caroteni - E 160a" },
  {
    value: "Annatto, bixină, norbixină",
    label: "Annatto, bixină, norbixină - E 160b",
  },
  {
    value: "Extract de ardei roșu, capsantină, capsorubină",
    label: "Extract de ardei roșu, capsantină, capsorubină - E 160c",
  },
  { value: "Licopen", label: "Licopen - E 160d" },
  {
    value: "Beta-apo-8′-carotenal (C 30)",
    label: "Beta-apo-8′-carotenal (C 30) - E 160e",
  },
  { value: "Luteină", label: "Luteină - E 161b" },
  { value: "Cantaxantină", label: "Cantaxantină - E 161g" },
  { value: "Roșu sfeclă, betanină", label: "Roșu sfeclă, betanină - E 162" },
  { value: "Antocianine", label: "Antocianine - E 163" },
  { value: "Carbonat de calciu", label: "Carbonat de calciu - E 170" },
  { value: "Dioxid de titan", label: "Dioxid de titan - E 171" },
  {
    value: "Oxizi și hidroxizi de fier",
    label: "Oxizi și hidroxizi de fier - E 172",
  },
  { value: "Aluminiu", label: "Aluminiu - E 173" },
  { value: "Argint", label: "Argint - E 174" },
  { value: "Aur", label: "Aur - E 175" },
  { value: "Litolrubină BK", label: "Litolrubină BK - E 180" },
  { value: "Sorbitoli", label: "Sorbitoli - E 420" },
  { value: "Manitol", label: "Manitol - E 421" },
  { value: "Acesulfam-K", label: "Acesulfam-K - E 950" },
  { value: "Aspartam", label: "Aspartam - E 951" },
  { value: "Ciclamați", label: "Ciclamați - E 952" },
  { value: "Izomalt", label: "Izomalt - E 953" },
  { value: "Zaharine", label: "Zaharine - E 954" },
  { value: "Sucraloză", label: "Sucraloză - E 955" },
  { value: "Taumatină", label: "Taumatină - E 957" },
  { value: "Neohesperidină DC", label: "Neohesperidină DC - E 959" },
  {
    value: "Glicozide derivate din steviol",
    label: "Glicozide derivate din steviol - E 960",
  },
  { value: "Neotam", label: "Neotam - E 961" },
  {
    value: "Sare de aspartam-acesulfam",
    label: "Sare de aspartam-acesulfam - E 962",
  },
  { value: "Sirop de poliglicito", label: "Sirop de poliglicito - E 964" },
  { value: "Maltitoli", label: "Maltitoli - E 965" },
  { value: "Lactitol", label: "Lactitol - E 966" },
  { value: "Xilitol", label: "Xilitol - E 967" },
  { value: "Eritritol", label: "Eritritol - E 968" },
  { value: "Advantam", label: "Advantam - E 969" },
  { value: "Acid sorbic", label: "Acid sorbic - E 200" },
  { value: "Sorbat de potasiu", label: "Sorbat de potasiu - E 202" },
  { value: "Sorbat de calciu", label: "Sorbat de calciu - E 203" },
  { value: "Acid benzoic", label: "Acid benzoic - E 210" },
  { value: "Benzoat de sodiu", label: "Benzoat de sodiu - E 211" },
  { value: "Benzoat de potasiu", label: "Benzoat de potasiu - E 212" },
  { value: "Benzoat de calciu", label: "Benzoat de calciu - E 213" },
  {
    value: "P-hidroxibenzoat de etil",
    label: "P-hidroxibenzoat de etil - E 214",
  },
  {
    value: "Sarea de sodiu a p-hidroxibenzoatului de etil",
    label: "Sarea de sodiu a p-hidroxibenzoatului de etil - E 215",
  },
  {
    value: "P-hidroxibenzoat de metil",
    label: "P-hidroxibenzoat de metil - E 218",
  },
  {
    value: "Sarea de sodiu a p-hidroxibenzoatului de metil",
    label: "Sarea de sodiu a p-hidroxibenzoatului de metil - E 219",
  },
  { value: "Dioxid de sulf", label: "Dioxid de sulf - E 220" },
  { value: "Sulfit de sodiu", label: "Sulfit de sodiu - E 221" },
  { value: "Sulfit acid de sodiu", label: "Sulfit acid de sodiu - E 222" },
  { value: "Metabisulfit de sodiu", label: "Metabisulfit de sodiu - E 223" },
  {
    value: "Metabisulfit de potasiu",
    label: "Metabisulfit de potasiu - E 224",
  },
  { value: "Sulfit de calciu", label: "Sulfit de calciu - E 226" },
  { value: "Sulfit acid de calciu", label: "Sulfit acid de calciu - E 227" },
  { value: "Sulfit acid de potasiu", label: "Sulfit acid de potasiu - E 228" },
  { value: "Nizină", label: "Nizină - E 234" },
  { value: "Natamicină", label: "Natamicină - E 235" },
  { value: "Hexametilentetramină", label: "Hexametilentetramină - E 239" },
  { value: "Dicarbonat de dimetil", label: "Dicarbonat de dimetil - E 242" },
  { value: "Etil lauroil arginat", label: "Etil lauroil arginat - E 243" },
  { value: "Nitrit de potasiu", label: "Nitrit de potasiu - E 249" },
  { value: "Nitrit de sodiu", label: "Nitrit de sodiu - E 250" },
  { value: "Nitrat de sodiu", label: "Nitrat de sodiu - E 251" },
  { value: "Nitrat de potasiu", label: "Nitrat de potasiu - E 252" },
  { value: "Acid acetic", label: "Acid acetic - E 260" },
  { value: "Acetați de potasiu", label: "Acetați de potasiu - E 261" },
  { value: "Acetați de sodiu", label: "Acetați de sodiu - E 262" },
  { value: "Acetat de calciu", label: "Acetat de calciu - E 263" },
  { value: "Acid lactic", label: "Acid lactic - E 270" },
  { value: "Acid propionic", label: "Acid propionic - E 280" },
  { value: "Propionat de sodiu", label: "Propionat de sodiu - E 281" },
  { value: "Propionat de calciu", label: "Propionat de calciu - E 282" },
  { value: "Propionat de potasiu", label: "Propionat de potasiu - E 283" },
  { value: "Acid boric", label: "Acid boric - E 284" },
  {
    value: "Tetraborat de sodiu (borax)",
    label: "Tetraborat de sodiu (borax) - E 285",
  },
  { value: "Dioxid de carbon ", label: "Dioxid de carbon - E 290" },
  { value: "Acid malic", label: "Acid malic - E 296" },
  { value: "Acid fumaric", label: "Acid fumaric - E 297" },
  { value: "Acid ascorbic", label: "Acid ascorbic - E 300" },
  { value: "Ascorbat de sodiu", label: "Ascorbat de sodiu - E 301" },
  { value: "Ascorbat de calciu", label: "Ascorbat de calciu - E 302" },
  {
    value: "Esteri ai acidului ascorbic cu acizi grași",
    label: "Esteri ai acidului ascorbic cu acizi grași - E 304",
  },
  {
    value: "Extract bogat în tocoferol",
    label: "Extract bogat în tocoferol - E 306",
  },
  { value: "Alfa-tocoferol", label: "Alfa-tocoferol - E 307" },
  { value: "Gama-tocoferol", label: "Gama-tocoferol - E 308" },
  { value: "Delta-tocoferol", label: "Delta-tocoferol - E 309" },
  { value: "Galat de propil", label: "Galat de propil - E 310" },
  { value: "Galat de octil", label: "Galat de octil - E 311" },
  { value: "Galat de dodecil", label: "Galat de dodecil - E 312" },
  { value: "Acid eritorbic", label: "Acid eritorbic - E 315" },
  { value: "Eritorbat de sodiu", label: "Eritorbat de sodiu - E 316" },
  {
    value: "Butilhidrochinonă terțiară (TBHQ)",
    label: "Butilhidrochinonă terțiară (TBHQ) - E 319",
  },
  {
    value: "Butilhidroxianisol (BHA)",
    label: "Butilhidroxianisol (BHA) - E 320",
  },
  {
    value: "Butilhidroxitoluen (BHT)",
    label: "Butilhidroxitoluen (BHT) - E 321",
  },
  { value: "Lecitine", label: "Lecitine - E 322" },
  { value: "Lactat de sodiu", label: "Lactat de sodiu - E 325" },
  { value: "Lactat de potasiu", label: "Lactat de potasiu - E 326" },
  { value: "Lactat de calciu", label: "Lactat de calciu - E 327" },
  { value: "Acid citric", label: "Acid citric - E 330" },
  { value: "Citrați de sodiu", label: "Citrați de sodiu - E 331" },
  { value: "Citrați de potasiu", label: "Citrați de potasiu - E 332" },
  { value: "Citrați de calciu", label: "Citrați de calciu - E 333" },
  { value: "Acid tartric", label: "Acid tartric - E 334" },
  { value: "Tartrați de sodiu", label: "Tartrați de sodiu - E 335" },
  { value: "Tartrați de potasiu", label: "Tartrați de potasiu - E 336" },
  {
    value: "Tartrat de sodiu și potasiu",
    label: "Tartrat de sodiu și potasiu - E 337",
  },
  { value: "Acid fosforic", label: "Acid fosforic - E 338" },
  { value: "Fosfați de sodiu", label: "Fosfați de sodiu - E 339" },
  { value: "Fosfați de potasiu", label: "Fosfați de potasiu - E 340" },
  { value: "Fosfați de calciu", label: "Fosfați de calciu - E 341" },
  { value: "Fosfați de magneziu", label: "Fosfați de magneziu - E 343" },
  { value: "Malați de sodiu", label: "Malați de sodiu - E 350" },
  { value: "Malat de potasiu", label: "Malat de potasiu - E 351" },
  { value: "Malați de calciu", label: "Malați de calciu - E 352" },
  { value: "Acid metatartric", label: "Acid metatartric - E 353" },
  { value: "Tartrat de calciu", label: "Tartrat de calciu - E 354" },
  { value: "Acid adipic", label: "Acid adipic - E 355" },
  { value: "Adipat de sodiu", label: "Adipat de sodiu - E 356" },
  { value: "Adipat de potasiu", label: "Adipat de potasiu - E 357" },
  { value: "Acid succinic", label: "Acid succinic - E 363" },
  { value: "Citrat triamonic", label: "Citrat triamonic - E 380" },
  {
    value: "Etilendiaminotetraacetat de calciu disodic (calciu disodic EDTA)",
    label:
      "Etilendiaminotetraacetat de calciu disodic (calciu disodic EDTA) - E 385",
  },
  { value: "Extracte de rozmarin", label: "Extracte de rozmarin - E 392" },
  { value: "Acid alginic", label: "Acid alginic - E 400" },
  { value: "Alginat de sodiu", label: "Alginat de sodiu - E 401" },
  { value: "Alginat de potasiu", label: "Alginat de potasiu - E 402" },
  { value: "Alginat de amoniu", label: "Alginat de amoniu - E 403" },
  { value: "Alginat de calciu", label: "Alginat de calciu - E 404" },
  {
    value: "Alginat de propan-1, 2-diol",
    label: "Alginat de propan-1, 2-diol - E 405",
  },
  { value: "Agar", label: "Agar - E 406" },
  {
    value: "Algă marină Eucheuma prelucrată",
    label: "Algă marină Eucheuma prelucrată - E 407a",
  },
  { value: "Caragenan", label: "Caragenan - E 407" },
  {
    value: "Gumă din semințe de carruba",
    label: "Gumă din semințe de carruba - E 410",
  },
  { value: "Gumă de guar", label: "Gumă de guar - E 412" },
  { value: "Tragacant", label: "Tragacant - E 413" },
  {
    value: "Gumă arabică (gumă de acacia)",
    label: "Gumă arabică (gumă de acacia) - E 414",
  },
  { value: "Gumă de xantan", label: "Gumă de xantan - E 415" },
  { value: "Gumă Karaya", label: "Gumă Karaya - E 416" },
  { value: "Gumă Tara", label: "Gumă Tara - E 417" },
  { value: "Gumă Gellan", label: "Gumă Gellan - E 418" },
  { value: "Glicerol", label: "Glicerol - E 422" },
  {
    value: "Gumă arabică modificată cu acid octenilsuccinic",
    label: "Gumă arabică modificată cu acid octenilsuccinic - E 423",
  },
  { value: "Konjac", label: "Konjac - E 425" },
  { value: "Hemiceluloză de soia", label: "Hemiceluloză de soia - E 426" },
  { value: "Guma cassia", label: "Guma cassia - E 427" },
  {
    value: "Stearat de polioxietilenă (40)",
    label: "Stearat de polioxietilenă (40) - E 431",
  },
  {
    value: "Monolaurat de polioxietilenă sorbitan (polisorbat 20)",
    label: "Monolaurat de polioxietilenă sorbitan (polisorbat 20) - E 432",
  },
  {
    value: "Monooleat de polioxietilenă sorbitan (polisorbat 80)",
    label: "Monooleat de polioxietilenă sorbitan (polisorbat 80) - E 433",
  },
  {
    value: "Monopalmitat de polioxietilenă sorbitan (polisorbat 40)",
    label: "Monopalmitat de polioxietilenă sorbitan (polisorbat 40) - E 434",
  },
  {
    value: "Monostearat de polioxietilenă sorbitan (polisorbat 60)",
    label: "Monostearat de polioxietilenă sorbitan (polisorbat 60) - E 435",
  },
  {
    value: "Tristearat de polioxietilenă sorbitan (polisorbat 65)",
    label: "Tristearat de polioxietilenă sorbitan (polisorbat 65) - E 436",
  },
  { value: "Pectine", label: "Pectine - E 440" },
  { value: "Fosfatide de amoniu", label: "Fosfatide de amoniu - E 442" },
  {
    value: "Acetat izobutirat de zaharoză",
    label: "Acetat izobutirat de zaharoză - E 444",
  },
  {
    value: "Esteri glicerici ai rășinilor din lemn",
    label: "Esteri glicerici ai rășinilor din lemn - E 445",
  },
  { value: "Difosfați", label: "Difosfați - E 450" },
  { value: "Trifosfați", label: "Trifosfați - E 451" },
  { value: "Polifosfați", label: "Polifosfați - E 452" },
  { value: "Beta-ciclodextrină", label: "Beta-ciclodextrină - E 459" },
  { value: "Celuloză", label: "Celuloză - E 460" },
  { value: "Metilceluloză", label: "Metilceluloză - E 461" },
  { value: "Etilceluloză", label: "Etilceluloză - E 462" },
  { value: "Hidroxipropilceluloză", label: "Hidroxipropilceluloză - E 463" },
  {
    value: "Hidroxipropilmetilceluloză",
    label: "Hidroxipropilmetilceluloză - E 464",
  },
  { value: "Etilmetilceluloză", label: "Etilmetilceluloză - E 465" },
  {
    value: "Carboximetilceluloză de sodiu, Gumă de celuloză",
    label: "Carboximetilceluloză de sodiu, Gumă de celuloză - E 466",
  },
  {
    value:
      "Carboximetilceluloză de sodiu reticulată, gumă de celuloză cu structură reticulată",
    label:
      "Carboximetilceluloză de sodiu reticulată, gumă de celuloză cu structură reticulată - E 468",
  },
  {
    value:
      "Carboximetilceluloză hidrolizată enzimatic, gumă de celuloză hidrolizată enzimatic",
    label:
      "Carboximetilceluloză hidrolizată enzimatic, gumă de celuloză hidrolizată enzimatic - E 469",
  },
  {
    value: "Săruri de sodiu, potasiu și calciu ale acizilor grași",
    label: "Săruri de sodiu, potasiu și calciu ale acizilor grași - E 470a",
  },
  {
    value: "Săruri de magneziu ale acizilor grași",
    label: "Săruri de magneziu ale acizilor grași - E 470b",
  },
  {
    value: "Mono- și digliceride ale acizilor grași",
    label: "Mono- și digliceride ale acizilor grași - E 471",
  },
  {
    value: "Esteri ai acidului acetic cu mono- și digliceridele acizilor grași",
    label:
      "Esteri ai acidului acetic cu mono- și digliceridele acizilor grași - E 472a",
  },
  {
    value: "Esteri ai acidului lactic cu mono- și digliceridele acizilor grași",
    label:
      "Esteri ai acidului lactic cu mono- și digliceridele acizilor grași - E 472b",
  },
  {
    value: "Esteri ai acidului citric cu mono- și digliceridele acizilor grași",
    label:
      "Esteri ai acidului citric cu mono- și digliceridele acizilor grași - E 472c",
  },
  {
    value:
      "Esteri ai acidului tartric cu mono- și digliceridele acizilor grași",
    label:
      "Esteri ai acidului tartric cu mono- și digliceridele acizilor grași - E 472d",
  },
  {
    value:
      "Esteri ai acizilor mono- și diacetiltartric cu mono- și digliceridele acizilor grași",
    label:
      "Esteri ai acizilor mono- și diacetiltartric cu mono- și digliceridele acizilor grași - E 472e",
  },
  {
    value:
      "Amestec de esteri ai acidului acetic și ai acidului tartric cu mono- și digliceridele acizilor grași",
    label:
      "Amestec de esteri ai acidului acetic și ai acidului tartric cu mono- și digliceridele acizilor grași - E 472f",
  },
  {
    value: "Esteri ai zaharozei cu acizi grași",
    label: "Esteri ai zaharozei cu acizi grași - E 473",
  },
  { value: "Zaharogliceride", label: "Zaharogliceride - E 474" },
  {
    value: "Esteri poliglicerici ai acizilor grași",
    label: "Esteri poliglicerici ai acizilor grași - E 475",
  },
  {
    value: "Poliricinoleat de poliglicerină",
    label: "Poliricinoleat de poliglicerină - E 476",
  },
  {
    value: "Esteri ai propan-1,2-diolului cu aciziir grași",
    label: "Esteri ai propan-1,2-diolului cu aciziir grași - E 477",
  },
  {
    value:
      "Ulei din semințe de soia oxidat termic interacționat cu mono- și diglicerideale acizilor grași",
    label:
      "Ulei din semințe de soia oxidat termic interacționat cu mono- și diglicerideale acizilor grași - E 479b",
  },
  {
    value: "Stearoil-2-lactilat de sodiu",
    label: "Stearoil-2-lactilat de sodiu - E 481",
  },
  {
    value: "Stearoil-2-lactilat de calciu",
    label: "Stearoil-2-lactilat de calciu - E 482",
  },
  { value: "Tartrat de stearil", label: "Tartrat de stearil - E 483" },
  {
    value: "Monostearat de sorbitan",
    label: "Monostearat de sorbitan - E 491",
  },
  { value: "Tristearat de sorbitan", label: "Tristearat de sorbitan - E 492" },
  { value: "Monolaurat de sorbitan", label: "Monolaurat de sorbitan - E 493" },
  { value: "Monooleat de sorbitan", label: "Monooleat de sorbitan - E 494" },
  {
    value: "Monopalmitat de sorbitan",
    label: "Monopalmitat de sorbitan - E 495",
  },
  {
    value: "Steroli vegetali bogați în stigmasterol",
    label: "Steroli vegetali bogați în stigmasterol - E 499",
  },
  { value: "Carbonați de sodiu", label: "Carbonați de sodiu - E 500" },
  { value: "Carbonați de potasiu", label: "Carbonați de potasiu - E 501" },
  { value: "Carbonați de amoniu", label: "Carbonați de amoniu - E 503" },
  { value: "Carbonați de magneziu", label: "Carbonați de magneziu - E 504" },
  { value: "Acid clorhidric", label: "Acid clorhidric - E 507" },
  { value: "Clorură de potasiu", label: "Clorură de potasiu - E 508" },
  { value: "Clorură de calciu", label: "Clorură de calciu - E 509" },
  { value: "Clorură de magneziu", label: "Clorură de magneziu - E 511" },
  { value: "Clorură stanoasă", label: "Clorură stanoasă - E 512" },
  { value: "Acid sulfuric", label: "Acid sulfuric - E 513" },
  { value: "Sulfați de sodiu", label: "Sulfați de sodiu - E 514" },
  { value: "Sulfați de potasiu", label: "Sulfați de potasiu - E 515" },
  { value: "Sulfat de calciu", label: "Sulfat de calciu - E 516" },
  { value: "Sulfat de amoniu", label: "Sulfat de amoniu - E 517" },
  { value: "Sulfat de aluminiu", label: "Sulfat de aluminiu - E 520" },
  {
    value: "Sulfat de aluminiu și sodiu",
    label: "Sulfat de aluminiu și sodiu - E 521",
  },
  {
    value: "Sulfat de aluminiu și potasiu",
    label: "Sulfat de aluminiu și potasiu - E 522",
  },
  {
    value: "Sulfat de aluminiu și amoniu",
    label: "Sulfat de aluminiu și amoniu - E 523",
  },
  { value: "Hidroxid de sodiu", label: "Hidroxid de sodiu - E 524" },
  { value: "Hidroxid de potasiu", label: "Hidroxid de potasiu - E 525" },
  { value: "Hidroxid de calciu", label: "Hidroxid de calciu - E 526" },
  { value: "Hidroxid de amoniu", label: "Hidroxid de amoniu - E 527" },
  { value: "Hidroxid de magneziu", label: "Hidroxid de magneziu - E 528" },
  { value: "Oxid de calciu", label: "Oxid de calciu - E 529" },
  { value: "Oxid de magneziu", label: "Oxid de magneziu - E 530" },
  { value: "Tartrat de fier", label: "Tartrat de fier - E 534" },
  { value: "Ferocianură de sodiu", label: "Ferocianură de sodiu - E 535" },
  { value: "Ferocianură de potasiu", label: "Ferocianură de potasiu - E 536" },
  { value: "Ferocianură de calciu", label: "Ferocianură de calciu - E 538" },
  {
    value: "Fosfat acid de aluminiu și sodiu",
    label: "Fosfat acid de aluminiu și sodiu - E 541",
  },
  { value: "Dioxid de siliciu", label: "Dioxid de siliciu - E 551" },
  { value: "Silicat de calciu", label: "Silicat de calciu - E 552" },
  { value: "Silicat de magneziu", label: "Silicat de magneziu - E 553a" },
  { value: "Talc", label: "Talc - E 553b" },
  {
    value: "Silicat de aluminiu și sodiu",
    label: "Silicat de aluminiu și sodiu - E 554",
  },
  {
    value: "Silicat de aluminiu și potasiu",
    label: "Silicat de aluminiu și potasiu - E 555",
  },
  {
    value: "Silicoaluminat de calciu",
    label: "Silicoaluminat de calciu - E 556",
  },
  { value: "Bentonită", label: "Bentonită - E 558" },
  {
    value: "Silicat de aluminiu (caolin)",
    label: "Silicat de aluminiu (caolin) - E 559",
  },
  { value: "Acizi grași", label: "Acizi grași - E 570" },
  { value: "Acid gluconic", label: "Acid gluconic - E 574" },
  { value: "Glucono-delta-lactonă", label: "Glucono-delta-lactonă - E 575" },
  { value: "Gluconat de sodiu", label: "Gluconat de sodiu - E 576" },
  { value: "Gluconat de potasiu", label: "Gluconat de potasiu - E 577" },
  { value: "Gluconat de calciu", label: "Gluconat de calciu - E 578" },
  { value: "Gluconat feros", label: "Gluconat feros - E 579" },
  { value: "Lactat feros", label: "Lactat feros - E 585" },
  { value: "4-hexilrezorcinol", label: "4-hexilrezorcinol - E 586" },
  { value: "Acid glutamic", label: "Acid glutamic - E 620" },
  { value: "Monoglutamat de sodiu", label: "Monoglutamat de sodiu - E 621" },
  {
    value: "Monoglutamat de potasiu",
    label: "Monoglutamat de potasiu - E 622",
  },
  { value: "Diglutamat de calciu", label: "Diglutamat de calciu - E 623" },
  { value: "Monoglutamat de amoniu", label: "Monoglutamat de amoniu - E 624" },
  { value: "Diglutamat de magneziu", label: "Diglutamat de magneziu - E 625" },
  { value: "Acid guanilic", label: "Acid guanilic - E 626" },
  { value: "Guanilat disodic", label: "Guanilat disodic - E 627" },
  { value: "Guanilat dipotasic", label: "Guanilat dipotasic - E 628" },
  { value: "Guanilat de calciu", label: "Guanilat de calciu - E 629" },
  { value: "Acid inozinic", label: "Acid inozinic - E 630" },
  { value: "Inozinat disodic", label: "Inozinat disodic - E 631" },
  { value: "Inozinat dipotasic", label: "Inozinat dipotasic - E 632" },
  { value: "Inozinat de calciu", label: "Inozinat de calciu - E 633" },
  {
    value: "5′-ribonucleotide de calciu",
    label: "5′-ribonucleotide de calciu - E 634",
  },
  {
    value: "5′-ribonucleotide disodice",
    label: "5′-ribonucleotide disodice - E 635",
  },
  {
    value: "Glicină și sarea sa de sodiu",
    label: "Glicină și sarea sa de sodiu - E 640",
  },
  { value: "L-leucină", label: "L-leucină - E 641" },
  { value: "Acetat de zinc", label: "Acetat de zinc - E 650" },
  { value: "Dimetilpolisiloxan", label: "Dimetilpolisiloxan - E 900" },
  {
    value: "Ceară de albine, albă și galbenă",
    label: "Ceară de albine, albă și galbenă - E 901",
  },
  { value: "Ceară de candelilla", label: "Ceară de candelilla - E 902" },
  { value: "Ceară de carnauba", label: "Ceară de carnauba - E 903" },
  { value: "Șelac", label: "Șelac - E 904" },
  { value: "Ceară microcristalină", label: "Ceară microcristalină - E 905" },
  {
    value: "Poli-1-decenă hidrogenată",
    label: "Poli-1-decenă hidrogenată - E 907",
  },
  {
    value: "Ceară polietilenică oxidată",
    label: "Ceară polietilenică oxidată - E 914",
  },
  { value: "L-cisteină", label: "L-cisteină - E 920" },
  { value: "Carbamidă", label: "Carbamidă - E 927b" },
  { value: "Argon", label: "Argon - E 938" },
  { value: "Heliu", label: "Heliu - E 939" },
  { value: "Azot", label: "Azot - E 941" },
  { value: "Protoxid de azot", label: "Protoxid de azot - E 942" },
  { value: "Butan", label: "Butan - E 943a" },
  { value: "Izobutan", label: "Izobutan - E 943b" },
  { value: "Propan", label: "Propan - E 944" },
  { value: "Oxigen", label: "Oxigen - E 948" },
  { value: "Hidrogen", label: "Hidrogen - E 949" },
  { value: "Extract de Quillaia", label: "Extract de Quillaia - E 999" },
  { value: "Invertază", label: "Invertază - E 1103" },
  { value: "Lizozim", label: "Lizozim - E 1105" },
  { value: "Polidextroză", label: "Polidextroză - E 1200" },
  { value: "Polivinilpirolidonă", label: "Polivinilpirolidonă - E 1201" },
  {
    value: "Polivinilpolipirolidonă",
    label: "Polivinilpolipirolidonă - E 1202",
  },
  {
    value: "Alcool polivinilic (PVA)",
    label: "Alcool polivinilic (PVA) - E 1203",
  },
  { value: "Pululan", label: "Pululan - E 1204" },
  {
    value: "Copolimer metacrilat bazic",
    label: "Copolimer metacrilat bazic - E 1205",
  },
  {
    value: "Copolimer metacrilat neutru",
    label: "Copolimer metacrilat neutru - E 1206",
  },
  {
    value: "Copolimer metacrilat anionic",
    label: "Copolimer metacrilat anionic - E 1207",
  },
  {
    value: "Copolimer polivinilpirolidonă-vinil acetat",
    label: "Copolimer polivinilpirolidonă-vinil acetat - E 1208",
  },
  {
    value: "Copolimer grefat de alcool polivinilic și polietilenglicol",
    label:
      "Copolimer grefat de alcool polivinilic și polietilenglicol - E 1209",
  },
  { value: "Amidon oxidat", label: "Amidon oxidat - E 1404" },
  { value: "Fosfat de monoamidon", label: "Fosfat de monoamidon - E 1410" },
  { value: "Fosfat de diamidon", label: "Fosfat de diamidon - E 1412" },
  {
    value: "Fosfat de diamidon fosfatat",
    label: "Fosfat de diamidon fosfatat - E 1413",
  },
  {
    value: "Fosfat de diamidon acetilat",
    label: "Fosfat de diamidon acetilat - E 1414",
  },
  { value: "Amidon acetilat", label: "Amidon acetilat - E 1420" },
  {
    value: "Adipat de diamidon acetilat",
    label: "Adipat de diamidon acetilat - E 1422",
  },
  { value: "Amidon hidroxipropilic", label: "Amidon hidroxipropilic - E 1440" },
  {
    value: "Fosfat de diamidon hidroxipropilat",
    label: "Fosfat de diamidon hidroxipropilat - E 1442",
  },
  {
    value: "Octenil succinat de amidon și sodiu",
    label: "Octenil succinat de amidon și sodiu - E 1450",
  },
  { value: "Amidon oxidat acetilat", label: "Amidon oxidat acetilat - E 1451" },
  {
    value: "Octenilsuccinat de amidon și aluminiu",
    label: "Octenilsuccinat de amidon și aluminiu - E 1452",
  },
  { value: "Trietil citrat", label: "Trietil citrat - E 1505" },
  {
    value: "Diacetat de glicerină (diacetină)",
    label: "Diacetat de glicerină (diacetină) - E 1517",
  },
  {
    value: "Triacetat de glicerină (triacetină)",
    label: "Triacetat de glicerină (triacetină) - E 1518",
  },
  { value: "Alcool benzilic", label: "Alcool benzilic - E 1519" },
  {
    value: "Propan-1,2-diol (propilenglicol)",
    label: "Propan-1,2-diol (propilenglicol) - E 1520",
  },
  { value: "Polietilenglicol", label: "Polietilenglicol - E 1521" },
];
