import React from 'react'
import { Box, Breadcrumbs, Button, InputAdornment, Link, Stack, TextField, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CustonDataGrid from "../../component/CustomDataGrid";
import refresh from '../../assets/img/refresh-icon.png'
import rightarrow from '../../assets/img/view-right-arrow.png';
import Switch from '@mui/material/Switch';
import './style.css'
import { useTranslation } from 'react-i18next';



const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const ViewDetails = () => {
    const { t } = useTranslation();
    const columns = [
        { field: 'id', headerName: t('ID'), flex: 1, minWidth: 100, headerAlign: "center", align: 'center' },
        { field: 'Restaurant_Name', headerName: t('Restaurant_Name'), flex: 3, minWidth: 200, headerAlign: "center", align: 'center' },
        { field: 'Address', headerName: t('Address'), flex: 3, minWidth: 250, headerAlign: "center", align: 'center' },
        { field: 'Phone', headerName: t('Phone'), flex: 2, minWidth: 200, headerAlign: "center", align: 'center' },
        { field: 'Email', headerName: t('Email'), flex: 2, minWidth: 200, headerAlign: "center", align: 'center' },
        { field: 'Delivery_commission', headerName: t('Delivery_commission'), flex: 2, minWidth: 200, headerAlign: "center", align: 'center' },
        { field: 'Pick_up_commission', headerName: t('Pick_up_commission'), flex: 2, minWidth: 200, headerAlign: "center", align: 'center' },
        { field: 'Revenue', headerName: t('Revenue'), flex: 2, headerAlign: "center", minWidth: 200, align: 'center' },
        {
            field: 'Status', headerName: t('Status'), headerAlign: "center", minWidth: 150, align: 'center',
            renderCell: () => (
                <Switch {...label} defaultChecked />
            ),
        },
        {
            field: 'Action', headerName: t('Action'), flex: 4, minWidth: 220, headerAlign: "center", align: 'center',
            renderCell: () => (
                <Button className="view-detail-btn">
                    {t('View_Analytics')}
                    <img src={rightarrow} />
                </Button>
            ),
        },
    ];

    const rows = [
        {
            id: 1, Restaurant_Name: 'Wok & roll', Address: '110 Courtneypark Dr E, Mississauga, ON L5T 2Y3', Phone: '+1 (467)433 8998', UserName: 'Doe', Password: '*******', Email: "woks@gmail.com", Delivery_commission: '10%', Pick_up_commission: '20%', Revenue: '$100222.34'
        },
        {
            id: 2, Restaurant_Name: 'Wok & roll', Address: '110 Courtneypark Dr E, Mississauga, ON L5T 2Y3', Phone: '+1 (467)433 8998', UserName: 'Doe', Password: '*******', Email: "woks@gmail.com", Delivery_commission: '10%', Pick_up_commission: '20%', Revenue: '$100222.34'
        },
        {
            id: 3, Restaurant_Name: 'Wok & roll', Address: '110 Courtneypark Dr E, Mississauga, ON L5T 2Y3', Phone: '+1 (467)433 8998', UserName: 'Doe', Password: '*******', Email: "woks@gmail.com", Delivery_commission: '10%', Pick_up_commission: '20%', Revenue: '$100222.34'
        },
        {
            id: 4, Restaurant_Name: 'Wok & roll', Address: '110 Courtneypark Dr E, Mississauga, ON L5T 2Y3', Phone: '+1 (143467)433 8998', UserName: 'Doe', Password: '*******', Email: "woks@gmail.com", Delivery_commission: '10%', Pick_up_commission: '20%', Revenue: '$100222.34'
        },
        {
            id: 5, Restaurant_Name: 'Wok & roll', Address: '110 Courtneypark Dr E, Mississauga, ON L5T 2Y3', Phone: '+1 (467)433 8998', UserName: 'Doe', Password: '*******', Email: "woks@gmail.com", Delivery_commission: '10%', Pick_up_commission: '20%', Revenue: '$100222.34'
        },
    ];

    const handleClick = (event) => {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    const breadcrumbs = [
        <Link underline="hover" key="1" color="#FF7A00" href="/" onClick={handleClick}>
            {t("registered_res")}
        </Link>,
        <Typography key="2" color="#000">
            {t("blocked_res")}
        </Typography>,
    ];

    return (
        <>
            <Box className="partner-title">
                <Stack spacing={2}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>
            </Box>
            <Box className="partner-detail">
                <Box className="search-box">
                    <TextField id="outlined-basic" variant="outlined" placeholder={t("Search")}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" sx={{ color: "#000" }}>
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }} />
                </Box>
                <Box>
                    <Button className="refresh-btn"><img src={refresh} />{t("refresh_button")}</Button>
                    <Button className="add-partner">{t("add_partner_btn")}</Button>
                </Box>
            </Box>
            <CustonDataGrid
                columns={columns}
                rows={rows}
                height={300}
            />
        </>
    );
}
