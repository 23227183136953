import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en_lang from "./translations/en.json";
import es_lang from "./translations/es.json";
import ro_lang from "./translations/ro.json";
import hu_lang from "./translations/hu.json";
import be_lang from "./translations/be.json";
import de_lang from "./translations/de.json";

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: en_lang,
    },
    es: {
      translation: es_lang,
    },
    ro: {
      translation: ro_lang,
    },
    hu: {
      translation: hu_lang,
    },
    be: {
      translation: be_lang,
    },
    de: {
      translation: de_lang,
    },
  },
});

export default i18n;
