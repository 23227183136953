import {
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGOUT_SUCCESS,
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAILED,
} from "../Actions/AuthActions";

const AuthState = {
  loading: false,
  currentAction: "INIT",
  token: "",
  success: false,
  message: "",
  login_id: "",
};

export const AuthReducer = (state = AuthState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        currentAction: action.type,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        currentAction: action.type,
        token: action.payload.token,
        message: action.payload.message,
        login_id: action.payload.id
      };

    case LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case ADMIN_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        currentAction: action.type,
      };

    case ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        currentAction: action.type,
        token: action.payload.token,
        message: action.payload.message,
      };

    case ADMIN_LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };

    case LOGOUT_SUCCESS:
      localStorage.clear();
      return {
        ...state,
        token: "",
        currentAction: action.type,
        loading: false,
      };

    default:
      return state;
  }
};
