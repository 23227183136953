import React from "react";
import { Box, Button, InputAdornment, Switch, TextField, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CustonDataGrid from "../../component/CustomDataGrid";
import refresh from '../../assets/img/refresh-icon.png'
import './style.css'
import { useTranslation } from "react-i18next";
import DefaultProfile from '../../assets/img/default-profile-pic.png'


const Users = () => {
  const { t } = useTranslation();

  const columns = [
    { field: 'id', headerName: t("col_id"), flex: 1, minWidth: 20, headerAlign: "center", align: 'center' },
    { field: 'image', headerName: 'Image', minWidth: 100, flex: 1, headerAlign: "center", align: 'center',renderCell: ({row}) => (
        <img className='rounded-full' src={row?.image ?? DefaultProfile} width={50} height={50} />
      
      )
    },
    { field: 'UserName', headerName: 'Name', minWidth: 170, flex: 1, headerAlign: "center", align: 'center',renderCell: ({row}) => (
      <div className="whitespace-normal text-center">
        {row.UserName}
      </div>
      )
    },
    { field: 'phone', headerName: 'Phone', minWidth: 150, flex: 1, headerAlign: "center", align: 'center' },
    { field: 'Email', headerName: t('Email'), minWidth: 150, flex: 1, headerAlign: "center", align: 'center' },
    { field: 'otp', headerName: 'OTP Verified', minWidth: 120, flex: 1, headerAlign: "center", align: 'center', renderCell:(({row}) => (
      <div className="font-semibold">
        {row.otp ? 'Yes' : 'No'}
      </div>
      )) 
    },
    { field: 'status', headerName: 'Status', minWidth: 100, flex: 1, headerAlign: "center", align: 'center', renderCell:(({row}) => (
        <Switch checked={row.status} color="warning" />
      )) 
    },
    { field: 'join', headerName: 'Joined On', minWidth: 80, flex: 1, headerAlign: "center", align: 'center' },
    {
      field: 'Action', headerName: t('Action'), minWidth: 150, flex: 2, headerAlign: "center", align: 'center',
      renderCell: (params) => (
        <Button className="view-detail-btn">
          {t("edit_detail")}
        </Button>
      ),
    },
  ];

  const rows = [
    {
      id: 1, UserName: 'Jonathan Zoe  Joe Doe', Password: '**********', Email: "wosjdksaalks@gmail.com", No_of_locations: '10', party: 'Ebriza', phone: '+1 (467)433 8998', otp: false, join: 'May 11, 2023', status: true
    },
    {
      id: 2, UserName: 'Doe', Password: '**********', Email: "woks@gmail.com", No_of_locations: '10', party: 'Ebriza', phone: '+1 (467)433 8998', otp: false, join: 'May 11, 2023', status: true
    },
    {
      id: 3, UserName: 'Doe', Password: '**********', Email: "woks@gmail.com", No_of_locations: '10', party: 'Ebriza', phone: '+1 (467)433 8998', otp: true, join: 'May 11, 2023', status: true
    },
  ];

  return (
    <>
      <Box className="partner-title">
        <Typography variant="h2">{t("Users")}</Typography>
      </Box>
      <Box className="partner-detail">
        <Box className="search-box">
          <TextField id="outlined-basic" variant="outlined" placeholder={t("Search")} InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ color: "#000" }}>
                <SearchIcon />
              </InputAdornment>
            )
          }} />
        </Box>
        <Box>
          <Button className="refresh-btn"><img src={refresh} />{t("refresh_button")}</Button>
        </Box>
      </Box>
      <CustonDataGrid
        columns={columns}
        rows={rows}
        height={300}
        rowHeight={70}
        disableRowSelectionOnClick
        getRowClassName={(param) => {
          if(param.indexRelativeToCurrentPage % 2 !== 0){
              return 'bg-secondaryColor'
          }
      }}
      />
    </>
  );
};
export default Users;
