import React from 'react'
import orderimg from '../assets/img/order-item.png'

const FoodList = ({foodPic, foodName, total, price, unitPrice = '$'}) => {
  return (
    <div className='flex w-full bg-white rounded-xl p-3 drop-shadow items-center space-x-8 mb-5'>
        <img src={foodPic ?? orderimg} />
        <div>
            <div className='font-semibold mb-3'>{`${foodName} x${total}`}</div>
            <div className='font-semibold'>{`${unitPrice}${price}`}</div>
        </div>
    </div>
  )
}

export default FoodList