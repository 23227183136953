import React, { useState } from 'react'
import QRGenerator from '../Locations/LocationDetails/QRGenerator'
import { Box, Button, FormControl, MenuItem, Select } from '@mui/material'
import QRCode from "react-qr-code";
import CustomButton from '../../component/CustomButton';
import { toJpeg } from 'html-to-image';



const index = () => {
    const [color, setColor] = useState(10);
    const [frame, setFrame] = useState(10);
    const [selectedTab, setSelectedTab] = useState('url')
    const [url, setUrl] = useState()
    const [text, setText] = useState()
    const [userName, setUserName] = useState()
    const [bgQR, setBgQR] = useState('#ffffff')
    const [QRcolor, setQRcolor] = useState("#000000")
    const [QRValue, setQRValue] = useState('')
    const [emailData, setEmailData] = useState({
        email: '',
        subject: '',
        message:''
    })
    const [smsData, setSmsData] = useState({
        number: '',
        message: ''
    })
    const [wifiData, setWifiData] = useState({
        ssid: '',
        password: ''
    })
    const [twitterData, setTwitterData] = useState('')

    const handleSelectTab = (e) => {
        console.log('e', e)
        setSelectedTab(e.target.id)
    }

    const handleGenerateQR = () => {
        switch (selectedTab) {
            case 'url':
                setQRValue(url)
                break;
            case 'text':
                setQRValue(text)
                break;
            case 'email':
                setQRValue(`mailto:${encodeURIComponent(emailData.email)}?subject=${encodeURIComponent(emailData.subject)}&body=${encodeURIComponent(emailData.message)}`)
                break;
            case 'twitter':
                setQRValue(`https://twitter.com/${twitterData}`)
                break;
            case 'sms':
                setQRValue(`window.open(sms:${smsData.number}?body=${smsData.message}})`)
            default:
                break;
        }
    }

    const handleDownloadSVG = () => {
        const svgElement = document.getElementById('QRcode');
        if (svgElement) {
          const svgContent = new XMLSerializer().serializeToString(svgElement);
          const blob = new Blob([svgContent], { type: 'image/svg+xml' });
          const url = URL.createObjectURL(blob);
    
          const a = document.createElement('a');
          a.href = url;
          a.download = 'QRcode.svg';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        } else {
          console.error('Not found SVG with ID "QRcode".');
        }
      };

      const handleDownloadJPG = async () => {
        const svgElement = document.getElementById('QRcode');
        if (svgElement) {
          try {
            const dataUrl = await toJpeg(svgElement, { quality: 0.95 });
            const a = document.createElement('a');
            a.href = dataUrl;
            a.download = 'QRcode.jpg';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } catch (error) {
            console.error('Convertion from SVG to JPG failed :', error);
          }
        } else {
          console.error('Not found SVG with ID "QRcode".');
        }
      };
    
  return (
    <div>
        <div className='text-xl font-semibold'>QR Code Generator</div>
        <div className='mt-10 lg:flex lg:space-x-4'>
            <Box className='qr-code-generator'>
                <Box className='border border-[#D9D9D9] rounded-xl p-3'>
                    {/* <img src={qrcode} /> */}
                    <QRCode
                        id='QRcode'
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={QRValue}
                        fgColor={bgQR}
                        bgColor={QRcolor}
                        // value={'https://m.media-amazon.com/images/M/MV5BN2IzYzBiOTQtNGZmMi00NDI5LTgxMzMtN2EzZjA1NjhlOGMxXkEyXkFqcGdeQXVyNjAwNDUxODI@._V1_FMjpg_UX1000_.jpg'}
                        viewBox={`0 0 256 256`}
                    />
                    <FormControl fullWidth className="input-field-title" sx={{
                        marginBottom: '15px',
                        marginTop:'15px'
                    }}>
                        <div>Background</div>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={bgQR}
                            label="frame"
                            onChange={(e) => setBgQR(e.target.value)}
                        >
                            <MenuItem value={"#ffffff"}>White</MenuItem>
                            <MenuItem value={"#04e22d"}>Green</MenuItem>
                            <MenuItem value={"#e5e502"}>Yellow</MenuItem>
                            <MenuItem value={"#c50105"}>Red</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth className="input-field-title">
                        <div>Color</div>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={QRcolor}
                            label="color"
                            onChange={(e) => setQRcolor(e.target.value)}
                        >
                            <MenuItem value={"#000000"}>Black</MenuItem>
                            <MenuItem value={"#0cd32f"}>Green</MenuItem>
                            <MenuItem value={"#f5f60a"}>Yellow</MenuItem>
                            <MenuItem value={"#e21519"}>Red</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <Button onClick={handleDownloadJPG} className="download-btn">Download <span>jpg</span></Button>
                    <Button onClick={handleDownloadSVG} className="vector-btn">Vector <span>SVG/EPS</span></Button>
                </Box>
            </Box>
            <div className='lg:w-full border border-[#D9D9D9] rounded-xl p-6 lg:h-auto'>
                <div className='grid grid-cols-3 gap-3'>
                    <div onClick={handleSelectTab} id={'url'} className={`cursor-pointer text-center font-semibold py-2 px-6 rounded-lg drop-shadow ${selectedTab == 'url' ? 'bg-primaryColor' : 'bg-white'}`}>URL</div>
                    <div onClick={handleSelectTab} id={'text'} className={`cursor-pointer text-center font-semibold py-2 px-6 rounded-lg drop-shadow ${selectedTab == 'text' ? 'bg-primaryColor' : 'bg-white'}`}>Text</div>
                    <div onClick={handleSelectTab} id={'email'} className={` text-center cursor-pointer font-semibold py-2 px-2 lg:px-6 rounded-lg drop-shadow ${selectedTab == 'email' ? 'bg-primaryColor' : 'bg-white'}`}>E-mail</div>
                    <div onClick={handleSelectTab} id={'sms'} className={`cursor-pointer text-center font-semibold py-2 px-6 rounded-lg drop-shadow ${selectedTab == 'sms' ? 'bg-primaryColor' : 'bg-white'}`}>SMS</div>
                    <div onClick={handleSelectTab} id={'wifi'} className={`cursor-pointer text-center font-semibold py-2 px-6 rounded-lg drop-shadow ${selectedTab == 'wifi' ? 'bg-primaryColor' : 'bg-white'}`}>WIFI</div>
                    <div onClick={handleSelectTab} id={'twitter'} className={`cursor-pointer text-center font-semibold py-2 px-2 lg:px-6 rounded-lg drop-shadow ${selectedTab == 'twitter' ? 'bg-primaryColor' : 'bg-white'}`}>Twitter</div>
                </div>
                <div className='mt-10 lg:mt-20 flex flex-col justify-between h-80 lg:h-2/4'>
                    {selectedTab == 'url' && <input name='url' placeholder='Enter your website' value={url} onChange={(e) => setUrl(e.target.value)} className='h-10 p-3 rounded-lg drop-shadow-sm w-full ' />}
                    {selectedTab == 'text' && <input name='text' placeholder='Enter your text' value={text} onChange={(e) => setText(e.target.value)} className='h-10 p-3 rounded-lg drop-shadow-sm w-full ' />}
                    {selectedTab == 'email' && (
                        <div className='space-y-3'>
                            <div>
                                <div className=' font-semibold'>
                                    Email
                                </div>
                                <input value={emailData.email} onChange={(e) => setEmailData(prev => ({...prev, email: e.target.value}))} placeholder='Your email' name='email' className='h-10 p-3 rounded-lg drop-shadow-sm w-full lg:w-2/3 ' />
                            </div>
                            <div>
                                <div className=' font-semibold'>
                                    Subject
                                </div>
                                <input value={emailData.subject} onChange={(e) => setEmailData(prev => ({...prev, subject: e.target.value}))} placeholder='Enter email subject' name='email' className='h-10 p-3 rounded-lg drop-shadow-sm w-full lg:w-2/3 ' />
                            </div>
                            <div>
                                <div className=' font-semibold'>
                                    Message
                                </div>
                                <textarea value={emailData.message} onChange={(e) => setEmailData(prev => ({...prev, message: e.target.value}))} placeholder='Enter your message' style={{resize: 'none'}} rows="4" className="block p-2.5 w-full lg:w-2/3 text-sm text-gray-900 bg-white rounded-lg drop-shadow"></textarea>
                            </div>
                        </div>
                    )}
                    {selectedTab == 'sms' && (
                        <div className='space-y-3'>
                            <div>
                                <div className=' font-semibold'>
                                    Number
                                </div>
                                <input placeholder='Your phone number' type='number' name='email' className='h-10 p-3 rounded-lg drop-shadow-sm w-2/3 ' />
                            </div>
                            <div>
                                <div className=' font-semibold'>
                                    Message
                                </div>
                                <textarea placeholder='Enter your message' style={{resize: 'none'}} rows="4" className="block p-2.5 w-2/3 text-sm text-gray-900 bg-white rounded-lg drop-shadow"></textarea>
                            </div>
                        </div>
                    )}
                    {selectedTab == 'wifi' && (
                        <div className='space-y-3'>
                            <div>
                                <div className=' font-semibold'>
                                    Network Name
                                </div>
                                <input placeholder='SSID' name='wifi' className='h-10 p-3 rounded-lg drop-shadow-sm w-2/3 ' />
                            </div>
                            <div>
                                <div className=' font-semibold'>
                                    Password
                                </div>
                                <input name='wifiPass' type='password' className='h-10 p-3 rounded-lg drop-shadow-sm w-2/3 ' />
                            </div>
                        </div>
                    )}
                    {selectedTab == 'twitter' && (
                        <div>
                            <div className=' font-semibold'>
                                Your Username
                            </div>
                            <input placeholder='userName' name='username' value={userName} onChange={(e) => setUserName(e.target.value)} className='h-10 p-3 rounded-lg drop-shadow-sm w-2/3 ' />
                    </div>
                    )}
                    {selectedTab && <CustomButton onClickButton={handleGenerateQR} label={'Generate QR Code'} className={'mt-5'}/>}
                </div>
            </div>
        </div>
    </div>
  )
}

export default index