import SearchBox from "./SearchBox";
import ReportList from "./ReportList";
import Details from "./details";
import * as React from 'react';
const headings = [
  "Sales", "Tips", "Order List"
];
export default function Reports() {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  
  const [filteredItems, setFilteredItems] = React.useState([
    "Total Earning/Income",
    "Total tips",
    "Orders list",
  ]);



  const handleSearchChange = (term) => {
    const items = [
      "Total Earning/Income",
      "Total tips",
      "Orders list",
    ];

    const filtered = items.filter((item) =>
      item.toLowerCase().includes(term.toLowerCase())
    );

    setFilteredItems(filtered);
  };
  return (
    <>
      <div className="mt-3 h-screen flex flex-col lg:flex-row gap-5">
        {/* First div takes up 30% of the screen width */}
        <div className="flex-none w-full lg:w-1/3 border rounded-xl" style={{padding:'16px'}}>
        <SearchBox items={filteredItems} onSearchChange={handleSearchChange} />
      <ReportList
        items={filteredItems}
        selectedIndex={selectedIndex}
        handleListItemClick={handleListItemClick}
      />
        </div>

        {/* Second div takes up the remaining 70% of the screen width */}
        <div className="flex-1 w-full lg:w-2/3 border rounded-xl" style={{padding:'16px'}}>
          <Details heading={headings[selectedIndex]} selectedIndex={selectedIndex} />
        </div>
      </div>

    </>
  );
}
